import { Component, Input } from '@angular/core';
import { AssetCashForm, AssetOtherForm, AssetPropertyForm, AssetVehicleForm, ExpenseDetailForm, Step5AssetsForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ApplicationExpenseType } from '../../../../api/models/CommonUtilities/application-expense-type';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { startWith, pairwise } from 'rxjs';
import { PeriodTypeEnum } from '../../../../api/models/Models/Application/FinancialDetails/period-type-enum';
import { IncomeType } from '../../../../api/models/Models/v3/Application/FinancialDetails/income-type';
import { conditionalRequiredValidator, numericRangeValidator } from '../../../../types/validators';
import { AssetCashInvestmentRequestCashInvestmentType } from '../../../../api/models/Models/v3/Application/AssetDetails/asset-cash-investment-request-cash-investment-type';
import { VehicleClass } from '../../../../api/models/CommonUtilities/vehicle-class';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { LivingSituationEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { Constants } from '../../../../dataobject/constants';

@Component({
  selector: 'app-assets',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    MatIconModule,
  ],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss'
})
export class AssetsComponent extends BaseStepComponent {

  @Input() fg!: FormGroup<Step5AssetsForm>;
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  UserAction = UserAction;
  SelectListType = SelectListType;
  
  individualHeaderDisplay: string = '';
  
  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }
  
  override ngOnInit(): void {
    super.ngOnInit();
    
    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);      
    }));

     // Add the disabled Property asset if the user is a homeowner
     this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.valueChanges
     .pipe(
       startWith(this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value), // Emit the initial value
       pairwise() // Emit the previous and current values as an array
     )
     .subscribe(([oldValue, newValue]) => {
      console.log('assets.component.ts -livingsituation', newValue, oldValue);
      let propertyIndex = this.fg.controls.propertyAssets.controls.findIndex(x => x.controls.description.disabled);

      if (newValue && newValue != oldValue) {
        
         let propertyIndex = this.fg.controls.propertyAssets.controls.findIndex(x => x.controls.description.disabled);
         
         if (newValue === LivingSituationEnum.HomeownerWithoutMortgage || newValue === LivingSituationEnum.Mortgaged) {
           if (propertyIndex === -1) {
             this.addProperty(this.parentFg.controls.step1.controls.addressPhysical.controls.caption.value);
             propertyIndex = this.fg.controls.propertyAssets.controls.length - 1;
           }            
           this.fg.controls.propertyAssets.controls[propertyIndex].controls.description.setValue(this.parentFg.controls.step1.controls.addressPhysical.controls.caption.value)
           this.fg.controls.isPropertyOwner.disable();           
           this.fg.controls.isPropertyOwner.setValue(UserAction.Yes);

           this.parentFg.controls.step1.controls.addressPhysical.valueChanges.subscribe((value) => {
            this.fg.controls.propertyAssets.controls[propertyIndex].controls.description.setValue(this.parentFg.controls.step1.controls.addressPhysical.controls.caption.value);
           });

         } else {
           if (propertyIndex !== -1) {
             this.removeProperty(propertyIndex);
             this.fg.controls.isPropertyOwner.enable();
           }
         }
       }else if (!newValue){
          if (propertyIndex !== -1) {
             this.removeProperty(propertyIndex);
             this.fg.controls.isPropertyOwner.enable();
             this.fg.controls.isPropertyOwner.setValue(null);
           }
       }
     });

    this.fg.controls.isPropertyOwner.valueChanges
      .pipe(
        startWith(this.fg.controls.isPropertyOwner.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.propertyAssets.clear();
          } else {
            if(this.fg.controls.propertyAssets.length==0){
              this.addProperty();
            }
          }
        }
      });

      this.fg.controls.hasAdditionalCashInvestments.valueChanges
      .pipe(
        startWith(this.fg.controls.isPropertyOwner.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.cashInvestmentAssets.clear();
          } else {
            this.addCashInvestmentAsset();
          }
        }
      });

      this.fg.controls.hasAdditionalVehicleAssets.valueChanges
      .pipe(
        startWith(this.fg.controls.isPropertyOwner.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.vehicleAssets.clear();
          } else {
            this.addVehicle();
          }
        }
      });

      this.fg.controls.hasOtherAssets.valueChanges
      .pipe(
        startWith(this.fg.controls.isPropertyOwner.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.otherAssets.clear();
          } else {
            this.addOther();
          }
        }
      });

  }

  removeProperty(index: number): void {
    this.fg.controls.propertyAssets.removeAt(index);
    if(index == 0){
      this.fg.controls.isPropertyOwner.setValue(UserAction.No);
    }
  }

  addProperty(description: string | null = null): void {
    this.fg.controls.propertyAssets.push(this.createPropertyAssetGroup(description));
  }

  createPropertyAssetGroup(description: string | null): FormGroup<AssetPropertyForm> {
    var fg = new FormGroup<AssetPropertyForm>({
        value: new FormControl<number | null>(null, Validators.compose([Validators.required, numericRangeValidator(1, Constants.Validator_MaxAssetValue)])),
        description: new FormControl<string | null>({ value: description, disabled: description != null }, Validators.required),
    } as AssetPropertyForm);

    return fg;
  }


  removeCashInvestmentAsset(index: number): void {
    this.fg.controls.cashInvestmentAssets.removeAt(index);
    if(index == 0){
      this.fg.controls.hasAdditionalCashInvestments.setValue(UserAction.No);
    }
  }

  addCashInvestmentAsset(): void {
    this.fg.controls.cashInvestmentAssets.push(this.createCashInvestmentAssetGroup());
  }

  createCashInvestmentAssetGroup(): FormGroup<AssetCashForm> {
    var fg = new FormGroup<AssetCashForm>({
        value: new FormControl<number | null>(null, Validators.compose([Validators.required, numericRangeValidator(1, Constants.Validator_MaxAssetValue)])),
        type: new FormControl<AssetCashInvestmentRequestCashInvestmentType | null>(null, Validators.required),
    } as AssetCashForm);

    return fg;
  }


  removeVehicle(index: number): void {
    this.fg.controls.vehicleAssets.removeAt(index);
    if(index == 0){
      this.fg.controls.hasAdditionalVehicleAssets.setValue(UserAction.No);
    }
  }

  addVehicle(): void {
    this.fg.controls.vehicleAssets.push(this.createVehicleAssetGroup());
  }

  createVehicleAssetGroup(): FormGroup<AssetVehicleForm> {
    var fg = new FormGroup<AssetVehicleForm>({
        value: new FormControl<number | null>(null, Validators.required),
        description: new FormControl<string | null>(null, Validators.required),
        vehicleClass: new FormControl<VehicleClass | null>(null, Validators.required),
        plateVin: new FormControl<string | null>(null, Validators.required),
        useAsLoanSecurity: new FormControl<boolean | null>(null),
    } as AssetVehicleForm);

    return fg;
  }

  removeOther(index: number): void {
    this.fg.controls.otherAssets.removeAt(index);
    if(index == 0){
      this.fg.controls.hasOtherAssets.setValue(UserAction.No);
    }
  }

  addOther(): void {
    this.fg.controls.otherAssets.push(this.createOtherAssetGroup());
  }

  createOtherAssetGroup(): FormGroup<AssetOtherForm> {
    var fg = new FormGroup<AssetOtherForm>({
        value: new FormControl<number | null>(null, Validators.compose([Validators.required, numericRangeValidator(1, Constants.Validator_MaxAssetValue)])),
        description: new FormControl<string | null>(null, Validators.required),
    } as AssetOtherForm);

    return fg;
  }

  

}
