<mat-horizontal-stepper #individualStepper [linear]="true" [animationDuration]="vehicleAppService.stepperDuration">

    <mat-step [stepControl]="individualFormGroup.controls.step1">
        <app-personal-detail [fg]="individualFormGroup.controls.step1" [parentStepper]="individualStepper"
            [parentFg]="individualFormGroup" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-personal-detail>
    </mat-step>

    <mat-step [stepControl]="individualFormGroup.controls.step2">
        <app-personal-identification [fg]="individualFormGroup.controls.step2" [parentStepper]="individualStepper"
            [parentFg]="individualFormGroup" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-personal-identification>
    </mat-step>

    <mat-step [stepControl]="individualFormGroup.controls.step3">
        <app-employment-detail [fg]="individualFormGroup.controls.step3" [parentStepper]="individualStepper"
            [parentFg]="individualFormGroup" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-employment-detail>
    </mat-step>

    <mat-step [stepControl]="individualFormGroup.controls.step4">
        <app-living-expenses [fg]="individualFormGroup.controls.step4" [parentStepper]="individualStepper"
            [parentFg]="individualFormGroup" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-living-expenses>
    </mat-step>

    <mat-step [stepControl]="individualFormGroup.controls.step5">
        <app-assets [fg]="individualFormGroup.controls.step5" [parentStepper]="individualStepper"
            [parentFg]="individualFormGroup" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-assets>
    </mat-step>
    
    <mat-step [stepControl]="individualFormGroup.controls.liabilitiesFg" *ngIf="showExpenses">
        <app-liabilities [parentStepper]="individualStepper" [parentFg]="individualFormGroup"
            (onButtonNextEvent)="onButtonNextCompleted($event)"></app-liabilities>
    </mat-step>

    <!-- <mat-step [stepControl]="$any(this.individualFormGroup.get('step3'))">
        <form [formGroup]="$any(this.individualFormGroup.get('step3'))" #form="ngForm">
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstNameFormCtrl2" required />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastNameFormCtrl2" required />
            </mat-form-field>

            <div>
                <button mat-flat-button (click)="finalizeStep()">Next</button>
            </div>
        </form>
    </mat-step> -->

</mat-horizontal-stepper>


<!-- <form [formGroup]="formGroup">
    <mat-horizontal-stepper #individualStepper formArrayName="formArray" [linear]="true">
        <mat-step formGroupName="0" [stepControl]="$any(formArray?.get([0]))">
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstNameFormCtrl" required />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastNameFormCtrl" required />
            </mat-form-field>

            <div>
                <button mat-button matStepperNext>Next</button>
            </div>
        </mat-step>

        <mat-step formGroupName="1" [stepControl]="$any(formArray?.get([1]))">
            <mat-form-field>
                <mat-label>Email address</mat-label>
                <input matInput formControlName="emailFormCtrl" required />                
            </mat-form-field>
            <div>                
                <button mat-button matStepperNext>Next</button>
            </div>
        </mat-step>

        <mat-step formGroupName="2" [stepControl]="$any(formArray?.get([2]))">
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstNameFormCtrl2" required />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastNameFormCtrl2" required />
            </mat-form-field>

            <div>
                <button mat-button (click)="finalizeStep()">Next</button>
            </div>
        </mat-step>

    </mat-horizontal-stepper>
</form> -->