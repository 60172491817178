import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, ValidationErrors } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { DateRangeType, FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { ApplicationFormControlNames } from '../../../../types/applicationFromControlNames';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { GenderEnum } from '../../../../api/models/Models/v3/Application/gender-enum';
import { NonResidentVisaTypeEnum } from '../../../../api/models/Models/v3/Application/Identification/non-resident-visa-type-enum';
import { MaritalStatusEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/marital-status-enum';
import { LookupService } from '../../../../services/lookup.service';
import { ListItem } from '../../../../types/listItem';
import { Country } from '../../../../api/models/CommonUtilities/country';
import { Step2PersonalIdentificationForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { Constants } from '../../../../dataobject/constants';
import { startWith, pairwise } from 'rxjs';
import { ContactType } from '../../../../api/models/CommonUtilities/contact-type';
import { VisaCategory } from '../../../../api/models/CommonUtilities/visa-category';
import { IdentificationTypeEnum } from '../../../../api/models/Models/v3/Application/Identification/identification-type-enum';
import { IdentificationTypeOtherEnum } from '../../../../api/models/Models/v3/Application/Identification/identification-type-other-enum';

@Component({
  selector: 'app-personal-identification',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
  ],
  templateUrl: './personal-identification.component.html',
  styleUrl: './personal-identification.component.scss'
})
export class PersonalIdentificationComponent extends BaseStepComponent {
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;
  @Input() fg!: FormGroup<Step2PersonalIdentificationForm>;

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  ApplicationFormControlNames = ApplicationFormControlNames;
  GenderEnum = GenderEnum;
  MaritalStatusEnum = MaritalStatusEnum;
  UserAction = UserAction;
  NonResidentVisaTypeEnum = NonResidentVisaTypeEnum;
  IdentificationTypeEnum = IdentificationTypeEnum;
  SelectListType = SelectListType;
  IdentificationTypeOtherEnum = IdentificationTypeOtherEnum;
  DateRangeType = DateRangeType;


  countriesNonResident: ListItem[] = [];
  individualApplicants: ListItem[] = [];
  individualHeaderDisplay: string = '';

  constructor(vehicleAppService: VehicleAppService, lookupService: LookupService) {
    super(vehicleAppService);

    lookupService.getCountries().subscribe((countries) => {
      //filter countries for non-resident
      this.countriesNonResident = countries.filter(country => country.valueCaption !== Country.Newzealand);
    });

  }



  override ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);
    }));
    
    this.fg.controls.driverLicenceNo.valueChanges.subscribe((value) => {
      var duplicateFound = false;
      this.vehicleAppService.vehicleApplicationFg?.indivFormArray?.controls.forEach((individualFg) => {
        if (individualFg != this.parentFg && individualFg.controls.step2.controls.driverLicenceNo.value == value) {
          this.fg.controls.driverLicenceNo.setErrors({ 'duplicate': true });
          duplicateFound = true;
        }
      });

      //clear the duplicate error if no duplicate found
      if (!duplicateFound) {
        this.clearError(this.fg.controls.driverLicenceNo, Constants.CustomErrors_Duplicate);
      }
    });


    this.fg.controls.maritalStatus.valueChanges.subscribe((value) => {
      if (value == MaritalStatusEnum.Married || value == MaritalStatusEnum.DeFacto) {
        this.individualApplicants = [];

        if (this.vehicleAppService?.vehicleApplicationFg?.indivFormArray && this.vehicleAppService?.vehicleApplicationFg?.indivFormArray?.length > 1) {
          this.vehicleAppService.vehicleApplicationFg?.indivFormArray?.controls.forEach((individualFg) => {
            if (individualFg != this.parentFg) {
              var isIndividualAvailableAsPartner = true;
              if ((individualFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.Married ||
                individualFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.DeFacto) &&
                individualFg.controls.step2.controls.isPartnerOnApplication.value == UserAction.No) {
                isIndividualAvailableAsPartner = false;
              }
              if (isIndividualAvailableAsPartner) {
                this.individualApplicants.push({
                  value: individualFg.controls.common.controls.id.value!.toString(),
                  valueCaption: individualFg.controls.common.controls.id.value!.toString(),
                  displayName: individualFg.controls.step1.controls.firstName.value + ' ' + individualFg.controls.step1.controls.lastName.value,
                });
              }
            }
          });
        } else {
          // const currentIndividualId = this.parentFg.controls.common.controls.id.value;
          // this.fg.controls.partnerIdentifier.setValue(currentIndividualId!.toString());
          // this.fg.controls.isPartnerOnApplication.setValue(UserAction.No);
        }

        if (this.individualApplicants.length == 0) {
          this.fg.controls.isPartnerOnApplication.setValue(UserAction.No);
          this.fg.controls.partnerIdentifier.setValue(null);
          this.fg.controls.isPartnerOnApplication.disable();
        }

      } else {
        this.fg.controls.isPartnerOnApplication.setValue(null);
        this.fg.controls.partnerIdentifier.setValue(null);
      }
    });

    this.fg.controls.isPartnerOnApplication.valueChanges
      .pipe(
        startWith(this.fg.controls.isPartnerOnApplication.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue == UserAction.Yes) {
          if (this.individualApplicants.length == 1) {
            this.fg.controls.partnerIdentifier.setValue(this.individualApplicants[0].value.toString());
          }
        } else {
          this.fg.controls.partnerIdentifier.setValue(null);
        }
      });

    this.fg.controls.partnerIdentifier.valueChanges
      .pipe(
        startWith(this.fg.controls.partnerIdentifier.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {

        if (newValue) {
          if (newValue != oldValue) {


            var dependentPartnerFg = this.vehicleAppService.getDependentPartnerFg(this.parentFg, newValue);
            if (dependentPartnerFg && dependentPartnerFg != this.parentFg) {
              // disable dependent partner controls
              dependentPartnerFg.controls.step2.controls.isPartnerOnApplication.setValue(UserAction.Yes);
              dependentPartnerFg.controls.step2.controls.isPartnerOnApplication.disable();
              dependentPartnerFg.controls.step2.controls.maritalStatus.setValue(this.fg.controls.maritalStatus.value);
              dependentPartnerFg.controls.step2.controls.maritalStatus.disable();
              dependentPartnerFg.controls.step2.controls.partnerIdentifier.setValue(this.parentFg.controls.common.controls.id.value!.toString());
              dependentPartnerFg.controls.step2.controls.partnerIdentifier.disable();
            } else {

              // get the parent partner from group


            }
          }
        }
        else {
          // debugger;
          var oldPartnerFg = this.vehicleAppService.vehicleApplicationFg?.indivFormArray?.controls.find((individualFg) => {
            return individualFg.controls.common.controls.id.value == oldValue;
          });

          if (oldPartnerFg) {
            if (oldPartnerFg.controls.step2.controls.isPartnerOnApplication.disabled) {
              oldPartnerFg.controls.step2.controls.isPartnerOnApplication.setValue(null, { emitEvent: false });
              oldPartnerFg.controls.step2.controls.isPartnerOnApplication.enable({ emitEvent: false });
            }
            if (oldPartnerFg.controls.step2.controls.maritalStatus.disabled) {
              oldPartnerFg.controls.step2.controls.maritalStatus.setValue(null, { emitEvent: false });
              oldPartnerFg.controls.step2.controls.maritalStatus.enable({ emitEvent: false });
            }

            oldPartnerFg.controls.step2.controls.partnerIdentifier.setValue(null, { emitEvent: true });
            oldPartnerFg.controls.step2.controls.partnerIdentifier.enable({ emitEvent: false });
          }

        }
      });

    //Acivate visa controls when non NZ resident
    this.activateControlsWhen(this.fg.controls.nzResident, [this.fg.controls.visaType, this.fg.controls.birthCountry, this.fg.controls.citizenshipCountry], null,
      [{ control: this.fg.controls.nzResident, expectedValues: [UserAction.No] }]);

    // Activate visa number, start date and expiry date when visa type is Working Visa or Other Visa
    this.activateControlsWhen(this.fg.controls.visaType, [this.fg.controls.visaNumber, this.fg.controls.visaStartDate, this.fg.controls.visaExpiryDate], null,
      [{ control: this.fg.controls.visaType, expectedValues: [NonResidentVisaTypeEnum.WorkingVisa, NonResidentVisaTypeEnum.OtherVisa] }]);


    this.subscriptions.push(this.fg.controls.identificationType.valueChanges.subscribe((value) => {

      this.disableControls([
        this.fg.controls.driverLicenceNo, this.fg.controls.driverLicenceVersion,
        this.fg.controls.passportNzExpiryDate, this.fg.controls.passportNzNumber,
        this.fg.controls.passportOtherExpiryDate, this.fg.controls.passportOtherNumber,this.fg.controls.passportOtherIssuerCountry,
        this.fg.controls.otherIdType, this.fg.controls.otherIdDescription, this.fg.controls.otherIdNumber
      ]);

      switch (value) {
        case IdentificationTypeEnum.NzDriverLicense:
          this.enableControls([this.fg.controls.driverLicenceNo, this.fg.controls.driverLicenceVersion]);          
          break;
        case IdentificationTypeEnum.NzPassport:
          this.enableControls([this.fg.controls.passportNzExpiryDate, this.fg.controls.passportNzNumber]);
          break;

        case IdentificationTypeEnum.OverseasPassport:
          this.enableControls([this.fg.controls.passportOtherExpiryDate, this.fg.controls.passportOtherNumber, this.fg.controls.passportOtherIssuerCountry]);          
          break;

          case IdentificationTypeEnum.Other:
            this.enableControls([this.fg.controls.otherIdType, this.fg.controls.otherIdNumber]);            
            break;
      }
    }));

    this.activateControlsWhen(this.fg.controls.otherIdType, [this.fg.controls.otherIdDescription], null,
      [{ control: this.fg.controls.otherIdType, expectedValues: [IdentificationTypeOtherEnum.Other] }]);
  }
}
