<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            <h1 class="mat-headline-large">Living expenses</h1>
        </div>
        <div class="group-section">
            <p class="mat-body-large">
                <mat-icon class="check-icon" svgIcon="tick"></mat-icon>             
                We automatically estimate living expenses based on information you provide in this application. These
                expenses cover items such as:
            </p>
            <ul class="expenses-list">               
                <li><mat-icon svgIcon="bills"></mat-icon>Bills & utilities</li>
                <li><mat-icon svgIcon="food"></mat-icon>Food & groceries</li>
                <li><mat-icon svgIcon="transport"></mat-icon>Transport & vehicle costs</li>
                <li><mat-icon svgIcon="phone"></mat-icon>Phone, internet & streaming services</li>
                <li><mat-icon svgIcon="clothing"></mat-icon>Clothing, shoes & personal care</li>
                <li><mat-icon svgIcon="insurance"></mat-icon>Insurances</li>
            </ul>
            <p class="mat-body-large">Occasionally, we may need to verify these estimates. In such cases, we'll ask you
                to provide bank statements for confirmation.
            </p>

            <mat-slide-toggle [formControl]="fg.controls.understandsBankStatReq" hideIcon>I understand that I may need
                to supply
                bank statements to verify my living expenses</mat-slide-toggle>
        </div>

        <!-- Visible when no partner is on the application and the individual is married or in a de facto relationship -->
        <!-- 
        *ngIf="showExpenses && (parentFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.Married || parentFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.DeFacto) &&
             parentFg.controls.step2.controls.isPartnerOnApplication.value != UserAction.Yes && this.fg.controls.expensesSharedWithPartner.enabled"
         -->
        <div class="group-section" *ngIf="fg.controls.expensesSharedWithPartner.enabled && !partnerFg">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="fg.controls.expensesSharedWithPartner"
                label="Do you share any of these living expenses with your spouse/partner?">
            </app-form-button-group-simple>
        </div>

        <!--  showExpenses && !partnerFg && 
            (parentFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.Married || parentFg.controls.step2.controls.maritalStatus.value == MaritalStatusEnum.DeFacto) &&
            fg.controls.expensesSharedWithPartner.value == UserAction.Yes  && this.fg.controls.expensesSharedWithPartner.enabled -->
        <div class="group-section"
            *ngIf="fg.controls.partnerGrossIncome.enabled && fg.controls.expensesSharedWithPartner.enabled && !partnerFg">
            <app-form-field-currency [formSubmitted]="form.submitted"
                label="Spouse/partner's annual income (before tax)" [control]="fg.controls.partnerGrossIncome">
            </app-form-field-currency>
        </div>

        <!-- Visible when the individual is married and the partner is on the application -->
        <ng-container>
            <div class="group-section"
                *ngIf="!showExpenses && partnerFg && this.fg.controls.hasNonSharedPartnerExpenses.enabled">
                <app-form-button-group-simple [formSubmitted]="form.submitted"
                    [control]="fg.controls.hasNonSharedPartnerExpenses"
                    [label]="'Do you have any regular expenses or liabilities that have not already been provided by ' + partnerFg.controls.step1.controls.firstName.value + ' ' + partnerFg.controls.step1.controls.lastName.value + '?'">
                </app-form-button-group-simple>
            </div>
        </ng-container>


        <div *ngIf="showExpenses || fg.controls.expensesSharedWithPartner.value == UserAction.No">
            <hr class="separator no-margin-top">
            <section>
                <div class="group-section">
                    <h1 class="mat-headline-large">Other expenses</h1>
                </div>
                <div class="group-section">
                    <div class="group-section">
                        <app-form-button-group-simple [formSubmitted]="form.submitted"
                            [control]="fg.controls.hasOtherExpenses"
                            label="Do you have any of the following regular expenses?"
                            hint="Rates, childcare, school fees, medical costs, child support, tithing/donations, any other regular ongoing non-discretionary expense">
                        </app-form-button-group-simple>
                    </div>
                </div>

                <ng-container *ngIf="fg.controls.otherExpenses && fg.controls.hasOtherExpenses.value == UserAction.Yes"
                    class="section-spacing">
                    <ng-container *ngFor="let expenseOther of fg.controls.otherExpenses.controls; let i = index">
                        <div class="group-section small-group">
                            <span
                                *ngIf="expenseOther.controls.type.disabled && expenseOther.controls.type.value == ApplicationExpenseType.Rates"
                                class="green-text">
                                We've automatically added rates as an expense as you indicated previously you are a
                                homeowner.
                            </span>

                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Other expense {{i+1}}</p>
                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            *ngIf="!expenseOther.controls.type.disabled"
                                            (click)="removeExpense(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field-select [control]="expenseOther.controls.type"
                                    [listType]="SelectListType.ExpenseTypes"
                                    label="Expense type"></app-form-field-select>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-7">
                                                <app-form-field-currency [formSubmitted]="form.submitted" label="Amount"
                                                    [control]="expenseOther.controls.amount">
                                                </app-form-field-currency>
                                            </div>
                                            <div class="col-5">
                                                <app-form-field-select [control]="expenseOther.controls.frequency"
                                                    [listType]="SelectListType.FrequencyTypesAll"
                                                    label="Frequency"></app-form-field-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="expenseOther.controls.type && expenseOther.controls.type.value == ApplicationExpenseType.Other">
                                    <app-form-field [isMultiLine]="true" label="Description"
                                        [control]="expenseOther.controls.description"
                                        hint="Please provide further details about this regular expense"></app-form-field>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="fg.controls.hasOtherExpenses.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addExpense()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </ng-container>
            </section>
        </div>

        <div class="group-section">
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>