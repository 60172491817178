import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, Routes } from '@angular/router';
import { ApplicationViewComponent } from './views/application-view/application-view.component';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { MaintenanceViewComponent } from './views/maintenance-view/maintenance-view.component';

@Injectable()
export class MaintenanceGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {		
		if (!environment.maintenance.active) { return true; }
		const from = new Date(environment.maintenance.from);
		const to = new Date(environment.maintenance.to);
		const current = new Date();
		if (current <= to && current >= from) {
			this.router.navigateByUrl('/maintenance');
			return false;
		}
		return true;
	}
}


export const routes: Routes = [
	{
		path: '',
		component: ApplicationViewComponent,
		canActivate: [MaintenanceGuard]
	},
	{
		path: 'maintenance',
		canActivate: [],
		component: MaintenanceViewComponent
	},

];
