/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthLoginGenericRequest as ModelsAuthenticationAuthLoginGenericRequest } from '../../models/Models/Authentication/auth-login-generic-request';
import { AuthLoginResponse as ModelsAuthenticationAuthLoginResponse } from '../../models/Models/Authentication/auth-login-response';

export interface V3TokenPost$Json$Params {
      body?: ModelsAuthenticationAuthLoginGenericRequest
}

export function v3TokenPost$Json(http: HttpClient, rootUrl: string, params?: V3TokenPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>> {
  const rb = new RequestBuilder(rootUrl, v3TokenPost$Json.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>;
    })
  );
}

v3TokenPost$Json.PATH = '/v3/token';
