import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../shared/shared.module';
import { NgxCurrencyDirective } from "ngx-currency";
import { MatIconModule } from '@angular/material/icon';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';

@Component({
  selector: 'app-form-field-currency',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxCurrencyDirective,
    MatIconModule     
  ],
  templateUrl: './form-field-currency.component.html',
  styleUrl: './form-field-currency.component.scss',
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class FormFieldCurrencyComponent {
  @Input() control!: FormControl;  
  @Input() label: string='';
  @Input() placeholder: string='';
  @Input() hint: string='';
  @Input() type: string='';
  @Input() decimal_precision: number=2;
  @Input() formSubmitted: boolean = false;
}
