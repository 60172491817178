import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../../shared/shared.module';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { ApplicationFormControlNames } from '../../../types/applicationFromControlNames';
import { FormFieldComponent } from "../../shared/form-field/form-field.component";
import { FormFieldNumericComponent } from '../../shared/form-field-numeric/form-field-numeric.component';
import { AsFormArrayPipe } from '../../../transforms/form-array-pipe';
import { AsFormControlPipe } from '../../../transforms/form-control-pipe';
import { BaseStepComponent } from '../base-step/base-step.component';
import { AddressComponent } from '../address/address.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LivingSituationEnum } from '../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { PeriodTypeEnum } from '../../../api/models/Models/v3/Application/FinancialDetails/period-type-enum';
import { Constants } from '../../../dataobject/constants';
import { IndividualCommonDataForm, VehicleIndividualForm } from '../../../types/vehicleIndividualSteps';
import { GenderEnum } from '../../../api/models/Models/v3/Application/gender-enum';
import { MaritalStatusEnum } from '../../../api/models/Models/v3/Application/PersonalDetails/marital-status-enum';
import { FormFieldDateComponent } from '../../shared/form-field-date/form-field-date.component';
import { FormButtonGroupSimpleComponent } from '../../shared/form-button-group-simple/form-button-group-simple.component';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { NonResidentVisaTypeEnum } from '../../../api/models/Models/v3/Application/Identification/non-resident-visa-type-enum';
import { LookupService } from '../../../services/lookup.service';
import { ListItem } from '../../../types/listItem';
import { FormFieldSelectComponent } from '../../shared/form-field-select/form-field-select.component';
import { Country } from '../../../api/models/CommonUtilities/country';
import { PersonalDetailComponent } from '../individual/personal-detail/personal-detail.component';
import { PersonalIdentificationComponent } from "../individual/personal-identification/personal-identification.component";
import { LoanEntityType } from '../../../api/models/CommonUtilities/loan-entity-type';
import { EmploymentDetailComponent } from '../individual/employment-detail/employment-detail.component';
import { LivingExpensesComponent } from '../individual/living-expenses/living-expenses.component';
import { AssetsComponent } from '../individual/assets/assets.component';
import { LiabilitiesComponent } from '../individual/liabilities/liabilities.component';
import { ButtonNextEventArgs } from '../../../dataobject/button-next-event-args';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { startWith, pairwise } from 'rxjs';

@Component({
  selector: 'app-individual-applicant',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    PersonalDetailComponent,
    PersonalIdentificationComponent,
    EmploymentDetailComponent,
    LivingExpensesComponent,
    AssetsComponent,
    LiabilitiesComponent
  ],  
  templateUrl: './individual-applicant.component.html',
  styleUrl: './individual-applicant.component.scss'
})
export class IndividualApplicantComponent extends BaseStepComponent implements OnInit {
  @ViewChild('individualStepper') private myStepper!: MatStepper;

  @Input() parentStepper!: MatStepper;
  @Input() currentStep!: MatStep;

  @Input() individualFormGroup!: FormGroup<VehicleIndividualForm>;

  public applicantIndividualNumber: number = 0;
  public entityTypeDisplay: string = '';

  constructor(private _formBuilder: FormBuilder, lookupService: LookupService, vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }

  showExpenses: boolean = true;

  override ngOnInit(): void {
    super.ngOnInit();

    this.individualFormGroup.controls.step4.controls.hasNonSharedPartnerExpenses.valueChanges
    .pipe(
      startWith(this.individualFormGroup.controls.step4.controls.hasNonSharedPartnerExpenses.value), // Emit the initial value
      pairwise() // Emit the previous and current values as an array
    )
    .subscribe(([oldValue, newValue]) => {
      if (newValue && newValue != oldValue) {
        if(newValue == UserAction.No){
          this.showExpenses = false;
          this.individualFormGroup.controls.liabilitiesFg.reset();
          this.individualFormGroup.controls.liabilitiesFg.disable();
          this.vehicleAppService.totalSteps = this.vehicleAppService.totalSteps - 1;
        }else{
          this.showExpenses = true;
          this.individualFormGroup.controls.liabilitiesFg.enable();
          this.vehicleAppService.totalSteps = this.vehicleAppService.totalSteps + 1;
        }        
      }
    });

    // this.individualFormGroup.controls.step4.controls.expensesSharedWithPartner.valueChanges
    // .pipe(
    //   startWith(this.individualFormGroup.controls.step4.controls.expensesSharedWithPartner.value), // Emit the initial value
    //   pairwise() // Emit the previous and current values as an array
    // )
    // .subscribe(([oldValue, newValue]) => {
    //   if (newValue && newValue != oldValue) {
    //     if(newValue == UserAction.Yes){
    //       this.showExpenses = false;
    //       this.individualFormGroup.controls.liabilitiesFg.reset();
    //       this.individualFormGroup.controls.liabilitiesFg.disable();
    //       this.vehicleAppService.totalSteps = this.vehicleAppService.totalSteps - 1;
    //     }else{
    //       this.showExpenses = true;
    //       this.individualFormGroup.controls.liabilitiesFg.enable();
    //       this.vehicleAppService.totalSteps = this.vehicleAppService.totalSteps + 1;
    //     }        
    //   }
    // });
  }

  goBack() {
    
    if (this.myStepper.selectedIndex === 0) {
      console.log('child stepper - go back on parent');
      this.parentStepper.previous();
    } else {
      console.log('child stepper - go back - new child step index', this.myStepper.selectedIndex - 1);
      this.myStepper.selectedIndex = this.myStepper.selectedIndex - 1;
      //this.myStepper.previous();
    }
  }

  getParentStepIndex() {
    var currentParentStepIndex = 0;
    this.parentStepper.steps.forEach((step, index) => {
      if (step === this.currentStep) {
        currentParentStepIndex = index;
        return;
      }
    });
    return currentParentStepIndex;
  }

  onButtonNextCompleted(eventArgs: ButtonNextEventArgs){
    var currentIndex = eventArgs.stepper.selectedIndex;
    var steps = eventArgs.stepper.steps.toArray();

    //check if is last step and trigger the parent stepper next
    if(eventArgs.fg.valid ){
      if(currentIndex < steps.length - 1){
        eventArgs.stepper.next();
        this.vehicleAppService.moveCurrentStepCountForward();
      }else{
        this.finalizeStep();
      }      
    }        
  }

  finalizeStep() {
    if (this.individualFormGroup.valid) {
      this.parentStepper.next();
      this.vehicleAppService.moveCurrentStepCountForward();
    }
  }

}
