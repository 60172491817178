/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DtoVehicleBaseResponse as LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse } from '../models/Limelight/Babylon/DataObjects/DTO/dto-vehicle-base-response';
import { DtoVehicleBasicBaseResponse as LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse } from '../models/Limelight/Babylon/DataObjects/DTO/dto-vehicle-basic-base-response';
import { ApplicationResponseBaseResponse as ModelsV3ApplicationApplicationResponseBaseResponse } from '../models/Models/v3/Application/application-response-base-response';
import { ApplicationUpdateVehicleResponseBaseResponse as ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse } from '../models/Models/v3/Application/Public/application-update-vehicle-response-base-response';
import { BooleanBaseResponse as SystemBooleanBaseResponse } from '../models/System/boolean-base-response';
import { v3PublicApplicationVehicleApplicationIdPut$Json } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-put-json';
import { V3PublicApplicationVehicleApplicationIdPut$Json$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-put-json';
import { v3PublicApplicationVehicleApplicationIdPut$Plain } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-put-plain';
import { V3PublicApplicationVehicleApplicationIdPut$Plain$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-put-plain';
import { v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-info-get-json';
import { V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-info-get-json';
import { v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-info-get-plain';
import { V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-info-get-plain';
import { v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-lookup-get-json';
import { V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-lookup-get-json';
import { v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-lookup-get-plain';
import { V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-application-id-vehicle-lookup-get-plain';
import { v3PublicApplicationVehiclePingGet$Json } from '../fn/public-vehicle-application/v-3-public-application-vehicle-ping-get-json';
import { V3PublicApplicationVehiclePingGet$Json$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-ping-get-json';
import { v3PublicApplicationVehiclePingGet$Plain } from '../fn/public-vehicle-application/v-3-public-application-vehicle-ping-get-plain';
import { V3PublicApplicationVehiclePingGet$Plain$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-ping-get-plain';
import { v3PublicApplicationVehiclePost$Json } from '../fn/public-vehicle-application/v-3-public-application-vehicle-post-json';
import { V3PublicApplicationVehiclePost$Json$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-post-json';
import { v3PublicApplicationVehiclePost$Plain } from '../fn/public-vehicle-application/v-3-public-application-vehicle-post-plain';
import { V3PublicApplicationVehiclePost$Plain$Params } from '../fn/public-vehicle-application/v-3-public-application-vehicle-post-plain';

@Injectable({ providedIn: 'root' })
export class PublicVehicleApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3PublicApplicationVehiclePingGet()` */
  static readonly V3PublicApplicationVehiclePingGetPath = '/v3/public/application/vehicle/ping';

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehiclePingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehiclePingGet$Plain$Response(params?: V3PublicApplicationVehiclePingGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemBooleanBaseResponse>> {
    return v3PublicApplicationVehiclePingGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehiclePingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehiclePingGet$Plain(params?: V3PublicApplicationVehiclePingGet$Plain$Params, context?: HttpContext): Observable<SystemBooleanBaseResponse> {
    return this.v3PublicApplicationVehiclePingGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemBooleanBaseResponse>): SystemBooleanBaseResponse => r.body)
    );
  }

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehiclePingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehiclePingGet$Json$Response(params?: V3PublicApplicationVehiclePingGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SystemBooleanBaseResponse>> {
    return v3PublicApplicationVehiclePingGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehiclePingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehiclePingGet$Json(params?: V3PublicApplicationVehiclePingGet$Json$Params, context?: HttpContext): Observable<SystemBooleanBaseResponse> {
    return this.v3PublicApplicationVehiclePingGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SystemBooleanBaseResponse>): SystemBooleanBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationVehiclePost()` */
  static readonly V3PublicApplicationVehiclePostPath = '/v3/public/application/vehicle';

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehiclePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehiclePost$Plain$Response(params?: V3PublicApplicationVehiclePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>> {
    return v3PublicApplicationVehiclePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehiclePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehiclePost$Plain(params?: V3PublicApplicationVehiclePost$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponseBaseResponse> {
    return this.v3PublicApplicationVehiclePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>): ModelsV3ApplicationApplicationResponseBaseResponse => r.body)
    );
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehiclePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehiclePost$Json$Response(params?: V3PublicApplicationVehiclePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>> {
    return v3PublicApplicationVehiclePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehiclePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehiclePost$Json(params?: V3PublicApplicationVehiclePost$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponseBaseResponse> {
    return this.v3PublicApplicationVehiclePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>): ModelsV3ApplicationApplicationResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationVehicleApplicationIdPut()` */
  static readonly V3PublicApplicationVehicleApplicationIdPutPath = '/v3/public/application/vehicle/{applicationId}';

  /**
   * Update the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehicleApplicationIdPut$Plain$Response(params: V3PublicApplicationVehicleApplicationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehicleApplicationIdPut$Plain(params: V3PublicApplicationVehicleApplicationIdPut$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>): ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse => r.body)
    );
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehicleApplicationIdPut$Json$Response(params: V3PublicApplicationVehicleApplicationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3PublicApplicationVehicleApplicationIdPut$Json(params: V3PublicApplicationVehicleApplicationIdPut$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>): ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationVehicleApplicationIdVehicleLookupGet()` */
  static readonly V3PublicApplicationVehicleApplicationIdVehicleLookupGetPath = '/v3/public/application/vehicle/{applicationId}/vehicleLookup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Response(params: V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain(params: V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Params, context?: HttpContext): Observable<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse>): LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Response(params: V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json(params: V3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Params, context?: HttpContext): Observable<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse>): LimelightBabylonDataObjectsDtoDtoVehicleBasicBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationVehicleApplicationIdVehicleInfoGet()` */
  static readonly V3PublicApplicationVehicleApplicationIdVehicleInfoGetPath = '/v3/public/application/vehicle/{applicationId}/vehicle/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Response(params: V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain(params: V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Params, context?: HttpContext): Observable<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse>): LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Response(params: V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse>> {
    return v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json(params: V3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Params, context?: HttpContext): Observable<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse> {
    return this.v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse>): LimelightBabylonDataObjectsDtoDtoVehicleBaseResponse => r.body)
    );
  }

}
