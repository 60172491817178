<form [formGroup]="fg" #form="ngForm">


    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            
            <h1 class="mat-headline-large">Assets</h1>
        </div>

        <!-- Property assets -->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.isPropertyOwner"
                label="Do you own any properties?">
            </app-form-button-group-simple>
        </div>
            <ng-container *ngIf="fg.controls.propertyAssets && fg.controls.isPropertyOwner.value == UserAction.Yes">
                <div class="section-spacing">
                    <ng-container *ngFor="let asset of fg.controls.propertyAssets.controls; let i = index">
                        <div class="group-section small-group">
                            <span *ngIf="fg.controls.isPropertyOwner.disabled" class="green-text">
                                We've automatically added the property you're living in, as you indicated previously you own this home.
                            </span>

                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Property {{i+1}}</p>
                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            *ngIf="!asset.controls.description.disabled"
                                            (click)="removeProperty(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field [formSubmitted]="form.submitted" label="Address"
                                    [control]="asset.controls.description">
                                </app-form-field>
                                <app-form-field-currency [formSubmitted]="form.submitted" label="Estimated value"
                                    [control]="asset.controls.value"
                                    [decimal_precision]="0">
                                </app-form-field-currency>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="fg.controls.isPropertyOwner.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addProperty()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </div>
            </ng-container>
      
        <!-- Vehicle assets -->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="fg.controls.hasAdditionalVehicleAssets" label="Do you own any vehicles?" hint="Do not include vehicles you intend to trade in">
            </app-form-button-group-simple>
        </div>

        <ng-container
            *ngIf="fg.controls.vehicleAssets && fg.controls.hasAdditionalVehicleAssets.value == UserAction.Yes"
            class="section-spacing">
            <ng-container *ngFor="let asset of fg.controls.vehicleAssets.controls; let i = index">
                <div class="group-section small-group">
                    <div class="row">
                        <div class="col-12">
                            <div class="flex-content height-24">
                                <p class="mat-body-large">Vehicle {{i+1}}</p>
                                <button mat-button class="link-button no-background-link remove" type="button"
                                    (click)="removeVehicle(i)"><mat-icon svgIcon="trash"></mat-icon>Remove</button>
                            </div>
                        </div>
                    </div>
                    <div class="group-section">
                        <app-form-field-select label="Vehicle type" [control]="asset.controls.vehicleClass"
                            [listType]="SelectListType.VehicleClasses">
                        </app-form-field-select>
                        <app-form-field [formSubmitted]="form.submitted" label="Plate No"
                            [control]="asset.controls.plateVin">
                        </app-form-field>
                        <app-form-field-currency [formSubmitted]="form.submitted" label="Estimated value"
                            [control]="asset.controls.value"
                            [decimal_precision]="0">
                        </app-form-field-currency>
                        <app-form-field [formSubmitted]="form.submitted" label="Description"
                            [control]="asset.controls.description" [isMultiLine]="true">
                        </app-form-field>

                        <mat-slide-toggle [formControl]="asset.controls.useAsLoanSecurity" hideIcon>Use as a security
                            for
                            this
                            loan</mat-slide-toggle>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="fg.controls.hasAdditionalVehicleAssets.value == UserAction.Yes" class="height-24">
                <button mat-button class="link-button no-background-link" (click)="addVehicle()" type="button"><mat-icon
                        svgIcon="add"></mat-icon>Add
                    another</button>
            </div>
        </ng-container>

        <!-- Cash/Investment assets -->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="fg.controls.hasAdditionalCashInvestments"
                label="Do you have any cash savings, investments/shares or superannuation? (includes Kiwisaver)">
            </app-form-button-group-simple>
        </div>

            <ng-container
                *ngIf="fg.controls.cashInvestmentAssets && fg.controls.hasAdditionalCashInvestments.value == UserAction.Yes">
                <div class="section-spacing">
                    <ng-container *ngFor="let asset of fg.controls.cashInvestmentAssets.controls; let i = index">
                        <div class="group-section small-group">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Investment {{i+1}}</p>

                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            (click)="removeCashInvestmentAsset(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field-select label="Asset type" [control]="asset.controls.type"
                                    [listType]="SelectListType.AssetInvestmentTypes">
                                </app-form-field-select>
                                <app-form-field-currency [formSubmitted]="form.submitted" label="Estimated value"
                                    [control]="asset.controls.value"
                                    [decimal_precision]="0">
                                </app-form-field-currency>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="fg.controls.hasAdditionalCashInvestments.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addCashInvestmentAsset()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </div>
            </ng-container>
       
        <!-- Other assets -->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.hasOtherAssets"
                label="Do you have any other assets?">
            </app-form-button-group-simple>
        </div>
            <ng-container *ngIf="fg.controls.otherAssets && fg.controls.hasOtherAssets.value == UserAction.Yes">
                <div class="section-spacing">
                    <ng-container *ngFor="let asset of fg.controls.otherAssets.controls; let i = index">
                        <div class="group-section small-group">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Asset {{i+1}}</p>
                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            (click)="removeOther(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field [formSubmitted]="form.submitted" label="Asset description"
                                    [control]="asset.controls.description">
                                </app-form-field>
                                <app-form-field-currency [formSubmitted]="form.submitted" label="Estimated value"
                                    [control]="asset.controls.value"
                                    [decimal_precision]="0">
                                </app-form-field-currency>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="fg.controls.hasOtherAssets.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addOther()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </div>
            </ng-container>
        
        <div>
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>