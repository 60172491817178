<div class="page-base ">
    <mat-icon svgIcon="engineering" class="warning-icon"></mat-icon>
    <h1 class="heading-xxl">Temporarily unavailable</h1>
    <p>We are currently down for maintenance and you are unable to complete an application during this time.</p>
    <p class="try-again"><strong>Please try again after {{untilTime | date:'d MMM YYYY, HH:mm'}}</strong></p>
    <p>We apologise for any inconvenience this may cause.</p>
    <p class="get-in-touch">
        <strong>Need to get in touch quickly?</strong><br>
        Please contact our team on <a href="tel:{{this.contactNumber}}">{{this.contactNumber}}</a> to discuss.
    </p>

    <div class="powered-by">
        Powered by <img src="assets/avanti-logo-grey.svg" alt="Avanti Finance" />
    </div>

</div>
