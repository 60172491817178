import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../shared/shared.module';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { AlphaNumericOnlyDirective } from '../../../directives/alpha-numeric-only.directive';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';

@Component({
  selector: 'app-form-field',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinner,
    MatIconModule,
    AlphaNumericOnlyDirective
  ],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
  animations: [
    trigger('loadingAnimation', [
      state('true', style({ transform: 'rotate(0deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('* => true', [
        animate('1s linear', keyframes([
          style({ transform: 'rotate(0deg)', offset: 0 }),
          style({ transform: 'rotate(360deg)', offset: 1 })
        ]))
      ]),
      transition('true => false', [
        animate('0s')
      ])
    ])
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class FormFieldComponent {

  constructor() { 

    if(this.isMultiLine){
      this.maxLength = 1024;
    }
  }
  
  @Input() control!: FormControl;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() type: string = '';
  @Input() isMultiLine: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() formSubmitted: boolean = false;
  @Input() success: boolean = false;
  @Input() forceShowErrorIcon: boolean = false;

  @Input() applyAlphaNumericOnly: boolean = false;

  @Input() minLength: number=0;
  @Input() maxLength: number=128

  @Input() textAreaRows: number=3;

}
