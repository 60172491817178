<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            <h1 class="mat-headline-large">Identification</h1>
        </div>

        <div class="group-section">
            <p class="mat-body-large section-title">
                How would you like to verify your identity?
            </p>
            <div class="group-required">
                <mat-button-toggle-group class="button-group" aria-label="How would you like to verify your identity?"
                    [formControl]="fg.controls.identificationType" [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="{{IdentificationTypeEnum.NzDriverLicense}}">NZ driver
                        licence</mat-button-toggle>
                    <mat-button-toggle value="{{IdentificationTypeEnum.NzPassport}}">NZ passport</mat-button-toggle>
                    <mat-button-toggle value="{{IdentificationTypeEnum.OverseasPassport}}">Other
                        passport</mat-button-toggle>
                    <mat-button-toggle value="{{IdentificationTypeEnum.Other}}">Other</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-error
                    *ngIf="form.submitted && fg.controls.identificationType.hasError('required')">Required</mat-error>
            </div>
        </div>

        <ng-container *ngIf="fg.controls.identificationType.value == IdentificationTypeEnum.NzDriverLicense">
            <div class="group-section">
                <div class="row">
                    <div class="col-8">
                        <app-form-field [formSubmitted]="form.submitted" label="Driver licence number"
                            placeholder="eg. AB123456" [control]="fg.controls.driverLicenceNo"
                            hint="Note: This must be a NZ driver licence" class="uppercase">
                        </app-form-field>
                    </div>
                    <div class="col-4">
                        <app-form-field-numeric [formSubmitted]="form.submitted" label="Version" placeholder="eg. 123"
                            [control]="fg.controls.driverLicenceVersion">
                        </app-form-field-numeric>
                    </div>
                </div>

                <div class="group-section dl-wrap info-box">
                    <img class="dl-img" style="max-width: 299px;" src="/assets/licence.png" alt="Driver licence example" />
                    <p>Your driver licence number and version can be found on the front
                        side of your driver licence</p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="fg.controls.identificationType.value == IdentificationTypeEnum.NzPassport">
            <div class="group-section">
                <app-form-field [formSubmitted]="form.submitted" label="NZ passport number" [minLength]="7" [maxLength]="8"
                    [control]="fg.controls.passportNzNumber" class="uppercase">
                </app-form-field>
                <app-form-field-date [formSubmitted]="form.submitted" label="Expiry date"
                    [control]="fg.controls.passportNzExpiryDate" hint="DD/MM/YYYY"
                    [dateRangeType]="DateRangeType.FutureOnly"
                    >
                </app-form-field-date>


                <div class="group-section dl-wrap info-box">
                    <img class="dl-img" style="max-width: 403px;" src="/assets/nzpassport.png"  alt="NZ passport example" />
                    <p>Your NZ passport number and expiry date can be found on the inside page of your passport</p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="fg.controls.identificationType.value == IdentificationTypeEnum.OverseasPassport">
            <div class="group-section">
                <app-form-field [formSubmitted]="form.submitted" label="Overseas passport number"
                    [control]="fg.controls.passportOtherNumber" class="uppercase">
                </app-form-field>
                <app-form-field-date [formSubmitted]="form.submitted" label="Expiry date"
                    [control]="fg.controls.passportOtherExpiryDate"
                    hint="DD/MM/YYYY"
                    [dateRangeType]="DateRangeType.FutureOnly"
                    >
                </app-form-field-date>
                <app-form-field-select label="Country of issue" [control]="fg.controls.passportOtherIssuerCountry" [options]="countriesNonResident" [autocomplete]="true">
                </app-form-field-select>
            </div>
        </ng-container>

        <ng-container *ngIf="fg.controls.identificationType.value == IdentificationTypeEnum.Other">
            <div class="group-section">
                <app-form-field-select [control]="fg.controls.otherIdType" [listType]="SelectListType.OtherIdTypes"
                    label="ID type"></app-form-field-select>

                <app-form-field [formSubmitted]="form.submitted" label="Description"
                    *ngIf="fg.controls.otherIdType.value == IdentificationTypeOtherEnum.Other"
                    [control]="fg.controls.otherIdDescription">
                </app-form-field>

                <app-form-field [formSubmitted]="form.submitted" label="Identification number"
                    [control]="fg.controls.otherIdNumber">
                </app-form-field>
            </div>
        </ng-container>

        <hr class="separator no-margin" />
        <div class="group-section">
            <h1 class="mat-headline-large">About you</h1>
        </div>
        <div class="group-section">
            <app-form-field-date [formSubmitted]="form.submitted" label="Date of birth"
                [control]="fg.controls.birthDate" hint="DD/MM/YYYY"
                [dateRangeType]="DateRangeType.BirthDate"
                >
            </app-form-field-date>
        </div>
        <div class="group-section small-group">
            <p class="mat-body-large section-title">Gender</p>
            <div class="group-required">
                <mat-button-toggle-group class="button-group" aria-label="Gender" [formControl]="fg.controls.gender"
                    [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="{{GenderEnum.Male}}">Male</mat-button-toggle>
                    <mat-button-toggle value="{{GenderEnum.Female}}">Female</mat-button-toggle>
                    <mat-button-toggle value="{{GenderEnum.Unspecified}}">Prefer not to say</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-error *ngIf="form.submitted && fg.controls.gender.hasError('required')">Please select a
                    gender</mat-error>
            </div>
        </div>

        <div class="group-section small-group" *ngIf="fg.controls.maritalStatus.enabled">
            <mat-label>Marital status</mat-label>
            <div class="group-required">
                <mat-button-toggle-group class="button-group" aria-label="Marital status"
                    [formControl]="fg.controls.maritalStatus" [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="{{MaritalStatusEnum.Single}}">Single</mat-button-toggle>
                    <mat-button-toggle value="{{MaritalStatusEnum.Married}}">Married / Civil
                        Union</mat-button-toggle>
                    <mat-button-toggle value="{{MaritalStatusEnum.DeFacto}}">De facto</mat-button-toggle>
                    <mat-button-toggle value="{{MaritalStatusEnum.Widowed}}">Widowed</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-error *ngIf="form.submitted && fg.controls.maritalStatus.hasError('required')">Please select a
                    marital status</mat-error>
            </div>
        </div>

        <ng-container
            *ngIf="fg.controls.isPartnerOnApplication.enabled && vehicleAppService.vehicleApplicationFg?.indivFormArray != null && vehicleAppService.vehicleApplicationFg!.indivFormArray!.length > 1 &&
             (fg.controls.maritalStatus.value == MaritalStatusEnum.Married || fg.controls.maritalStatus.value == MaritalStatusEnum.DeFacto)">
            <div class="group-section small-group">
                <app-form-button-group-simple [formSubmitted]="form.submitted"
                    [control]="fg.controls.isPartnerOnApplication"
                    label="Is your spouse/partner also on this application?">
                </app-form-button-group-simple>
            </div>

            <div class="group-section small-group" *ngIf="fg.controls.isPartnerOnApplication.value == UserAction.Yes">
                <app-form-field-select label="Please select your partner" [control]="fg.controls.partnerIdentifier"
                    [options]="individualApplicants">
                </app-form-field-select>
            </div>
        </ng-container>

        <div class="group-section small-group">
            <p class="mat-body-large section-title">
                Number of dependents
                <span class="mat-body-large section-subtitle">An individual totally or substantially reliant on you for
                    financial support, whether you live with them or not </span>
            </p>

            <div class="group-required">
                <mat-button-toggle-group class="button-group" aria-label="Number of dependents"
                    label="Number of dependents" [formControl]="fg.controls.dependentsNo"
                    [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="0">None</mat-button-toggle>
                    <mat-button-toggle value="1">1</mat-button-toggle>
                    <mat-button-toggle value="2">2</mat-button-toggle>
                    <mat-button-toggle value="3">3</mat-button-toggle>
                    <mat-button-toggle value="4">4</mat-button-toggle>
                    <mat-button-toggle value="5">5+</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-error *ngIf="form.submitted && fg.controls.dependentsNo.hasError('required')">Please select a
                    number of dependents</mat-error>
            </div>
        </div>


        <hr class="separator no-margin" />
        <div class="group-section">
            <h1 class="mat-headline-large">Visa information</h1>
        </div>
        <div class="group-section small-group">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.nzResident"
                label="Are you a New Zealand citizen or permanent resident?">
            </app-form-button-group-simple>
        </div>

        <ng-container *ngIf="fg.controls.nzResident.value == UserAction.No">
            <div class="group-section">
                <mat-form-field class="form-field select" subscriptSizing="dynamic">
                    <mat-label>Residency status</mat-label>
                    <mat-select [formControl]="fg.controls.visaType">
                        <mat-option value="{{NonResidentVisaTypeEnum.WorkingVisa}}">Non-resident - working
                            visa</mat-option>
                        <mat-option value="{{NonResidentVisaTypeEnum.OtherVisa}}">Non-resident - other
                            visa</mat-option>
                        <mat-option value="{{NonResidentVisaTypeEnum.NoVisaRequired}}">Non-resident - no visa
                            required</mat-option>
                    </mat-select>
                    <mat-hint>Note: You must have one of these statuses to apply for a loan with us</mat-hint>
                </mat-form-field>

                <ng-container *ngIf="fg.controls.visaType.value == NonResidentVisaTypeEnum.WorkingVisa ||
                           fg.controls.visaType.value == NonResidentVisaTypeEnum.OtherVisa">

                    <app-form-field [formSubmitted]="form.submitted" label="Visa number" placeholder="eg. 12345678"
                        [control]="fg.controls[ApplicationFormControlNames.VisaNumber] | asFormControl">
                    </app-form-field>

                    <app-form-field-date [formSubmitted]="form.submitted" label="Visa start date"
                        [control]="fg.controls[ApplicationFormControlNames.VisaStartDate] | asFormControl"
                        [dateRangeType]="DateRangeType.VisaStartDate"
                        >
                    </app-form-field-date>

                    <app-form-field-date [formSubmitted]="form.submitted" label="Visa expiry date"
                        [control]="fg.controls[ApplicationFormControlNames.VisaExpiryDate] | asFormControl"
                        [dateRangeType]="DateRangeType.VisaEndDate"
                        >
                    </app-form-field-date>
                </ng-container>

                <app-form-field-select label="Country of citizenship" [control]="fg.controls.citizenshipCountry"
                    [options]="countriesNonResident" [autocomplete]="true">
                </app-form-field-select>

                <app-form-field-select label="Country of birth" [control]="fg.controls.birthCountry"
                    [options]="countriesNonResident" [autocomplete]="true">
                </app-form-field-select>
            </div>
        </ng-container>
        <div>
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>
