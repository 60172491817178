<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            <h1 class="mat-headline-large">Employment & Income</h1>
        </div>

        <div class="group-section small-group">
            <p class="mat-body-large">What is your current employment status?</p>
            <div class="group-required">
                <mat-button-toggle-group class="button-group" aria-label="What is your current employment status?"
                    [formControl]="fg.controls.employmentStatus" [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="{{EmploymentStatus.Employed}}">Employed</mat-button-toggle>
                    <mat-button-toggle value="{{EmploymentStatus.SelfEmployed}}">Self-employed</mat-button-toggle>
                    <mat-button-toggle value="{{EmploymentStatus.Unemployed}}">Not employed</mat-button-toggle>
                    <mat-button-toggle value="{{EmploymentStatus.Student}}">Student</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-error
                    *ngIf="form.submitted && fg.controls.employmentStatus.hasError('required')">Required</mat-error>
            </div>
        </div>

        <ng-container class="group-section"
            *ngIf="fg.controls.employmentStatus.value == EmploymentStatus.Employed || fg.controls.employmentStatus.value == EmploymentStatus.SelfEmployed">
            <app-employment-basis [fg]="fg" [formSubmitted]="form.submitted"></app-employment-basis>
        </ng-container>

        <ng-container *ngIf="fg.controls.employmentStatus.value == EmploymentStatus.Unemployed">
            <div class="group-section small-group">
                <p class="mat-body-large">Please select from one of the following</p>
                <div class="group-required">
                    <mat-button-toggle-group class="button-group" aria-label="Please select from one of the following"
                        [formControl]="fg.controls.unemploymentStatus" [hideSingleSelectionIndicator]="true">
                        <mat-button-toggle
                            value="{{UnemploymentStatusEnum.Beneficiary}}">Beneficiary</mat-button-toggle>
                        <mat-button-toggle
                            value="{{UnemploymentStatusEnum.Superannuation}}">Superannuation</mat-button-toggle>
                        <mat-button-toggle
                            value="{{UnemploymentStatusEnum.SelfFunding}}">Self-funding</mat-button-toggle>
                    </mat-button-toggle-group>

                    <mat-error
                        *ngIf="form.submitted && fg.controls.unemploymentStatus.hasError('required')">Required</mat-error>
                </div>
            </div>

            <div class="group-section small-group">
                <p class="mat-body-large">What is your current income during this time?</p>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-7">
                                <app-form-field-currency [formSubmitted]="form.submitted" label="Amount"
                                    [control]="fg.controls.incomeAmount">
                                </app-form-field-currency>
                            </div>
                            <div class="col-5">
                                <app-form-field-select [control]="fg.controls.incomeFrequency"
                                    [listType]="SelectListType.FrequencyTypesAll"
                                    label="Frequency"></app-form-field-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-slide-toggle [formControl]="fg.controls.incomeAfterTax"
                            hideIcon>{{(!fg.controls.incomeAfterTax.value ? 'Before tax' : 'After
                            tax')}}</mat-slide-toggle>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="fg.controls.employmentStatus.value == EmploymentStatus.Student">
            <div class="group-section">
                <app-form-button-group-simple [formSubmitted]="form.submitted"
                    [control]="fg.controls.studentReceivesAllowance" label="Do you receive a student allowance?">
                </app-form-button-group-simple>
            </div>

            <div class="group-section">
                <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.isStudentEmployed"
                    label="Are you also employed?">
                </app-form-button-group-simple>
            </div>

            <ng-container *ngIf="fg.controls.isStudentEmployed.value == UserAction.Yes">
                <app-employment-basis [fg]="fg" [formSubmitted]="form.submitted"></app-employment-basis>
            </ng-container>
        </ng-container>


        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.hasStudentLoan"
                label="Do you have a student loan?">
            </app-form-button-group-simple>
        </div>

        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.hasOtherIncome"
                label="Do you have other sources of income?">
            </app-form-button-group-simple>
        </div>

        <ng-container *ngIf="fg.controls.otherIncome && fg.controls.hasOtherIncome.value == UserAction.Yes"
            class="section-spacing">
            <ng-container *ngFor="let incomeOther of fg.controls.otherIncome.controls; let i = index">

                <div class="group-section small-group">
                    <div class="row">
                        <div class="col-12">
                            <div class="flex-content height-24">
                                <p class="mat-body-large">Income {{i+1}}</p>
                                <button mat-button class="link-button no-background-link remove" type="button"
                                    (click)="removeIncome(i)"><mat-icon svgIcon="trash"></mat-icon>Remove</button>
                            </div>
                        </div>
                    </div>
                    <div class="group-section">
                        <app-form-field-select [control]="incomeOther.controls.type"
                            [listType]="SelectListType.IncomeSourceTypes" label="Source"></app-form-field-select>

                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-7">
                                        <app-form-field-currency [formSubmitted]="form.submitted"
                                            label="Amount (after tax)" [control]="incomeOther.controls.amount">
                                        </app-form-field-currency>
                                    </div>
                                    <div class="col-5">
                                        <app-form-field-select [control]="incomeOther.controls.incomeFrequency"
                                            [listType]="SelectListType.FrequencyTypesAll"
                                            label="Frequency"></app-form-field-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-form-field [isMultiLine]="true" label="Description"
                            [control]="incomeOther.controls.description"
                            hint="Please provide further details about this income source e.g. second job"></app-form-field>
                    </div>
                </div>
            </ng-container>
            <div class="height-24"
                *ngIf="fg.controls.hasOtherIncome.value == UserAction.Yes && this.fg.controls.otherIncome.controls.length < 5">
                <button mat-button class="link-button no-background-link" (click)="addIncome()" type="button"><mat-icon
                        svgIcon="add"></mat-icon>Add
                    another</button>
            </div>
        </ng-container>

        <div>
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>