/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NzbnCompanyResponseBaseResponse as ModelsCustomerNzbnCompanyResponseBaseResponse } from '../models/Models/Customer/nzbn-company-response-base-response';
import { NzbnCompanyResponseListBaseResponse as ModelsCustomerNzbnCompanyResponseListBaseResponse } from '../models/Models/Customer/nzbn-company-response-list-base-response';
import { v3CompaniesGet$Json } from '../fn/companies/v-3-companies-get-json';
import { V3CompaniesGet$Json$Params } from '../fn/companies/v-3-companies-get-json';
import { v3CompaniesGet$Plain } from '../fn/companies/v-3-companies-get-plain';
import { V3CompaniesGet$Plain$Params } from '../fn/companies/v-3-companies-get-plain';
import { v3CompaniesNzbnGet$Json } from '../fn/companies/v-3-companies-nzbn-get-json';
import { V3CompaniesNzbnGet$Json$Params } from '../fn/companies/v-3-companies-nzbn-get-json';
import { v3CompaniesNzbnGet$Plain } from '../fn/companies/v-3-companies-nzbn-get-plain';
import { V3CompaniesNzbnGet$Plain$Params } from '../fn/companies/v-3-companies-nzbn-get-plain';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3CompaniesGet()` */
  static readonly V3CompaniesGetPath = '/v3/companies';

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CompaniesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesGet$Plain$Response(params?: V3CompaniesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>> {
    return v3CompaniesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CompaniesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesGet$Plain(params?: V3CompaniesGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseBaseResponse> {
    return this.v3CompaniesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>): ModelsCustomerNzbnCompanyResponseBaseResponse => r.body)
    );
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CompaniesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesGet$Json$Response(params?: V3CompaniesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>> {
    return v3CompaniesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CompaniesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesGet$Json(params?: V3CompaniesGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseBaseResponse> {
    return this.v3CompaniesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>): ModelsCustomerNzbnCompanyResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3CompaniesNzbnGet()` */
  static readonly V3CompaniesNzbnGetPath = '/v3/companies/{nzbn}';

  /**
   * DTOCompanyAutocompleteDetails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CompaniesNzbnGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesNzbnGet$Plain$Response(params: V3CompaniesNzbnGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseListBaseResponse>> {
    return v3CompaniesNzbnGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * DTOCompanyAutocompleteDetails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CompaniesNzbnGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesNzbnGet$Plain(params: V3CompaniesNzbnGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseListBaseResponse> {
    return this.v3CompaniesNzbnGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseListBaseResponse>): ModelsCustomerNzbnCompanyResponseListBaseResponse => r.body)
    );
  }

  /**
   * DTOCompanyAutocompleteDetails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CompaniesNzbnGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesNzbnGet$Json$Response(params: V3CompaniesNzbnGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseListBaseResponse>> {
    return v3CompaniesNzbnGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * DTOCompanyAutocompleteDetails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CompaniesNzbnGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CompaniesNzbnGet$Json(params: V3CompaniesNzbnGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseListBaseResponse> {
    return this.v3CompaniesNzbnGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseListBaseResponse>): ModelsCustomerNzbnCompanyResponseListBaseResponse => r.body)
    );
  }

}
