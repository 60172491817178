/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApplicationQuoteRequestEx as ModelsV3ApplicationApplicationQuoteRequestEx } from '../../models/Models/v3/Application/application-quote-request-ex';
import { ApplicationQuoteResponseBaseResponse as ModelsV3ApplicationApplicationQuoteResponseBaseResponse } from '../../models/Models/v3/Application/application-quote-response-base-response';

export interface V3ApplicationsApplicationIdInsuranceQuotePost$Plain$Params {
  applicationId: string;
      body?: ModelsV3ApplicationApplicationQuoteRequestEx
}

export function v3ApplicationsApplicationIdInsuranceQuotePost$Plain(http: HttpClient, rootUrl: string, params: V3ApplicationsApplicationIdInsuranceQuotePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationsApplicationIdInsuranceQuotePost$Plain.PATH, 'post');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>;
    })
  );
}

v3ApplicationsApplicationIdInsuranceQuotePost$Plain.PATH = '/v3/applications/{applicationId}/insurance/quote';
