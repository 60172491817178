import { Component, EventEmitter, Input, OnInit, Output, NgZone } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ApplicationFormControlNames } from '../../../types/applicationFromControlNames';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../shared/shared.module';
import { FormButtonGroupSimpleComponent } from '../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldNumericComponent } from '../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldComponent } from '../../shared/form-field/form-field.component';
import { LoanEntityType } from '../../../api/models/CommonUtilities/loan-entity-type';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { MatIcon } from '@angular/material/icon';
import { BaseStepComponent } from '../base-step/base-step.component';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { ContactType } from '../../../api/models/CommonUtilities/contact-type';
import { MatSelectModule } from '@angular/material/select';
import { AsFormControlPipe } from "../../../transforms/form-control-pipe";
import { LoanTypeEntityDirectorForm, LoanTypeEntityForm, LoanTypeForm, LoanTypeIndividualForm } from '../../../types/vehicle-application-types';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { startWith, pairwise, timeout } from 'rxjs';
import { conditionalRequiredValidator, dateValidatorMoment } from '../../../types/validators';
import { ListItem } from '../../../types/listItem';
import { LookupService } from '../../../services/lookup.service';
import { Country } from '../../../api/models/CommonUtilities/country';
import { FormFieldSelectComponent, SelectListType } from '../../shared/form-field-select/form-field-select.component';
import { Constants } from '../../../dataobject/constants';
import { DateRangeType, FormFieldDateComponent } from '../../shared/form-field-date/form-field-date.component';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FormFieldAutocompleteComponent } from '../../shared/form-field-autocomplete/form-field-autocomplete.component';
import { ModelsCustomerNzbnCompanyDetailsRole } from '../../../api/models';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';

@Component({
  selector: 'app-loan-type-step',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormButtonGroupSimpleComponent,
    MatSlideToggle,
    MatIcon,
    MatOption,
    MatSelectModule,
    AsFormControlPipe,
    FormFieldSelectComponent,
    FormFieldDateComponent,
    MatAutocomplete,
    FormFieldAutocompleteComponent
  ],
  templateUrl: './loan-type-step.component.html',
  styleUrl: './loan-type-step.component.scss',
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class LoanTypeStepComponent extends BaseStepComponent implements OnInit {
  @Output() formGroupEmitter = new EventEmitter<FormGroup<LoanTypeForm>>();
  @Output() removeApplicantEmitter = new EventEmitter<number>();

  @Input() parentStepper!: MatStepper;
  @Input() fg!: FormGroup<LoanTypeForm>;
  @Input() formSubmitted!: boolean;

  ApplicationFormControlNames = ApplicationFormControlNames;
  LoanEntityType = LoanEntityType;
  UserAction = UserAction;
  ContactType = ContactType;
  SelectListType = SelectListType;
  DateRangeType = DateRangeType;

  isManualCompanyEdit: boolean = false;
  countriesPhone: ListItem[] = [];
  legalNameLabel: string = 'Company legal name';
  entityTypeDisplay: string = '';

  constructor(private fb: FormBuilder,
    private ngZone: NgZone,
    vehicleAppService: VehicleAppService,
    private lookupService: LookupService
  ) {
    super(vehicleAppService);

    lookupService.getCountries().subscribe((countries) => {
      //filter countries for non-resident
      this.countriesPhone = countries;
    });

    this.vehicleAppService.individualType$.subscribe(value => {
      this.entityTypeDisplay = value;
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.fg.controls.hasAdditionalBorrowers.valueChanges
      .pipe(
        startWith(this.fg.controls.hasAdditionalBorrowers.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            // remove all the but the first individual
            while (this.fg.controls.individuals.length > 1) {
              this.removeIndividual(1);
            }
          } else {
            if (this.fg.controls.individuals.length === 1) {
              this.addIndividual();
            }
          }
        }
      });


  }

  private clearIndividuals(){
    this.fg.controls.individuals.clear();
    this.removeApplicantEmitter.emit(-1);
  }

  private clearEntities() {
    this.fg.controls.entities.clear();
    if (this.vehicleAppService.vehicleApplicationFg?.entityFg) {
      this.vehicleAppService.totalSteps -= 2;
      this.vehicleAppService.vehicleApplicationFg.entityFg = null;
    }
  }

  public onLoanEntityTypeValChange(value: LoanEntityType) {

    //this.entityTypeDisplay = this.vehicleAppService.getLoanEntityTypeDisplay(value);
    this.vehicleAppService.setEntityType(value);

    switch (value) {
      case LoanEntityType.Individual:
      case LoanEntityType.Partner:
      case LoanEntityType.SoleTrader:
        this.enableControls([this.fg.controls.hasAdditionalBorrowers]);
        this.clearIndividuals();
        if (this.fg.controls.individuals.length == 0) {
          this.addIndividual(true, ContactType.Primary);
        }
        this.clearEntities();

        break;
      case LoanEntityType.Company:
      case LoanEntityType.Trust:
      case LoanEntityType.Other:
        this.disableControls([this.fg.controls.hasAdditionalBorrowers]);
        this.clearIndividuals();
        this.clearEntities();

        if (this.fg.controls.entities.length == 0) {
          this.addEntity(value);
        }

        break;
    }
    console.log(value);
  }


  addIndividual(setContactType: boolean = false, contactType: ContactType = ContactType.Primary, index: number = -1): FormGroup<LoanTypeIndividualForm> {
    var fc = this.fb.group<LoanTypeIndividualForm>({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      contactType: new FormControl(setContactType ? contactType : null, Validators.required),
      mobile: new FormControl(''),
      birthDate: new FormControl(''),
      email: new FormControl(''),
      directorIndex: new FormControl(-1),
    });

    if (index == -1) {
      this.fg.controls.individuals.push(fc);
    } else {
      fc.controls.directorIndex.setValue(index);
      this.fg.controls.individuals.insert(index, fc);
    }

    return fc;
  }

  removeIndividual(index: number) {
    this.fg.controls.individuals.removeAt(index);
    this.removeApplicantEmitter.emit(index);

    if (this.fg.controls.individuals.length === 1) {
      this.fg.controls.hasAdditionalBorrowers.setValue(UserAction.No);
    }
  }

  getDirectorIndividualFg(directorIndex: number): FormGroup<LoanTypeIndividualForm> | null {

    for (let indivFg of this.fg.controls.individuals.controls) {
      if (indivFg.controls.directorIndex.value == directorIndex) {
        return indivFg;
      }
    }
    return null;
  }

  removeIndividualDirectorGuarantor(directorIndex: number) {

    let indexToRemove = -1;
    this.fg.controls.individuals.controls.forEach((indivFg, index) => {
      if (indivFg.controls.directorIndex.value == directorIndex) {
        indexToRemove = index;
      }
    });

    if (indexToRemove != -1) {
      this.removeIndividual(indexToRemove);
    }
  }


  addEntity(applicantType: LoanEntityType) {
    this.legalNameLabel = 'Company legal name';

    var fc = this.fb.group<LoanTypeEntityForm>({
      caption: new FormControl({ value: '', disabled: true }, Validators.required),
      legalName: new FormControl('', Validators.required),
      isManualCompanyNameEdit: new FormControl(false),
      nzbn: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern('^[0-9]{13}$')]),
      directors: this.fb.array<FormGroup<LoanTypeEntityDirectorForm>>([])
    });

    if (applicantType == LoanEntityType.Company) {
      fc.controls.nzbn.enable();
      fc.controls.caption.enable();

      fc.controls.directors = this.fb.array<FormGroup<LoanTypeEntityDirectorForm>>([
        this.buildDirectorFormGroup(),
        this.buildDirectorFormGroup()
      ]);

      this.subscriptions.push(fc.valueChanges.subscribe((value) => {
        let duplicateEmailFound = false;
        const emailAddresses = fc.controls.directors.controls.map(directorFg => directorFg.controls.email.value);

        // Check for duplicates
        const emailSet = new Set(emailAddresses);
        if (emailSet.size !== emailAddresses.length) {
          duplicateEmailFound = true;

          //foreach fc.controls.directors
          fc.controls.directors.controls.forEach((directorFg, index) => {
            const email = directorFg.controls.email.value;
            const duplicateEmail = emailAddresses.filter((emailAddress) => emailAddress === email);
            if (duplicateEmail.length > 1) {
              directorFg.controls.email.setErrors({ 'duplicate': true });
            }
          });
        }

        // Clear the duplicate error if no duplicate found
        if (!duplicateEmailFound) {
          fc.controls.directors.controls.forEach((directorFg, index) => {
            this.clearError(directorFg.controls.email, Constants.CustomErrors_Duplicate);
          });
        }




      }));
    }
    else {
      if (applicantType == LoanEntityType.Other) {
        fc.controls.isManualCompanyNameEdit.setValue(true);
        this.legalNameLabel = 'Entity legal name';
        fc.controls.nzbn.disable();
        fc.controls.caption.disable();
        this.addIndividual(false);

      } else if (applicantType == LoanEntityType.Trust) {
        fc.controls.isManualCompanyNameEdit.setValue(true);
        this.legalNameLabel = 'Trust legal name';
        fc.controls.nzbn.disable();
        fc.controls.caption.disable();
        this.addIndividual(false);

      } else {
        this.addIndividual(true, ContactType.Guarantor);
      }
    }


    this.fg.controls.entities.push(fc);
  }

  buildDirectorFormGroup(): FormGroup<LoanTypeEntityDirectorForm> {
    var dFg = this.fb.group<LoanTypeEntityDirectorForm>({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      //mobileCountry: new FormControl(Country.Newzealand, Validators.required),
      addAsGuarantor: new FormControl(false),
      birthDate: new FormControl(null, [Validators.required, dateValidatorMoment()]),
      companyLookup: new FormControl(null),
    });

    this.subscriptions.push(dFg.controls.addAsGuarantor.valueChanges
      .pipe(
        startWith(dFg.controls.addAsGuarantor.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {

        if (newValue != oldValue) {
          // get the index of the director
          let index = this.getPrimaryEntity().controls.directors.controls.indexOf(dFg);

          if (newValue) {
            var individualFg = this.addIndividual(true, ContactType.Guarantor, index);
            individualFg.controls.firstName.setValue(dFg.controls.firstName.value);
            individualFg.controls.middleName.setValue(dFg.controls.middleName.value);
            individualFg.controls.lastName.setValue(dFg.controls.lastName.value);
            individualFg.controls.mobile.setValue(dFg.controls.mobile.value);
            individualFg.controls.email.setValue(dFg.controls.email.value);
            individualFg.controls.birthDate.setValue(dFg.controls.birthDate.value);
            individualFg.disable();
          }
          else {
            this.removeIndividualDirectorGuarantor(index);
          }
        }
      }));

    this.subscriptions.push(dFg.valueChanges.subscribe((value) => {

      //check if the director is a guarantor
      if (dFg.controls.addAsGuarantor.value) {
        // get the index of the director
        let index = this.getPrimaryEntity().controls.directors.controls.indexOf(dFg);

        // get the individual form group by the director's index        
        var individualFg = this.getDirectorIndividualFg(index);
        if (individualFg) {
          individualFg.controls.firstName.setValue(dFg.controls.firstName.value);
          individualFg.controls.middleName.setValue(dFg.controls.middleName.value);
          individualFg.controls.lastName.setValue(dFg.controls.lastName.value);
          individualFg.controls.mobile.setValue(dFg.controls.mobile.value);
          individualFg.controls.email.setValue(dFg.controls.email.value);
          individualFg.controls.birthDate.setValue(dFg.controls.birthDate.value);
        }
      }

    }));
    return dFg;
  }

  removeEntity(index: number) {
    this.fg.controls.entities.removeAt(index);
    this.removeApplicantEmitter.emit(index);
  }

  setCompanyManualEditVisibility(isManualEdit: boolean) {
    this.getPrimaryEntity().controls.isManualCompanyNameEdit.setValue(isManualEdit);
  }

  finalizeStep() {
    if (this.fg.controls.loanEntityType.value == LoanEntityType.Company) {
      var entity = this.getPrimaryEntity();
      entity.disable();
      entity.enable();
    }

    super.refreshValidators(this.fg, this.parentStepper, false);

    if (this.fg.valid) {
      console.log('finalize loan type step - is valid');

      this.formGroupEmitter.emit(this.fg);

      // Run the next line of code after the event is fully emitted
      // Introduce a delay before running the next line of code
      const delay = 1; // Delay in milliseconds (e.g., 1000ms = 1 second)
      setTimeout(() => {
        this.ngZone.run(() => {
          this.vehicleAppService.moveCurrentStepCountForward();
          this.parentStepper.next();
        });
      }, delay);

    }
  }

  addDirector(): void {
    this.getPrimaryEntity().controls.directors.push(this.buildDirectorFormGroup());
  }
  removeDirector(index: number): void {
    this.getPrimaryEntity().controls.directors.removeAt(index);
    
    var indivFg = this.getDirectorIndividualFg(index);
    if(indivFg)
    {
      this.removeIndividual(this.fg.controls.individuals.controls.indexOf(indivFg));
    }
  }

  companies: any[] = [];
  companyOptions: ListItem[] = [];
  currentCompanySearch: string = '';
  public filterCompanies(search: string) {
    console.log('filterCompanies', search);
    if (search.length < 5) {
      console.log('filterCompanies reset company details');
      this.companies = [];
      this.companyOptions = [];
      var entity = this.getPrimaryEntity();
      entity.controls.legalName.setValue('');
      entity.controls.nzbn.setValue('');
      return;
    }

    // console.log('filterAddresses', event);
    //if the search is the same as the last search, don't do anything
    if (search == this.currentCompanySearch) {
      console.log('filterCompanies search is the same as the last search');
      return;
    }

    this.currentCompanySearch = search;
    this.lookupService.getCompanyAutocomplete(search)
      .subscribe((companies) => {
        // console.log(companies);
        this.companies = companies;

        // Filters the companies array to include only those with an entityStatusDescription of 'Registered'.
        // Then maps the filtered companies to a new array of objects with 'value' set to the company's nzbn
        // and 'caption' set to the company's entityName. If the company has a tradingName that is not 'no trading name'
        // and is different from the entityName, it appends ' t/a ' followed by the tradingName to the caption.
        this.companyOptions = companies
          .filter(company => company.entityStatusDescription == 'Registered')
          .filter(company => company.entityTypeCode == 'LTD')
          .map(company => (
            {
              value: company.nzbn,
              valueCaption: company.nzbn,
              displayName: company.entityName +
                (company.tradingName &&
                  company.tradingName.toLowerCase() !== 'no trading name' &&
                  company.tradingName.toLowerCase() !== company.entityName?.toLowerCase() ?
                  ' t/a ' + company.tradingName : '')
            } as ListItem
          ));

        // console.log(this.companyOptions);
      });
  }


  getPrimaryEntity(): FormGroup<LoanTypeEntityForm> {
    return this.fg.controls.entities.controls[0];
  }

  public filterCompaniesOptionSelected(option: ListItem) {
    const nzbn = option.value;

    var entity = this.getPrimaryEntity();
    console.log('filterCompaniesOptionSelected', nzbn, entity.controls.caption.value);

    entity.controls.nzbn.setValue(nzbn);

    //set legal name to the caption value
    entity.controls.legalName.setValue(option.displayName);

    //fetch the company details from the api
    this.lookupService.getCompanyDetails(option.value)
      .subscribe({
        next: (company) => {
          console.log('Company details fetched', company);
          if (!company) throw new Error('Company details not found');

          //remove all individuals so the any guarantor details are not 'hanging' after a company change occurs
          for (let i = this.fg.controls.individuals.length - 1; i >= 0; i--) {
            this.removeIndividual(i)
          }
          //this.addIndividual(true, ContactType.Guarantor);

          //add director 1 details
          if (company.roles && company.roles.length > 0) {
            //filter for roleType of "Director"
            var directors = company.roles.filter(role => role.roleType == 'Director');
            if (directors.length > 0) {
              if (entity.controls.directors.length > 0) {
                entity.controls.directors.controls[0].reset();
              }

              this.populateDirectorDetails(entity.controls.directors.controls[0], directors[0]);
            }
            else {
              if (entity.controls.directors.length > 0) {
                entity.controls.directors.controls[0].reset();
              }
            }

            if (directors.length > 1) {
              if (entity.controls.directors.length > 1) {
                entity.controls.directors.controls[1].reset();
              }

              this.populateDirectorDetails(entity.controls.directors.controls[1], directors[1]);
            } else {
              if (entity.controls.directors.length > 1) {
                entity.controls.directors.controls[1].reset();
              }
            }
          }

          //entity.disable();
          //setTimeout(() => { entity.enable(); });

        },
        error: (errorResponse) => {
          console.error('Error fetching company details', errorResponse);
        }
      });
  }

  populateDirectorDetails(fg: FormGroup<LoanTypeEntityDirectorForm>, role: ModelsCustomerNzbnCompanyDetailsRole) {
    fg.controls.firstName.setValue(role?.rolePerson?.firstName ?? '');
    fg.controls.middleName.setValue(role?.rolePerson?.middleNames ?? '');
    fg.controls.lastName.setValue(role?.rolePerson?.lastName ?? '');
  }

  // addDirectorAsGuarantor(director: FormGroup<LoanTypeEntityDirectorForm>)
  // {

  //   let index = this.getPrimaryEntity().controls.directors.controls.indexOf(director);
  //   //check if index exists in this.fg.controls.individuals
  //   //if it does, then add the director as a guarantor
  //   //if it doesn't, then add the director as a primary applicant
  //   var individualFg = this.fg.controls.individuals.controls[index];
  //   if (!individualFg)
  //   {
  //     individualFg = this.addIndividual(true, ContactType.Guarantor);
  //   }

  //   individualFg.controls.firstName.setValue(director.controls.firstName.value);
  //   individualFg.controls.middleName.setValue(director.controls.middleName.value);
  //   individualFg.controls.lastName.setValue(director.controls.lastName.value);

  // }




}
