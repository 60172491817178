/* tslint:disable */
/* eslint-disable */
export enum VehicleClass {
  None = 'None',
  PassengerVehicle = 'PassengerVehicle',
  GoodsVehicle = 'GoodsVehicle',
  Motorcycle = 'Motorcycle',
  Boat = 'Boat',
  Bus = 'Bus',
  Caravan = 'Caravan',
  Machine = 'Machine',
  Motorhome = 'Motorhome',
  All = 'All'
}
