import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ButtonNextEventArgs } from '../../../dataobject/button-next-event-args';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { first, Subscription } from 'rxjs';
import { Condition } from '../../../dataobject/condition';
import { Country } from '../../../api/models/CommonUtilities/country';
import { AddressForm } from '../../../types/vehicleIndividualSteps';

@Component({
  selector: 'app-base-step',
  standalone: true,
  imports: [],
  templateUrl: './base-step.component.html',
  styleUrl: './base-step.component.scss'
})
export class BaseStepComponent implements OnInit {

  @Output() onButtonNextEvent = new EventEmitter<ButtonNextEventArgs>();

  subscriptions: Subscription[] = []

  constructor(public vehicleAppService: VehicleAppService) {
    // Common constructor logic
    // console.log('BaseStepComponent constructor');
  }

  ngOnInit(): void {

  }

  protected destroy() {
    // console.log('destroy');
    this.unsubscribe();
  }

  protected unsubscribe() {
    for (let s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }


  initialiseSubscriptions(formDef: any) {
    // for (const [k, v] of Object.entries(formDef))
    // {
    // 	if (v instanceof (FormFieldBase<any>))
    // 	{
    // 		const ffb = v as FormFieldBase<any>;
    // 		if (ffb && ffb.options?.revealCondition && ffb.options?.revealCondition?.function && ffb.options.revealCondition.controls)
    // 		{
    // 			this.initialiseSubscription(ffb.options.revealCondition, ffb);
    // 		}
    // 	}
    // }
  }


  refreshValidators(fg: FormGroup, stepper: MatStepper, moveNext: boolean = true) {
    // this is needed to check the validators on the form button next, to remove any required validators that were added during toggling of controls
    // needed this for married/partner not on application with shared expenses (living expenses)
    fg.updateValueAndValidity();
    //this.updateNestedFormGroupValidity(fg);

    // debugger;

    if (moveNext) {
      // if (fg.valid) {
      //   stepper.next();
      //   this.vehicleAppService.moveCurrentStepCountForward();
      // }
    }

    if (!fg.valid) {
      this.scrollToInvalidControl();
      console.log('invalid', fg);
    } else {
      console.log('valid', fg);
    }
  }

  private updateNestedFormGroupValidity(fg: FormGroup): void {

    Object.keys(fg.controls).forEach(key => {
      const control = fg.get(key);
      if (control instanceof FormGroup) {
        this.updateNestedFormGroupValidity(control);
      } else if (control) {

        // Get existing errors
        const existingErrors = control.errors || null;

        // // Store custom errors
        // const customErrors = { 'noMatch': true, 'duplicate': true };

        // // Merge existing errors with custom errors
        // const newErrors = { ...existingErrors, ...customErrors };

        // Set merged errors
        //control.setErrors(newErrors);
        //control.setErrors(existingErrors);

        // Update value and validity

        const manualErrorKeys = ['noMatch', 'duplicate'];

        if (this.hasManuallySetErrors(control, manualErrorKeys)) {
          console.log('The control has manually set errors.');
        } else {
          if (control.invalid) {
            control.updateValueAndValidity();
          }
        }

        // if(existingErrors != null){
        //   var newErrors = { ...control.errors, ...existingErrors };
        //   control.setErrors(newErrors);
        // }
        // Reapply custom errors
        //control.setErrors({ ...control.errors, ...customErrors });
        //control.setErrors({ ...control.errors, ...existingErrors });
      }
    });
  }

  hasManuallySetErrors(control: AbstractControl, manualErrorKeys: string[]): boolean {
    const errors = control.errors;
    if (!errors) {
      return false;
    }

    return manualErrorKeys.some(key => errors.hasOwnProperty(key));
  }

  markFormGroupUntouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        // Recursive call for nested FormGroup
        this.markFormGroupUntouched(control);
      } else if (control instanceof FormArray) {
        // Handle FormArray: iterate through each FormGroup in the FormArray
        control.controls.forEach(group => {
          if (group instanceof FormGroup) {
            this.markFormGroupUntouched(group);
          }
        });
      } else {
        // Mark FormControl as untouched and pristine
        control.markAsUntouched();
        control.markAsPristine();
      }
    });
  }


  onButtonNext(fg: FormGroup, stepper: MatStepper) {
    this.refreshValidators(fg, stepper, true);

    this.onButtonNextEvent.emit({
      fg: fg,
      stepper: stepper
    });
  }

  scrollToInvalidControl() {
    const firstInvalidControl: HTMLElement = document.querySelector('.ng-invalid:not(form)') as HTMLElement;

    if (firstInvalidControl) {
      console.log(firstInvalidControl);
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //firstInvalidControl.focus();
    }
  }



  clearError(fc: FormControl, errorCode: string) {
    // Get the current errors
    const currentErrors = fc.errors;

    // Check if there are any errors and if the 'duplicate' error exists
    if (currentErrors && currentErrors[errorCode]) {
      // Remove the 'duplicate' error
      delete currentErrors[errorCode];

      // Set the updated errors back to the form control
      if (Object.keys(currentErrors).length === 0) {
        fc.setErrors(null);
      } else {
        fc.setErrors(currentErrors);
      }
    }
  }


  setupConditionalControl(
    formGroup: FormGroup,
    controlToWatch: string,
    controlToToggle: string,
    condition: (value: any) => boolean
  ): void {

    this.subscriptions.push(
      formGroup.controls[controlToWatch].valueChanges.subscribe((value) => {
        if (condition(value)) {
          formGroup.controls[controlToToggle].enable();
        } else {
          formGroup.controls[controlToToggle].reset();
          formGroup.controls[controlToToggle].disable();
        }
      }));
  }


  activateControlsWhen(
    controlToWatch: FormControl,
    controlsToToggleEnable: FormControl[] | null,
    controlsToToggleDisable: FormControl[] | null,
    conditions: Condition[]
  ): void {

    this.subscriptions.push(
      controlToWatch.valueChanges.subscribe((value) => {

        const allConditionsMet = conditions.every(condition =>
          condition.expectedValues.includes(condition.control.value)
        );

        if (allConditionsMet) {
          //enable the contorls that meet the conditions
          this.enableControls(controlsToToggleEnable);

          //Disable any controls that don't meet the conditions
          this.disableControls(controlsToToggleDisable);
        } else {

          //disable and reset the controls that don't meet the conditions
          this.disableControls(controlsToToggleEnable);

          // Reverse the logic for any controls that don't meet the conditions
          this.enableControls(controlsToToggleDisable);
        }
      }));
  }

  enableControls(controlsToToggle: FormControl[] | null) {
    if (controlsToToggle) {
      // Enable all controls
      controlsToToggle.forEach(control => {
        if (!control.enabled) {
          control.enable({ emitEvent: true });
        }
      });
    }
  }

  disableControls(controlsToToggle: FormControl[] | null) {
    if (controlsToToggle) {
      controlsToToggle.forEach(control => {
        if (!control.disabled) {
          control.reset();
          control.disable({ emitEvent: false });
        }
      });
    }
  }

  disableFormGroupControls(formGroupsToToggle: FormGroup[] | null) {
    if (formGroupsToToggle) {

      formGroupsToToggle.forEach(fg => {
        if (fg) {
          fg.reset();
          fg.disable({ emitEvent: false });
          Object.keys(fg.controls).forEach(key => {
            const formControl = fg.controls[key] as FormControl;
            if (!formControl.disabled) {
              formControl.reset();
              formControl.disable({ emitEvent: false });
            }
          });
        }
      });
    }
  }

  enableFormGroupControls(formGroupsToToggle: FormGroup[] | null) {
    if (formGroupsToToggle) {

      // Enable all controls
      formGroupsToToggle.forEach(fg => {
        if (fg) {
          fg.enable({ emitEvent: true });
          Object.keys(fg.controls).forEach(key => {
            const formControl = fg.controls[key] as FormControl;
            if (!formControl.enabled) {
              formControl.enable({ emitEvent: true });
            }
          });
        }
      });
    }
  }

  // activateControlWhen(
  //   controlToWatch: FormControl,
  //   controlsToToggleEnable: FormControl[],
  //   controlsToToggleDisable: FormControl[] | null,
  //   conditions: Condition[]
  // ): void {

  //   controlToWatch.valueChanges.subscribe(() => {
  //     const allConditionsMet = conditions.every(condition =>
  //       condition.control.value === condition.expectedValue
  //     );

  //     if (allConditionsMet) {
  //       formGroup.controls[controlToToggle].enable({ emitEvent: false });
  //     } else {
  //       formGroup.controls[controlToToggle].disable({ emitEvent: false });
  //     }
  //   });
  // }


  copyAddressPhysicalToPostal(physicalControls:AddressForm, postalControls:AddressForm): void
  {
    Object.keys(physicalControls).forEach(key =>
    {
      if (postalControls[key])
      {
        postalControls[key].setValue(physicalControls[key].value);
      }
    });
  }

  clearAddress(addressControls:AddressForm): void
  {
    addressControls.caption.setValue('');
    addressControls.city.setValue('');
    addressControls.postCode.setValue('');
    addressControls.street.setValue('');
    addressControls.streetNo.setValue('');
    addressControls.suburb.setValue('');    
    addressControls.region.setValue('');    
    addressControls.isManualEdit.setValue(false);
    addressControls.durationMonths.setValue(null);
    addressControls.livingSituation.setValue(null);

    //set country to New Zealand
    addressControls.country.setValue(Country.Newzealand);
  }


}
