/* tslint:disable */
/* eslint-disable */
export enum PeriodTypeEnum {
  None = 'None',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually'
}
