/* tslint:disable */
/* eslint-disable */
export enum PreferredLoanTermEnum {
  None = 'None',
  Months112 = 'Months1_12',
  Months1324 = 'Months13_24',
  Months2536 = 'Months25_36',
  Months3748 = 'Months37_48',
  Months4960 = 'Months49_60'
}
