import { debounceTime, pairwise, startWith } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, Host, Input, Optional } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { ApplicationFormControlNames } from '../../../../types/applicationFromControlNames';
import { Constants } from '../../../../dataobject/constants';
import { LivingSituationEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { PeriodTypeEnum } from '../../../../api/models/Models/v3/Application/FinancialDetails/period-type-enum';
import { AddressForm, Step1PersonalDetailsForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { Country } from '../../../../api/models/CommonUtilities/country';
import { ModelsCustomerAutocompleteAddress } from '../../../../api/models';
import { IndividualApplicantComponent } from '../../individual-applicant/individual-applicant.component';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { MyErrorStateMatcher } from '../../../../types/custom-error-state-matcher';
import { AddressRequest } from '../../../../api/models/Models/v3/Application/PersonalDetails/address-request';

@Component({
  selector: 'app-personal-detail',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
  ],
  templateUrl: './personal-detail.component.html',
  styleUrl: './personal-detail.component.scss',
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class PersonalDetailComponent extends BaseStepComponent {
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;
  @Input() fg!: FormGroup<Step1PersonalDetailsForm>;
  @Input() parentStepper!: MatStepper;

  ApplicationFormControlNames = ApplicationFormControlNames;
  Constants = Constants;
  LivingSituationEnum = LivingSituationEnum;
  PeriodTypeEnum = PeriodTypeEnum;
  SelectListType = SelectListType;
  UserAction = UserAction;
  
  showPartnerAddressQuestion: boolean = false;
  partnerFg: FormGroup<VehicleIndividualForm> | null = null;
  parentPartnerFg: FormGroup<VehicleIndividualForm> | null = null;

  livingPaymentLabel: string = '';
  individualHeaderDisplay: string = '';

  constructor(
    vehicleAppService: VehicleAppService,
    @Optional() @Host() private applicantComponent: IndividualApplicantComponent) {
    super(vehicleAppService);
  }


  override ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);      
    }));

    this.fg.controls.addressPostalIsSame.valueChanges.subscribe((value) => {
      if (value) {
        this.copyAddressPhysicalToPostal(this.fg.controls.addressPhysical.controls, this.fg.controls.addressPostal.controls)
      }
      else {
        this.clearAddress(this.fg.controls.addressPostal.controls);
      }
    });

    this.fg.controls.emailAddress.valueChanges.subscribe((value) => {
      var duplicateEmailFound = false;
      this.vehicleAppService.vehicleApplicationFg?.indivFormArray?.controls.forEach((individualFg) => {
        if (individualFg != this.parentFg && individualFg.controls.step1.controls.emailAddress.value == value) {
          this.fg.controls.emailAddress.setErrors({ 'duplicate': true });
          duplicateEmailFound = true;
        }
      });

      //clear the duplicate error if no duplicate found
      if (!duplicateEmailFound) {
        this.clearError(this.fg.controls.emailAddress, Constants.CustomErrors_Duplicate);
      }
    });

    this.fg.controls.mobileNumber.valueChanges.subscribe((value) => {
      var duplicateFound = false;
      this.vehicleAppService.vehicleApplicationFg?.indivFormArray?.controls.forEach((individualFg) => {
        if (individualFg != this.parentFg && individualFg.controls.step1.controls.mobileNumber.value == value) {
          this.fg.controls.mobileNumber.setErrors({ 'duplicate': true });
          duplicateFound = true;
        }
      });

      //clear the duplicate error if no duplicate found
      if (!duplicateFound) {
        this.clearError(this.fg.controls.mobileNumber, Constants.CustomErrors_Duplicate);
      }
    });


    this.activateControlsWhen(this.fg.controls.addressPhysical.controls.livingSituation,
      null, [this.fg.controls.livingPaymentAmount, this.fg.controls.livingPaymentFrequency],
      [{ control: this.fg.controls.addressPhysical.controls.livingSituation, expectedValues: [LivingSituationEnum.HomeownerWithoutMortgage] }]);


    this.parentFg.controls.step2.controls.partnerIdentifier.valueChanges
      .pipe(
        startWith(this.parentFg.controls.step2.controls.partnerIdentifier.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          var dependentPartnerFg = this.vehicleAppService.getDependentPartnerFg(this.parentFg, newValue);

          if (dependentPartnerFg && dependentPartnerFg != this.parentFg) {
            this.showPartnerAddressQuestion = false;
            this.fg.controls.isAddressProvidedByPartner.disable();
            //this.partnerFg = dependentPartnerFg;
          } else {
            this.showPartnerAddressQuestion = true;
            this.partnerFg = this.vehicleAppService.getParentPartnerFg(this.parentFg, newValue);
            this.fg.controls.isAddressProvidedByPartner.enable();
          }
        } else {
          if (newValue == null && oldValue) {
            // Look to see if there is a parent partner
            this.parentPartnerFg = this.vehicleAppService.getParentPartnerFg(this.parentFg, oldValue);
            if (this.parentPartnerFg) {
              console.log('parentPartnerFg', this.parentPartnerFg);             
            }

            this.showPartnerAddressQuestion = false;
            this.partnerFg = null;
            this.fg.controls.isAddressProvidedByPartner.enable();
            this.fg.controls.isAddressProvidedByPartner.setValue(null);
          }
        }
      });

    this.fg.controls.isAddressProvidedByPartner.valueChanges
      .pipe(
        startWith(this.fg.controls.isAddressProvidedByPartner.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue != oldValue) {
          if (newValue == UserAction.Yes) {            
            this.parentPartnerFg = this.vehicleAppService.getParentPartnerFg(this.parentFg, this.parentFg.controls.step2.controls.partnerIdentifier.value!);
            
            if(this.parentPartnerFg && this.parentPartnerFg.controls.step1.controls.addressPhysical.controls.durationMonths.value! < Constants.App_ShowPreviousAddressMonths){
              this.disableFormGroupControls([this.fg.controls.addressPhysical, this.fg.controls.addressPostal]);
              this.enableFormGroupControls([this.fg.controls.addressPrevious]);
              this.fg.setControl(ApplicationFormControlNames.AddressPrevious, this.vehicleAppService.buildAddressFg({} as AddressRequest));
              
            }else{
              this.disableFormGroupControls([this.fg.controls.addressPhysical, this.fg.controls.addressPostal, this.fg.controls.addressPrevious]);              
            }
            
            this.fg.controls.addressPostalIsSame.disable()
            this.fg.controls.addressPostalIsSame.setValue(true);
            this.fg.controls.livingPaymentAmount.disable();
            this.fg.controls.livingPaymentFrequency.disable();
          } else {
            this.enableFormGroupControls([this.fg.controls.addressPhysical, this.fg.controls.addressPostal, this.fg.controls.addressPrevious]);
            this.enableControls([this.fg.controls.addressPostalIsSame]);
          }
        }
      });


    this.subscriptions.push(this.fg.controls.addressPhysical.controls.livingSituation.valueChanges.subscribe((value) => {
      switch (value) {
        case LivingSituationEnum.Mortgaged:
          this.livingPaymentLabel = 'Mortgage repayments';
          break;
        case LivingSituationEnum.Renter:
        case LivingSituationEnum.EmployerProvided:
          this.livingPaymentLabel = 'Rent amount';
          break;
        case LivingSituationEnum.Boarder:
        case LivingSituationEnum.LivingWithFamily:
          this.livingPaymentLabel = 'Board amount';
          break;
      }
      this.fg.controls.livingPaymentAmount.setValue(null, {emitEvent:false});
    }));
  }

  physicalAddressChanged(address: ModelsCustomerAutocompleteAddress) {
    // console.log('physicalAddressChanged', address);
    if (this.fg.controls.addressPostalIsSame.value) {
      setTimeout(() =>
        this.copyAddressPhysicalToPostal(this.fg.controls.addressPhysical.controls, this.fg.controls.addressPostal.controls)
        , 0);
    }
  }

}
