/* tslint:disable */
/* eslint-disable */
export enum ApplicationSyncRequestLivingSituation {
  None = 'None',
  Homeowner = 'Homeowner',
  Mortgaged = 'Mortgaged',
  Renter = 'Renter',
  Boarder = 'Boarder',
  LivingWithFamily = 'LivingWithFamily',
  GovernmentHousing = 'GovernmentHousing',
  EmployerProvided = 'EmployerProvided',
  Campground = 'Campground',
  Other = 'Other',
  Any = 'Any'
}
