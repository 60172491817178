import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PostMessageService
{

	public progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(100);
	public focusSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor() { }

	private emitMessage(msg: WindowMessage)
	{
		window?.parent?.postMessage(msg, '*');
	}

	inIFrame() : boolean{
		return window.location != window.parent.location;
	}

	inIFramePopup() : boolean{
		return window.location != window.parent.location && window.location.href.includes('&popup=true');
	}

	createMessageBase(event: eventName, type: eventType): WindowMessage
	{
		return {
			event: event,
			type: type
		};
	}

	emitResize(newSize: number)
	{
		let x = this.createMessageBase('iframe-resize', 'iframe') as IframeResizeEvent;
		//x.height = newSize + 100;
		//x.height = newSize + 90;
		x.height = newSize;

		this.emitMessage(x);
	}	

	emitNavigated()
	{
		let x = this.createMessageBase('navigated-step', 'navigation');
		this.emitMessage(x);
	}

	emitClosed()
	{
		let x = this.createMessageBase('iframe-close', 'iframe');
		this.emitMessage(x);
	}

	emitRequestContainerHeight()
	{
		let x = this.createMessageBase('iframe-height-request', 'iframe');
		this.emitMessage(x);
	}
}

interface WindowMessage
{
	event: string,
	type: string
}
interface IframeResizeEvent extends WindowMessage
{
	width: number,
	height: number
}
type eventType = 'iframe' | 'navigation' | ''
type eventName = 'iframe-resize' | 'iframe-close' | 'navigated-step' | 'iframe-height-request' | ''
