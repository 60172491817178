export enum ApplicationFormControlNames {
    FirstName = 'firstName',
    LastName = 'lastName',
    MiddleName = 'middleName',
    PreferredName = 'preferredName',
    EmailAddress = 'emailAddress',
    MobileNumber = 'mobileNumber',
    DaytimeNumber = 'daytimeNumber',
    
    
    //About Step
    PurVehicleType = 'purVehicleType',
    HasVehicle = 'hasVehicle',
    IsBusinessPurpose = 'isBusinessPurpose',
    LoanAmount = 'loanAmount',
    HasOtherItems = 'hasOtherItems',
    OtherItemsCost = 'otherItemsCost',
    HasDeposit= 'hasDeposit',
    DepositAmount= 'depositAmount',
    ApproveLesserAmount= 'approveLesserAmount',

    //LoanType Step
    LoanEntityType = 'loanEntityType',
    ApplicantFirstName = 'applicantFirstName',
    ApplicantLastName = 'applicantLastName',
    Individuals = 'individuals',
    HasAdditionalBorrowers = 'hasAdditionalBorrowers',
    ContactType = 'contactType',
    LivingPaymentAmount = 'livingPaymentAmount',
    LivingPaymentFrequency = 'livingPaymentFrequency',

    // Address Common
    AddressIsManualEdit= 'addressIsManualEdit',
    AddressCaption= 'addressCaption',
    AddressStreetNo= 'addressStreetNo',
    AddressStreet= 'addressStreet',
    AddressCity= 'addressCity',
    AddressSuburb= 'addressSuburb',
    AddressPostCode= 'addressPostCode',
    AddressRegion ='addressRegion',
    AddressCountry= 'addressCountry',
    AddressDuration= 'addressDuration',
    AddressLivingSituation= 'addressLivingSituation',

    // Individual Applicant
    AddressPhysical= 'addressPhysical',
    AddressPostal = 'addressPostal',
    AddressPrevious= 'addressPrevious',
    AddressPostalIsSame = 'addressPostalIsSame',

    IdentificationType = 'identificationType',
    DriverLicenceNo = 'driverLicenceNo',
    DriverLicenceVersion = 'driverLicenceVersion',
    Gender = 'gender',
    MaritalStatus= 'maritalStatus',
    DependentsNo = 'dependentsNo',
    BirthDate = 'birthDate',
    
    VisaType = 'visaType',
    VisaNumber= 'visaNumber',
    VisaExpiryDate = 'visaExpiryDate',
    VisaStartDate = 'visaStartDate',
    BirthCountry = 'birthCountry',
    CitenzenshipCountry = 'citenzenshipCountry',
  



  }
