import { NgModule, Provider, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule } from '../api/api.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PublicApiInterceptor } from '../interceptors/public-api-interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { AsFormControlPipe } from '../transforms/form-control-pipe';
import { IconService } from '../services/icon.service';
import { LookupService } from '../services/lookup.service';
import { VehicleAppService } from '../services/vehicle-app.service';
import { CodeInputComponent } from 'angular-code-input';


export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => PublicApiInterceptor),
  multi: true
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
  ],
  exports:[
    ApiModule,
  ],
  providers: [
    PublicApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {hideRequiredMarker: true, appearance: 'outline'}},

    LookupService,
    VehicleAppService
  ]
})

export class SharedModule {
  constructor(private iconService: IconService) {
    this.iconService.registerIcons('assets/svg');
  }

}
