import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, importProvidersFrom, Renderer2 } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PostMessageService } from './services/post-message.service';
import { EnvironmentService } from './services/environment.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiModule } from './api/api.module';
import { ModuleWithProviders } from '@angular/core'; // Import the ModuleWithProviders type
import { SharedModule } from './shared/shared.module';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    SharedModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [

  ],
  hostDirectives: [{
    directive: ResizeObserverDirective,
    outputs: ['onElementHeightChange']
  }]
})
export class AppComponent implements AfterViewInit {
  @HostBinding('attr.data-env') get env() { return this.environmentService.environment(); }
  @HostBinding('class.embedded') get embedded() { return this.postMessageService.inIFrame(); }
  @HostBinding('class.popup') get popup() { return this.postMessageService.inIFramePopup(); }

  @HostListener('onElementHeightChange', ['$event'])
  heightChange(height: number) {
    if (typeof (height) === 'number' && height > 0) {
      // console.log(height);
      this.postMessageService.emitResize(height + 64);
    }
  }

  title = 'UfApp';

  dateYear: number = new Date().getFullYear();
  version: string = '';
  debug: boolean = false;


  constructor(
    private environmentService: EnvironmentService,
    private postMessageService: PostMessageService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {

    this.version = `${environment.buildNumber}`;
    if (environment.production) {
      // var angularPlugin = new AngularPlugin();
      // const appInsights = new ApplicationInsights(
      // 	{
      // 		config: {
      // 			connectionString: environment.applicationInsightsConnectionString,
      // 			extensions: [angularPlugin],
      // 			extensionConfig: {
      // 				[angularPlugin.identifier]: { router: this.router }
      // 			}
      // 		}
      // 	});
      // appInsights.loadAppInsights();
    }
  }

  ngAfterViewInit() {
    const appRoot = document.querySelector('app-root');
    if (appRoot) {
      appRoot.classList.remove('hidden-until-loaded');
    }

    // Setup the message listener
    window.addEventListener('message', this.receiveMessage.bind(this));

    // Send a request to the parent iframe to get the height of the container
    // It will response (if in the fc website) via the iframe-minsize event
    this.postMessageService.emitRequestContainerHeight();
  }

  regex = new RegExp('^https://([a-z0-9]+[.])*financecentral\.co\.nz$');
  receiveMessage(event: MessageEvent) {
    if ((this.regex.test(event.origin) || event.origin.startsWith('http://localhost') || event.origin.startsWith('https://localhost'))) {
      if (event.data.event && event.data.event == 'iframe-minsize') {
        console.log('Received message', event.data);
        this.setHeight(event.data.minHeight - 64);
        //this.setHeight(event.data.minHeight - 96);
      }
    }
  }

  setHeight(minHeight: number) {
    console.log('Setting min height', minHeight);
    const appRoot = document.querySelector('app-root');
    if (appRoot) {
      this.renderer.setStyle(appRoot, 'height', `${minHeight}px`);
    }
  }

}

