import { MatOption } from '@angular/material/core';
import { FormFieldAutocompleteComponent } from './../../shared/form-field-autocomplete/form-field-autocomplete.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../../shared/shared.module';
import { AsFormControlPipe } from '../../../transforms/form-control-pipe';
import { FormFieldNumericComponent } from '../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldComponent } from '../../shared/form-field/form-field.component';
import { ApplicationFormControlNames } from '../../../types/applicationFromControlNames';
import { FormFieldSelectComponent } from '../../shared/form-field-select/form-field-select.component';
import { LookupService } from '../../../services/lookup.service';
import { ListItem } from '../../../types/listItem';
import { Country } from '../../../api/models/CommonUtilities/country';
import { AddressForm } from '../../../types/vehicleIndividualSteps';
import { ErrorService } from '../../../services/error.service';
import { PublicApplicationService } from '../../../api/services';
import { AutocompleteAddress } from '../../../api/models/Models/Customer/autocomplete-address';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldAutocompleteComponent,
    AsFormControlPipe,
    FormFieldSelectComponent,
    MatAutocomplete,
    MatOption
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss'
})
export class AddressComponent implements OnInit
{
  @Input() fg!: FormGroup<AddressForm>;
  @Input() label: string = '';
  @Input() formSubmitted: boolean = false;
  @Output() addressChanged = new EventEmitter<AutocompleteAddress>();

  ApplicationFormControlNames = ApplicationFormControlNames;

  //countriesAll: ListItem[] = [];
  countries: ListItem[] = [];
  addresses: AutocompleteAddress[] = [];
  addressOptions: ListItem[] = [];

  constructor(
    private lookupService: LookupService,
    private publicApplicationService: PublicApplicationService,
    private errorService: ErrorService,
  )
  {
    lookupService.getCountries().subscribe((countries) =>
    {
      // console.log(countries);
      this.countries = countries;
      this.fg.controls.country.setValue(Country.Newzealand);
    });
  }

  ngOnInit(): void
  {
    //subscribe to changes in the input
    // this.fg.controls.caption.valueChanges
    //   .pipe(debounceTime(500))
    //   .subscribe((value) =>
    //   {
    //     console.log(value);
    //     if (value)
    //     {
    //       this.applyAddressLookup(value);
    //     }
    //   });

    this.fg.valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) =>
      {
        if (this.fg.controls.isManualEdit.value)
        {
          const previousCaption = this.fg.controls.caption.value;

          //update caption
          let newCaption = '';
          if (value.streetNo && value.street && value.suburb && value.city)
          {
            newCaption = [(value.streetNo != '-' ? value.streetNo + ' ' : '') + value.street, value.suburb, value.city]
              .filter(part => part) // Remove empty values
              .join(', ').trim(); // Join the remaining parts with a comma and space
          }

          if (previousCaption != newCaption)
          {
            this.fg.controls.caption.setValue(newCaption, { emitEvent: false });
          }

          //if streetnumber is empty set to hyphen (assuming there's other address stuff set)
          if (!value.streetNo && newCaption)
          {
            this.fg.controls.streetNo.setValue('-', { emitEvent: false });
          }

          let addressManual = {
            caption: this.fg.controls.caption.value ?? '',
            city: this.fg.controls.city.value ?? '',
            postcode: this.fg.controls.postCode.value ?? '',
            region: this.fg.controls.region.value ?? '',
            street: this.fg.controls.street.value ?? '',
            streetNumber: this.fg.controls.streetNo.value ?? '',
            suburb: this.fg.controls.suburb.value ?? ''
          } as AutocompleteAddress;

          this.addressChanged.emit(addressManual);
        }
        // else
        // {
        //   if (this.fg.controls.caption.value?.trim() == '-')
        //   {
        //     this.fg.controls.caption.setValue('', { emitEvent: false });
        //   }
        // }
      });
  }

  onOptionSelected(address: ListItem)
  {
    console.log('onOptionSelected', address);
    if (address)
    {
      this.applyAddressLookup(address.value);
    }
  }

  /// <summary>
  /// Toggle the visibility of the address fields when the "Can't find your address?" link is clicked
  /// </summary
  toggleAddressVisiblity()
  {
    var isManualEditFg = this.fg.controls.isManualEdit;
    this.setAddressVisibility(!isManualEditFg);
  }

  setAddressVisibility(isManualEditFg: boolean)
  {
    this.fg.controls.isManualEdit.setValue(isManualEditFg);
  }

  /// <summary>
  /// When an address is selected, set the address fields to the selected address
  /// </summary
  currentSearch: string = '';
  applyAddressLookup(selectedAddress: string)
  {
    console.log('applyAddressLookup', selectedAddress);
    //see if we have a match in addresses (.caption) (this might be due to someone selecting the correct address, or typing an address that matches the autocomplete list)
    //if we do, set the address fields to the address
    var match = this.addresses.find(address => address.caption == selectedAddress);
    if (match)
    {
      // console.log('applyAddressLookup address selected', match);
      var addressFg = this.fg;

      addressFg.controls.streetNo.setValue(match.streetNumber ?? '');
      addressFg.controls.street.setValue(match.street ?? '');
      addressFg.controls.suburb.setValue(match.suburb ?? '');
      addressFg.controls.city.setValue(match.city ?? '');
      addressFg.controls.region.setValue(match.region ?? '');
      addressFg.controls.postCode.setValue(match.postcode ?? '');
      //set country to NZ as it's only NZ addresses we match on
      addressFg.controls.country.setValue(Country.Newzealand);
      this.addressChanged.emit(match);

      //if there's no street number then toggle manual edit
      if (!match.streetNumber)
      {
        this.setAddressVisibility(true);
      }
      return;
    }
  }

  /// <summary>
  /// Filter addresses based on search string
  /// </summary>
  filterAddresses(search: string)
  {
    if (search.length < 5)
    {
      // console.log('applyAddressLookup reset address');
      this.addresses = [];
      this.addressOptions = [];
      var addressFg = this.fg;
      addressFg.controls.streetNo.setValue('');
      addressFg.controls.street.setValue('');
      addressFg.controls.suburb.setValue('');
      addressFg.controls.city.setValue('');
      addressFg.controls.region.setValue('');
      addressFg.controls.postCode.setValue('');

      return;
    }

    // console.log('filterAddresses', event);
    //if the search is the same as the last search, don't do anything
    if (search == this.currentSearch)
    {
      return;
    }

    this.currentSearch = search;
    this.lookupService.getAddressAutocomplete(search)
      .subscribe((addresses) =>
      {
        //filter countries for loan applicable countries
        this.addresses = addresses;

        //set this.addressOptions to be an array of the captions from addresses
        this.addressOptions = addresses
          .filter(address => address.caption)
          .map(address => (
            {
              value: address.caption ?? '',
              valueCaption: address.caption ?? '',
              displayName: address.caption ?? ''
            } as ListItem
          ));

        // console.log(this.addressOptions);
      });
  }
}


