/* tslint:disable */
/* eslint-disable */
export enum EmploymentStatusEnum {
  None = 'None',
  SelfEmployed = 'SelfEmployed',
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Retired = 'Retired',
  Beneficiary = 'Beneficiary',
  Homemaker = 'Homemaker',
  Contract = 'Contract',
  Casual = 'Casual',
  Seasonal = 'Seasonal',
  Unspecified = 'Unspecified',
  Unemployed = 'Unemployed',
  Student = 'Student',
  Other = 'Other'
}
