<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            <h1 class="mat-headline-large">Liabilities</h1>
        </div> 

        <!-- Credit cards / loans etc -->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.hasCreditFacilities"
                label="Do you have any credit cards, store cards, overdrafts or revolving home loans?">
            </app-form-button-group-simple>

            <ng-container
                *ngIf="fg.controls.creditFacilities && fg.controls.hasCreditFacilities.value == UserAction.Yes">
                <div class="section-spacing">
                    <ng-container *ngFor="let creditFacility of fg.controls.creditFacilities.controls; let i = index">
                        <div class="group-section small-group">
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Card / Loan {{i+1}}</p>
                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            *ngIf="!creditFacility.controls.type.disabled"
                                            (click)="removeCreditFacility(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field-select [control]="creditFacility.controls.type"
                                    [listType]="SelectListType.LiabilityCreditTypes"
                                    label="Credit type"></app-form-field-select>

                                <app-form-field [formSubmitted]="form.submitted" label="Provider"
                                    [control]="creditFacility.controls.provider">
                                </app-form-field>

                                <app-form-field-currency [formSubmitted]="form.submitted" label="Max limit"
                                    [control]="creditFacility.controls.creditLimit">
                                </app-form-field-currency>

                                <app-form-field-currency [formSubmitted]="form.submitted" label="Current balance"
                                    [control]="creditFacility.controls.balance">
                                </app-form-field-currency>

                                <app-form-button-group-simple [formSubmitted]="form.submitted"
                                    [control]="creditFacility.controls.isPaidInFullEachMonth"
                                    label="Do you pay the balance off each month in full?"
                                    *ngIf="creditFacility.controls.isPaidInFullEachMonth.enabled">
                                </app-form-button-group-simple>

                                <app-form-button-group-simple [formSubmitted]="form.submitted"
                                    [control]="creditFacility.controls.repaymentsSharedWithPartner"
                                    label="Do you share the repayments of this credit facility with your spouse/partner?"
                                    *ngIf="creditFacility.controls.repaymentsSharedWithPartner.enabled">
                                </app-form-button-group-simple>
                                
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="fg.controls.hasCreditFacilities.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addCreditFacility()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Other loans-->
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="fg.controls.hasOtherFinancialCommitments"
                label="Do you have any existing loans or other finance commitments?" hint="This excludes student loans">
            </app-form-button-group-simple>

            <ng-container
                *ngIf="fg.controls.creditFacilities && fg.controls.hasOtherFinancialCommitments.value == UserAction.Yes">
                <div class="section-spacing">
                    <ng-container *ngFor="let finCommittment of fg.controls.financeCommitments.controls; let i = index">
                        <div class="group-section small-group">
                            <span
                                *ngIf="finCommittment.controls.type.disabled && finCommittment.controls.type.value == OtherFinanceCommitmentType.HomeLoan"
                                class="green-text">
                                We've automatically added a home loan as you indicated previously you are a homeowner.
                            </span>
                            <div class="row">
                                <div class="col-12">
                                    <div class="flex-content height-24">
                                        <p class="mat-body-large">Other loan {{i+1}}</p>
                                        <button mat-button class="link-button no-background-link remove" type="button"
                                            *ngIf="!finCommittment.controls.type.disabled"
                                            (click)="removeFinanceCommitment(i)"><mat-icon
                                                svgIcon="trash"></mat-icon>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div class="group-section">
                                <app-form-field-select [control]="finCommittment.controls.type"
                                    [listType]="SelectListType.LiabilityCommitmentTypes"
                                    label="Loan type"></app-form-field-select>
                                <app-form-field [formSubmitted]="form.submitted" label="Provider"
                                    [control]="finCommittment.controls.provider">
                                </app-form-field>
                                <app-form-field-currency [formSubmitted]="form.submitted" label="Current balance"
                                    [control]="finCommittment.controls.balance">
                                </app-form-field-currency>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-7">
                                                <app-form-field-currency [formSubmitted]="form.submitted"
                                                    label="Loan repayments"
                                                    [control]="finCommittment.controls.amountPerPeriod">
                                                </app-form-field-currency>
                                            </div>
                                            <div class="col-5">
                                                <app-form-field-select [control]="finCommittment.controls.paymentPeriod"
                                                    [listType]="SelectListType.FrequencyTypesAll"
                                                    label="Frequency"></app-form-field-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <app-form-button-group-simple [formSubmitted]="form.submitted"
                                    [control]="finCommittment.controls.repaymentsSharedWithPartner"
                                    label="Do you share the repayments of this loan with your spouse/partner?"
                                    *ngIf="finCommittment.controls.repaymentsSharedWithPartner.enabled">
                                    >
                                </app-form-button-group-simple>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="fg.controls.hasOtherFinancialCommitments.value == UserAction.Yes" class="height-24">
                        <button mat-button class="link-button no-background-link" (click)="addFinanceCommitment()"
                            type="button"><mat-icon svgIcon="add"></mat-icon>Add
                            another</button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div>
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>