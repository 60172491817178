<div class="group-section small-group">
  <div class="group-section x-small-group" *ngIf="!fg.controls.isManualEdit.value">
    <app-form-field-autocomplete [label]="label" [control]="fg.controls.caption" [options]="addressOptions" (filterChanged)="filterAddresses($event)" (optionSelected)="onOptionSelected($event)">
    </app-form-field-autocomplete>
    <div class="flex-content">
      <button mat-button class="link-button no-background-link height-24" type="button" (click)="setAddressVisibility(true)">Enter address manually</button>
    </div>
  </div>
  <div class="group-section" *ngIf="fg.controls.isManualEdit.value">

    <div class="group-section">
      <div class="group-section small-group" style="gap: 10px;">
        <h3 class="mat-title-medium black-font">{{label}}</h3>
        <div class="flex-content">
          <button mat-button class="link-button no-background-link height-24" type="button" (click)="setAddressVisibility(false)">Search for address instead</button>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-5">
        <app-form-field [formSubmitted]="formSubmitted" label="Street number" [control]="fg.controls.streetNo">
        </app-form-field>
      </div>
      <div class="col-7">
        <app-form-field [formSubmitted]="formSubmitted" label="Street name" [control]="fg.controls.street">
        </app-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-form-field [formSubmitted]="formSubmitted" label="Suburb" [control]="fg.controls.suburb">
        </app-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-form-field [formSubmitted]="formSubmitted" label="City" [control]="fg.controls.city">
        </app-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6 ">
        <app-form-field [formSubmitted]="formSubmitted" label="Postcode" [control]="fg.controls.postCode">
        </app-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-form-field-select label="Country" [control]="fg.controls.country" [options]="countries" [autocomplete]="true">
        </app-form-field-select>
      </div>
    </div>
  </div>
</div>
