/* tslint:disable */
/* eslint-disable */
export enum EmploymentBasis {
  None = 'None',
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Contract = 'Contract',
  Casual = 'Casual',
  Seasonal = 'Seasonal'
}
