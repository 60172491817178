@if(showLabel){
<p class="mat-body-large section-title">
    {{label}}
    <span *ngIf="hint" class="mat-body-large section-subtitle">{{hint}}</span>
</p>
}
<div class="group-required">
    <mat-button-toggle-group class="button-group" [formControl]="control" [attr.aria-label]="label"
        [hideSingleSelectionIndicator]="true">

        <mat-button-toggle [value]="yesValue">{{yesLabel}}</mat-button-toggle>
        <mat-button-toggle [value]="noValue">{{noLabel}}</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-error *ngIf="formSubmitted && control.hasError('required')" class="error-wrap">{{errorPlaceholder}}</mat-error>
</div>