/* tslint:disable */
/* eslint-disable */
export enum ProductGroupEnum {
  None = 'None',
  Vehicle = 'Vehicle',
  Cash = 'Cash',
  Retail = 'Retail',
  Card = 'Card',
  BuyNowPayLater = 'BuyNowPayLater'
}
