import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Step3EmploymentDetailsForm } from '../../../../types/vehicleIndividualSteps';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../../../../shared/shared.module';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { EmploymentBasis } from '../../../../api/models/CommonUtilities/employment-basis';
import { KiwiSaverRate } from '../../../../api/models/CommonUtilities/kiwi-saver-rate';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { EmploymentStatus } from '../../../../api/models/Models/v3/Application/EmploymentDetails/employment-status';

@Component({
  selector: 'app-employment-basis',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    MatIconModule,
  ],
  templateUrl: './employment-basis.component.html',
  styleUrl: './employment-basis.component.scss'
})
export class EmploymentBasisComponent {
  
  @Input() fg!: FormGroup<Step3EmploymentDetailsForm>;
  @Input() formSubmitted!: boolean;

  EmploymentStatus = EmploymentStatus;
  EmploymentBasis = EmploymentBasis;
  KiwisaverRate = KiwiSaverRate;
  UserAction = UserAction;
  SelectListType = SelectListType;
}
