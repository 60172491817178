/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApplicationSyncRequestBaseResponse as ModelsV3ApplicationApplicationSyncRequestBaseResponse } from '../../models/Models/v3/Application/application-sync-request-base-response';
import { ApplicationSyncRequestEx as ModelsV3ApplicationApplicationSyncRequestEx } from '../../models/Models/v3/Application/application-sync-request-ex';

export interface V3ApplicationsApplicationIdPut$Plain$Params {
  applicationId: string;
      body?: ModelsV3ApplicationApplicationSyncRequestEx
}

export function v3ApplicationsApplicationIdPut$Plain(http: HttpClient, rootUrl: string, params: V3ApplicationsApplicationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationsApplicationIdPut$Plain.PATH, 'put');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>;
    })
  );
}

v3ApplicationsApplicationIdPut$Plain.PATH = '/v3/applications/{applicationId}';
