/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApplicationQuoteResponseBaseResponse as ModelsV3ApplicationApplicationQuoteResponseBaseResponse } from '../models/Models/v3/Application/application-quote-response-base-response';
import { ApplicationSyncRequestBaseResponse as ModelsV3ApplicationApplicationSyncRequestBaseResponse } from '../models/Models/v3/Application/application-sync-request-base-response';
import { DtoInsuranceProductListBaseResponse as ModelsV3InsuranceDtoInsuranceProductListBaseResponse } from '../models/Models/v3/Insurance/dto-insurance-product-list-base-response';
import { DtoVehicleBaseResponse as ModelsV3VehicleDtoVehicleBaseResponse } from '../models/Models/v3/Vehicle/dto-vehicle-base-response';
import { v3ApplicationsApplicationIdInsuranceProductsPost$Json } from '../fn/applications/v-3-applications-application-id-insurance-products-post-json';
import { V3ApplicationsApplicationIdInsuranceProductsPost$Json$Params } from '../fn/applications/v-3-applications-application-id-insurance-products-post-json';
import { v3ApplicationsApplicationIdInsuranceProductsPost$Plain } from '../fn/applications/v-3-applications-application-id-insurance-products-post-plain';
import { V3ApplicationsApplicationIdInsuranceProductsPost$Plain$Params } from '../fn/applications/v-3-applications-application-id-insurance-products-post-plain';
import { v3ApplicationsApplicationIdInsuranceQuotePost$Json } from '../fn/applications/v-3-applications-application-id-insurance-quote-post-json';
import { V3ApplicationsApplicationIdInsuranceQuotePost$Json$Params } from '../fn/applications/v-3-applications-application-id-insurance-quote-post-json';
import { v3ApplicationsApplicationIdInsuranceQuotePost$Plain } from '../fn/applications/v-3-applications-application-id-insurance-quote-post-plain';
import { V3ApplicationsApplicationIdInsuranceQuotePost$Plain$Params } from '../fn/applications/v-3-applications-application-id-insurance-quote-post-plain';
import { v3ApplicationsApplicationIdNotifyDealerPost } from '../fn/applications/v-3-applications-application-id-notify-dealer-post';
import { V3ApplicationsApplicationIdNotifyDealerPost$Params } from '../fn/applications/v-3-applications-application-id-notify-dealer-post';
import { v3ApplicationsApplicationIdPut$Json } from '../fn/applications/v-3-applications-application-id-put-json';
import { V3ApplicationsApplicationIdPut$Json$Params } from '../fn/applications/v-3-applications-application-id-put-json';
import { v3ApplicationsApplicationIdPut$Plain } from '../fn/applications/v-3-applications-application-id-put-plain';
import { V3ApplicationsApplicationIdPut$Plain$Params } from '../fn/applications/v-3-applications-application-id-put-plain';
import { v3ApplicationsApplicationIdVehicleGet$Json } from '../fn/applications/v-3-applications-application-id-vehicle-get-json';
import { V3ApplicationsApplicationIdVehicleGet$Json$Params } from '../fn/applications/v-3-applications-application-id-vehicle-get-json';
import { v3ApplicationsApplicationIdVehicleGet$Plain } from '../fn/applications/v-3-applications-application-id-vehicle-get-plain';
import { V3ApplicationsApplicationIdVehicleGet$Plain$Params } from '../fn/applications/v-3-applications-application-id-vehicle-get-plain';
import { v3CustomersAdminAppNotificationsPost } from '../fn/applications/v-3-customers-admin-app-notifications-post';
import { V3CustomersAdminAppNotificationsPost$Params } from '../fn/applications/v-3-customers-admin-app-notifications-post';

@Injectable({ providedIn: 'root' })
export class ApplicationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3ApplicationsApplicationIdVehicleGet()` */
  static readonly V3ApplicationsApplicationIdVehicleGetPath = '/v3/applications/{applicationId}/vehicle';

  /**
   * Gets a vehicle.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdVehicleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3ApplicationsApplicationIdVehicleGet$Plain$Response(params: V3ApplicationsApplicationIdVehicleGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>> {
    return v3ApplicationsApplicationIdVehicleGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets a vehicle.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdVehicleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3ApplicationsApplicationIdVehicleGet$Plain(params: V3ApplicationsApplicationIdVehicleGet$Plain$Params, context?: HttpContext): Observable<ModelsV3VehicleDtoVehicleBaseResponse> {
    return this.v3ApplicationsApplicationIdVehicleGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>): ModelsV3VehicleDtoVehicleBaseResponse => r.body)
    );
  }

  /**
   * Gets a vehicle.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdVehicleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3ApplicationsApplicationIdVehicleGet$Json$Response(params: V3ApplicationsApplicationIdVehicleGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>> {
    return v3ApplicationsApplicationIdVehicleGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets a vehicle.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdVehicleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3ApplicationsApplicationIdVehicleGet$Json(params: V3ApplicationsApplicationIdVehicleGet$Json$Params, context?: HttpContext): Observable<ModelsV3VehicleDtoVehicleBaseResponse> {
    return this.v3ApplicationsApplicationIdVehicleGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>): ModelsV3VehicleDtoVehicleBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3ApplicationsApplicationIdNotifyDealerPost()` */
  static readonly V3ApplicationsApplicationIdNotifyDealerPostPath = '/v3/applications/{applicationId}/notify-dealer';

  /**
   * NotifyDealer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdNotifyDealerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdNotifyDealerPost$Response(params: V3ApplicationsApplicationIdNotifyDealerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3ApplicationsApplicationIdNotifyDealerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * NotifyDealer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdNotifyDealerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdNotifyDealerPost(params: V3ApplicationsApplicationIdNotifyDealerPost$Params, context?: HttpContext): Observable<void> {
    return this.v3ApplicationsApplicationIdNotifyDealerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v3ApplicationsApplicationIdInsuranceProductsPost()` */
  static readonly V3ApplicationsApplicationIdInsuranceProductsPostPath = '/v3/applications/{applicationId}/insurance/products';

  /**
   * Get vehicle insurance products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdInsuranceProductsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceProductsPost$Plain$Response(params: V3ApplicationsApplicationIdInsuranceProductsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>> {
    return v3ApplicationsApplicationIdInsuranceProductsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get vehicle insurance products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdInsuranceProductsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceProductsPost$Plain(params: V3ApplicationsApplicationIdInsuranceProductsPost$Plain$Params, context?: HttpContext): Observable<ModelsV3InsuranceDtoInsuranceProductListBaseResponse> {
    return this.v3ApplicationsApplicationIdInsuranceProductsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>): ModelsV3InsuranceDtoInsuranceProductListBaseResponse => r.body)
    );
  }

  /**
   * Get vehicle insurance products.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdInsuranceProductsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceProductsPost$Json$Response(params: V3ApplicationsApplicationIdInsuranceProductsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>> {
    return v3ApplicationsApplicationIdInsuranceProductsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get vehicle insurance products.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdInsuranceProductsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceProductsPost$Json(params: V3ApplicationsApplicationIdInsuranceProductsPost$Json$Params, context?: HttpContext): Observable<ModelsV3InsuranceDtoInsuranceProductListBaseResponse> {
    return this.v3ApplicationsApplicationIdInsuranceProductsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>): ModelsV3InsuranceDtoInsuranceProductListBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3ApplicationsApplicationIdInsuranceQuotePost()` */
  static readonly V3ApplicationsApplicationIdInsuranceQuotePostPath = '/v3/applications/{applicationId}/insurance/quote';

  /**
   * GetQuote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdInsuranceQuotePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceQuotePost$Plain$Response(params: V3ApplicationsApplicationIdInsuranceQuotePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>> {
    return v3ApplicationsApplicationIdInsuranceQuotePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * GetQuote.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdInsuranceQuotePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceQuotePost$Plain(params: V3ApplicationsApplicationIdInsuranceQuotePost$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationQuoteResponseBaseResponse> {
    return this.v3ApplicationsApplicationIdInsuranceQuotePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>): ModelsV3ApplicationApplicationQuoteResponseBaseResponse => r.body)
    );
  }

  /**
   * GetQuote.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdInsuranceQuotePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceQuotePost$Json$Response(params: V3ApplicationsApplicationIdInsuranceQuotePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>> {
    return v3ApplicationsApplicationIdInsuranceQuotePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * GetQuote.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdInsuranceQuotePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdInsuranceQuotePost$Json(params: V3ApplicationsApplicationIdInsuranceQuotePost$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationQuoteResponseBaseResponse> {
    return this.v3ApplicationsApplicationIdInsuranceQuotePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationQuoteResponseBaseResponse>): ModelsV3ApplicationApplicationQuoteResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3ApplicationsApplicationIdPut()` */
  static readonly V3ApplicationsApplicationIdPutPath = '/v3/applications/{applicationId}';

  /**
   * Sync application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdPut$Plain$Response(params: V3ApplicationsApplicationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>> {
    return v3ApplicationsApplicationIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdPut$Plain(params: V3ApplicationsApplicationIdPut$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationSyncRequestBaseResponse> {
    return this.v3ApplicationsApplicationIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>): ModelsV3ApplicationApplicationSyncRequestBaseResponse => r.body)
    );
  }

  /**
   * Sync application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationsApplicationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdPut$Json$Response(params: V3ApplicationsApplicationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>> {
    return v3ApplicationsApplicationIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sync application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationsApplicationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationsApplicationIdPut$Json(params: V3ApplicationsApplicationIdPut$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationSyncRequestBaseResponse> {
    return this.v3ApplicationsApplicationIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationSyncRequestBaseResponse>): ModelsV3ApplicationApplicationSyncRequestBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersAdminAppNotificationsPost()` */
  static readonly V3CustomersAdminAppNotificationsPostPath = '/v3/customers-admin/app-notifications';

  /**
   * Creates a notification for a given client ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersAdminAppNotificationsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersAdminAppNotificationsPost$Response(params?: V3CustomersAdminAppNotificationsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3CustomersAdminAppNotificationsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a notification for a given client ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersAdminAppNotificationsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersAdminAppNotificationsPost(params?: V3CustomersAdminAppNotificationsPost$Params, context?: HttpContext): Observable<void> {
    return this.v3CustomersAdminAppNotificationsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
