/* tslint:disable */
/* eslint-disable */
export enum ContactType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Guarantor = 'Guarantor',
  Trustee = 'Trustee',
  ProfessionalTrustee = 'ProfessionalTrustee',
  DirectorShareholder = 'DirectorShareholder',
  DirectorShareholderofProfessionalTrustee = 'DirectorShareholderofProfessionalTrustee',
  EntityGuarantor = 'EntityGuarantor'
}
