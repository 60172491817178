/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DtoVehicleBaseResponse as ModelsV3VehicleDtoVehicleBaseResponse } from '../../models/Models/v3/Vehicle/dto-vehicle-base-response';

export interface V3ApplicationsApplicationIdVehicleGet$Plain$Params {
  applicationId: string;
  StockNo: number;
}

export function v3ApplicationsApplicationIdVehicleGet$Plain(http: HttpClient, rootUrl: string, params: V3ApplicationsApplicationIdVehicleGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationsApplicationIdVehicleGet$Plain.PATH, 'get');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.query('StockNo', params.StockNo, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3VehicleDtoVehicleBaseResponse>;
    })
  );
}

v3ApplicationsApplicationIdVehicleGet$Plain.PATH = '/v3/applications/{applicationId}/vehicle';
