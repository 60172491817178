/* tslint:disable */
/* eslint-disable */
export enum RoleEnum {
  ArtsandMediaProfessionals = 'ArtsandMediaProfessionals',
  AutomotiveAndEngineeringTradesWorkers = 'AutomotiveAndEngineeringTradesWorkers',
  BusinessHumanResourceAndMarketingProfessionals = 'BusinessHumanResourceAndMarketingProfessionals',
  CarersandAides = 'CarersandAides',
  ChiefExecutivesGeneralManagersandLegislators = 'ChiefExecutivesGeneralManagersandLegislators',
  CleanersandLaundryWorkers = 'CleanersandLaundryWorkers',
  ClericalandOfficeSupportWorkers = 'ClericalandOfficeSupportWorkers',
  ConstructionandMiningLabourers = 'ConstructionandMiningLabourers',
  ConstructionTradesWorkers = 'ConstructionTradesWorkers',
  DesignEngineeringScienceandTransportProfessionals = 'DesignEngineeringScienceandTransportProfessionals',
  EducationProfessionals = 'EducationProfessionals',
  ElectroTechnologyAndTelecommunicationsTradesWorkers = 'ElectroTechnologyAndTelecommunicationsTradesWorkers',
  EngineeringIcTandScienceTechnicians = 'EngineeringICTandScienceTechnicians',
  FactoryProcessWorkers = 'FactoryProcessWorkers',
  FarmForestryAndGardenWorkers = 'FarmForestryAndGardenWorkers',
  FarmersandFarmManagers = 'FarmersandFarmManagers',
  FoodPreparationAssistants = 'FoodPreparationAssistants',
  FoodTradesWorkers = 'FoodTradesWorkers',
  GeneralClericalWorkers = 'GeneralClericalWorkers',
  HealthandWelfareSupportWorkers = 'HealthandWelfareSupportWorkers',
  HealthProfessionals = 'HealthProfessionals',
  HospitalityWorkers = 'HospitalityWorkers',
  HospitalityRetailandServiceManagers = 'HospitalityRetailandServiceManagers',
  Housewifehusband = 'Housewifehusband',
  IctProfessionals = 'ICTProfessionals',
  InquiryClerksandReceptionists = 'InquiryClerksandReceptionists',
  Invalidpensioner = 'Invalidpensioner',
  LegalSocialandWelfareProfessionals = 'LegalSocialandWelfareProfessionals',
  MachineandStationaryPlantOperators = 'MachineandStationaryPlantOperators',
  MobilePlantOperators = 'MobilePlantOperators',
  NumericalClerks = 'NumericalClerks',
  OfficeManagersAndProgramAdministrators = 'OfficeManagersAndProgramAdministrators',
  OtherClericalandAdministrativeWorkers = 'OtherClericalandAdministrativeWorkers',
  OtherLabourers = 'OtherLabourers',
  OtherOccupationInadequatelyDescribed = 'OtherOccupationInadequatelyDescribed',
  OtherPensioner = 'OtherPensioner',
  OtherTechniciansAndTradesWorkers = 'OtherTechniciansAndTradesWorkers',
  PersonalAssistantsAndSecretaries = 'PersonalAssistantsAndSecretaries',
  ProtectiveServiceWorkers = 'ProtectiveServiceWorkers',
  RoadandRailDrivers = 'RoadandRailDrivers',
  SalesAssistantsAndSalespersons = 'SalesAssistantsAndSalespersons',
  SalesRepresentativesAndAgents = 'SalesRepresentativesAndAgents',
  SalesSupportWorkers = 'SalesSupportWorkers',
  SkilledAnimalandHorticulturalWorkers = 'SkilledAnimalandHorticulturalWorkers',
  SpecialistManagers = 'SpecialistManagers',
  SportsandPersonalServiceWorkers = 'SportsandPersonalServiceWorkers',
  StorePersons = 'StorePersons',
  SkilledAnimalAndHorticulturalWorkers = 'SkilledAnimalAndHorticulturalWorkers'
}
