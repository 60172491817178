<form [formGroup]="fg" #form="ngForm" *ngIf="fg.controls">
    <section>
        <div class="group-section title-section">
            <h1 class="mat-headline-large">{{entityTypeCaption}} income & expenses</h1>
        </div>

        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.isBusinessTrading"
                [label]="'Does ' + (appForm.loanTypeFg.controls.entities ? appForm.loanTypeFg.controls.entities.controls[0].controls.legalName.value : '') + ' trade?'">
            </app-form-button-group-simple>
        </div>

        <ng-container *ngIf="fg.controls.isBusinessTrading.value == UserAction.Yes">
            <div class="group-section">
                <app-form-field [formSubmitted]="form.submitted" label="Trading name"
                    [control]="fg.controls.tradingName">
                </app-form-field>

                <app-form-field-select label="Industry type" [control]="fg.controls.industryClassification"
                    [listType]="SelectListType.IndustryClassifications">
                </app-form-field-select>

                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-7">
                                <app-form-field-currency [formSubmitted]="formSubmitted" label="Income"
                                    [control]="fg.controls.incomeAmount">
                                </app-form-field-currency>
                            </div>
                            <div class="col-5">
                                <app-form-field-select [control]="fg.controls.incomeFrequency"
                                    [listType]="SelectListType.FrequencyTypesAll"
                                    label="Frequency"></app-form-field-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="group-section">
                <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="fg.controls.isIncomeOffshore"
                    label="Is any of this income earned offshore?">
                </app-form-button-group-simple>
            </div>

            <div class="group-section">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-7">
                                <app-form-field-currency [formSubmitted]="formSubmitted" label="Expenses"
                                    [control]="fg.controls.expenseAmount">
                                </app-form-field-currency>
                            </div>
                            <div class="col-5">
                                <app-form-field-select [control]="fg.controls.expenseFrequency"
                                    [listType]="SelectListType.FrequencyTypesAll"
                                    label="Frequency"></app-form-field-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <div>
            <!-- <button mat-button matStepperNext>Next</button> -->
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>


</form>