import { Component, HostBinding } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { PostMessageService } from '../../services/post-message.service';
import { VehicleFinanceViewComponent } from '../vehicle-finance/vehicle-finance-view/vehicle-finance-view.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-application-view',
  standalone: true,
  imports: [
    CommonModule,
    VehicleFinanceViewComponent
  ],
  templateUrl: './application-view.component.html',
  styleUrl: './application-view.component.scss'
})
export class ApplicationViewComponent {
  @HostBinding('attr.data-env') get env() { return this.environmentService.environment(); }

	constructor(
		private environmentService: EnvironmentService,
		private postMessageService: PostMessageService
	){ }
}
