/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApplicationResponse as ModelsV3ApplicationApplicationResponse } from '../models/Models/v3/Application/application-response';
import { ApplicationUpdateVehicleResponseBaseResponse as ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse } from '../models/Models/v3/Application/Public/application-update-vehicle-response-base-response';
import { v3ApplicationVehicleApplicationIdPut$Json } from '../fn/vehicle-application/v-3-application-vehicle-application-id-put-json';
import { V3ApplicationVehicleApplicationIdPut$Json$Params } from '../fn/vehicle-application/v-3-application-vehicle-application-id-put-json';
import { v3ApplicationVehicleApplicationIdPut$Plain } from '../fn/vehicle-application/v-3-application-vehicle-application-id-put-plain';
import { V3ApplicationVehicleApplicationIdPut$Plain$Params } from '../fn/vehicle-application/v-3-application-vehicle-application-id-put-plain';
import { v3ApplicationVehicleBrokerPost$Json } from '../fn/vehicle-application/v-3-application-vehicle-broker-post-json';
import { V3ApplicationVehicleBrokerPost$Json$Params } from '../fn/vehicle-application/v-3-application-vehicle-broker-post-json';
import { v3ApplicationVehicleBrokerPost$Plain } from '../fn/vehicle-application/v-3-application-vehicle-broker-post-plain';
import { V3ApplicationVehicleBrokerPost$Plain$Params } from '../fn/vehicle-application/v-3-application-vehicle-broker-post-plain';
import { v3ApplicationVehiclePost$Json } from '../fn/vehicle-application/v-3-application-vehicle-post-json';
import { V3ApplicationVehiclePost$Json$Params } from '../fn/vehicle-application/v-3-application-vehicle-post-json';
import { v3ApplicationVehiclePost$Plain } from '../fn/vehicle-application/v-3-application-vehicle-post-plain';
import { V3ApplicationVehiclePost$Plain$Params } from '../fn/vehicle-application/v-3-application-vehicle-post-plain';

@Injectable({ providedIn: 'root' })
export class VehicleApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3ApplicationVehiclePost()` */
  static readonly V3ApplicationVehiclePostPath = '/v3/application/vehicle';

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehiclePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehiclePost$Plain$Response(params?: V3ApplicationVehiclePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponse>> {
    return v3ApplicationVehiclePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehiclePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehiclePost$Plain(params?: V3ApplicationVehiclePost$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponse> {
    return this.v3ApplicationVehiclePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponse>): ModelsV3ApplicationApplicationResponse => r.body)
    );
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehiclePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehiclePost$Json$Response(params?: V3ApplicationVehiclePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponse>> {
    return v3ApplicationVehiclePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehiclePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehiclePost$Json(params?: V3ApplicationVehiclePost$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponse> {
    return this.v3ApplicationVehiclePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponse>): ModelsV3ApplicationApplicationResponse => r.body)
    );
  }

  /** Path part for operation `v3ApplicationVehicleBrokerPost()` */
  static readonly V3ApplicationVehicleBrokerPostPath = '/v3/application/vehicle/broker';

  /**
   * Starts new Vehicle application by a broker.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehicleBrokerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleBrokerPost$Plain$Response(params?: V3ApplicationVehicleBrokerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponse>> {
    return v3ApplicationVehicleBrokerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application by a broker.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehicleBrokerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleBrokerPost$Plain(params?: V3ApplicationVehicleBrokerPost$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponse> {
    return this.v3ApplicationVehicleBrokerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponse>): ModelsV3ApplicationApplicationResponse => r.body)
    );
  }

  /**
   * Starts new Vehicle application by a broker.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehicleBrokerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleBrokerPost$Json$Response(params?: V3ApplicationVehicleBrokerPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponse>> {
    return v3ApplicationVehicleBrokerPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Starts new Vehicle application by a broker.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehicleBrokerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleBrokerPost$Json(params?: V3ApplicationVehicleBrokerPost$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationResponse> {
    return this.v3ApplicationVehicleBrokerPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationResponse>): ModelsV3ApplicationApplicationResponse => r.body)
    );
  }

  /** Path part for operation `v3ApplicationVehicleApplicationIdPut()` */
  static readonly V3ApplicationVehicleApplicationIdPutPath = '/v3/application/vehicle/{applicationId}';

  /**
   * Update the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehicleApplicationIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleApplicationIdPut$Plain$Response(params: V3ApplicationVehicleApplicationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>> {
    return v3ApplicationVehicleApplicationIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehicleApplicationIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleApplicationIdPut$Plain(params: V3ApplicationVehicleApplicationIdPut$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse> {
    return this.v3ApplicationVehicleApplicationIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>): ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse => r.body)
    );
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ApplicationVehicleApplicationIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleApplicationIdPut$Json$Response(params: V3ApplicationVehicleApplicationIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>> {
    return v3ApplicationVehicleApplicationIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ApplicationVehicleApplicationIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ApplicationVehicleApplicationIdPut$Json(params: V3ApplicationVehicleApplicationIdPut$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse> {
    return this.v3ApplicationVehicleApplicationIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>): ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse => r.body)
    );
  }

}
