<mat-form-field class="form-field" subscriptSizing="dynamic">
  <mat-label>{{label}}</mat-label>
  <input type="text" matInput [placeholder]="placeholder" [formControl]="control" (focus)="onFocus($event)" (input)="onFilterChanged($event)" (keydown)="onKeyDown($event)" [matAutocomplete]="auto" autocomplete="off">
  <mat-autocomplete #auto="matAutocomplete" requireSelection (optionSelected)="onOptionSelected($event.option)" [displayWith]="displayFn.bind(this)">
    @for (option of options; track option) {
    <mat-option [value]="option.value">{{option.displayName}}</mat-option>
    }
  </mat-autocomplete>

  <ng-content></ng-content>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
