/* tslint:disable */
/* eslint-disable */
export enum InsuranceProductType {
  Mbi = 'Mbi',
  Mvi = 'Mvi',
  Ppi = 'Ppi',
  Cci = 'Cci',
  Lpi = 'Lpi',
  Gap = 'Gap'
}
