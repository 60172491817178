/* tslint:disable */
/* eslint-disable */
export enum IncomeType {
  None = 'None',
  WageSalary = 'WageSalary',
  WinzBenefit = 'WinzBenefit',
  Boarder = 'Boarder',
  Superannuation = 'Superannuation',
  Investment = 'Investment',
  RentalIncome = 'RentalIncome',
  BusinessContract = 'BusinessContract',
  CommissionBonus = 'CommissionBonus',
  NonTaxableBenefitsAllowances = 'NonTaxableBenefitsAllowances',
  OtherRegular = 'OtherRegular'
}
