export const environment = {

  bypassOtpVerification: false,
  
  apiUrl: 'https://api-integration.financecentral.co.nz',
  apiHeaderId: '73f04ac952844f5096dfee9f18fe5407',
  version: 1,
  buildNumber: 1,
  production: true,
  debug: false,
  startPageDealerLed: '',
  startPageCustomerLed: '',
  maintenance: {
    active: false,
    from: '2021-05-14T18:00:00.000Z',
    to: '2021-05-15T10:00:00.000Z'
  },
  creditSense: {
    code: '',
    debug: true,
  },
  applicationInsightsConnectionString: ''
};
