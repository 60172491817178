import { Component, Input } from '@angular/core';
import { ExpenseDetailForm, IncomeDetailForm, Step4LivingExpensesForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { ApplicationExpenseType } from '../../../../api/models/CommonUtilities/application-expense-type';
import { startWith, pairwise } from 'rxjs';
import { PeriodTypeEnum } from '../../../../api/models/Models/Application/FinancialDetails/period-type-enum';
import { conditionalRequiredValidator } from '../../../../types/validators';
import { LivingSituationEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { ContactType } from '../../../../api/models/CommonUtilities/contact-type';
import { MaritalStatusEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/marital-status-enum';

@Component({
  selector: 'app-living-expenses',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    MatIconModule,
    FormFieldCurrencyComponent
  ],
  templateUrl: './living-expenses.component.html',
  styleUrl: './living-expenses.component.scss'
})

export class LivingExpensesComponent extends BaseStepComponent {

  @Input() fg!: FormGroup<Step4LivingExpensesForm>;
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  UserAction = UserAction;
  SelectListType = SelectListType;
  ApplicationExpenseType = ApplicationExpenseType;
  ContactType = ContactType;
  MaritalStatusEnum = MaritalStatusEnum;

  defaultExpenseType: ApplicationExpenseType = ApplicationExpenseType.Accommodation;

  partnerFg: FormGroup<VehicleIndividualForm> | null = null;

  showExpenses: boolean = true;
  individualHeaderDisplay: string = '';

  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);      
    }));

    // Add the disabled Rates expense if the user is a homeowner
    this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.valueChanges
      .pipe(
        startWith(this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          const ratesExpenseIndex = this.fg.controls.otherExpenses.controls.findIndex(x => x.controls.type.value === ApplicationExpenseType.Rates);

          if (newValue === LivingSituationEnum.HomeownerWithoutMortgage || newValue === LivingSituationEnum.Mortgaged) {
            if (ratesExpenseIndex === -1) {
              this.addExpense(ApplicationExpenseType.Rates, null, null);
            }

            this.fg.controls.hasOtherExpenses.setValue(UserAction.Yes);
            this.fg.controls.hasOtherExpenses.disable();

            if(this.parentFg.controls.step1.controls.isAddressProvidedByPartner.value == UserAction.No){
              this.fg.controls.hasNonSharedPartnerExpenses.setValue(UserAction.Yes);
              this.fg.controls.hasNonSharedPartnerExpenses.disable();
            }

          } else {
            if (ratesExpenseIndex !== -1) {
              this.removeExpense(ratesExpenseIndex);
              this.fg.controls.hasOtherExpenses.enable();

              if (this.fg.controls.otherExpenses.length === 0) {
                this.fg.controls.hasOtherExpenses.setValue(UserAction.No);
              }

              if(this.parentFg.controls.step1.controls.isAddressProvidedByPartner.value == UserAction.Yes){                
                this.fg.controls.hasNonSharedPartnerExpenses.enable();
              }
            }
          }
        }
      });

    this.fg.controls.hasOtherExpenses.valueChanges
      .pipe(
        startWith(this.fg.controls.hasOtherExpenses.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.otherExpenses.clear();
          } else {
            if (this.fg.controls.otherExpenses.length === 0) {
              this.addExpense();
            }
          }
        }
      });

    this.parentFg.controls.step2.controls.partnerIdentifier.valueChanges
      .pipe(
        startWith(this.parentFg.controls.step2.controls.partnerIdentifier.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          var dependentPartnerFg = this.vehicleAppService.getDependentPartnerFg(this.parentFg, newValue);

          if (dependentPartnerFg && dependentPartnerFg != this.parentFg) {
            this.showExpenses = true;
            this.fg.controls.expensesSharedWithPartner.setValue(null);
            this.fg.controls.expensesSharedWithPartner.disable();
            ////dependentPartnerFg.controls.step4.controls.expensesSharedWithPartner.enable();
            ////this.partnerFg = dependentPartnerFg;
          } else {
            this.showExpenses = false;
            this.partnerFg = this.vehicleAppService.getParentPartnerFg(this.parentFg, newValue);
            this.fg.controls.expensesSharedWithPartner.enable();
          }

          ////this.partnerFg = this.vehicleAppService.getIndividualPartnerFormGroup(this.parentFg, newValue);
        }else if (newValue == null) {
          this.showExpenses = true;
          this.partnerFg = null;
          this.fg.controls.expensesSharedWithPartner.enable();
        }

      });

    this.fg.controls.expensesSharedWithPartner.valueChanges.subscribe((value) => {
      if (this.showExpenses) {
        if (this.fg.controls.expensesSharedWithPartner.value == UserAction.Yes) {
          this.fg.controls.partnerGrossIncome.enable();
        } else {
          this.fg.controls.partnerGrossIncome.setValue(0);
          this.fg.controls.partnerGrossIncome.disable();
        }

      } else {
        // // Don't show expenses
        // if (this.fg.controls.expensesSharedWithPartner.value == UserAction.Yes) {
        //   this.fg.controls.otherExpenses.clear();
        // }
        // else if (this.fg.controls.expensesSharedWithPartner.value == UserAction.No) {

        //   if (this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value === LivingSituationEnum.HomeownerWithoutMortgage ||
        //     this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value === LivingSituationEnum.Mortgaged) {
        //     const ratesExpenseIndex = this.fg.controls.otherExpenses.controls.findIndex(x => x.controls.type.value === ApplicationExpenseType.Rates);
        //     if (ratesExpenseIndex === -1) {
        //       this.addExpense(ApplicationExpenseType.Rates, null, null);
        //     }

        //     this.fg.controls.hasOtherExpenses.setValue(UserAction.Yes);
        //     this.fg.controls.hasOtherExpenses.disable();

        //   } else {
        //     this.fg.controls.hasOtherExpenses.enable();
        //   }
        // }
      }

    });

    this.parentFg.controls.step2.controls.maritalStatus.valueChanges.subscribe((value) => {
      //this.fg.controls.expensesSharedWithPartner.enable();

      if(value == MaritalStatusEnum.Married || value == MaritalStatusEnum.DeFacto){
        this.fg.controls.expensesSharedWithPartner.setValue(null);
        this.fg.controls.expensesSharedWithPartner.enable();
      }else{
        this.fg.controls.expensesSharedWithPartner.disable();        
        this.fg.controls.expensesSharedWithPartner.setValue(UserAction.No);
      }
    });

    this.parentFg.controls.step2.controls.isPartnerOnApplication.valueChanges.subscribe((value) => {
      if (value && value == UserAction.No) {
        //this.fg.controls.expensesSharedWithPartner.enable();
      }else{
        //this.fg.controls.expensesSharedWithPartner.disable();
      }    
    });


    this.fg.controls.hasNonSharedPartnerExpenses.valueChanges.subscribe((value) => {
      this.fg.controls.expensesSharedWithPartner.enable();
      
      console.log('hasNonSharedPartnerExpenses changed', value);

      if (value && value == UserAction.Yes) {        
        this.fg.controls.expensesSharedWithPartner.setValue(UserAction.No);

        if(this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value == LivingSituationEnum.HomeownerWithoutMortgage ||
          this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value == LivingSituationEnum.Mortgaged) {
            this.fg.controls.hasOtherExpenses.setValue(UserAction.Yes);
            this.fg.controls.hasOtherExpenses.disable();
          } else
          {            
            this.fg.controls.hasOtherExpenses.setValue(null);
            this.fg.controls.hasOtherExpenses.enable();
          }
        
      }
      else{        
        this.fg.controls.expensesSharedWithPartner.setValue(UserAction.Yes);
        this.fg.controls.hasOtherExpenses.setValue(UserAction.No);
        this.fg.controls.hasOtherExpenses.disable();        
      }

      //Set the parent applicant value as well
      var parentApplicant = this.vehicleAppService.getParentPartnerFg(this.parentFg, this.parentFg.controls.step2.controls.partnerIdentifier.value!);      
      if(parentApplicant != null){
        parentApplicant.controls.step4.controls.expensesSharedWithPartner.setValue(value, {emitEvent:false});
      }
      
    });
  }

  removeExpense(index: number): void {
    this.fg.controls.otherExpenses.removeAt(index);
    if (index == 0) {
      this.fg.controls.hasOtherExpenses.setValue(UserAction.No);
    }
  }

  addExpense(expenseType: ApplicationExpenseType | null = null, amount: number | null = null, frequency: PeriodTypeEnum | null = null): FormGroup<ExpenseDetailForm> {
    var exp = this.createExpenseGroup(expenseType, amount, frequency);
    this.fg.controls.otherExpenses.push(exp);
    return exp;
  }

  createExpenseGroup(expenseType: ApplicationExpenseType | null, amount: number | null, frequency: PeriodTypeEnum | null): FormGroup<ExpenseDetailForm> {
    var idFg = new FormGroup<ExpenseDetailForm>({
      type: new FormControl<ApplicationExpenseType | null>({ value: expenseType, disabled: expenseType != null }, Validators.required),
      amount: new FormControl<number | null>(null, Validators.required),
      frequency: new FormControl<PeriodTypeEnum | null>(null, Validators.required),
      description: new FormControl<string | null>(null, conditionalRequiredValidator('type', ApplicationExpenseType.Other))
    } as ExpenseDetailForm);

    return idFg;
  }

}
