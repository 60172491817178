import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaNumericOnly]',
  standalone: true
})
export class AlphaNumericOnlyDirective {

  private regex: RegExp = new RegExp(/^[a-zA-Z0-9]*$/);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    // debugger;
    const input = this.el.nativeElement as HTMLInputElement;
    const initialValue = input.value;

    input.value = initialValue.replace(/[^a-zA-Z0-9]*/g, '');
    if (initialValue !== input.value) {
      event.stopPropagation();
    }
  }
}
