/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthOtpResponseBaseResponse as ModelsAuthenticationAuthOtpResponseBaseResponse } from '../models/Models/Authentication/auth-otp-response-base-response';
import { AutocompleteAddressResponseBaseResponse as ModelsCustomerAutocompleteAddressResponseBaseResponse } from '../models/Models/Customer/autocomplete-address-response-base-response';
import { NzbnCompanyDetailsBaseResponse as ModelsCustomerNzbnCompanyDetailsBaseResponse } from '../models/Models/Customer/nzbn-company-details-base-response';
import { NzbnCompanyResponseBaseResponse as ModelsCustomerNzbnCompanyResponseBaseResponse } from '../models/Models/Customer/nzbn-company-response-base-response';
import { ApplicationConfigResponseBaseResponse as ModelsV3ApplicationApplicationConfigResponseBaseResponse } from '../models/Models/v3/Application/application-config-response-base-response';
import { LookupInternalResponseListBaseResponse as ModelsV3CommonLookupInternalResponseListBaseResponse } from '../models/Models/v3/Common/lookup-internal-response-list-base-response';
import { v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json } from '../fn/public-application/v-3-public-application-application-id-get-address-autocomplete-get-json';
import { V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Params } from '../fn/public-application/v-3-public-application-application-id-get-address-autocomplete-get-json';
import { v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain } from '../fn/public-application/v-3-public-application-application-id-get-address-autocomplete-get-plain';
import { V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Params } from '../fn/public-application/v-3-public-application-application-id-get-address-autocomplete-get-plain';
import { v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-get-json';
import { V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Params } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-get-json';
import { v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-get-plain';
import { V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Params } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-get-plain';
import { v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-nzbn-get-json';
import { V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Params } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-nzbn-get-json';
import { v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-nzbn-get-plain';
import { V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Params } from '../fn/public-application/v-3-public-application-application-id-get-company-autocomplete-nzbn-get-plain';
import { v3PublicApplicationConfigGet$Json } from '../fn/public-application/v-3-public-application-config-get-json';
import { V3PublicApplicationConfigGet$Json$Params } from '../fn/public-application/v-3-public-application-config-get-json';
import { v3PublicApplicationConfigGet$Plain } from '../fn/public-application/v-3-public-application-config-get-plain';
import { V3PublicApplicationConfigGet$Plain$Params } from '../fn/public-application/v-3-public-application-config-get-plain';
import { v3PublicApplicationLookupsApplicationIdCountriesGet$Json } from '../fn/public-application/v-3-public-application-lookups-application-id-countries-get-json';
import { V3PublicApplicationLookupsApplicationIdCountriesGet$Json$Params } from '../fn/public-application/v-3-public-application-lookups-application-id-countries-get-json';
import { v3PublicApplicationLookupsApplicationIdCountriesGet$Plain } from '../fn/public-application/v-3-public-application-lookups-application-id-countries-get-plain';
import { V3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Params } from '../fn/public-application/v-3-public-application-lookups-application-id-countries-get-plain';
import { v3PublicApplicationStartOtpGet$Json } from '../fn/public-application/v-3-public-application-start-otp-get-json';
import { V3PublicApplicationStartOtpGet$Json$Params } from '../fn/public-application/v-3-public-application-start-otp-get-json';
import { v3PublicApplicationStartOtpGet$Plain } from '../fn/public-application/v-3-public-application-start-otp-get-plain';
import { V3PublicApplicationStartOtpGet$Plain$Params } from '../fn/public-application/v-3-public-application-start-otp-get-plain';

@Injectable({ providedIn: 'root' })
export class PublicApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3PublicApplicationApplicationIdGetAddressAutocompleteGet()` */
  static readonly V3PublicApplicationApplicationIdGetAddressAutocompleteGetPath = '/v3/public/application/{applicationId}/getAddressAutocomplete';

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Response(params: V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerAutocompleteAddressResponseBaseResponse>> {
    return v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain(params: V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerAutocompleteAddressResponseBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerAutocompleteAddressResponseBaseResponse>): ModelsCustomerAutocompleteAddressResponseBaseResponse => r.body)
    );
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Response(params: V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerAutocompleteAddressResponseBaseResponse>> {
    return v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets address starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json(params: V3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerAutocompleteAddressResponseBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerAutocompleteAddressResponseBaseResponse>): ModelsCustomerAutocompleteAddressResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationApplicationIdGetCompanyAutocompleteGet()` */
  static readonly V3PublicApplicationApplicationIdGetCompanyAutocompleteGetPath = '/v3/public/application/{applicationId}/getCompanyAutocomplete';

  /**
   * Gets company starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Response(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>> {
    return v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets company starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>): ModelsCustomerNzbnCompanyResponseBaseResponse => r.body)
    );
  }

  /**
   * Gets company starting with the input string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Response(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>> {
    return v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets company starting with the input string.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyResponseBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyResponseBaseResponse>): ModelsCustomerNzbnCompanyResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet()` */
  static readonly V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGetPath = '/v3/public/application/{applicationId}/getCompanyAutocomplete/{nzbn}';

  /**
   * Get Company Details by NZBN.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Response(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyDetailsBaseResponse>> {
    return v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Company Details by NZBN.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyDetailsBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyDetailsBaseResponse>): ModelsCustomerNzbnCompanyDetailsBaseResponse => r.body)
    );
  }

  /**
   * Get Company Details by NZBN.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Response(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerNzbnCompanyDetailsBaseResponse>> {
    return v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Company Details by NZBN.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json(params: V3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerNzbnCompanyDetailsBaseResponse> {
    return this.v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerNzbnCompanyDetailsBaseResponse>): ModelsCustomerNzbnCompanyDetailsBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationStartOtpGet()` */
  static readonly V3PublicApplicationStartOtpGetPath = '/v3/public/application/start-otp';

  /**
   * Generate OTP code for application start.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationStartOtpGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationStartOtpGet$Plain$Response(params?: V3PublicApplicationStartOtpGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthOtpResponseBaseResponse>> {
    return v3PublicApplicationStartOtpGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate OTP code for application start.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationStartOtpGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationStartOtpGet$Plain(params?: V3PublicApplicationStartOtpGet$Plain$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthOtpResponseBaseResponse> {
    return this.v3PublicApplicationStartOtpGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthOtpResponseBaseResponse>): ModelsAuthenticationAuthOtpResponseBaseResponse => r.body)
    );
  }

  /**
   * Generate OTP code for application start.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationStartOtpGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationStartOtpGet$Json$Response(params?: V3PublicApplicationStartOtpGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthOtpResponseBaseResponse>> {
    return v3PublicApplicationStartOtpGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate OTP code for application start.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationStartOtpGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationStartOtpGet$Json(params?: V3PublicApplicationStartOtpGet$Json$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthOtpResponseBaseResponse> {
    return this.v3PublicApplicationStartOtpGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthOtpResponseBaseResponse>): ModelsAuthenticationAuthOtpResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationConfigGet()` */
  static readonly V3PublicApplicationConfigGetPath = '/v3/public/application/config';

  /**
   * Get the config settings for an application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationConfigGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationConfigGet$Plain$Response(params?: V3PublicApplicationConfigGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationConfigResponseBaseResponse>> {
    return v3PublicApplicationConfigGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the config settings for an application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationConfigGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationConfigGet$Plain(params?: V3PublicApplicationConfigGet$Plain$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationConfigResponseBaseResponse> {
    return this.v3PublicApplicationConfigGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationConfigResponseBaseResponse>): ModelsV3ApplicationApplicationConfigResponseBaseResponse => r.body)
    );
  }

  /**
   * Get the config settings for an application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationConfigGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationConfigGet$Json$Response(params?: V3PublicApplicationConfigGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationConfigResponseBaseResponse>> {
    return v3PublicApplicationConfigGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the config settings for an application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationConfigGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationConfigGet$Json(params?: V3PublicApplicationConfigGet$Json$Params, context?: HttpContext): Observable<ModelsV3ApplicationApplicationConfigResponseBaseResponse> {
    return this.v3PublicApplicationConfigGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3ApplicationApplicationConfigResponseBaseResponse>): ModelsV3ApplicationApplicationConfigResponseBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3PublicApplicationLookupsApplicationIdCountriesGet()` */
  static readonly V3PublicApplicationLookupsApplicationIdCountriesGetPath = '/v3/public/application/lookups/{applicationId}/countries';

  /**
   * Get country list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationLookupsApplicationIdCountriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Response(params: V3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3CommonLookupInternalResponseListBaseResponse>> {
    return v3PublicApplicationLookupsApplicationIdCountriesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get country list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationLookupsApplicationIdCountriesGet$Plain(params: V3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Params, context?: HttpContext): Observable<ModelsV3CommonLookupInternalResponseListBaseResponse> {
    return this.v3PublicApplicationLookupsApplicationIdCountriesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3CommonLookupInternalResponseListBaseResponse>): ModelsV3CommonLookupInternalResponseListBaseResponse => r.body)
    );
  }

  /**
   * Get country list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3PublicApplicationLookupsApplicationIdCountriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationLookupsApplicationIdCountriesGet$Json$Response(params: V3PublicApplicationLookupsApplicationIdCountriesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3CommonLookupInternalResponseListBaseResponse>> {
    return v3PublicApplicationLookupsApplicationIdCountriesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get country list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3PublicApplicationLookupsApplicationIdCountriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3PublicApplicationLookupsApplicationIdCountriesGet$Json(params: V3PublicApplicationLookupsApplicationIdCountriesGet$Json$Params, context?: HttpContext): Observable<ModelsV3CommonLookupInternalResponseListBaseResponse> {
    return this.v3PublicApplicationLookupsApplicationIdCountriesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsV3CommonLookupInternalResponseListBaseResponse>): ModelsV3CommonLookupInternalResponseListBaseResponse => r.body)
    );
  }

}
