/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { WebhookEventCreateRequestBaseResponse as ModelsWebhookWebhookEventCreateRequestBaseResponse } from '../models/Models/Webhook/webhook-event-create-request-base-response';
import { WebhookEventNotificationResponseBaseBaseResponse as ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse } from '../models/Models/Webhook/webhook-event-notification-response-base-base-response';
import { v3WebhooksCallbacktestPost$Json } from '../fn/webhooks/v-3-webhooks-callbacktest-post-json';
import { V3WebhooksCallbacktestPost$Json$Params } from '../fn/webhooks/v-3-webhooks-callbacktest-post-json';
import { v3WebhooksCallbacktestPost$Plain } from '../fn/webhooks/v-3-webhooks-callbacktest-post-plain';
import { V3WebhooksCallbacktestPost$Plain$Params } from '../fn/webhooks/v-3-webhooks-callbacktest-post-plain';
import { v3WebhooksDeregisterDelete$Json } from '../fn/webhooks/v-3-webhooks-deregister-delete-json';
import { V3WebhooksDeregisterDelete$Json$Params } from '../fn/webhooks/v-3-webhooks-deregister-delete-json';
import { v3WebhooksDeregisterDelete$Plain } from '../fn/webhooks/v-3-webhooks-deregister-delete-plain';
import { V3WebhooksDeregisterDelete$Plain$Params } from '../fn/webhooks/v-3-webhooks-deregister-delete-plain';
import { v3WebhooksRegisterPost$Json } from '../fn/webhooks/v-3-webhooks-register-post-json';
import { V3WebhooksRegisterPost$Json$Params } from '../fn/webhooks/v-3-webhooks-register-post-json';
import { v3WebhooksRegisterPost$Plain } from '../fn/webhooks/v-3-webhooks-register-post-plain';
import { V3WebhooksRegisterPost$Plain$Params } from '../fn/webhooks/v-3-webhooks-register-post-plain';

@Injectable({ providedIn: 'root' })
export class WebhooksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3WebhooksRegisterPost()` */
  static readonly V3WebhooksRegisterPostPath = '/v3/webhooks/register';

  /**
   * Register event's callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksRegisterPost$Plain$Response(params?: V3WebhooksRegisterPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>> {
    return v3WebhooksRegisterPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Register event's callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksRegisterPost$Plain(params?: V3WebhooksRegisterPost$Plain$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventCreateRequestBaseResponse> {
    return this.v3WebhooksRegisterPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>): ModelsWebhookWebhookEventCreateRequestBaseResponse => r.body)
    );
  }

  /**
   * Register event's callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksRegisterPost$Json$Response(params?: V3WebhooksRegisterPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>> {
    return v3WebhooksRegisterPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Register event's callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksRegisterPost$Json(params?: V3WebhooksRegisterPost$Json$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventCreateRequestBaseResponse> {
    return this.v3WebhooksRegisterPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>): ModelsWebhookWebhookEventCreateRequestBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3WebhooksDeregisterDelete()` */
  static readonly V3WebhooksDeregisterDeletePath = '/v3/webhooks/deregister';

  /**
   * Deregister callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksDeregisterDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3WebhooksDeregisterDelete$Plain$Response(params?: V3WebhooksDeregisterDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>> {
    return v3WebhooksDeregisterDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Deregister callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksDeregisterDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3WebhooksDeregisterDelete$Plain(params?: V3WebhooksDeregisterDelete$Plain$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventCreateRequestBaseResponse> {
    return this.v3WebhooksDeregisterDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>): ModelsWebhookWebhookEventCreateRequestBaseResponse => r.body)
    );
  }

  /**
   * Deregister callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksDeregisterDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3WebhooksDeregisterDelete$Json$Response(params?: V3WebhooksDeregisterDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>> {
    return v3WebhooksDeregisterDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Deregister callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksDeregisterDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3WebhooksDeregisterDelete$Json(params?: V3WebhooksDeregisterDelete$Json$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventCreateRequestBaseResponse> {
    return this.v3WebhooksDeregisterDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventCreateRequestBaseResponse>): ModelsWebhookWebhookEventCreateRequestBaseResponse => r.body)
    );
  }

  /** Path part for operation `v3WebhooksCallbacktestPost()` */
  static readonly V3WebhooksCallbacktestPostPath = '/v3/webhooks/callbacktest';

  /**
   * Test event's callback.
   *
   * If the webhook callback was created with a Secret, only the UnixTimestamp property will be HMAC hashed with the secret (not the entire response payload).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksCallbacktestPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksCallbacktestPost$Plain$Response(params?: V3WebhooksCallbacktestPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse>> {
    return v3WebhooksCallbacktestPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Test event's callback.
   *
   * If the webhook callback was created with a Secret, only the UnixTimestamp property will be HMAC hashed with the secret (not the entire response payload).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksCallbacktestPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksCallbacktestPost$Plain(params?: V3WebhooksCallbacktestPost$Plain$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse> {
    return this.v3WebhooksCallbacktestPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse>): ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse => r.body)
    );
  }

  /**
   * Test event's callback.
   *
   * If the webhook callback was created with a Secret, only the UnixTimestamp property will be HMAC hashed with the secret (not the entire response payload).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3WebhooksCallbacktestPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksCallbacktestPost$Json$Response(params?: V3WebhooksCallbacktestPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse>> {
    return v3WebhooksCallbacktestPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Test event's callback.
   *
   * If the webhook callback was created with a Secret, only the UnixTimestamp property will be HMAC hashed with the secret (not the entire response payload).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3WebhooksCallbacktestPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3WebhooksCallbacktestPost$Json(params?: V3WebhooksCallbacktestPost$Json$Params, context?: HttpContext): Observable<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse> {
    return this.v3WebhooksCallbacktestPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse>): ModelsWebhookWebhookEventNotificationResponseBaseBaseResponse => r.body)
    );
  }

}
