import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { EmploymentStatus } from '../api/models/Models/v3/Application/EmploymentDetails/employment-status';
import { UserAction } from '../api/models/CommonUtilities/user-action';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Condition } from '../dataobject/condition';
import { LivingSituationEnum } from '../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
export function numericValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    const isValid = /^\d+$/.test(control.value);
    return isValid ? null : { numeric: true };
  };
}

export function conditionalRequiredValidator(parentControlName: string, requiredValue: any): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    if (!control.parent)
    {
      return null;
    }

    const parentControl = control.parent.get(parentControlName);
    if (parentControl && parentControl.value === requiredValue && !control.value)
    {
      return { required: true };
    }

    return null;
  };
}

const subscriptionMap = new WeakMap<AbstractControl, boolean>();
export function conditionalRequiredValidator2(conditions: Condition[]): ValidatorFn
{
  let isSubscribed = false;
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    if (!subscriptionMap.has(control))
    {
      // Subscribe to value changes of condition controls
      conditions.forEach(condition =>
      {
        condition.control.valueChanges.subscribe(() =>
        {
          control.updateValueAndValidity();
          if (control.parent)
          {
            control.parent.updateValueAndValidity();
          }
        });
      });
      subscriptionMap.set(control, true);
    }

    const allConditionsMet = conditions.every(condition =>
      condition.expectedValues.includes(condition.control.value)
    );

    if (allConditionsMet && !control.value)
    {
      return { required: true };
    }
    return null;
  };
}


export function conditionalRequiredValidatorTraverseFg(parentControlName: string, requiredValue: any): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    var parent = control.parent as FormGroup;
    if (!parent)
    {
      return null;
    }

    // search parent level 1
    var relatedControl = parent.get(parentControlName);
    if (!relatedControl)
    {
      var parent = parent.parent as FormGroup;
      if (!parent)
      {
        return null;
      }
      relatedControl = parent.get(parentControlName);
    }

    if (relatedControl && relatedControl.value === requiredValue)
    {
      return Validators.required(control) ? { required: true } : null;
    }
    return null;
  };
}





export function conditionalRequiredValidatorMulti(parentControlName: string, requiredValues: any[]): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    if (!control.parent)
    {
      return null;
    }

    const parentControl = control.parent.get(parentControlName);

    if (parentControl && requiredValues.includes(parentControl.value) && !control.value)
    {
      return { required: true };
    }

    return null;
  };
}

export function conditionalRequiredValidatorMulti2(dependentParentControl: FormGroup, controlName: string, requiredValues: any[], negate: boolean): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {

    const parentControl = dependentParentControl.get(controlName);
    if (negate == false)
    {
      if (parentControl && requiredValues.includes(parentControl.value) && !control.value)
      {
        return { required: true };
      }
    } else
    {
      if (parentControl && !requiredValues.includes(parentControl.value) && !control.value)
      {
        return { required: true };
      }
    }


    return null;
  };
}


export function conditionalRequiredBasedOnParentValidator(parentControlName: string, requiredState: boolean): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    if (!control.parent)
    {
      return null;
    }

    const parentControl = control.parent.get(parentControlName);
    if (!parentControl)
    {
      return null;
    }

    const isParentRequired = parentControl.hasValidator(Validators.required);
    if (isParentRequired === requiredState && !control.value)
    {
      return { required: true };
    }

    return null;
  };
}


export function conditionalRequiredValidatorMultiParentDependent(parentDependentControlName: string, parentDependentControlRequiredValue: any[],
  parentControlName: string, requiredValues: any[]): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    if (!control.parent)
    {
      return null;
    }

    const parentDependentControl = control.parent.get(parentDependentControlName);
    if (!parentDependentControl)
    {
      return null;
    }

    let parentControlDependedIsRequired = false;

    if (parentDependentControl && parentDependentControlRequiredValue.includes(parentDependentControl.value) && !control.value)
    {
      parentControlDependedIsRequired = true;
    }

    const parentControl = control.parent.get(parentControlName);
    if (parentControlDependedIsRequired && parentControl && requiredValues.includes(parentControl.value) && !control.value)
    {
      return { required: true };
    }

    return null;
  };
}



export function employmentSectionRequiredValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    const parent = control.parent;
    if (!parent)
    {
      return null;
    }

    const condition1Control = parent.get('employmentStatus');
    const condition2Control = parent.get('isStudentEmployed');

    if (!condition1Control || !condition2Control)
    {
      return null;
    }

    const condition1Met = [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed].includes(condition1Control.value);
    const condition2Met = [EmploymentStatus.Student].includes(condition1Control.value) && condition2Control.value == UserAction.Yes;

    if (condition1Met || condition2Met)
    {
      return control.value ? null : { required: true };
    }

    return null;
  };
}


export function incomeSectionRequiredValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    const parent = control.parent;
    if (!parent)
    {
      return null;
    }

    const condition1Control = parent.get('employmentStatus');
    const condition2Control = parent.get('isStudentEmployed');

    if (!condition1Control || !condition2Control)
    {
      return null;
    }

    const condition1Met = [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed, EmploymentStatus.Unemployed].includes(condition1Control.value);
    const condition2Met = [EmploymentStatus.Student].includes(condition1Control.value) && condition2Control.value == UserAction.Yes

    if (condition1Met || condition2Met)
    {
      return control.value ? null : { required: true };
    }

    return null;
  };
}




export function dateValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const value = control.value;

    if (!value)
    {
      return null; // Don't validate empty values to allow required validator to handle them
    }

    const match = datePattern.exec(value);
    if (!match)
    {
      return { invalidDate: true };
    }

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Months are 0-based in JavaScript Date
    const year = parseInt(match[3], 10);

    const date = new Date(year, month, day);
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day)
    {
      return { invalidDate: true };
    }

    return null;
  };
}

export function dateValidatorMoment(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const value = control.value;

    if (!value)
    {
      return null; // Don't validate empty values to allow required validator to handle them
    }

    if (moment.isMoment(value))
    {
      if (!value.isValid())
      {
        return { invalidDate: true };
      }
      return null;
    }

    const match = datePattern.exec(value);
    if (!match)
    {
      return { invalidDate: true };
    }

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Months are 0-based in JavaScript Date
    const year = parseInt(match[3], 10);

    const date = new Date(year, month, day);
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day)
    {
      return { invalidDate: true };
    }

    return null;
  };
}

export function emailValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    if (!control.value)
    {
      return null;
    }
    else
    {
      return Validators.email(control); // Apply email validation if the field is visible
    }

    //return valid
    return null;
  };
}


const phoneNumberUtil = PhoneNumberUtil.getInstance();
export function phoneNumberValidator(): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null =>
  {
    if (control.value)
    {
      try
      {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          control.value, 'NZ'
        );
        return phoneNumberUtil.isValidNumber(phoneNumber) ? null : { invalid: { value: control.value } };
      }
      catch (e)
      {
        console.debug('phoneNumberValidator', e);
        return { invalid: { value: control.value } };
      }
    }

    return null;
  };
}

export function numericRangeValidator(min: number, max: number | null = null): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    return isRangeValid(control, min, max);
  };
}

function isRangeValid(control: AbstractControl<any, any>, min: number, max: number | null, nullValueIsValid: boolean = true)
{
  var isValid = true;
  var errorMessage = '';

  if (control.value != null)
  {
    var number = Number(control.value);
    isValid = number >= min;

    if (!isValid)
    {
      errorMessage = `Value must be at least ${min.toLocaleString()}.`;
    }

    if (isValid && max !== null && number > max)
    {
      isValid = false;
      errorMessage = `Value must be less than or equal to ${max.toLocaleString()}.`;
    }
  }
  else
  {
    if (!nullValueIsValid)
    {
      isValid = false;
      errorMessage = 'Value is required.';
    }
  }

  return isValid ? null : { rangeError: { value: control.value, message: errorMessage } };
}

export function nzDriversLicenceValidator(): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {
    const pattern = /^[A-Za-z]{2}\d{6}$/;
    const isValid = pattern.test(control.value);
    if (control.value == '')
    {
      return null;
    }
    console.log('is nz drivers licence valid:', isValid);
    return isValid ? null : { invalidLicence: { value: control.value } };
  };

}

export function livingPaymentAmountValidator(livingSituationControl: FormControl<LivingSituationEnum | null>): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null =>
  {

    if (livingSituationControl.value != LivingSituationEnum.HomeownerWithoutMortgage)
    {
      switch (livingSituationControl.value)
      {
        case LivingSituationEnum.LivingWithFamily:
        case LivingSituationEnum.EmployerProvided:
          return isRangeValid(control, 0, 10000, false);
        default:
          return isRangeValid(control, 1, 10000, false);
      }
    }
    return null;
  };
}
