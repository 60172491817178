import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, signal, Signal } from '@angular/core';
import { Form, FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../../shared/shared.module';
import { FormButtonGroupSimpleComponent } from '../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldNumericComponent } from '../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldCurrencyComponent } from '../../shared/form-field-currency/form-field-currency.component';
import { FormFieldComponent } from '../../shared/form-field/form-field.component';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { ApplicationFormControlNames } from '../../../types/applicationFromControlNames';
import { VehicleClass } from '../../../api/models/CommonUtilities/vehicle-class';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BaseStepComponent } from '../base-step/base-step.component';
import { AsFormControlPipe } from "../../../transforms/form-control-pipe";
import { FormFieldSelectComponent } from '../../shared/form-field-select/form-field-select.component';
import { ListItem } from '../../../types/listItem';
import { AboutForm } from '../../../types/vehicle-application-types';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { VehicleLookupComponent } from '../vehicle-lookup/vehicle-lookup.component';
import { DtoVehicleBasic } from '../../../api/models/Limelight/Babylon/DataObjects/DTO/dto-vehicle-basic';

@Component({
  selector: 'app-about-step',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    FormButtonGroupSimpleComponent,
    MatSlideToggle,
    AsFormControlPipe,
    FormFieldSelectComponent,
    MatButtonModule,
    MatIcon,
    MatTooltipModule,
    VehicleLookupComponent
  ],
  templateUrl: './about-step.component.html',
  styleUrl: './about-step.component.scss'
})
export class AboutStepComponent extends BaseStepComponent implements OnDestroy, OnInit {
  @Input() parentStepper!: MatStepper;
  @Input() aboutFg!: FormGroup<AboutForm>;

  @Input() formSubmitted!: boolean;

  ApplicationFormControlNames = ApplicationFormControlNames;
  UserAction = UserAction;
  VehicleClass = VehicleClass;

  listItems: ListItem[] = [];

  loanTotal = signal(0);
  netTradeIn = signal(0);

  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.updateNetTradein();
    this.updateLoanTotal();

    this.aboutFg.controls.vehiclePrice.valueChanges.subscribe((value: number | null) => {
      if (value) {
        this.aboutFg.controls.loanAmount.setValue(value);
      }
    });

    // Subscribe to form control value changes and update loan total
    this.subscriptions.push(this.aboutFg.valueChanges.subscribe(() => {
      this.updateNetTradein();
      this.updateLoanTotal();
    }));

    // Subscribe to form control value changes and update loan total
    this.subscriptions.push(this.aboutFg.controls.hasVehicle.valueChanges.subscribe((value) => {
      if(value && value == UserAction.Yes) {
        this.aboutFg.controls.loanAmount.reset();
      }
      //this.updateNetTradein();
      //this.updateLoanTotal();
    }));


    // Clear values on has plate change
    this.aboutFg.controls.hasVehiclePlateVin.valueChanges.subscribe(() => {
      if (this.aboutFg.controls.hasVehiclePlateVin.value == UserAction.Yes) {
        this.aboutFg.controls.vehicleMake.setValue(null);
        this.aboutFg.controls.vehicleModel.setValue(null);
        this.aboutFg.controls.vehicleYear.setValue(null);
      }
      else {
        this.aboutFg.controls.vehiclePlateVin.setValue(null);
      }
    });

    this.subscriptions.push(this.aboutFg.controls.hasVehicle.valueChanges.subscribe((value) => {
      if(value == UserAction.Yes){
        this.enableControls([this.aboutFg.controls.hasVehiclePlateVin, this.aboutFg.controls.vehicleIsBrandNew, this.aboutFg.controls.vehiclePrice]);
      }else{
        this.disableControls([this.aboutFg.controls.hasVehiclePlateVin, this.aboutFg.controls.vehicleIsBrandNew, this.aboutFg.controls.vehiclePrice]);
        this.disableControls([this.aboutFg.controls.vehicleYear,
          this.aboutFg.controls.vehicleMake,
          this.aboutFg.controls.vehicleModel]);
      }
    }));

    this.subscriptions.push(this.aboutFg.controls.hasVehiclePlateVin.valueChanges.subscribe((value) => {
      if(value == UserAction.Yes){
        this.enableControls([this.aboutFg.controls.vehiclePlateVin]);
        this.disableControls([this.aboutFg.controls.vehicleYear, this.aboutFg.controls.vehicleMake, this.aboutFg.controls.vehicleModel]);
      }
      else if(value == UserAction.No){
        this.enableControls([this.aboutFg.controls.vehicleYear, this.aboutFg.controls.vehicleMake, this.aboutFg.controls.vehicleModel]);
        this.disableControls([this.aboutFg.controls.vehiclePlateVin]);
      }
      else{        
        this.disableControls([this.aboutFg.controls.vehiclePlateVin, this.aboutFg.controls.vehicleYear, this.aboutFg.controls.vehicleMake, this.aboutFg.controls.vehicleModel]);
      }
    }));
   
    this.activateControlsWhen(this.aboutFg.controls.hasOtherItems,
      [this.aboutFg.controls.otherItemsCost], null,
      [{ control: this.aboutFg.controls.hasOtherItems, expectedValues: [UserAction.Yes] }]);

    this.activateControlsWhen(this.aboutFg.controls.hasDeposit,
      [this.aboutFg.controls.depositAmount], null,
      [{ control: this.aboutFg.controls.hasDeposit, expectedValues: [UserAction.Yes] }]);

    this.activateControlsWhen(this.aboutFg.controls.hasTradeInVehicle,
      [this.aboutFg.controls.tradeInVehicle.controls.plate,
      this.aboutFg.controls.tradeInVehicle.controls.price,
      this.aboutFg.controls.isVehicleTradeInFinanced], null,
      [{ control: this.aboutFg.controls.hasTradeInVehicle, expectedValues: [UserAction.Yes] }]);

    this.activateControlsWhen(this.aboutFg.controls.isVehicleTradeInFinanced,
      [this.aboutFg.controls.tradeInVehicle.controls.encumbrance], null,
      [{ control: this.aboutFg.controls.hasTradeInVehicle, expectedValues: [UserAction.Yes] },
       { control: this.aboutFg.controls.isVehicleTradeInFinanced, expectedValues: [UserAction.Yes] }
      ]);

  }

  ngOnDestroy(): void {
    this.destroy();
  }

  updateLoanTotal(): void {
    let total = 0;

    if (this.aboutFg.controls.loanAmount.value) {
      total += this.aboutFg.controls.loanAmount.value;
    }

    if (this.aboutFg.controls.hasOtherItems.value == UserAction.Yes && this.aboutFg.controls.otherItemsCost.value) {
      total += this.aboutFg.controls.otherItemsCost.value;
    }

    if (this.aboutFg.controls.hasDeposit.value == UserAction.Yes && this.aboutFg.controls.depositAmount.value) {
      total -= this.aboutFg.controls.depositAmount.value;
    }

    total -= this.netTradeIn();

    this.loanTotal.set(total);
    this.aboutFg.controls.netLoanAmount.setValue(total, { emitEvent: false });
  }

  updateNetTradein(): void {
    var netTradeIn = 0;
    if (this.aboutFg.controls.hasTradeInVehicle.value == UserAction.Yes && this.aboutFg.controls.tradeInVehicle.controls.price.value &&
      this.aboutFg.controls.tradeInVehicle.controls.price.value > 0) {
      netTradeIn = this.aboutFg.controls.tradeInVehicle.controls.price.value;

      if (this.aboutFg.controls.isVehicleTradeInFinanced.value == UserAction.Yes && this.aboutFg.controls.tradeInVehicle.controls.encumbrance.value &&
        this.aboutFg.controls.tradeInVehicle.controls.encumbrance.value > 0) {

        netTradeIn -= this.aboutFg.controls.tradeInVehicle.controls.encumbrance.value
      }
    }

    this.netTradeIn.set(netTradeIn);
  }

  purchaseVehicleSelected(vehicle: DtoVehicleBasic) {
    console.log(vehicle);
    if (vehicle.make) {
      this.aboutFg.controls.vehicleMake.setValue(vehicle.make);
    }
    if (vehicle.model) {
      this.aboutFg.controls.vehicleModel.setValue(vehicle.model);
    }
    if (vehicle.year) {
      this.aboutFg.controls.vehicleYear.setValue(vehicle.year);
    }
  }

  tradeinVehicleSelected(vehicle: DtoVehicleBasic) {
    console.log(vehicle);
    if (vehicle.make) {
      this.aboutFg.controls.tradeInVehicle.controls.make.setValue(vehicle.make);
    }
    if (vehicle.model) {
      this.aboutFg.controls.tradeInVehicle.controls.model.setValue(vehicle.model);
    }
    if (vehicle.year) {
      this.aboutFg.controls.tradeInVehicle.controls.year.setValue(vehicle.year);
    }
  }

}
