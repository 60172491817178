<div class="page-base">
    <i class="icon-warning"></i>
    <h1 class="heading-xxl">Temporarily unavailable</h1>
    <p>We are currently down for maintenance and you are unable to complete an application during this time.</p>
    <p><strong>Please try again after {{untilTime | date:'d MMM YYYY, HH:mm'}}</strong></p>
    <p>We apologise for any inconvenience this may cause.</p>
    <p>
        <strong>Need to get in touch quickly?</strong><br>
        Please contact our team on <a href="tel:{{this.contactNumber}}">{{this.contactNumber}}</a> to discuss.
    </p>
</div>
