<section>
    <div class="group-section small-group">
        <p class="mat-body-large">What is your employment basis?</p>
        <div class="group-required">
            <mat-button-toggle-group class="button-group" aria-label="What is your employment basis?"
                [formControl]="fg.controls.employmentBasis" [hideSingleSelectionIndicator]="true">
                <mat-button-toggle value="{{EmploymentBasis.FullTime}}">Full time</mat-button-toggle>
                <mat-button-toggle value="{{EmploymentBasis.PartTime}}">Part time</mat-button-toggle>
                <mat-button-toggle value="{{EmploymentBasis.Contract}}">Contract</mat-button-toggle>
                <mat-button-toggle value="{{EmploymentBasis.Casual}}">Casual / Seasonal</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error *ngIf="formSubmitted && fg.controls.employmentBasis.hasError('required')">Required</mat-error>
        </div>
    </div>

    <div class="group-section">
        <app-form-field-select label="Occupation" [control]="fg.controls.role"
            [listType]="SelectListType.OccupationTypes">
        </app-form-field-select>
        <app-form-field-select label="Industry" [control]="fg.controls.industryClassification"
            [listType]="SelectListType.IndustryClassifications">
        </app-form-field-select>

        <app-form-field
            [label]="fg.controls.employmentStatus.value == EmploymentStatus.Employed ? 'Current employer' : 'Business name'"
            [control]="fg.controls.employerName">
        </app-form-field>
    </div>

    <div class="group-section small-group">
        <p class="mat-body-large">How long have you been working there?</p>
        <div class="group-required">
            <mat-button-toggle-group class="button-group" aria-label="How long have you been working there?"
                [formControl]="fg.controls.durationMonths" [hideSingleSelectionIndicator]="true">
                <mat-button-toggle value="3">Less than 3 months</mat-button-toggle>
                <mat-button-toggle value="12">3-12 months</mat-button-toggle>
                <mat-button-toggle value="24">1-2 years</mat-button-toggle>
                <mat-button-toggle value="36">2-3 years</mat-button-toggle>
                <mat-button-toggle value="37">More than 3 years</mat-button-toggle>
            </mat-button-toggle-group>

            <mat-error *ngIf="formSubmitted && fg.controls.durationMonths.hasError('required')">Required</mat-error>
        </div>
    </div>

    <div class="group-section small-group">
        <p class="mat-body-large">What is your wage or salary during this time?</p>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-7">
                        <app-form-field-currency [formSubmitted]="formSubmitted" label="Amount"
                            [control]="fg.controls.incomeAmount">
                        </app-form-field-currency>
                    </div>
                    <div class="col-5">
                        <app-form-field-select [control]="fg.controls.incomeFrequency"
                            [listType]="SelectListType.FrequencyTypes_WFMA" label="Frequency"></app-form-field-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-slide-toggle [formControl]="fg.controls.incomeAfterTax" hideIcon>{{(!fg.controls.incomeAfterTax.value ? 'Before tax' : 'After tax')}}</mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="group-section small-group">
        <p class="mat-body-large">What's your Kiwisaver contribution rate?</p>
        <div class="group-required">
            <mat-button-toggle-group class="button-group" aria-label="What's your Kiwisaver contribution rate"
                [formControl]="fg.controls.kiwiSaverRate" [hideSingleSelectionIndicator]="true">
                <mat-button-toggle value="{{KiwisaverRate.Percent0}}">None</mat-button-toggle>
                <mat-button-toggle value="{{KiwisaverRate.Percent3}}">3%</mat-button-toggle>
                <mat-button-toggle value="{{KiwisaverRate.Percent4}}">4%</mat-button-toggle>
                <mat-button-toggle value="{{KiwisaverRate.Percent6}}">6%</mat-button-toggle>
                <mat-button-toggle value="{{KiwisaverRate.Percent8}}">8%</mat-button-toggle>
                <mat-button-toggle value="{{KiwisaverRate.Percent10}}">10%</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error *ngIf="formSubmitted && fg.controls.kiwiSaverRate.hasError('required')">Required</mat-error>
        </div>
    </div>
</section>