/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthOtpResponse as ModelsAuthenticationAuthOtpResponse } from '../models/Models/Authentication/auth-otp-response';
import { CustomerAccountsResponse as ModelsCustomerCustomerAccountsResponse } from '../models/Models/Customer/customer-accounts-response';
import { CustomerDetailsResponse as ModelsCustomerCustomerDetailsResponse } from '../models/Models/Customer/customer-details-response';
import { CustomerEnquiryResponse as ModelsCustomerCustomerEnquiryResponse } from '../models/Models/Customer/customer-enquiry-response';
import { CustomerOffersResponse as ModelsCustomerCustomerOffersResponse } from '../models/Models/Customer/customer-offers-response';
import { CustomerScheduledPaymentsResponse as ModelsCustomerCustomerScheduledPaymentsResponse } from '../models/Models/Customer/customer-scheduled-payments-response';
import { v3CustomersMeAccountsAccountNumberGet$Json } from '../fn/customers/v-3-customers-me-accounts-account-number-get-json';
import { V3CustomersMeAccountsAccountNumberGet$Json$Params } from '../fn/customers/v-3-customers-me-accounts-account-number-get-json';
import { v3CustomersMeAccountsAccountNumberGet$Plain } from '../fn/customers/v-3-customers-me-accounts-account-number-get-plain';
import { V3CustomersMeAccountsAccountNumberGet$Plain$Params } from '../fn/customers/v-3-customers-me-accounts-account-number-get-plain';
import { v3CustomersMeAccountsAccountNumberPaymentsGet$Json } from '../fn/customers/v-3-customers-me-accounts-account-number-payments-get-json';
import { V3CustomersMeAccountsAccountNumberPaymentsGet$Json$Params } from '../fn/customers/v-3-customers-me-accounts-account-number-payments-get-json';
import { v3CustomersMeAccountsAccountNumberPaymentsGet$Plain } from '../fn/customers/v-3-customers-me-accounts-account-number-payments-get-plain';
import { V3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Params } from '../fn/customers/v-3-customers-me-accounts-account-number-payments-get-plain';
import { v3CustomersMeAccountsGet$Json } from '../fn/customers/v-3-customers-me-accounts-get-json';
import { V3CustomersMeAccountsGet$Json$Params } from '../fn/customers/v-3-customers-me-accounts-get-json';
import { v3CustomersMeAccountsGet$Plain } from '../fn/customers/v-3-customers-me-accounts-get-plain';
import { V3CustomersMeAccountsGet$Plain$Params } from '../fn/customers/v-3-customers-me-accounts-get-plain';
import { v3CustomersMeContactDetailsGet } from '../fn/customers/v-3-customers-me-contact-details-get';
import { V3CustomersMeContactDetailsGet$Params } from '../fn/customers/v-3-customers-me-contact-details-get';
import { v3CustomersMeDetailsGet } from '../fn/customers/v-3-customers-me-details-get';
import { V3CustomersMeDetailsGet$Params } from '../fn/customers/v-3-customers-me-details-get';
import { v3CustomersMeDetailsOtpGet$Json } from '../fn/customers/v-3-customers-me-details-otp-get-json';
import { V3CustomersMeDetailsOtpGet$Json$Params } from '../fn/customers/v-3-customers-me-details-otp-get-json';
import { v3CustomersMeDetailsOtpGet$Plain } from '../fn/customers/v-3-customers-me-details-otp-get-plain';
import { V3CustomersMeDetailsOtpGet$Plain$Params } from '../fn/customers/v-3-customers-me-details-otp-get-plain';
import { v3CustomersMeDetailsPatch$Json } from '../fn/customers/v-3-customers-me-details-patch-json';
import { V3CustomersMeDetailsPatch$Json$Params } from '../fn/customers/v-3-customers-me-details-patch-json';
import { v3CustomersMeDetailsPatch$Plain } from '../fn/customers/v-3-customers-me-details-patch-plain';
import { V3CustomersMeDetailsPatch$Plain$Params } from '../fn/customers/v-3-customers-me-details-patch-plain';
import { v3CustomersMeDetailsPut$Json } from '../fn/customers/v-3-customers-me-details-put-json';
import { V3CustomersMeDetailsPut$Json$Params } from '../fn/customers/v-3-customers-me-details-put-json';
import { v3CustomersMeDetailsPut$Plain } from '../fn/customers/v-3-customers-me-details-put-plain';
import { V3CustomersMeDetailsPut$Plain$Params } from '../fn/customers/v-3-customers-me-details-put-plain';
import { v3CustomersMeEnquiryPost$Json } from '../fn/customers/v-3-customers-me-enquiry-post-json';
import { V3CustomersMeEnquiryPost$Json$Params } from '../fn/customers/v-3-customers-me-enquiry-post-json';
import { v3CustomersMeEnquiryPost$Plain } from '../fn/customers/v-3-customers-me-enquiry-post-plain';
import { V3CustomersMeEnquiryPost$Plain$Params } from '../fn/customers/v-3-customers-me-enquiry-post-plain';
import { v3CustomersMeNotificationsGet$Json } from '../fn/customers/v-3-customers-me-notifications-get-json';
import { V3CustomersMeNotificationsGet$Json$Params } from '../fn/customers/v-3-customers-me-notifications-get-json';
import { v3CustomersMeNotificationsGet$Plain } from '../fn/customers/v-3-customers-me-notifications-get-plain';
import { V3CustomersMeNotificationsGet$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-get-plain';
import { v3CustomersMeNotificationsNotificationIdDelete$Json } from '../fn/customers/v-3-customers-me-notifications-notification-id-delete-json';
import { V3CustomersMeNotificationsNotificationIdDelete$Json$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-delete-json';
import { v3CustomersMeNotificationsNotificationIdDelete$Plain } from '../fn/customers/v-3-customers-me-notifications-notification-id-delete-plain';
import { V3CustomersMeNotificationsNotificationIdDelete$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-delete-plain';
import { v3CustomersMeNotificationsNotificationIdGet$Json } from '../fn/customers/v-3-customers-me-notifications-notification-id-get-json';
import { V3CustomersMeNotificationsNotificationIdGet$Json$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-get-json';
import { v3CustomersMeNotificationsNotificationIdGet$Plain } from '../fn/customers/v-3-customers-me-notifications-notification-id-get-plain';
import { V3CustomersMeNotificationsNotificationIdGet$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-get-plain';
import { v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json } from '../fn/customers/v-3-customers-me-notifications-notification-id-read-status-patch-json';
import { V3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-read-status-patch-json';
import { v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain } from '../fn/customers/v-3-customers-me-notifications-notification-id-read-status-patch-plain';
import { V3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-notification-id-read-status-patch-plain';
import { v3CustomersMeNotificationsPatch$Json } from '../fn/customers/v-3-customers-me-notifications-patch-json';
import { V3CustomersMeNotificationsPatch$Json$Params } from '../fn/customers/v-3-customers-me-notifications-patch-json';
import { v3CustomersMeNotificationsPatch$Plain } from '../fn/customers/v-3-customers-me-notifications-patch-plain';
import { V3CustomersMeNotificationsPatch$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-patch-plain';
import { v3CustomersMeNotificationsUnreadGet$Json } from '../fn/customers/v-3-customers-me-notifications-unread-get-json';
import { V3CustomersMeNotificationsUnreadGet$Json$Params } from '../fn/customers/v-3-customers-me-notifications-unread-get-json';
import { v3CustomersMeNotificationsUnreadGet$Plain } from '../fn/customers/v-3-customers-me-notifications-unread-get-plain';
import { V3CustomersMeNotificationsUnreadGet$Plain$Params } from '../fn/customers/v-3-customers-me-notifications-unread-get-plain';
import { v3CustomersMeOffersGet$Json } from '../fn/customers/v-3-customers-me-offers-get-json';
import { V3CustomersMeOffersGet$Json$Params } from '../fn/customers/v-3-customers-me-offers-get-json';
import { v3CustomersMeOffersGet$Plain } from '../fn/customers/v-3-customers-me-offers-get-plain';
import { V3CustomersMeOffersGet$Plain$Params } from '../fn/customers/v-3-customers-me-offers-get-plain';
import { v3CustomersMeOffersOfferIdPatch$Json } from '../fn/customers/v-3-customers-me-offers-offer-id-patch-json';
import { V3CustomersMeOffersOfferIdPatch$Json$Params } from '../fn/customers/v-3-customers-me-offers-offer-id-patch-json';
import { v3CustomersMeOffersOfferIdPatch$Plain } from '../fn/customers/v-3-customers-me-offers-offer-id-patch-plain';
import { V3CustomersMeOffersOfferIdPatch$Plain$Params } from '../fn/customers/v-3-customers-me-offers-offer-id-patch-plain';
import { v3CustomersMePaymentsGet$Json } from '../fn/customers/v-3-customers-me-payments-get-json';
import { V3CustomersMePaymentsGet$Json$Params } from '../fn/customers/v-3-customers-me-payments-get-json';
import { v3CustomersMePaymentsGet$Plain } from '../fn/customers/v-3-customers-me-payments-get-plain';
import { V3CustomersMePaymentsGet$Plain$Params } from '../fn/customers/v-3-customers-me-payments-get-plain';
import { v3CustomersMePingGet } from '../fn/customers/v-3-customers-me-ping-get';
import { V3CustomersMePingGet$Params } from '../fn/customers/v-3-customers-me-ping-get';

@Injectable({ providedIn: 'root' })
export class CustomersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3CustomersMePingGet()` */
  static readonly V3CustomersMePingGetPath = '/v3/customers/me/ping';

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMePingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePingGet$Response(params?: V3CustomersMePingGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3CustomersMePingGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Authenticated Ping - returns 1 if authenticated and with customer portal role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMePingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePingGet(params?: V3CustomersMePingGet$Params, context?: HttpContext): Observable<void> {
    return this.v3CustomersMePingGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeDetailsGet()` */
  static readonly V3CustomersMeDetailsGetPath = '/v3/customers/me/details';

  /**
   * Gets the details belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsGet$Response(params?: V3CustomersMeDetailsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3CustomersMeDetailsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the details belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsGet(params?: V3CustomersMeDetailsGet$Params, context?: HttpContext): Observable<void> {
    return this.v3CustomersMeDetailsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeDetailsPut()` */
  static readonly V3CustomersMeDetailsPutPath = '/v3/customers/me/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPut$Plain$Response(params?: V3CustomersMeDetailsPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>> {
    return v3CustomersMeDetailsPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPut$Plain(params?: V3CustomersMeDetailsPut$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerDetailsResponse> {
    return this.v3CustomersMeDetailsPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>): ModelsCustomerCustomerDetailsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPut$Json$Response(params?: V3CustomersMeDetailsPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>> {
    return v3CustomersMeDetailsPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPut$Json(params?: V3CustomersMeDetailsPut$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerDetailsResponse> {
    return this.v3CustomersMeDetailsPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>): ModelsCustomerCustomerDetailsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeDetailsPatch()` */
  static readonly V3CustomersMeDetailsPatchPath = '/v3/customers/me/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPatch$Plain$Response(params?: V3CustomersMeDetailsPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>> {
    return v3CustomersMeDetailsPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPatch$Plain(params?: V3CustomersMeDetailsPatch$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerDetailsResponse> {
    return this.v3CustomersMeDetailsPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>): ModelsCustomerCustomerDetailsResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPatch$Json$Response(params?: V3CustomersMeDetailsPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>> {
    return v3CustomersMeDetailsPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeDetailsPatch$Json(params?: V3CustomersMeDetailsPatch$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerDetailsResponse> {
    return this.v3CustomersMeDetailsPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerDetailsResponse>): ModelsCustomerCustomerDetailsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeDetailsOtpGet()` */
  static readonly V3CustomersMeDetailsOtpGetPath = '/v3/customers/me/details-otp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsOtpGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsOtpGet$Plain$Response(params?: V3CustomersMeDetailsOtpGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthOtpResponse>> {
    return v3CustomersMeDetailsOtpGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsOtpGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsOtpGet$Plain(params?: V3CustomersMeDetailsOtpGet$Plain$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthOtpResponse> {
    return this.v3CustomersMeDetailsOtpGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthOtpResponse>): ModelsAuthenticationAuthOtpResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeDetailsOtpGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsOtpGet$Json$Response(params?: V3CustomersMeDetailsOtpGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthOtpResponse>> {
    return v3CustomersMeDetailsOtpGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeDetailsOtpGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeDetailsOtpGet$Json(params?: V3CustomersMeDetailsOtpGet$Json$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthOtpResponse> {
    return this.v3CustomersMeDetailsOtpGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthOtpResponse>): ModelsAuthenticationAuthOtpResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeContactDetailsGet()` */
  static readonly V3CustomersMeContactDetailsGetPath = '/v3/customers/me/contact-details';

  /**
   * Gets the details belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeContactDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeContactDetailsGet$Response(params?: V3CustomersMeContactDetailsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3CustomersMeContactDetailsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the details belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeContactDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeContactDetailsGet(params?: V3CustomersMeContactDetailsGet$Params, context?: HttpContext): Observable<void> {
    return this.v3CustomersMeContactDetailsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeAccountsGet()` */
  static readonly V3CustomersMeAccountsGetPath = '/v3/customers/me/accounts';

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsGet$Plain$Response(params?: V3CustomersMeAccountsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>> {
    return v3CustomersMeAccountsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsGet$Plain(params?: V3CustomersMeAccountsGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerAccountsResponse> {
    return this.v3CustomersMeAccountsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>): ModelsCustomerCustomerAccountsResponse => r.body)
    );
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsGet$Json$Response(params?: V3CustomersMeAccountsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>> {
    return v3CustomersMeAccountsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsGet$Json(params?: V3CustomersMeAccountsGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerAccountsResponse> {
    return this.v3CustomersMeAccountsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>): ModelsCustomerCustomerAccountsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeAccountsAccountNumberGet()` */
  static readonly V3CustomersMeAccountsAccountNumberGetPath = '/v3/customers/me/accounts/{accountNumber}';

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsAccountNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberGet$Plain$Response(params: V3CustomersMeAccountsAccountNumberGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>> {
    return v3CustomersMeAccountsAccountNumberGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsAccountNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberGet$Plain(params: V3CustomersMeAccountsAccountNumberGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerAccountsResponse> {
    return this.v3CustomersMeAccountsAccountNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>): ModelsCustomerCustomerAccountsResponse => r.body)
    );
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsAccountNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberGet$Json$Response(params: V3CustomersMeAccountsAccountNumberGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>> {
    return v3CustomersMeAccountsAccountNumberGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the accounts belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsAccountNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberGet$Json(params: V3CustomersMeAccountsAccountNumberGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerAccountsResponse> {
    return this.v3CustomersMeAccountsAccountNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerAccountsResponse>): ModelsCustomerCustomerAccountsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMePaymentsGet()` */
  static readonly V3CustomersMePaymentsGetPath = '/v3/customers/me/payments';

  /**
   * Gets the scheduled payments belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMePaymentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePaymentsGet$Plain$Response(params?: V3CustomersMePaymentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>> {
    return v3CustomersMePaymentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the scheduled payments belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMePaymentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePaymentsGet$Plain(params?: V3CustomersMePaymentsGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerScheduledPaymentsResponse> {
    return this.v3CustomersMePaymentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>): ModelsCustomerCustomerScheduledPaymentsResponse => r.body)
    );
  }

  /**
   * Gets the scheduled payments belonging to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMePaymentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePaymentsGet$Json$Response(params?: V3CustomersMePaymentsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>> {
    return v3CustomersMePaymentsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the scheduled payments belonging to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMePaymentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMePaymentsGet$Json(params?: V3CustomersMePaymentsGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerScheduledPaymentsResponse> {
    return this.v3CustomersMePaymentsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>): ModelsCustomerCustomerScheduledPaymentsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeAccountsAccountNumberPaymentsGet()` */
  static readonly V3CustomersMeAccountsAccountNumberPaymentsGetPath = '/v3/customers/me/accounts/{accountNumber}/payments';

  /**
   * Gets the scheduled payments belonging to the specified account for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsAccountNumberPaymentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Response(params: V3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>> {
    return v3CustomersMeAccountsAccountNumberPaymentsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the scheduled payments belonging to the specified account for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberPaymentsGet$Plain(params: V3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerScheduledPaymentsResponse> {
    return this.v3CustomersMeAccountsAccountNumberPaymentsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>): ModelsCustomerCustomerScheduledPaymentsResponse => r.body)
    );
  }

  /**
   * Gets the scheduled payments belonging to the specified account for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeAccountsAccountNumberPaymentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberPaymentsGet$Json$Response(params: V3CustomersMeAccountsAccountNumberPaymentsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>> {
    return v3CustomersMeAccountsAccountNumberPaymentsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the scheduled payments belonging to the specified account for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeAccountsAccountNumberPaymentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeAccountsAccountNumberPaymentsGet$Json(params: V3CustomersMeAccountsAccountNumberPaymentsGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerScheduledPaymentsResponse> {
    return this.v3CustomersMeAccountsAccountNumberPaymentsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerScheduledPaymentsResponse>): ModelsCustomerCustomerScheduledPaymentsResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeOffersGet()` */
  static readonly V3CustomersMeOffersGetPath = '/v3/customers/me/offers';

  /**
   * Gets the offers applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeOffersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersGet$Plain$Response(params?: V3CustomersMeOffersGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeOffersGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the offers applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeOffersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersGet$Plain(params?: V3CustomersMeOffersGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeOffersGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /**
   * Gets the offers applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeOffersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersGet$Json$Response(params?: V3CustomersMeOffersGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeOffersGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the offers applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeOffersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersGet$Json(params?: V3CustomersMeOffersGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeOffersGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeOffersOfferIdPatch()` */
  static readonly V3CustomersMeOffersOfferIdPatchPath = '/v3/customers/me/offers/{offerId}';

  /**
   * Saves the read status for offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeOffersOfferIdPatch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersOfferIdPatch$Plain$Response(params: V3CustomersMeOffersOfferIdPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeOffersOfferIdPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeOffersOfferIdPatch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersOfferIdPatch$Plain(params: V3CustomersMeOffersOfferIdPatch$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeOffersOfferIdPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /**
   * Saves the read status for offer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeOffersOfferIdPatch$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersOfferIdPatch$Json$Response(params: V3CustomersMeOffersOfferIdPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeOffersOfferIdPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for offer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeOffersOfferIdPatch$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeOffersOfferIdPatch$Json(params: V3CustomersMeOffersOfferIdPatch$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeOffersOfferIdPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeEnquiryPost()` */
  static readonly V3CustomersMeEnquiryPostPath = '/v3/customers/me/enquiry';

  /**
   * Sends an enquiry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeEnquiryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeEnquiryPost$Plain$Response(params?: V3CustomersMeEnquiryPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeEnquiryPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends an enquiry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeEnquiryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeEnquiryPost$Plain(params?: V3CustomersMeEnquiryPost$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeEnquiryPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /**
   * Sends an enquiry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeEnquiryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeEnquiryPost$Json$Response(params?: V3CustomersMeEnquiryPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeEnquiryPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends an enquiry.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeEnquiryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3CustomersMeEnquiryPost$Json(params?: V3CustomersMeEnquiryPost$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeEnquiryPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsGet()` */
  static readonly V3CustomersMeNotificationsGetPath = '/v3/customers/me/notifications';

  /**
   * Gets the notifications applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsGet$Plain$Response(params?: V3CustomersMeNotificationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notifications applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsGet$Plain(params?: V3CustomersMeNotificationsGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /**
   * Gets the notifications applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsGet$Json$Response(params?: V3CustomersMeNotificationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notifications applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsGet$Json(params?: V3CustomersMeNotificationsGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsPatch()` */
  static readonly V3CustomersMeNotificationsPatchPath = '/v3/customers/me/notifications';

  /**
   * Mark all as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsPatch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsPatch$Plain$Response(params?: V3CustomersMeNotificationsPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark all as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsPatch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsPatch$Plain(params?: V3CustomersMeNotificationsPatch$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /**
   * Mark all as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsPatch$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsPatch$Json$Response(params?: V3CustomersMeNotificationsPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark all as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsPatch$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsPatch$Json(params?: V3CustomersMeNotificationsPatch$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsNotificationIdGet()` */
  static readonly V3CustomersMeNotificationsNotificationIdGetPath = '/v3/customers/me/notifications/{notificationId}';

  /**
   * Gets the notification for a given notificationId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdGet$Plain$Response(params: V3CustomersMeNotificationsNotificationIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsNotificationIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notification for a given notificationId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdGet$Plain(params: V3CustomersMeNotificationsNotificationIdGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsNotificationIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /**
   * Gets the notification for a given notificationId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdGet$Json$Response(params: V3CustomersMeNotificationsNotificationIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsNotificationIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notification for a given notificationId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdGet$Json(params: V3CustomersMeNotificationsNotificationIdGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsNotificationIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsNotificationIdDelete()` */
  static readonly V3CustomersMeNotificationsNotificationIdDeletePath = '/v3/customers/me/notifications/{notificationId}';

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdDelete$Plain$Response(params: V3CustomersMeNotificationsNotificationIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsNotificationIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdDelete$Plain(params: V3CustomersMeNotificationsNotificationIdDelete$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsNotificationIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdDelete$Json$Response(params: V3CustomersMeNotificationsNotificationIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsNotificationIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdDelete$Json(params: V3CustomersMeNotificationsNotificationIdDelete$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsNotificationIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsUnreadGet()` */
  static readonly V3CustomersMeNotificationsUnreadGetPath = '/v3/customers/me/notifications/unread';

  /**
   * Gets the notifications count applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsUnreadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsUnreadGet$Plain$Response(params?: V3CustomersMeNotificationsUnreadGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsUnreadGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notifications count applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsUnreadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsUnreadGet$Plain(params?: V3CustomersMeNotificationsUnreadGet$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsUnreadGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /**
   * Gets the notifications count applicable to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsUnreadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsUnreadGet$Json$Response(params?: V3CustomersMeNotificationsUnreadGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerOffersResponse>> {
    return v3CustomersMeNotificationsUnreadGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets the notifications count applicable to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsUnreadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsUnreadGet$Json(params?: V3CustomersMeNotificationsUnreadGet$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerOffersResponse> {
    return this.v3CustomersMeNotificationsUnreadGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerOffersResponse>): ModelsCustomerCustomerOffersResponse => r.body)
    );
  }

  /** Path part for operation `v3CustomersMeNotificationsNotificationIdReadStatusPatch()` */
  static readonly V3CustomersMeNotificationsNotificationIdReadStatusPatchPath = '/v3/customers/me/notifications/{notificationId}/{readStatus}';

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Response(params: V3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain(params: V3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsNotificationIdReadStatusPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Response(params: V3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>> {
    return v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Saves the read status for notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json(params: V3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Params, context?: HttpContext): Observable<ModelsCustomerCustomerEnquiryResponse> {
    return this.v3CustomersMeNotificationsNotificationIdReadStatusPatch$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsCustomerCustomerEnquiryResponse>): ModelsCustomerCustomerEnquiryResponse => r.body)
    );
  }

}
