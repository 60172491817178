/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { v3ContactsMePasswordPut } from '../fn/contacts/v-3-contacts-me-password-put';
import { V3ContactsMePasswordPut$Params } from '../fn/contacts/v-3-contacts-me-password-put';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3ContactsMePasswordPut()` */
  static readonly V3ContactsMePasswordPutPath = '/v3/contacts/me/password';

  /**
   * Changes the logged in user's password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3ContactsMePasswordPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ContactsMePasswordPut$Response(params?: V3ContactsMePasswordPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return v3ContactsMePasswordPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes the logged in user's password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3ContactsMePasswordPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3ContactsMePasswordPut(params?: V3ContactsMePasswordPut$Params, context?: HttpContext): Observable<void> {
    return this.v3ContactsMePasswordPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
