/* tslint:disable */
/* eslint-disable */
export enum KiwiSaverRate {
  Unassigned = 'Unassigned',
  Percent0 = 'Percent_0',
  Percent3 = 'Percent_3',
  Percent4 = 'Percent_4',
  Percent6 = 'Percent_6',
  Percent8 = 'Percent_8',
  Percent10 = 'Percent_10'
}
