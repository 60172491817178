<div class="thank-you-wrap">
    <div class="row">
        <div class="col-12">
            <mat-icon class="check-icon" svgIcon="tick"></mat-icon>
            <h1 class="mat-headline-large">Success!</h1>
            <p class="mat-body-large">
                Your application has been sent to our team for review.
            </p>
            <p class="mat-body-large">
                This should take less than 24 hours and we'll be in touch once we reach a decision or if we need to
                clarify any further information from you.
            </p>
            <p class="mat-body-large" *ngIf="applicationConfigResponse.dealershipName != ''">
                @if(!applicationConfigResponse.isFallbackAccount){
                    We've also let the team at <strong>{{applicationConfigResponse.dealershipName}}</strong> know your application is currently being assessed.
                }   
                @else {
                    <!-- Is the fallback/broker account -->
                     
                }
                
            </p>
            <p class="mat-body-large">
                In the meantime if you have any questions or would like to discuss your application, please contact our
                team on 0800 003 111 or via our website at <a href="https://www.avantifinance.co.nz/"
                    target="_blank">www.avantifinance.co.nz</a>.
            </p>
            <p class="mat-body-large">
                Thanks,<br />
                The Avanti Finance team
            </p>
            <img src="assets/paper-plane.png" alt="Thank you " />
        </div>
    </div>