@if (autocomplete)
{
<app-form-field-autocomplete [label]="label" [control]="control" [options]="filteredOptions" (filterChanged)="filterOptions($event)" [triggerOnFocus]="true">
</app-form-field-autocomplete>
}
@else
{
<mat-form-field class="form-field select" subscriptSizing="dynamic">
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngFor="let o of options" [value]="o.value">{{o.displayName}}</mat-option>
  </mat-select>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
}
