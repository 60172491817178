import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';
import { ErrorStateMatcher } from '@angular/material/core';
import { ListItem } from '../../../types/listItem';

@Component({
  selector: 'app-form-field-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatAutocomplete,
    MatOption
  ],
  templateUrl: './form-field-autocomplete.component.html',
  styleUrl: './form-field-autocomplete.component.scss',
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class FormFieldAutocompleteComponent
{

  @Input() control!: FormControl;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() triggerOnFocus: boolean = false;

  @Input() options: ListItem[] = [];
  @Output() filterChanged = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<ListItem>();

  private debounceTimeout: any;
  onFilterChanged(event: any)
  {
    if (this.debounceTimeout)
    {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() =>
    {
      console.log('onFilterChanged', event.target.value);
      this.filterChanged.emit(event.target.value);
    }, 500);
  }

  onFocus(event: any)
  {
    if (this.triggerOnFocus)
    {
      this.filterChanged.emit(event.target.value);
    }
  }

  onOptionSelected(event: any)
  {
    console.log('onOptionSelected', event);

    let value = event.value ?? null;
    if (value)
    {
      const option = this.options.find(option => option.value === value);
      // console.log('onOptionSelected', option);
      this.optionSelected.emit(option);
    }
  }

  displayFn(value: string): string
  {
    if (!value)
    {
      return '';
    }

    const option = this.options.find(option => option.value === value);
    var returnVal = option ? option.displayName : value;
    return returnVal;
  }

  onKeyDown(event: any)
  {
    //capture enter key and make sure it doesn't submit the form
    if (event.key === 'Enter')
    {
      event.preventDefault();
      if (this.options.length === 1)
      {
        this.optionSelected.emit(this.options[0]);
      }
    }
  }
}
