/* tslint:disable */
/* eslint-disable */
export enum DriversLicenceType {
  Unspecified = 'Unspecified',
  None = 'None',
  Learners = 'Learners',
  Restricted = 'Restricted',
  Full = 'Full',
  Overseas = 'Overseas',
  SpecialConditions = 'SpecialConditions',
  Other = 'Other'
}
