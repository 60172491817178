/* tslint:disable */
/* eslint-disable */
export enum MaritalStatus {
  None = 'None',
  Single = 'Single',
  Married = 'Married',
  DeFacto = 'De_Facto',
  Separated = 'Separated',
  Divorced = 'Divorced',
  Widowed = 'Widowed'
}
