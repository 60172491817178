import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})

export class ErrorService
{

  constructor(private snackBar: MatSnackBar) { }

  handleError(errorResponse: any)
  {

    console.error('Error', errorResponse);
    // Set the error message
    var errors = [];

    // Loop through error.errors and add to errorMessages
    if (errorResponse.error && errorResponse.error.errors)
    {
      for (const key in errorResponse.error.errors)
      {
        if (errorResponse.error.errors.hasOwnProperty(key))
        {
          errors.push(`${key}: ${errorResponse.error.errors[key]}`);
        }
      }
    }
    else if (errorResponse.error && errorResponse.error.detail)
    {
      errors.push(`${errorResponse.error.title}: ${errorResponse.error.detail}`);
    }
    else if (errorResponse instanceof HttpErrorResponse)
    {
      console.error(`${errorResponse.statusText}: ${errorResponse.message}`);
      switch (errorResponse.status)
      {
        case 0:
          errors.push('Server is not available.');
          break;
        default:
          errors.push('An unhandled error occurred: ' + errorResponse.message);
          break;
      }
    }
    //if errorReponse is not an object
    else if (typeof errorResponse === 'string')
    {
      errors.push(errorResponse);
    }
    else
    {
      errors.push('Unexpected error.');
    }

    if (errors.length > 0)
    {
      this.showError(errors.join(';'));      
    }
    console.log('Error', errorResponse);
  }

  showError(message:string){
    this.snackBar.open(`Error: ${message}`, 'Close', {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-warn', 'error-snackbar']
    });
  }

  logFormValidationErrors(formGroup: FormGroup)
  {

    const errors: string[] = [];
    Object.keys(formGroup.controls).forEach(key =>
    {
      const controlErrors = formGroup.get(key)?.errors;
      if (controlErrors)
      {
        Object.keys(controlErrors).forEach(keyError =>
        {
          errors.push(`${key} has error: ${keyError}`);
        });
      }
    });
    console.error('Form validation errors', errors);
  }
}
