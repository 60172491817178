<mat-form-field class="form-field" subscriptSizing="dynamic">
    <mat-label>{{label}}</mat-label>

    <ng-container *ngIf="!isMultiLine; else multiLineTemplate">
        <input matInput [formControl]="control" [placeholder]="placeholder"
            *ngIf="applyAlphaNumericOnly; else noDirective" appAlphaNumericOnly [minlength]="minLength"
            [maxlength]="maxLength" [type]="type">
        <ng-template #noDirective>
            <input matInput [formControl]="control" [placeholder]="placeholder" [minlength]="minLength"
                [maxlength]="maxLength" [type]="type">
        </ng-template>
    </ng-container>

    <ng-template #multiLineTemplate>
        <textarea matInput [formControl]="control" [placeholder]="placeholder" [rows]="textAreaRows" [minlength]="minLength"
            [maxlength]="maxLength"></textarea>
    </ng-template>

    <mat-icon class="error-icon" matSuffix
        *ngIf="(formSubmitted && control.errors) || forceShowErrorIcon">error</mat-icon>
    <mat-icon class="loading-icon" matSuffix *ngIf="isLoading" [@loadingAnimation]="isLoading"
        svgIcon="progress"></mat-icon>
    <mat-icon class="success-icon" matSuffix *ngIf="success">check</mat-icon>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error *ngIf="control.errors && control.errors.hasOwnProperty('duplicate')">Must be a unique value</mat-error>
</mat-form-field>