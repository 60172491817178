import { Injectable } from '@angular/core';
import { Constants } from '../dataobject/constants';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  environment(): string {
    return Constants.Environment_VehicleFinance;
  }
}
