/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DtoAccountInfoBaseResponse as ModelsAccountDtoAccountInfoBaseResponse } from '../models/Models/Account/dto-account-info-base-response';
import { v3AccountsEntityIdInfoGet$Json } from '../fn/accounts/v-3-accounts-entity-id-info-get-json';
import { V3AccountsEntityIdInfoGet$Json$Params } from '../fn/accounts/v-3-accounts-entity-id-info-get-json';
import { v3AccountsEntityIdInfoGet$Plain } from '../fn/accounts/v-3-accounts-entity-id-info-get-plain';
import { V3AccountsEntityIdInfoGet$Plain$Params } from '../fn/accounts/v-3-accounts-entity-id-info-get-plain';

@Injectable({ providedIn: 'root' })
export class AccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3AccountsEntityIdInfoGet()` */
  static readonly V3AccountsEntityIdInfoGetPath = '/v3/accounts/{entityId}/info';

  /**
   * Gets account info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3AccountsEntityIdInfoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3AccountsEntityIdInfoGet$Plain$Response(params: V3AccountsEntityIdInfoGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAccountDtoAccountInfoBaseResponse>> {
    return v3AccountsEntityIdInfoGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets account info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3AccountsEntityIdInfoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3AccountsEntityIdInfoGet$Plain(params: V3AccountsEntityIdInfoGet$Plain$Params, context?: HttpContext): Observable<ModelsAccountDtoAccountInfoBaseResponse> {
    return this.v3AccountsEntityIdInfoGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAccountDtoAccountInfoBaseResponse>): ModelsAccountDtoAccountInfoBaseResponse => r.body)
    );
  }

  /**
   * Gets account info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3AccountsEntityIdInfoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3AccountsEntityIdInfoGet$Json$Response(params: V3AccountsEntityIdInfoGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAccountDtoAccountInfoBaseResponse>> {
    return v3AccountsEntityIdInfoGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets account info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3AccountsEntityIdInfoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3AccountsEntityIdInfoGet$Json(params: V3AccountsEntityIdInfoGet$Json$Params, context?: HttpContext): Observable<ModelsAccountDtoAccountInfoBaseResponse> {
    return this.v3AccountsEntityIdInfoGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAccountDtoAccountInfoBaseResponse>): ModelsAccountDtoAccountInfoBaseResponse => r.body)
    );
  }

}
