import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../shared/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { ListItem } from '../../../types/listItem';
import { PeriodTypeEnum } from '../../../api/models/Models/Application/FinancialDetails/period-type-enum';
import { IncomeType } from '../../../api/models/Models/v3/Application/FinancialDetails/income-type';
import { RoleEnum } from '../../../api/models/Models/v3/Application/EmploymentDetails/role-enum';
import { IndustryClassificationEnum } from '../../../api/models/Models/v3/Application/EmploymentDetails/industry-classification-enum';
import { ApplicationExpenseType } from '../../../api/models/CommonUtilities/application-expense-type';
import { VehicleClass } from '../../../api/models/CommonUtilities/vehicle-class';
import { AssetCashInvestmentRequestCashInvestmentType } from '../../../api/models/Models/v3/Application/AssetDetails/asset-cash-investment-request-cash-investment-type';
import { CreditFacilityType } from '../../../api/models/Models/v3/Application/FinancialDetails/credit-facility-type';
import { OtherFinanceCommitmentType } from '../../../api/models/Models/v3/Application/FinancialDetails/other-finance-commitment-type';
import { ContactType } from '../../../api/models/CommonUtilities/contact-type';
import { ErrorStateMatcher } from '@angular/material/core';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';
import { IdentificationTypeOtherEnum } from '../../../api/models/Models/v3/Application/Identification/identification-type-other-enum';
import { FormFieldAutocompleteComponent } from '../form-field-autocomplete/form-field-autocomplete.component';

@Component({
  selector: 'app-form-field-select',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormFieldAutocompleteComponent
  ],
  templateUrl: './form-field-select.component.html',
  styleUrl: './form-field-select.component.scss',
  providers: [
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ]
})
export class FormFieldSelectComponent implements OnInit
{


  @Input() control!: FormControl;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() options: ListItem[] = [];
  @Input() listType: SelectListType = SelectListType.Custom;

  @Input() autocomplete: boolean = false;

  ngOnInit(): void
  {
    if (this.autocomplete) {
      this.filteredOptions = [...this.options];
    }

    if (this.listType == SelectListType.FrequencyTypes)
    {
      this.options = [
        { value: PeriodTypeEnum.Weekly, displayName: 'Weekly', valueCaption: PeriodTypeEnum.Weekly },
        { value: PeriodTypeEnum.Fortnightly, displayName: 'Fortnightly', valueCaption: PeriodTypeEnum.Fortnightly },
        { value: PeriodTypeEnum.Monthly, displayName: 'Monthly', valueCaption: PeriodTypeEnum.Monthly },
      ]
    }
    else if (this.listType == SelectListType.FrequencyTypes_WFMA)
      {
        this.options = [
          { value: PeriodTypeEnum.Weekly, displayName: 'Weekly', valueCaption: PeriodTypeEnum.Weekly },
          { value: PeriodTypeEnum.Fortnightly, displayName: 'Fortnightly', valueCaption: PeriodTypeEnum.Fortnightly },
          { value: PeriodTypeEnum.Monthly, displayName: 'Monthly', valueCaption: PeriodTypeEnum.Monthly },
          { value: PeriodTypeEnum.Annually, displayName: 'Annually', valueCaption: PeriodTypeEnum.Annually },
        ]
      }
    else if (this.listType == SelectListType.FrequencyTypesAll)
    {
      this.options = [
        { value: PeriodTypeEnum.Weekly, displayName: 'Weekly', valueCaption: PeriodTypeEnum.Weekly },
        { value: PeriodTypeEnum.Fortnightly, displayName: 'Fortnightly', valueCaption: PeriodTypeEnum.Fortnightly },
        { value: PeriodTypeEnum.Monthly, displayName: 'Monthly', valueCaption: PeriodTypeEnum.Monthly },
        { value: PeriodTypeEnum.Quarterly, displayName: 'Quarterly', valueCaption: PeriodTypeEnum.Quarterly },
        { value: PeriodTypeEnum.Annually, displayName: 'Annually', valueCaption: PeriodTypeEnum.Annually },
      ]
    }
    
    else if (this.listType == SelectListType.IncomeSourceTypes)
    {
      this.options = [
        { value: IncomeType.WageSalary, displayName: 'Salary / Wages', valueCaption: IncomeType.WageSalary },
        { value: IncomeType.BusinessContract, displayName: 'Business / Contract income', valueCaption: IncomeType.BusinessContract },
        { value: IncomeType.Investment, displayName: 'Investment income', valueCaption: IncomeType.Investment },
        { value: IncomeType.RentalIncome, displayName: 'Rental', valueCaption: IncomeType.RentalIncome },
        { value: IncomeType.Boarder, displayName: 'Boarder / Flatmate', valueCaption: IncomeType.Boarder },
        { value: IncomeType.CommissionBonus, displayName: 'Commission / Bonus', valueCaption: IncomeType.CommissionBonus },
        { value: IncomeType.Superannuation, displayName: 'Benefits / Superannuation', valueCaption: IncomeType.Superannuation },
        { value: IncomeType.NonTaxableBenefitsAllowances, displayName: 'Non-Taxable benefits / Allowances', valueCaption: IncomeType.NonTaxableBenefitsAllowances },
        { value: IncomeType.OtherRegular, displayName: 'Other regular', valueCaption: IncomeType.OtherRegular },
      ]
    }
    else if (this.listType == SelectListType.OccupationTypes)
    {
      this.options = [
        { value: RoleEnum.ArtsandMediaProfessionals, displayName: 'Arts and Media Professionals', valueCaption: RoleEnum.ArtsandMediaProfessionals },
        { value: RoleEnum.AutomotiveAndEngineeringTradesWorkers, displayName: 'Automotive and Engineering Trades Workers', valueCaption: RoleEnum.AutomotiveAndEngineeringTradesWorkers },
        { value: RoleEnum.AutomotiveAndEngineeringTradesWorkers, displayName: 'Automotive and Engineering Trades Workers', valueCaption: RoleEnum.AutomotiveAndEngineeringTradesWorkers },
        { value: RoleEnum.BusinessHumanResourceAndMarketingProfessionals, displayName: 'Business, Human Resource and Marketing Professionals', valueCaption: RoleEnum.BusinessHumanResourceAndMarketingProfessionals },
        { value: RoleEnum.CarersandAides, displayName: 'Carers and Aides', valueCaption: RoleEnum.CarersandAides },
        { value: RoleEnum.ChiefExecutivesGeneralManagersandLegislators, displayName: 'Chief Executives, General Managers and Legislators', valueCaption: RoleEnum.ChiefExecutivesGeneralManagersandLegislators },
        { value: RoleEnum.CleanersandLaundryWorkers, displayName: 'Cleaners and Laundry Workers', valueCaption: RoleEnum.CleanersandLaundryWorkers },
        { value: RoleEnum.ClericalandOfficeSupportWorkers, displayName: 'Clerical and Office Support Workers', valueCaption: RoleEnum.ClericalandOfficeSupportWorkers },
        { value: RoleEnum.ConstructionandMiningLabourers, displayName: 'Construction and Mining Labourers', valueCaption: RoleEnum.ConstructionandMiningLabourers },
        { value: RoleEnum.ConstructionTradesWorkers, displayName: 'Construction Trades Workers', valueCaption: RoleEnum.ConstructionTradesWorkers },
        { value: RoleEnum.DesignEngineeringScienceandTransportProfessionals, displayName: 'Design, Engineering, Science and Transport Professionals', valueCaption: RoleEnum.DesignEngineeringScienceandTransportProfessionals },
        { value: RoleEnum.ElectroTechnologyAndTelecommunicationsTradesWorkers, displayName: 'Electrotechnology and Telecommunications Trades Workers', valueCaption: RoleEnum.ElectroTechnologyAndTelecommunicationsTradesWorkers },
        { value: RoleEnum.EngineeringIcTandScienceTechnicians, displayName: 'Engineering, ICT and Science Technicians', valueCaption: RoleEnum.EngineeringIcTandScienceTechnicians },
        { value: RoleEnum.FactoryProcessWorkers, displayName: 'Factory Process Workers', valueCaption: RoleEnum.FactoryProcessWorkers },
        { value: RoleEnum.FarmForestryAndGardenWorkers, displayName: 'Farm, Forestry and Garden Workers', valueCaption: RoleEnum.FarmForestryAndGardenWorkers },
        { value: RoleEnum.FarmersandFarmManagers, displayName: 'Farmers and Farm Managers', valueCaption: RoleEnum.FarmersandFarmManagers },
        { value: RoleEnum.FoodPreparationAssistants, displayName: 'Food Preparation Assistants', valueCaption: RoleEnum.FoodPreparationAssistants },
        { value: RoleEnum.FoodTradesWorkers, displayName: 'Food Trades Workers', valueCaption: RoleEnum.FoodTradesWorkers },
        { value: RoleEnum.GeneralClericalWorkers, displayName: 'General Clerical Workers', valueCaption: RoleEnum.GeneralClericalWorkers },
        { value: RoleEnum.HealthandWelfareSupportWorkers, displayName: 'Health and Welfare Support Workers', valueCaption: RoleEnum.HealthandWelfareSupportWorkers },
        { value: RoleEnum.HealthProfessionals, displayName: 'Health Professionals', valueCaption: RoleEnum.HealthProfessionals },
        { value: RoleEnum.HospitalityWorkers, displayName: 'Hospitality Workers', valueCaption: RoleEnum.HospitalityWorkers },
        { value: RoleEnum.HospitalityRetailandServiceManagers, displayName: 'Hospitality, Retail and Service Managers', valueCaption: RoleEnum.HospitalityRetailandServiceManagers },
        { value: RoleEnum.Housewifehusband, displayName: 'House wife/husband', valueCaption: RoleEnum.Housewifehusband },
        { value: RoleEnum.IctProfessionals, displayName: 'ICT Professionals', valueCaption: RoleEnum.IctProfessionals },
        { value: RoleEnum.InquiryClerksandReceptionists, displayName: 'Inquiry Clerks and Receptionists', valueCaption: RoleEnum.InquiryClerksandReceptionists },
        { value: RoleEnum.Invalidpensioner, displayName: 'Invalid pensioner', valueCaption: RoleEnum.Invalidpensioner },
        { value: RoleEnum.LegalSocialandWelfareProfessionals, displayName: 'Legal, Social and Welfare Professionals', valueCaption: RoleEnum.LegalSocialandWelfareProfessionals },
        { value: RoleEnum.MachineandStationaryPlantOperators, displayName: 'Machine and Stationary Plant Operators', valueCaption: RoleEnum.MachineandStationaryPlantOperators },
        { value: RoleEnum.MobilePlantOperators, displayName: 'Mobile Plant Operators', valueCaption: RoleEnum.MobilePlantOperators },
        { value: RoleEnum.NumericalClerks, displayName: 'Numerical Clerks', valueCaption: RoleEnum.NumericalClerks },
        { value: RoleEnum.OfficeManagersAndProgramAdministrators, displayName: 'Office Managers and Program Administrators', valueCaption: RoleEnum.OfficeManagersAndProgramAdministrators },
        { value: RoleEnum.OtherClericalandAdministrativeWorkers, displayName: 'Other Clerical and Administrative Workers', valueCaption: RoleEnum.OtherClericalandAdministrativeWorkers },
        { value: RoleEnum.OtherLabourers, displayName: 'Other Labourers', valueCaption: RoleEnum.OtherLabourers },
        { value: RoleEnum.OtherOccupationInadequatelyDescribed, displayName: 'Other Occupation (Inadequately Described)', valueCaption: RoleEnum.OtherOccupationInadequatelyDescribed },
        { value: RoleEnum.OtherTechniciansAndTradesWorkers, displayName: 'Other Technicians and Trades Workers', valueCaption: RoleEnum.OtherTechniciansAndTradesWorkers },
        { value: RoleEnum.PersonalAssistantsAndSecretaries, displayName: 'Personal Assistants and Secretaries', valueCaption: RoleEnum.PersonalAssistantsAndSecretaries },
        { value: RoleEnum.ProtectiveServiceWorkers, displayName: 'Protective Service Workers', valueCaption: RoleEnum.ProtectiveServiceWorkers },
        //{ value: RoleEnum.Retired, displayName: 'Retired', valueCaption: RoleEnum.Retired },
        { value: RoleEnum.RoadandRailDrivers, displayName: 'Road and Rail Drivers', valueCaption: RoleEnum.RoadandRailDrivers },
        { value: RoleEnum.SalesAssistantsAndSalespersons, displayName: 'Sales Assistants and Salespersons', valueCaption: RoleEnum.SalesAssistantsAndSalespersons },
        { value: RoleEnum.SalesRepresentativesAndAgents, displayName: 'Sales Representatives and Agents', valueCaption: RoleEnum.SalesRepresentativesAndAgents },
        { value: RoleEnum.SalesSupportWorkers, displayName: 'Sales Support Workers', valueCaption: RoleEnum.SalesSupportWorkers },
        { value: RoleEnum.SkilledAnimalAndHorticulturalWorkers, displayName: 'Skilled Animal and Horticultural Workers', valueCaption: RoleEnum.SkilledAnimalAndHorticulturalWorkers },
        { value: RoleEnum.SpecialistManagers, displayName: 'Specialist Managers', valueCaption: RoleEnum.SpecialistManagers },
        { value: RoleEnum.SportsandPersonalServiceWorkers, displayName: 'Sports and Personal Service Workers', valueCaption: RoleEnum.SportsandPersonalServiceWorkers },
        { value: RoleEnum.StorePersons, displayName: 'Storepersons', valueCaption: RoleEnum.StorePersons },
        //{ value: RoleEnum.Student, displayName: 'Student', valueCaption: RoleEnum.Student },
        //{ value: RoleEnum.Unemployed, displayName: 'Unemployed', valueCaption: RoleEnum.Unemployed },
      ]
    }
    else if (this.listType == SelectListType.IndustryClassifications)
    {
      this.options = [
        { value: IndustryClassificationEnum.AgricultureForestryAndFishing, displayName: 'Agriculture, Forestry and Fishing', valueCaption: IndustryClassificationEnum.AgricultureForestryAndFishing },
        { value: IndustryClassificationEnum.Mining, displayName: 'Mining', valueCaption: IndustryClassificationEnum.Mining },
        { value: IndustryClassificationEnum.Manufacturing, displayName: 'Manufacturing', valueCaption: IndustryClassificationEnum.Manufacturing },
        { value: IndustryClassificationEnum.ElectricityGasWaterAndWasteServices, displayName: 'Electricity, Gas, Water and Waste Services', valueCaption: IndustryClassificationEnum.ElectricityGasWaterAndWasteServices },
        { value: IndustryClassificationEnum.Construction, displayName: 'Construction', valueCaption: IndustryClassificationEnum.Construction },
        { value: IndustryClassificationEnum.WholesaleTrade, displayName: 'Wholesale Trade', valueCaption: IndustryClassificationEnum.WholesaleTrade },
        { value: IndustryClassificationEnum.RetailTrade, displayName: 'Retail Trade', valueCaption: IndustryClassificationEnum.RetailTrade },
        { value: IndustryClassificationEnum.AccommodationAndFoodServices, displayName: 'Accommodation and Food Services', valueCaption: IndustryClassificationEnum.AccommodationAndFoodServices },
        { value: IndustryClassificationEnum.TransportPostalAndWarehousing, displayName: 'Transport, Postal and Warehousing', valueCaption: IndustryClassificationEnum.TransportPostalAndWarehousing },
        { value: IndustryClassificationEnum.InformationMediaAndTelecommunications, displayName: 'Information Media and Telecommunications', valueCaption: IndustryClassificationEnum.InformationMediaAndTelecommunications },
        { value: IndustryClassificationEnum.FinancialAndInsuranceServices, displayName: 'Financial and Insurance Services', valueCaption: IndustryClassificationEnum.FinancialAndInsuranceServices },
        { value: IndustryClassificationEnum.RentalHiringAndRealEstateServices, displayName: 'Rental, Hiring and Real Estate Services', valueCaption: IndustryClassificationEnum.RentalHiringAndRealEstateServices },
        { value: IndustryClassificationEnum.ProfessionalScientificAndTechnicalServices, displayName: 'Professional, Scientific and Technical Services', valueCaption: IndustryClassificationEnum.ProfessionalScientificAndTechnicalServices },
        { value: IndustryClassificationEnum.AdministrativeAndSupportServices, displayName: 'Administrative and Support Services', valueCaption: IndustryClassificationEnum.AdministrativeAndSupportServices },
        { value: IndustryClassificationEnum.PublicAdministrationAndSafety, displayName: 'Public Administration and Safety', valueCaption: IndustryClassificationEnum.PublicAdministrationAndSafety },
        { value: IndustryClassificationEnum.EducationAndTraining, displayName: 'Education and Training', valueCaption: IndustryClassificationEnum.EducationAndTraining },
        { value: IndustryClassificationEnum.HealthCareAndSocialAssistance, displayName: 'Health Care and Social Assistance', valueCaption: IndustryClassificationEnum.HealthCareAndSocialAssistance },
        { value: IndustryClassificationEnum.ArtsAndRecreationServices, displayName: 'Arts and Recreation Services', valueCaption: IndustryClassificationEnum.ArtsAndRecreationServices },
        { value: IndustryClassificationEnum.Other, displayName: 'Other Services', valueCaption: IndustryClassificationEnum.Other },
      ]
    }
    else if (this.listType == SelectListType.ExpenseTypes)
    {
      this.options = [
        { value: ApplicationExpenseType.Accommodation, displayName: 'Accommodation costs (rent/board)', valueCaption: ApplicationExpenseType.Accommodation },
        { value: ApplicationExpenseType.Medical, displayName: 'Medical costs', valueCaption: ApplicationExpenseType.Medical },
        { value: ApplicationExpenseType.EducationChildcare, displayName: 'Childcare & education', valueCaption: ApplicationExpenseType.EducationChildcare },
        { value: ApplicationExpenseType.Rates, displayName: 'Rates', valueCaption: ApplicationExpenseType.Rates },
        { value: ApplicationExpenseType.ChildSupport, displayName: 'Child support', valueCaption: ApplicationExpenseType.ChildSupport },
        { value: ApplicationExpenseType.GivingTithing, displayName: 'Giving / tithing', valueCaption: ApplicationExpenseType.GivingTithing },
        { value: ApplicationExpenseType.Other, displayName: 'Other regular', valueCaption: ApplicationExpenseType.Other },
      ]
    }
    else if (this.listType == SelectListType.VehicleClasses)
    {
      this.options = [
        { value: VehicleClass.PassengerVehicle, displayName: 'Passenger / Van / Ute', valueCaption: VehicleClass.PassengerVehicle },
        { value: VehicleClass.Motorcycle, displayName: 'Motorcycle', valueCaption: VehicleClass.Motorcycle },
        { value: VehicleClass.Caravan, displayName: 'Caravan', valueCaption: VehicleClass.Caravan },
        { value: VehicleClass.Motorhome, displayName: 'Motorhome', valueCaption: VehicleClass.Motorhome },
        { value: VehicleClass.Boat, displayName: 'Marine', valueCaption: VehicleClass.Boat },
        { value: VehicleClass.GoodsVehicle, displayName: 'Truck', valueCaption: VehicleClass.GoodsVehicle },
        { value: VehicleClass.Machine, displayName: 'Machinery', valueCaption: VehicleClass.Machine }
      ]
    }
    else if (this.listType == SelectListType.AssetInvestmentTypes)
    {
      this.options = [
        { value: AssetCashInvestmentRequestCashInvestmentType.Savings, displayName: 'Savings', valueCaption: AssetCashInvestmentRequestCashInvestmentType.Savings },
        { value: AssetCashInvestmentRequestCashInvestmentType.Investment, displayName: 'Investments / Shares', valueCaption: AssetCashInvestmentRequestCashInvestmentType.Investment },
        { value: AssetCashInvestmentRequestCashInvestmentType.Superannuation, displayName: 'Superannuation', valueCaption: AssetCashInvestmentRequestCashInvestmentType.Superannuation }
      ]
    }
    else if (this.listType == SelectListType.LiabilityCreditTypes)
    {
      this.options = [
        { value: CreditFacilityType.CreditCard, displayName: 'Credit / Store / Charge cards ', valueCaption: CreditFacilityType.CreditCard },
        { value: CreditFacilityType.Overdraft, displayName: 'Overdraft', valueCaption: CreditFacilityType.Overdraft },
        { value: CreditFacilityType.RevolvingCreditHomeLoan, displayName: 'Revolving Credit Home Loan', valueCaption: CreditFacilityType.RevolvingCreditHomeLoan },
      ]
    }
    else if (this.listType == SelectListType.LiabilityCommitmentTypes)
    {
      this.options = [
        { value: OtherFinanceCommitmentType.HomeLoan, displayName: 'Home loan', valueCaption: OtherFinanceCommitmentType.HomeLoan },
        { value: OtherFinanceCommitmentType.CarLoan, displayName: 'Car loan', valueCaption: OtherFinanceCommitmentType.CarLoan },
        { value: OtherFinanceCommitmentType.PersonalLoan, displayName: 'Personal loan', valueCaption: OtherFinanceCommitmentType.PersonalLoan },
        { value: OtherFinanceCommitmentType.BuyNowPayLater, displayName: 'Buy now pay later', valueCaption: OtherFinanceCommitmentType.BuyNowPayLater },
        { value: OtherFinanceCommitmentType.OtherLoans, displayName: 'Other loans', valueCaption: OtherFinanceCommitmentType.OtherLoans },
      ]
    }
    else if (this.listType == SelectListType.TrusteeTypes)
    {
      this.options = [
        { value: ContactType.Trustee, displayName: 'Trustee', valueCaption: ContactType.Trustee },
        { value: ContactType.ProfessionalTrustee, displayName: 'Professional Trustee', valueCaption: ContactType.ProfessionalTrustee },
      ]
    }
    else if (this.listType == SelectListType.OtherIdTypes)
    {
      this.options = [
        { value: IdentificationTypeOtherEnum.ForeignDriversLicense, displayName: 'Foreign Drivers License', valueCaption: IdentificationTypeOtherEnum.ForeignDriversLicense },
        { value: IdentificationTypeOtherEnum.KiwiAccess, displayName: 'Kiwi Access / 18+', valueCaption: IdentificationTypeOtherEnum.KiwiAccess },
        { value: IdentificationTypeOtherEnum.FirearmsLicense, displayName: 'NZ Fire Arms Licence', valueCaption: IdentificationTypeOtherEnum.FirearmsLicense },
        { value: IdentificationTypeOtherEnum.BirthCertificate, displayName: 'Birth Certificate', valueCaption: IdentificationTypeOtherEnum.BirthCertificate },
        { value: IdentificationTypeOtherEnum.Other, displayName: 'Other', valueCaption: IdentificationTypeOtherEnum.Other },
      ]
    }
    else if (this.listType == SelectListType.OtherApplicantTypes)
      {
        this.options = [
          { value: ContactType.Secondary, displayName: 'Co-borrower', valueCaption: ContactType.Secondary },
          { value: ContactType.Guarantor, displayName: 'Guarantor', valueCaption: ContactType.Guarantor },
        ]
      }
  }

  filteredOptions: ListItem[] = [];
  filterOptions(search: string)
  {
    if (!search)
    {
      this.filteredOptions = [...this.options];
    }
    this.filteredOptions = this.options.filter(option => option.displayName.toLowerCase().includes(search.toLowerCase()));
  }

}

export enum SelectListType
{
  Custom = 'Custom',
  FrequencyTypes = 'FrequencyTypes',
  FrequencyTypes_WFMA = 'FrequencyTypes_WFMA',
  FrequencyTypesAll = 'FrequencyTypesAll',
  IncomeSourceTypes = 'IncomeSourceTypes',
  OccupationTypes = 'OccupationTypes',
  IndustryClassifications = 'IndustryClassifications',
  ExpenseTypes = 'ExpenseTypes',
  VehicleClasses = 'VehicleClasses',
  AssetInvestmentTypes = 'AssetInvestmentTypes',
  LiabilityCreditTypes = 'LiabilityCreditTypes',
  LiabilityCommitmentTypes = 'LiabilityCommitmentTypes',
  TrusteeTypes = 'TrusteeTypes',
  OtherApplicantTypes = 'OtherApplicantTypes',
  OtherIdTypes = 'OtherIdTypes',
}
