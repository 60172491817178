import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService
{

  constructor() { }

  //loading subject
  public loading$ = new BehaviorSubject<boolean>(false);
  //set loading state
  public setLoadingState(loading: boolean): void
  {
    this.loading$.next(loading);
  }

}
