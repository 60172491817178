/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApplicationRequest as ModelsV3ApplicationApplicationRequest } from '../../models/Models/v3/Application/application-request';
import { ApplicationUpdateVehicleResponseBaseResponse as ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse } from '../../models/Models/v3/Application/Public/application-update-vehicle-response-base-response';

export interface V3ApplicationVehicleApplicationIdPut$Plain$Params {
  applicationId: string;
      body?: ModelsV3ApplicationApplicationRequest
}

export function v3ApplicationVehicleApplicationIdPut$Plain(http: HttpClient, rootUrl: string, params: V3ApplicationVehicleApplicationIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationVehicleApplicationIdPut$Plain.PATH, 'put');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3ApplicationPublicApplicationUpdateVehicleResponseBaseResponse>;
    })
  );
}

v3ApplicationVehicleApplicationIdPut$Plain.PATH = '/v3/application/vehicle/{applicationId}';
