<mat-progress-bar mode="indeterminate" *ngIf="loading" class="in-page-loading-progress-bar"></mat-progress-bar>

<div class="main-container" *ngIf="!vehicleAppService.isVerified" [class.loading]="loading">
    <app-step-otp (otpCompleted)="verifyInitIdentity($event)"></app-step-otp>
</div>

<div class="container app-form" *ngIf="vehicleAppService.isVerified " [class.loading]="loading">

    <div *ngIf="!isSubmitted">
        <div class="row">
            <div class="col-12">
                <div class="progress-container">
                    <button mat-icon-button class="back-btn no-hover-bg" (click)="previousStep(stepper)"
                        [ngClass]="{'disabled': stepper.selectedIndex === 0}"><mat-icon svgIcon="back-arrow"></mat-icon>
                    </button>
                    <mat-progress-bar mode="determinate"
                        [value]="vehicleAppService.percentageStepsComplete"></mat-progress-bar>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <mat-horizontal-stepper linear #stepper orientation="horizontal"
                    [animationDuration]="vehicleAppService.stepperDuration">
                    <ng-container *ngIf="vehicleAppService.vehicleApplicationFg">


                        <!-- Step 1 -->
                        <mat-step [stepControl]="vehicleAppService.vehicleApplicationFg.aboutFg">
                            <app-about-step [aboutFg]="vehicleAppService.vehicleApplicationFg.aboutFg"
                                [parentStepper]="stepper" (onButtonNextEvent)="onButtonNextCompleted($event)"></app-about-step>
                        </mat-step>

                        <!-- Step 2 -->
                        <mat-step [stepControl]="vehicleAppService.vehicleApplicationFg.loanTypeFg">
                            <app-loan-type-step [fg]="vehicleAppService.vehicleApplicationFg.loanTypeFg"
                                [parentStepper]="stepper" (formGroupEmitter)="handleLoanTypeStepFinalize($event)"
                                (removeApplicantEmitter)="handleLoanTypeRemoveApplicant($event)"
                                (onButtonNextEvent)="onButtonNextCompleted($event)"
                                ></app-loan-type-step>
                        </mat-step>

                        <!-- Entity Steps -->
                        <ng-container *ngIf="vehicleAppService.vehicleApplicationFg.entityFg?.controls">
                            <mat-step [stepControl]="vehicleAppService.vehicleApplicationFg.entityFg?.controls!.contactDetailFg">
                                <app-contact-detail
                                    [fg]="vehicleAppService.vehicleApplicationFg.entityFg!.controls.contactDetailFg"
                                    [parentFg]="vehicleAppService.vehicleApplicationFg.entityFg!"
                                    [parentStepper]="stepper"
                                    (onButtonNextEvent)="onButtonNextCompleted($event)"></app-contact-detail>
                            </mat-step>

                            <mat-step [stepControl]="vehicleAppService.vehicleApplicationFg.entityFg?.controls!.incomeExpensesFg">
                                <app-income-expenses
                                    [fg]="vehicleAppService.vehicleApplicationFg.entityFg!.controls.incomeExpensesFg"
                                    [parentFg]="vehicleAppService.vehicleApplicationFg.entityFg!"
                                    [parentStepper]="stepper"
                                    (onButtonNextEvent)="onButtonNextCompleted($event)"></app-income-expenses>
                            </mat-step>

                        </ng-container>


                        <!-- Step 3> Individual's steps-->
                        <ng-container *ngIf="this.vehicleAppService.vehicleApplicationFg.indivFormArray">
                            <ng-container
                                *ngFor="let indFg of this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls; let i = index">
                                <mat-step [stepControl]="indFg" #stepRef>
                                    <app-individual-applicant [individualFormGroup]="indFg" [parentStepper]="stepper"
                                        [currentStep]="stepRef"></app-individual-applicant>
                                </mat-step>
                            </ng-container>
                        </ng-container>

                        <!-- Summary Review Step -->
                        <mat-step [stepControl]="vehicleAppService.vehicleApplicationFg.summaryReviewFg">
                            <app-summary-review-step [fg]="vehicleAppService.vehicleApplicationFg.summaryReviewFg"
                                [parentStepper]="stepper"
                                (onButtonNextEvent)="onFinalStepNextCompleted($event)"></app-summary-review-step>
                        </mat-step>


                    </ng-container>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isSubmitted">
        <div class="col-12">
            <app-application-completed></app-application-completed>
        </div>
    </div>

    <div class="powered-by">
        Powered by <img src="assets/avanti-logo-grey.svg" alt="Avanti Finance" />
    </div>

    @if(environment.debug == true){
    <button mat-button (click)="submit()">Submit application</button>
    }
</div>