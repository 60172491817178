/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DtoInsuranceProductListBaseResponse as ModelsV3InsuranceDtoInsuranceProductListBaseResponse } from '../../models/Models/v3/Insurance/dto-insurance-product-list-base-response';
import { InsuranceProductsRequestEx as ModelsV3InsuranceInsuranceProductsRequestEx } from '../../models/Models/v3/Insurance/insurance-products-request-ex';

export interface V3ApplicationsApplicationIdInsuranceProductsPost$Plain$Params {
  applicationId: string;
      body?: ModelsV3InsuranceInsuranceProductsRequestEx
}

export function v3ApplicationsApplicationIdInsuranceProductsPost$Plain(http: HttpClient, rootUrl: string, params: V3ApplicationsApplicationIdInsuranceProductsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationsApplicationIdInsuranceProductsPost$Plain.PATH, 'post');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3InsuranceDtoInsuranceProductListBaseResponse>;
    })
  );
}

v3ApplicationsApplicationIdInsuranceProductsPost$Plain.PATH = '/v3/applications/{applicationId}/insurance/products';
