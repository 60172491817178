/* tslint:disable */
/* eslint-disable */
export enum LoanEntityType {
  None = 'None',
  Individual = 'Individual',
  Company = 'Company',
  Trust = 'Trust',
  Partner = 'Partner',
  SoleTrader = 'SoleTrader',
  Other = 'Other'
}
