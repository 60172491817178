<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section">
            <h1 class="mat-headline-large">Other</h1>
        </div>

        <div class="group-section">
            <p class="mat-body-large section-title">
                Is there anything else you'd like us to know?
                <span class="mat-body-large section-subtitle">This should include any known upcoming changes to your
                    circumstances (ie. anything that will affect your income, expenses or liabilities).</span>
            </p>
        </div>

        <div class="group-section">
            <app-form-field [formSubmitted]="form.submitted" label="Description"
                [control]="fg.controls.futureUpcomingChangesDescription" [isMultiLine]="true" [textAreaRows]="6">
            </app-form-field>
        </div>

        <div class="section-next">
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Submit</button>
        </div>
    </section>
</form>