import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleGroup, MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { MatError} from '@angular/material/form-field';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';

@Component({
  selector: 'app-form-button-group-simple',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
  ],
  templateUrl: './form-button-group-simple.component.html',
  styleUrl: './form-button-group-simple.component.scss'
})
export class FormButtonGroupSimpleComponent {
  @Input() formSubmitted!: boolean;
  @Input() control!: FormControl;
    
  @Input() label: string='';
  @Input() showLabel: boolean=true;
  @Input() errorPlaceholder: string='Required';

  @Input() yesLabel: string='Yes';
  @Input() yesValue : string = UserAction.Yes;
  
  @Input() noLabel: string='No';
  @Input() noValue: string = UserAction.No;
  
  @Input() hint: string='';

}
