<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <span class="mat-title-medium section-subtitle">{{individualHeaderDisplay}} {{parentFg.controls.step1.controls.firstName.value}} {{parentFg.controls.step1.controls.lastName.value}}</span>
            <h1 class="mat-headline-large">Address & contact info</h1>
        </div>

        <div class="group-section">            
            <ng-container *ngIf="showPartnerAddressQuestion && partnerFg" >
                <div class="group-section small-group">
                    <app-form-button-group-simple [formSubmitted]="form.submitted"
                        [control]="fg.controls.isAddressProvidedByPartner"
                        [label]="'Do you live at the same address as ' + partnerFg.controls.step1.controls.firstName.value + ' ' + partnerFg.controls.step1.controls.lastName.value + '?'">
                    </app-form-button-group-simple>
                </div>
            </ng-container>

            <ng-container
                *ngIf="!(showPartnerAddressQuestion && partnerFg) || (fg.controls.isAddressProvidedByPartner.value == UserAction.No)">
                <!-- Physical address -->
                <app-address [fg]="fg.controls.addressPhysical" label="Physical Address"
                    (addressChanged)="physicalAddressChanged($event)"></app-address>

                <mat-slide-toggle [formControl]="fg.controls.addressPostalIsSame" hideIcon>Postal
                    same as physical</mat-slide-toggle>
            </ng-container>
        </div>

        <ng-container
            *ngIf="!(showPartnerAddressQuestion && partnerFg) || (fg.controls.isAddressProvidedByPartner.value == UserAction.No)">
            <div class="group-section">
                <!-- Postal address -->
                <ng-container *ngIf="fg.controls.addressPostalIsSame.value == false">
                    <app-address [fg]="fg.controls.addressPostal" label="Postal Address"></app-address>
                </ng-container>
            </div>

            <div class="group-section">
                <mat-form-field class="form-field select">
                    <mat-label>Living situation</mat-label>
                    <mat-select [formControl]="fg.controls.addressPhysical.controls.livingSituation">
                        <mat-option value="{{LivingSituationEnum.Mortgaged}}">Own home with mortgage</mat-option>
                        <mat-option value="{{LivingSituationEnum.HomeownerWithoutMortgage}}">Own home without
                            mortgage</mat-option>
                        <mat-option value="{{LivingSituationEnum.Renter}}">Renting</mat-option>
                        <mat-option value="{{LivingSituationEnum.Boarder}}">Boarding</mat-option>
                        <mat-option value="{{LivingSituationEnum.LivingWithFamily}}">Living with family</mat-option>
                        <mat-option value="{{LivingSituationEnum.EmployerProvided}}">Supplied by
                            employer</mat-option>
                    </mat-select>
                    <mat-hint>Describe your living situation at your current address</mat-hint>
                </mat-form-field>
            </div>

            <div class="group-section"
                *ngIf="fg.controls.addressPhysical.controls.livingSituation.value && fg.controls.addressPhysical.controls.livingSituation.value != LivingSituationEnum.HomeownerWithoutMortgage">
                <div class="row">
                    <div class="col-12">

                        <div class="row">
                            <div class="col-7">
                                <app-form-field-currency [formSubmitted]="form.submitted" [label]="livingPaymentLabel"
                                    [control]="fg.controls.livingPaymentAmount">
                                </app-form-field-currency>
                            </div>
                            <div class="col-5">
                                <app-form-field-select [control]="fg.controls.livingPaymentFrequency"
                                    [listType]="SelectListType.FrequencyTypes"
                                    label="Frequency"></app-form-field-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="group-section">
                <p class="mat-body-large section-title">
                    How long have you lived at your current address?
                </p>
                <div class="group-required">
                    <mat-button-toggle-group class="button-group"
                        aria-label="How long have you lived at your current address?"
                        [formControl]="fg.controls.addressPhysical.controls.durationMonths"
                        [hideSingleSelectionIndicator]="true">

                        <mat-button-toggle value="1">Less than 6 months</mat-button-toggle>
                        <mat-button-toggle value="6">6-12 months</mat-button-toggle>
                        <mat-button-toggle value="12">1-2 years</mat-button-toggle>
                        <mat-button-toggle value="24">2-3 years</mat-button-toggle>
                        <mat-button-toggle value="37">More than 3 years</mat-button-toggle>
                    </mat-button-toggle-group>

                    <mat-error
                        *ngIf="form.submitted && fg.controls.addressPhysical.controls.durationMonths.hasError('required')">Please
                        set the length of time at your current address</mat-error>
                </div>
            </div>
        </ng-container>


        @if(fg.controls.addressPrevious && fg.controls.addressPrevious.controls){
        <div class="group-section">

            <!-- Previous address -->
            <ng-container
                *ngIf="(fg.controls.addressPhysical.controls.durationMonths.value && fg.controls.addressPhysical.controls.durationMonths.value < Constants.App_ShowPreviousAddressMonths) ||
                (parentPartnerFg && parentPartnerFg.controls.step1.controls.addressPhysical.controls.durationMonths.value! < Constants.App_ShowPreviousAddressMonths)">
                <app-address [fg]="fg.controls.addressPrevious" label="Previous Address"></app-address>
            </ng-container>
        </div>
        }

        <hr class="separator no-margin" />
        <div class="group-section">
            <h1 class="mat-headline-large">Other contact info</h1>
        </div>
        <div class="group-section">
            <app-form-field [formSubmitted]="form.submitted" label="Mobile phone" [control]="fg.controls.mobileNumber"
                type="tel">
            </app-form-field>

            <app-form-field [formSubmitted]="form.submitted" label="Work phone (optional)" [control]="fg.controls.daytimeNumber"
                type="tel">
            </app-form-field>

            <app-form-field [formSubmitted]="form.submitted" label="Email address" [control]="fg.controls.emailAddress">
            </app-form-field>
        </div>
        <div>
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>