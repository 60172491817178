<form [formGroup]="aboutFg" #form="ngForm">

    <section>
        <div class="group-section">
            <h1 class="mat-headline-large">About your vehicle loan</h1>
        </div>
        <div class="group-section small-group">
            <p class="mat-body-large">What type of vehicle are you looking to purchase?</p>
            <div class="group-required">
                <mat-button-toggle-group class="button-group"
                    aria-label="What type of vehicle are you looking to purchase?"
                    [formControl]="aboutFg.controls.purchaseVehicleType" [hideSingleSelectionIndicator]="true">
                    <mat-button-toggle value="{{VehicleClass.PassengerVehicle}}">Passenger / Van /
                        Ute</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.Motorcycle}}">Motorcycle</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.Caravan}}">Motorhome</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.Motorhome}}">Caravan</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.Boat}}">Marine</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.GoodsVehicle}}">Truck</mat-button-toggle>
                    <mat-button-toggle value="{{VehicleClass.Machine}}">Machinery</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-error *ngIf="form.submitted && aboutFg.controls.purchaseVehicleType.hasError('required')">
                    Please select a vehicle type
                </mat-error>
            </div>
        </div>

        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="aboutFg.controls.hasVehicle"
                label="Do you have a specific vehicle in mind?">
            </app-form-button-group-simple>
        </div>


        <div class="group-section" *ngIf="aboutFg.controls.hasVehiclePlateVin.enabled">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="aboutFg.controls.hasVehiclePlateVin"
                label="Do you know the plate number or VIN of the vehicle?">
            </app-form-button-group-simple>
        </div>

        <ng-container *ngIf="aboutFg.controls.hasVehicle.value == UserAction.Yes">
            <ng-container *ngIf="aboutFg.controls.hasVehiclePlateVin.value == UserAction.Yes">
                <div class="group-section">
                    <app-vehicle-lookup [formSubmitted]="form.submitted" label="Plate No or VIN"
                        [control]="aboutFg.controls.vehiclePlateVin"
                        [parentControl]="aboutFg.controls.hasVehiclePlateVin"
                        (vehicleSelected)="purchaseVehicleSelected($event)">
                    </app-vehicle-lookup>
                </div>
            </ng-container>

            <ng-container *ngIf="aboutFg.controls.hasVehiclePlateVin.value == UserAction.No">
                <div class="group-section small-group">
                    <app-form-field-numeric [formSubmitted]="form.submitted" label="Vehicle year"
                        [control]="aboutFg.controls.vehicleYear">
                    </app-form-field-numeric>

                    <app-form-field [formSubmitted]="form.submitted" label="Vehicle make"
                        [control]="aboutFg.controls.vehicleMake">
                    </app-form-field>

                    <app-form-field [formSubmitted]="form.submitted" label="Vehicle model"
                        [control]="aboutFg.controls.vehicleModel">
                    </app-form-field>
                </div>
            </ng-container>

            <div class="group-section">
                <app-form-button-group-simple [formSubmitted]="form.submitted"
                    [control]="aboutFg.controls.vehicleIsBrandNew" label="Is it new or used?" yesLabel="New"
                    noLabel="Used">
                </app-form-button-group-simple>
            </div>


        </ng-container>
<!-- 
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="aboutFg.controls.isBusinessPurpose"
                label="Do you intend to use this vehicle for business purposes?">
            </app-form-button-group-simple>
        </div> -->

        @if(aboutFg.controls.hasVehicle.value == UserAction.Yes){
        <div class="group-section small-group">
            <mat-label>What is your vehicle cost?</mat-label>
            <app-form-field-currency [formSubmitted]="form.submitted" label="Vehicle cost"
                [control]="aboutFg.controls.vehiclePrice" hint="Please include GST, registration & licensing"
                [decimal_precision]="2">
            </app-form-field-currency>
        </div>
        }
        @else {
        <div class="group-section small-group">
            <mat-label>What is your vehicle budget?</mat-label>
            <app-form-field-currency [formSubmitted]="form.submitted" label="Vehicle budget"
                [control]="aboutFg.controls.loanAmount" hint="Please include GST, registration & licensing"
                [decimal_precision]="0">
            </app-form-field-currency>
        </div>
        }

        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="aboutFg.controls.hasOtherItems"
                label="Will you be purchasing any additional items?" hint="This includes insurances, accessories etc.">
            </app-form-button-group-simple>
        </div>
        <div class="group-section" *ngIf="aboutFg.controls.hasOtherItems.value == UserAction.Yes">
            <app-form-field-currency [formSubmitted]="form.submitted" label="Other items cost"
                hint="Use an estimate if you're unsure of the exact cost" [control]="aboutFg.controls.otherItemsCost">
            </app-form-field-currency>
        </div>
    </section>
    <hr class="separator" />
    <section>
        <div class="group-section">
            <h1 class="mat-headline-large">Cash deposit / trade in</h1>
        </div>
        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted" [control]="aboutFg.controls.hasDeposit"
                label="Do you have a cash deposit?">
            </app-form-button-group-simple>
        </div>
        <div class="group-section"
            *ngIf="aboutFg.controls[ApplicationFormControlNames.HasDeposit].value == UserAction.Yes">
            <app-form-field-currency [formSubmitted]="form.submitted" label="Cash deposit value"
                [control]="aboutFg.controls.depositAmount" [decimal_precision]="0">
            </app-form-field-currency>
        </div>

        <div class="group-section">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
                [control]="aboutFg.controls.hasTradeInVehicle" label="Do you have a trade in vehicle?">
            </app-form-button-group-simple>
        </div>
        <ng-container *ngIf="aboutFg.controls.hasTradeInVehicle.value == UserAction.Yes">
            <div class="group-section">
                <app-vehicle-lookup [formSubmitted]="form.submitted" label="Plate No or VIN"
                    [control]="aboutFg.controls.tradeInVehicle.controls.plate"
                    [parentControl]="aboutFg.controls.hasTradeInVehicle"
                    (vehicleSelected)="tradeinVehicleSelected($event)">
                </app-vehicle-lookup>
            </div>

            <div class="group-section">
                <app-form-field-currency [formSubmitted]="form.submitted" label="Estimated trade in value"
                    [control]="aboutFg.controls.tradeInVehicle.controls.price" [decimal_precision]="0">
                </app-form-field-currency>
            </div>

            <div class="group-section">
                <app-form-button-group-simple [formSubmitted]="form.submitted"
                    [control]="aboutFg.controls.isVehicleTradeInFinanced" label="Is the vehicle currently financed?">
                </app-form-button-group-simple>
            </div>

            <div class="group-section" *ngIf="aboutFg.controls.isVehicleTradeInFinanced.value == UserAction.Yes">
                <app-form-field-currency [formSubmitted]="form.submitted" label="Balance owing"
                    [control]="aboutFg.controls.tradeInVehicle.controls.encumbrance" [decimal_precision]="2">
                </app-form-field-currency>
            </div>

        </ng-container>
    </section>


    <section>
        <div class="group-section info-box">
            <div class="flex-content">
                <span class="mat-body-large">Vehicle cost</span>
                <span class="mat-body-large">{{ aboutFg.get('loanAmount')?.value ?
                    (aboutFg.get('loanAmount')?.value
                    | currency) : '$0.00' }}</span>
            </div>
            <div class="flex-content">
                <span class="mat-body-large">Other items</span>
                <span class="mat-body-large">{{ aboutFg.get('otherItemsCost')?.value ?
                    (aboutFg.get('otherItemsCost')?.value | currency) : '$0.00' }}</span>
            </div>
            <div class="flex-content">
                <span class="mat-body-large"><span class="italic-text">Less</span> cash deposit</span>
                <span class="mat-body-large">{{ aboutFg.get('depositAmount')?.value ?
                    ''+(aboutFg.get('depositAmount')?.value | currency) : '$0.00' }}</span>
            </div>
            <div class="flex-content">
                <span class="mat-body-large"><span class="italic-text">Less</span> net trade in value <mat-icon
                        class="info-tooltip" svgIcon="info"
                        matTooltip="Estimated value of your trade in vehicle less any finance you currently owe on it"></mat-icon></span>
                <span class="mat-body-large">{{ netTradeIn() > 0 ? '' + (netTradeIn() | currency) : (netTradeIn() |
                    currency) }}</span>

            </div>
            <hr class="separator no-margin" />
            <div class="flex-content text-end stacked-text hide-for-large">
                <span class="mat-title-medium black-font">Total amount to borrow</span>
                <span class="mat-headline-large">{{loanTotal() | currency}}</span>
                <span class="mat-body-small black-font no-letterspace">Excludes any applicable loan related fees</span>
                <mat-error *ngIf="aboutFg.controls.netLoanAmount.errors?.['rangeError']" style="margin-top: 8px;">{{ aboutFg.controls.netLoanAmount.errors?.['rangeError']?.message }}</mat-error>
            </div>
            <div class="flex-content show-for-large">
                <div>
                    <div class="mat-title-medium black-font">Total amount to borrow</div>
                    <div class="mat-body-small black-font no-letterspace">Excludes any applicable loan related fees
                    </div>
                </div>
                <div>
                    <span class="mat-headline-large">{{loanTotal() | currency}}</span>
                    
                </div>

            </div>
            
            <div class="show-for-large">
                <mat-error *ngIf="aboutFg.controls.netLoanAmount.errors?.['rangeError']">{{ aboutFg.controls.netLoanAmount.errors?.['rangeError']?.message }}</mat-error>
            </div>
        </div>
        
        <div class="group-section">
            <mat-slide-toggle [formControl]="aboutFg.controls.approveLesserAmount" hideIcon>If this amount cannot be approved, please try to approve me for a lesser amount</mat-slide-toggle>
        </div>



        <div class="section-next">
            <button class="btn-next" mat-flat-button (click)="onButtonNext(aboutFg, parentStepper)">Next</button>
        </div>
    </section>
</form>