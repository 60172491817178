import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { EntityForm, EntityIncomeExpensesForm, VehicleApplicationForm } from '../../../../types/vehicle-application-types';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { LoanEntityType } from '../../../../api/models/CommonUtilities/loan-entity-type';

@Component({
  selector: 'app-income-expenses',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    FormButtonGroupSimpleComponent,
    MatSlideToggle,
    AsFormControlPipe,
    FormFieldSelectComponent,
    MatButtonModule,
    MatIcon,
    MatTooltipModule,
  ],
  templateUrl: './income-expenses.component.html',
  styleUrl: './income-expenses.component.scss'
})

export class IncomeExpensesComponent extends BaseStepComponent {
  @Input() parentStepper!: MatStepper;
  @Input() fg!: FormGroup<EntityIncomeExpensesForm>;
  @Input() parentFg!: FormGroup<EntityForm>;
  @Input() formSubmitted!: boolean;


  UserAction = UserAction;
  SelectListType = SelectListType;

  get appForm(): VehicleApplicationForm {
    return this.vehicleAppService.getApplicationFg();
  }

  entityTypeCaption: string = '';
  
  constructor(private fb: FormBuilder,
    vehicleAppService: VehicleAppService
  ) {
    super(vehicleAppService);

    this.vehicleAppService.entityType$.subscribe(value => {
      this.entityTypeCaption = value;
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
   
    this.activateControlsWhen(this.fg.controls.isBusinessTrading,
      [this.fg.controls.tradingName, this.fg.controls.industryClassification, this.fg.controls.incomeAmount, this.fg.controls.incomeFrequency,
       this.fg.controls.isIncomeOffshore, this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount, this.fg.controls.expenseAmount, 
       this.fg.controls.expenseFrequency
      ], null,
      [{ control: this.fg.controls.isBusinessTrading, expectedValues: [UserAction.Yes] }]);

  }

}
