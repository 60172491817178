import { DatePipe } from '@angular/common';
import { Component} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Constants } from '../../dataobject/constants';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-maintenance-view',
  standalone: true,
  imports: [ DatePipe, MatIcon],
  templateUrl: './maintenance-view.component.html',
  styleUrl: './maintenance-view.component.scss'
})
export class MaintenanceViewComponent {
  untilTime?: Date;

  contactNumber = Constants.VehicleSupportPhone;

  constructor() { }


  ngOnInit() {
    if (environment.maintenance) {
      this.untilTime = new Date(environment.maintenance.to);
    }
  }
}
