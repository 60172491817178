import { M } from "@angular/cdk/keycodes";

export const Constants ={
    apiBase: 'v1/public/application',

    Environment_VehicleFinance: 'VEHICLE',
    VehicleSupportPhone: '0800 808 003',


    App_ShowPreviousAddressMonths: 24,


    CustomErrors_Duplicate: 'duplicate',

    Validator_MaxAssetValue: 10_000_000,

    DefaultApiTimeout: 90000,

}
