/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountsService } from './services/accounts.service';
import { AddressesService } from './services/addresses.service';
import { ApplicationsService } from './services/applications.service';
import { CompaniesService } from './services/companies.service';
import { ContactsService } from './services/contacts.service';
import { CustomersService } from './services/customers.service';
import { ProductsService } from './services/products.service';
import { PublicApplicationService } from './services/public-application.service';
import { PublicVehicleApplicationService } from './services/public-vehicle-application.service';
import { TokenAuthorizationService } from './services/token-authorization.service';
import { VehicleApplicationService } from './services/vehicle-application.service';
import { WebhooksService } from './services/webhooks.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountsService,
    AddressesService,
    ApplicationsService,
    CompaniesService,
    ContactsService,
    CustomersService,
    ProductsService,
    PublicApplicationService,
    PublicVehicleApplicationService,
    TokenAuthorizationService,
    VehicleApplicationService,
    WebhooksService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
