import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, map, pairwise, startWith, Subscription } from 'rxjs';
import { DtoVehicleBasic } from '../../../api/models/Limelight/Babylon/DataObjects/DTO/dto-vehicle-basic';
import { FormFieldComponent } from '../../shared/form-field/form-field.component';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { PublicVehicleApplicationService } from '../../../api/services';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { AlphaNumericOnlyDirective } from '../../../directives/alpha-numeric-only.directive';

@Component({
  selector: 'app-vehicle-lookup',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonToggleModule,
    FormFieldComponent,
    MatIcon,
    MatTooltipModule
  ],
  templateUrl: './vehicle-lookup.component.html',
  styleUrl: './vehicle-lookup.component.scss'
})
export class VehicleLookupComponent {

  @Output() vehicleSelected = new EventEmitter<DtoVehicleBasic>();

  @Input() control!: FormControl;
  @Input() parentControl!: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() formSubmitted: boolean = false;

  valid: boolean = false;
  loading: boolean = false;
  showErrorIcon: boolean = false;
  results: string = '';
  error: string = '';
  subscription: Subscription | undefined;
  parentSubscription: Subscription | undefined;

  constructor(
    private publicVehicleApplicationService: PublicVehicleApplicationService,
    private vehicleAppService: VehicleAppService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {

    this.subscription = this.control.valueChanges
      .pipe(
        debounceTime(750),
        startWith(this.control.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        console.log('vehicle-lookup.component.ts', newValue, oldValue);


        if (!newValue) {
          //this.clearVehicleLookup();
        } else {

          if (newValue && newValue != oldValue) {
            this.lookup();
            // if (newValue == UserAction.No) {
            //   this.fg.controls.creditFacilities.clear();
            // } else {
            //   if (this.fg.controls.creditFacilities.length === 0) {
            //     this.addCreditFacility();
            //   }
            // }
          } else {


            if (!this.valid) {
              console.log('vehicle-lookup.component.ts - not  valid, setting error');
              //this.control.setErrors({ 'noMatch': true });
              this.setError();
            }
          }
        }
      });

    // this.subscription = this.control.valueChanges
    //     .pipe(
    //       debounceTime(750),
    //     )
    //     .subscribe(() => {
    //       this.lookup();
    //     });

    this.parentSubscription = this.parentControl.valueChanges.subscribe(() => {
      console.log('parentControl.valueChanges', this.parentControl.value);      
      if (this.parentControl.value == UserAction.Yes) {

        if (!this.valid && this.control.value) {
          this.setError();
        }
      } else {
        this.clearVehicleLookup();
      }
    });
  }

  ngAfterViewInit() {

  }
  ngAfterViewChecked(): void {

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.parentSubscription) {
      this.parentSubscription.unsubscribe();
    }
  }

  lookup() {
    console.log('lookup');
    if (this.control.errors == null) {
      this.loading = true;
      this.results = '';
      this.error = '';
      this.valid = false;
      this.showErrorIcon = false;

      if (this.control.value) {
        this.publicVehicleApplicationService.v3PublicApplicationVehicleApplicationIdVehicleLookupGet$Json({
          plateVin: this.control.value,
          applicationId: this.vehicleAppService.newApplicationResponse.publicId!
        })
          .subscribe({
            next: x => {
              this.valid = true;

              const currentErrors = this.control.errors;
              if (currentErrors && currentErrors['noMatch']) {
                if (Object.keys(currentErrors).length === 0) {
                  this.control.setErrors(null);
                } else {
                  this.control.setErrors(currentErrors);
                }
                // Remove the error if exists
                delete currentErrors['noMatch'];
              }

              console.log(x);
              this.loading = false;

              let resultsStrArr = [];
              if (x.entityData?.year) {
                resultsStrArr.push(x.entityData?.year);
              }
              if (x.entityData?.make) {
                resultsStrArr.push(x.entityData?.make);
              }
              if (x.entityData?.model) {
                resultsStrArr.push(x.entityData?.model);
              }
              if (x.entityData?.color1) {
                resultsStrArr.push('(' + x.entityData?.color1 + ')');
              }
              this.results = resultsStrArr.join(' ');

              if (!this.results) {
                this.valid = false;
                this.showErrorIcon = true;
                this.results = "unspecified";
                this.error = "No matching vehicle found";
                this.control.setErrors({ 'noMatch': true });

                let x = {} as DtoVehicleBasic;
                // x.year = null;
                // x.make = 'unspecified';
                // x.model = 'unspecified';
              }

              console.log('vehicle-lookup.component.ts - emit vehicleSelected', x.entityData);
              this.vehicleSelected.emit(x.entityData);
              this.changeDetectorRef.detectChanges();

            },
            error: e => {
              console.log(e);

              this.setError();

              switch (e.message) {
                case 'Unable to lookup vehicle':
                  this.error = '';
              }

            }

          });
      } else {
        this.clearVehicleLookup();
      }
    }
    else {
      this.clearVehicleLookup();
      //this.control.markAllAsTouched();
      //add some kind of pulse/focus here
    }
  }

  private setError() {
    this.valid = false;
    this.showErrorIcon = true;
    //this.control.setValidators(Validators.required);
    this.control.setErrors({ 'noMatch': true });
    //this.control.setErrors({ 'required': true });
    //this.control.updateValueAndValidity();
    this.results = "unspecified";


    this.error = 'No matching vehicle found';
    this.loading = false;

    let x = {} as DtoVehicleBasic;
    // x.year = 1950;
    // x.make = 'unspecified';
    // x.model = 'unspecified';
    this.vehicleSelected.emit(x);

    this.changeDetectorRef.detectChanges();
  }

  clearVehicleLookup() {
    //this.control.setValidators(null);
    //this.control.updateValueAndValidity();
    this.results = '';
    this.control.reset();
    this.valid = false;
    this.showErrorIcon = false;
  }


}
