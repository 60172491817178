<div class="container landing-container" *ngIf="!isStarted">
  <div class="row" *ngIf="!isLoaded">
    <div class="col-12">
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
        <img src="assets/avanti-logo-grey.svg" alt="Avanti Finance logo" />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isLoaded">
    <div class="col-12">
      <mat-card>
        <div class="landing-content">
          <div class="img">
            <img class="landing-img" src="assets/begin-image.png" alt="Begin your finance journey" />
          </div>
          <div class="welcome-text">
            <div class="dealer-logo"
              *ngIf="applicationConfigResponse.dealershipLogo && applicationConfigResponse.dealershipLogo!= ''">
              <img [src]="applicationConfigResponse.dealershipLogo" alt="Logo" />
            </div>
            <h1>Apply for finance</h1>
            <p>Before we proceed, please ensure you:</p>
            <ul class="tick-list">
              <li>are at least 18 years old</li>
              <li>have a valid form of identification</li>
              <li>can provide proof of your income</li>
              <li>have an email account & NZ mobile number</li>
            </ul>
            <button mat-flat-button class="landing-btn" (click)="isStarted = !isStarted">Begin</button>
            <div class="powered-by landing-powered">
              Powered by <img src="assets/avanti-logo-grey.svg" alt="Avanti Finance" />
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div class="container" *ngIf="isStarted">
  <div class="row">
    <div class="col-12">
      <div class="progress-container">
        <button mat-icon-button [disableRipple]="true" class="back-btn" (click)="previousStep(stepper)"><mat-icon
            svgIcon="back-arrow"></mat-icon>
        </button>
        <mat-progress-bar mode="determinate" value="10"></mat-progress-bar>
      </div>

      <mat-horizontal-stepper linear #stepper orientation="horizontal"
        ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}">

        <mat-step [stepControl]="formGroupBasic">
          <form [formGroup]="formGroupBasic" #form="ngForm" [class.loading]="loading">
            <section>
              <h1 class="mat-headline-large">Let's get started</h1>
              <div class="group-section">
                <app-form-field [formSubmitted]="form.submitted" label="First name"
                  [control]="formGroupBasic.controls.firstName">
                </app-form-field>

                <app-form-field [formSubmitted]="form.submitted" label="Last name"
                  [control]="formGroupBasic.controls.lastName">
                </app-form-field>
                <div class="group-section group-16">
                  <div *ngIf="otpHasMobile">
                    <app-form-field [formSubmitted]="form.submitted" label="Mobile"
                      [control]="formGroupBasic.controls.mobile" type="tel">
                    </app-form-field>
                  </div>
                  <div class="flex-content" *ngIf="otpHasMobile">
                    <button mat-button class="link-button active no-background-link height-24" type="button"
                      (click)="hasNoMobileClicked()">I don't have a mobile</button>
                  </div>

                  <div *ngIf="!otpHasMobile">
                    <app-form-field [formSubmitted]="form.submitted" label="Email"
                      [control]="formGroupBasic.controls.email">
                    </app-form-field>
                  </div>
                  <div class="flex-content" *ngIf="!otpHasMobile">
                    <button mat-button class="link-button active no-background-link height-24" type="button"
                      (click)="hasNoEmailClicked()">I don't have an email</button>
                  </div>
                  <div class="flex-content" *ngIf="otpHasMobileBothStatesRevealed">
                    <mat-error>We need a mobile number or email address in order to proceed with the
                      application.<br /><br />If you don't have either, please contact our team on <a
                        href="tel:{{contactNumber}}">{{contactNumber}}</a> to discuss.</mat-error>
                  </div>
                </div>
              </div>
              <div class="group-section">
                <div class="group-section small-group">
                  <h3 class="mat-title-medium black-font">Privacy Statement</h3>
                  <p class="mat-body-large">
                    During the application process, we collect and store personal information about you. We may share
                    this information with third parties, but only in accordance
                    with the purposes outlined in our <a href="https://www.avantifinance.co.nz/privacy/"
                      target="_blank">privacy policy</a>.
                  </p>
                </div>
                <div class="group-required">
                  <mat-slide-toggle [formControl]="formGroupBasic.controls.acceptedPrivacyCheck" hideIcon>I/we have
                    read, understood and accept the <a href="https://www.avantifinance.co.nz/privacy/"
                      target="_blank">privacy
                      policy</a></mat-slide-toggle>
                  <mat-error *ngIf="form.submitted && formGroupBasic.controls.acceptedPrivacyCheck.hasError('required')"
                    class="error-wrap">Required</mat-error>
                </div>
                <p class="mat-body-large">
                  To evaluate your application, we may also obtain credit reports about you from third-party sources.
                </p>
                <div class="group-required">
                  <mat-slide-toggle [formControl]="formGroupBasic.controls.acceptedCreditCheck" hideIcon>I/we consent to
                    any necessary credit enquiries being completed</mat-slide-toggle>
                  <mat-error *ngIf="form.submitted && formGroupBasic.controls.acceptedCreditCheck.hasError('required')"
                    class="error-wrap">Required</mat-error>
                </div>
              </div>
              <div class="section-next">
                <button mat-flat-button (click)="sendOtpRequest(stepper)">Next</button>
              </div>
            </section>
          </form>
        </mat-step>
        <mat-step>
          <form #formB="ngForm">
            <section>
              <h1 class="mat-headline-large">Verify your identity</h1>
              <div class="group-section small-group">
                <p class="mat-body-large">
                  @if (authOtpResponse.emailObfuscated)
                  {
                  To verify your identity, we've sent a code to your email address, {{authOtpResponse.emailObfuscated}}.
                  }
                  @else
                  {
                  To verify your identity, we've sent a code to your mobile phone ending in
                  {{authOtpResponse.mobileObfuscated}}.
                  }
                </p>

                <div class="group-section">
                  <input type="hidden" [formControl]="formGroupOtp.controls.validationCode">
                  <code-input #codeInput [isCodeHidden]="false" [codeLength]="authOtpResponse.length ?? 6"
                    (codeChanged)="onCodeChanged($event)" (codeCompleted)="onCodeCompleted($event)"
                     [class.error]="codeInputErrorExists">
                  </code-input>
                  <p class="mat-body-large resend-code-text">
                    <button mat-button [class.active]="secondsToRetry<1"
                      class="link-button no-background-link height-24" type="button" (click)="onResendCode()">Resend
                      code</button> <span class="retry height-24" *ngIf="secondsToRetry>0"> in {{secondsToRetry}}s</span>
                  </p>
                  <p class="mat-body-large margin-bottom-50 neutral40">
                    Having problems? <a href="https://www.avantifinance.co.nz/contact-us" target="_blank">Contact our
                      team</a> so we can assist you with your application.
                  </p>
                </div>
              </div>
            </section>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
      <div class="powered-by">
        Powered by <img src="assets/avanti-logo-grey.svg" alt="Avanti Finance" />
      </div>
    </div>
  </div>
</div>