import { Component, Input } from '@angular/core';
import { EntityContactDetailForm, EntityForm, VehicleApplicationForm } from '../../../../types/vehicle-application-types';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldSelectComponent } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { ModelsCustomerAutocompleteAddress } from '../../../../api/models';
import { LoanEntityType } from '../../../../api/models/CommonUtilities/loan-entity-type';

@Component({
  selector: 'app-contact-detail',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    FormButtonGroupSimpleComponent,
    MatSlideToggle,
    AsFormControlPipe,
    FormFieldSelectComponent,
    MatButtonModule,
    MatIcon,
    MatTooltipModule,
    AddressComponent
  ],
  templateUrl: './contact-detail.component.html',
  styleUrl: './contact-detail.component.scss'
})

export class ContactDetailComponent extends BaseStepComponent {
  @Input() parentStepper!: MatStepper;
  @Input() fg!: FormGroup<EntityContactDetailForm>;
  @Input() parentFg!: FormGroup<EntityForm>;

  entityTypeCaption: string = '';

  constructor(private fb: FormBuilder,
    vehicleAppService: VehicleAppService
  ) {
    super(vehicleAppService);

    this.vehicleAppService.entityType$.subscribe(value => {
      this.entityTypeCaption = value;
    });
  }


  override ngOnInit(): void {
    super.ngOnInit();

    this.fg.controls.addressPostalIsSame.valueChanges.subscribe((value) => {
      if (value) {
        this.copyAddressPhysicalToPostal(this.fg.controls.addressPhysical.controls, this.fg.controls.addressPostal.controls)
      }
      else {
        this.clearAddress(this.fg.controls.addressPostal.controls);
      }
    });
  }

  physicalAddressChanged(address: ModelsCustomerAutocompleteAddress) {
    // console.log('physicalAddressChanged', address);
    if (this.fg.controls.addressPostalIsSame.value) {
      setTimeout(() =>
        this.copyAddressPhysicalToPostal(this.fg.controls.addressPhysical.controls, this.fg.controls.addressPostal.controls)
        , 0);
    }

  }
}
