/* tslint:disable */
/* eslint-disable */
export enum Country {
  None = 'None',
  Newzealand = 'NEWZEALAND',
  Australia = 'AUSTRALIA',
  Afghanistan = 'AFGHANISTAN',
  Alandislands = 'ALANDISLANDS',
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  Americansamoa = 'AMERICANSAMOA',
  Andorra = 'ANDORRA',
  Angola = 'ANGOLA',
  Anguilla = 'ANGUILLA',
  Antarctica = 'ANTARCTICA',
  Antiguaandbarbuda = 'ANTIGUAANDBARBUDA',
  Argentina = 'ARGENTINA',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Barbados = 'BARBADOS',
  Bechuanaland = 'BECHUANALAND',
  Belarus = 'BELARUS',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bermuda = 'BERMUDA',
  Bhutan = 'BHUTAN',
  Bolivia = 'BOLIVIA',
  Bosniaandherzegovina = 'BOSNIAANDHERZEGOVINA',
  Botswana = 'BOTSWANA',
  Bouvetisland = 'BOUVETISLAND',
  Brazil = 'BRAZIL',
  Britishindianoceanterrititorychagosarch = 'BRITISHINDIANOCEANTERRITITORYCHAGOSARCH',
  Brunei = 'BRUNEI',
  Bulgaria = 'BULGARIA',
  Burkinafaso = 'BURKINAFASO',
  Burma = 'BURMA',
  Burundi = 'BURUNDI',
  Byelorussia = 'BYELORUSSIA',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  Canada = 'CANADA',
  Capeverde = 'CAPEVERDE',
  Caymanislands = 'CAYMANISLANDS',
  Centralafricanrepublic = 'CENTRALAFRICANREPUBLIC',
  Chad = 'CHAD',
  Chile = 'CHILE',
  China = 'CHINA',
  Christmasisland = 'CHRISTMASISLAND',
  Cocoskeelingislands = 'COCOSKEELINGISLANDS',
  Colombia = 'COLOMBIA',
  Comoros = 'COMOROS',
  Congo = 'CONGO',
  Congodemocraticrepublicofthe = 'CONGODEMOCRATICREPUBLICOFTHE',
  Cookislands = 'COOKISLANDS',
  Costarica = 'COSTARICA',
  Croatia = 'CROATIA',
  Cuba = 'CUBA',
  Cyprus = 'CYPRUS',
  Czechrepublic = 'CZECHREPUBLIC',
  Czechoslovakia = 'CZECHOSLOVAKIA',
  Dahomey = 'DAHOMEY',
  Denmark = 'DENMARK',
  Djibouti = 'DJIBOUTI',
  Dominica = 'DOMINICA',
  Dominicanrepublic = 'DOMINICANREPUBLIC',
  Eastpakistan = 'EASTPAKISTAN',
  Ecuador = 'ECUADOR',
  Egypt = 'EGYPT',
  Elsalvador = 'ELSALVADOR',
  Equatorialguinea = 'EQUATORIALGUINEA',
  Eritrea = 'ERITREA',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  Falklandislandsmalvinas = 'FALKLANDISLANDSMALVINAS',
  Faroeislands = 'FAROEISLANDS',
  Fiji = 'FIJI',
  Finland = 'FINLAND',
  France = 'FRANCE',
  Frenchalgeria = 'FRENCHALGERIA',
  Frenchguiana = 'FRENCHGUIANA',
  Frenchpolynesia = 'FRENCHPOLYNESIA',
  Frenchsouthernterritories = 'FRENCHSOUTHERNTERRITORIES',
  Frenchterritoryofafarsandissas = 'FRENCHTERRITORYOFAFARSANDISSAS',
  Gabon = 'GABON',
  Gambia = 'GAMBIA',
  Georgia = 'GEORGIA',
  Germaneastafrica = 'GERMANEASTAFRICA',
  Germanydemocraticrepublicof = 'GERMANYDEMOCRATICREPUBLICOF',
  Germanyfederalrepublicof = 'GERMANYFEDERALREPUBLICOF',
  Ghana = 'GHANA',
  Gibraltar = 'GIBRALTAR',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Guernsey = 'GUERNSEY',
  Guinea = 'GUINEA',
  Guineabissau = 'GUINEABISSAU',
  Guyana = 'GUYANA',
  Haiti = 'HAITI',
  Heardandmcdonaldislands = 'HEARDANDMCDONALDISLANDS',
  Honduras = 'HONDURAS',
  Hongkongsar = 'HONGKONGSAR',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  Iran = 'IRAN',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  Isleofman = 'ISLEOFMAN',
  Israel = 'ISRAEL',
  Italy = 'ITALY',
  Ivorycoast = 'IVORYCOAST',
  Jamaica = 'JAMAICA',
  Japan = 'JAPAN',
  Jersey = 'JERSEY',
  Jordan = 'JORDAN',
  Kantonandenderburyislands = 'KANTONANDENDERBURYISLANDS',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kiribati = 'KIRIBATI',
  Koreanorth = 'KOREANORTH',
  Koreasouth = 'KOREASOUTH',
  Kosovo = 'KOSOVO',
  Kuwait = 'KUWAIT',
  Kyrgyzstan = 'KYRGYZSTAN',
  Laos = 'LAOS',
  Latvia = 'LATVIA',
  Lebanon = 'LEBANON',
  Lesotho = 'LESOTHO',
  Liberia = 'LIBERIA',
  Libya = 'LIBYA',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  Macausar = 'MACAUSAR',
  Macedonia = 'MACEDONIA',
  Formeryugoslavrepublicof = 'FORMERYUGOSLAVREPUBLICOF',
  Madagascar = 'MADAGASCAR',
  Malawi = 'MALAWI',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Mali = 'MALI',
  Malta = 'MALTA',
  Marshallislands = 'MARSHALLISLANDS',
  Martinique = 'MARTINIQUE',
  Mauritania = 'MAURITANIA',
  Mauritius = 'MAURITIUS',
  Mayotte = 'MAYOTTE',
  Mexico = 'MEXICO',
  Micronesia = 'MICRONESIA',
  Moldova = 'MOLDOVA',
  Monaco = 'MONACO',
  Mongolia = 'MONGOLIA',
  Montenegro = 'MONTENEGRO',
  Montserrat = 'MONTSERRAT',
  Morocco = 'MOROCCO',
  Mozambique = 'MOZAMBIQUE',
  Myanmar = 'MYANMAR',
  Namibia = 'NAMIBIA',
  Nauru = 'NAURU',
  Nepal = 'NEPAL',
  Netherlands = 'NETHERLANDS',
  Netherlandsantilles = 'NETHERLANDSANTILLES',
  Neutralzone = 'NEUTRALZONE',
  Newcaledonia = 'NEWCALEDONIA',
  Nicaragua = 'NICARAGUA',
  Niger = 'NIGER',
  Nigeria = 'NIGERIA',
  Niue = 'NIUE',
  Norfolkisland = 'NORFOLKISLAND',
  Northernmarianaislands = 'NORTHERNMARIANAISLANDS',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Palau = 'PALAU',
  Palestinianterritories = 'PALESTINIANTERRITORIES',
  Panama = 'PANAMA',
  Papuanewguinea = 'PAPUANEWGUINEA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Pitcairn = 'PITCAIRN',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  Puertorico = 'PUERTORICO',
  Qatar = 'QATAR',
  Reunion = 'REUNION',
  Rhodesia = 'RHODESIA',
  Romania = 'ROMANIA',
  Russia = 'RUSSIA',
  Rwanda = 'RWANDA',
  Saintbarthelemy = 'SAINTBARTHELEMY',
  Sainthelena = 'SAINTHELENA',
  Saintkittsandnevis = 'SAINTKITTSANDNEVIS',
  Saintlucia = 'SAINTLUCIA',
  Saintmartin = 'SAINTMARTIN',
  Saintpierreandmiquecon = 'SAINTPIERREANDMIQUECON',
  Saintvincentandgrenadines = 'SAINTVINCENTANDGRENADINES',
  Samoa = 'SAMOA',
  Sanmarino = 'SANMARINO',
  Saotomeprincipe = 'SAOTOMEPRINCIPE',
  Saudiarabia = 'SAUDIARABIA',
  Senegal = 'SENEGAL',
  Serbia = 'SERBIA',
  Serbiaandmontenegro = 'SERBIAANDMONTENEGRO',
  Seychelles = 'SEYCHELLES',
  Sierraleone = 'SIERRALEONE',
  Singapore = 'SINGAPORE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  Solomonislands = 'SOLOMONISLANDS',
  Somalia = 'SOMALIA',
  Southafrica = 'SOUTHAFRICA',
  Southgeorgiaandsouthsandwichislands = 'SOUTHGEORGIAANDSOUTHSANDWICHISLANDS',
  Southernrhodesia = 'SOUTHERNRHODESIA',
  Spain = 'SPAIN',
  Srilanka = 'SRILANKA',
  Sudan = 'SUDAN',
  Suriname = 'SURINAME',
  Svalbardandjanmayen = 'SVALBARDANDJANMAYEN',
  Swaziland = 'SWAZILAND',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Syria = 'SYRIA',
  Taiwan = 'TAIWAN',
  Tajikistan = 'TAJIKISTAN',
  Tanganyika = 'TANGANYIKA',
  Tanzania = 'TANZANIA',
  Thailand = 'THAILAND',
  Timorlesteformerlyeasttimor = 'TIMORLESTEFORMERLYEASTTIMOR',
  Togo = 'TOGO',
  Tokelau = 'TOKELAU',
  Tonga = 'TONGA',
  Trinidadtobago = 'TRINIDADTOBAGO',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  Turkmenistan = 'TURKMENISTAN',
  Turkscaicosislands = 'TURKSCAICOSISLANDS',
  Tuvalu = 'TUVALU',
  Ussr = 'USSR',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  Unitedarabemirates = 'UNITEDARABEMIRATES',
  Unitedkingdom = 'UNITEDKINGDOM',
  Unitedstatesminoroutlyingislands = 'UNITEDSTATESMINOROUTLYINGISLANDS',
  Uruguay = 'URUGUAY',
  Usa = 'USA',
  Uzbekistan = 'UZBEKISTAN',
  Vanuatu = 'VANUATU',
  Vaticancitystateholysee = 'VATICANCITYSTATEHOLYSEE',
  Venezuela = 'VENEZUELA',
  Vietnam = 'VIETNAM',
  Virginislandsbritish = 'VIRGINISLANDSBRITISH',
  Virginislandsusa = 'VIRGINISLANDSUSA',
  Wallisandfutunaislands = 'WALLISANDFUTUNAISLANDS',
  Westernsahara = 'WESTERNSAHARA',
  Westernsamoa = 'WESTERNSAMOA',
  Yemen = 'YEMEN',
  Yemendemocraticpeoplesrepublic = 'YEMENDEMOCRATICPEOPLESREPUBLIC',
  Yugoslavia = 'YUGOSLAVIA',
  Zaire = 'ZAIRE',
  Zimbabwe = 'ZIMBABWE',
  Zambia = 'ZAMBIA',
  Zanzibar = 'ZANZIBAR',
  Other = 'Other'
}
