import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PublicApplicationService } from '../../api/services';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { V3PublicApplicationStartOtpGet$Json$Params } from '../../api/fn/public-application/v-3-public-application-start-otp-get-json';
import { SharedModule } from '../../shared/shared.module';
import { ErrorService } from '../../services/error.service';
import { AuthOtpResponse } from '../../api/models/Models/Authentication/auth-otp-response';
import { OtpEventArgs } from '../../dataobject/otp-event-args';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { OtpCodeValidateForm, OtpForm } from '../../types/vehicle-application-types';
import { FormFieldComponent } from '../shared/form-field/form-field.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { VehicleAppService } from '../../services/vehicle-app.service';
import { ApplicationConfigResponse } from '../../api/models/Models/v3/Application/application-config-response';
import { FormFieldNumericComponent } from '../shared/form-field-numeric/form-field-numeric.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { emailValidator, phoneNumberValidator } from '../../types/validators';
import { Constants } from '../../dataobject/constants';
import { timeout } from 'rxjs';
import { MessageService } from '../../services/message.service';


@Component({
  selector: 'app-step-otp',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatIcon,
    MatFormFieldModule,
    FormFieldComponent,
    MatSlideToggleModule,
    MatProgressSpinner,
    FormFieldNumericComponent,
    CodeInputModule
  ],
  templateUrl: './step-otp.component.html',
  styleUrl: './step-otp.component.scss'
})
export class StepOtpComponent
{
  @Output() otpCompleted = new EventEmitter<OtpEventArgs>();

  //https://github.com/AlexMiniApps/angular-code-input
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  @ViewChild('stepper') stepper!: MatStepper;

  isLoaded: boolean = false;
  isStarted: boolean = false;
  otpHasMobile: boolean = true;
  nonceCode: string = '';
  otpResponse: string = '';
  authOtpResponse: AuthOtpResponse = {};
  otpValue: string = '';
  secondsToRetry: number = 0;
  otpHasMobileBothStatesRevealed: boolean = false;

  contactNumber = Constants.VehicleSupportPhone;
  loading: boolean = false;

  formGroupBasic = this.formBuilder.group<OtpForm>({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    mobile: new FormControl(null, Validators.compose([Validators.required, phoneNumberValidator()])),
    email: new FormControl(null, Validators.compose([Validators.required, emailValidator()])),
    acceptedCreditCheck: new FormControl(null, Validators.requiredTrue),
    acceptedPrivacyCheck: new FormControl(null, Validators.requiredTrue),
  });

  formGroupOtp = this.formBuilder.group<OtpCodeValidateForm>({
    validationCode: new FormControl(null, Validators.compose([Validators.required])),
  });

  applicationConfigResponse: ApplicationConfigResponse = {};
  codeInputErrorExists: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private publicApplicationService: PublicApplicationService,
    private errorService: ErrorService,
    private vehicleAppService: VehicleAppService,
    private messageService: MessageService
  )
  {

    const urlParams = new URLSearchParams(window.location.search);
    const publicId = urlParams.get('publicId');
    const dealershipCode = urlParams.get('dealershipCode');
    const origin = urlParams.get('origin');

    this.publicApplicationService.v3PublicApplicationConfigGet$Json(
      {
        publicAccountId: publicId ? publicId : '',
        dealershipShortcode: dealershipCode ? dealershipCode : '',
        origin: origin ? origin : '',
      }
    )
      .subscribe({
        next: (response) =>
        {
          this.isLoaded = true;
          if (response.entityData)
          {
            this.applicationConfigResponse = response.entityData;
            vehicleAppService.ApplicationConfigResponse = response.entityData;
          }
        },
        error: (errorResponse) =>
        {
          this.isLoaded = true;
          this.errorService.handleError(errorResponse);
        }
      });
  }

  ngOnInit()
  {
    //look for mobile change events
    this.formGroupBasic.controls.mobile.valueChanges.subscribe((value) =>
    {
      if (value)
      {
        this.otpHasMobileBothStatesRevealed = false;
      }
    });
    //look for email change events
    this.formGroupBasic.controls.email.valueChanges.subscribe((value) =>
    {
      if (value)
      {
        this.otpHasMobileBothStatesRevealed = false;
      }
    });

    this.formGroupBasic.controls.email.disable();
  }


  sendOtpRequest(sourceStepper: MatStepper)
  {
    if (this.loading)
    {
      console.error('sendOtpRequest: already loading');
      return;
    }

    if (this.formGroupBasic.valid)
    {
      this.loading = true;
      this.messageService.setLoadingState(true);

      this.nonceCode = Math.random().toString(36).substring(2, 15);

      //pipe a timeout
      this.publicApplicationService.v3PublicApplicationStartOtpGet$Json$Response(
        {
          nonce: this.nonceCode,
          mobile: this.formGroupBasic.controls.mobile.value,
          email: this.formGroupBasic.controls.email.value,
        } as V3PublicApplicationStartOtpGet$Json$Params
      )
        .pipe(timeout(Constants.DefaultApiTimeout))
        .subscribe({
          next: (response) =>
          {
            this.loading = false;
            this.messageService.setLoadingState(false);
            this.authOtpResponse = response.body.entityData!;

            this.otpResponse = response.body.entityData?.payload!;

            this.setupRetryTimer();
            this.reset();

            sourceStepper.next();
          },
          error: (errorResponse) =>
          {
            this.loading = false;
            console.error(errorResponse);
            this.setupRetryTimer();
            this.reset();
            this.messageService.setLoadingState(false);
            this.errorService.handleError(errorResponse);
          }
        });
    }
    else
    {
      // Print out all the validation errors
      this.errorService.logFormValidationErrors(this.formGroupBasic);

      this.errorService.handleError('Please fill in all required fields');

    }
  }


  previousStep(sourceStepper: MatStepper)
  {
    // console.log(sourceStepper.selectedIndex);
    if (sourceStepper.selectedIndex > 0)
    {
      sourceStepper.previous();
    } else
    {
      this.isStarted = false;
    }
  }

  finishStep(sourceStepper: MatStepper)
  {
    this.otpCompleted.emit({
      mobile: this.formGroupBasic.controls.mobile.value!,
      email: this.formGroupBasic.controls.email.value!,
      firstName: this.formGroupBasic.controls.firstName.value!,
      lastName: this.formGroupBasic.controls.lastName.value!,
      creditCheckConsented: this.formGroupBasic.controls.acceptedCreditCheck.value!,
      privacyPolicyConsented: this.formGroupBasic.controls.acceptedPrivacyCheck.value!,
      otpNonce: this.nonceCode,
      otpHash: this.otpResponse,

      // otpCode: this.formGroupOtp.controls.validationCode.value!,
      otpCode: this.otpValue
    });

  }

  // this called every time when user changed the code
  onCodeChanged(code: any)
  {
    this.codeInputErrorExists = false;
  }

  // this called only if user entered full code
  onCodeCompleted(code: any)
  {
    this.otpValue = code;
    this.messageService.setLoadingState(true);
    this.finishStep(this.stepper);
  }

  setupRetryTimer()
  {
    if (this.authOtpResponse?.retrySeconds ?? 0 > 0)
    {
      this.secondsToRetry = this.authOtpResponse.retrySeconds ?? 0;
      this.runTimer();
    }
  }

  seconds: number = 0;
  timerRunning: boolean = false;
  runTimer()
  {
    if (this.timerRunning) return;

    this.timerRunning = true;
    setTimeout(() =>
    {
      this.timerRunning = false;
      this.secondsToRetry -= 1;
      if (this.secondsToRetry > 0)
      {
        this.runTimer();
      }
    }, 1000);
  }

  onResendCode()
  {
    if (this.seconds > 1)
    {
      return;
    }

    this.reset();
    this.sendOtpRequest(this.stepper);
  }

  showOtpValidationError()
  {
    this.codeInputErrorExists = true;
    this.codeInput.focusOnField(5);
  }

  private reset()
  {
    setTimeout(() =>
    {
      this.codeInput.reset();
      this.codeInput.focusOnField(0);
      this.codeInputErrorExists = false;
    }, 500);
  }

  hasNoMobileClicked()
  {
    this.otpHasMobile = false;
    this.formGroupBasic.controls.email.enable();

    this.formGroupBasic.controls.mobile.setValue(null);
    this.formGroupBasic.controls.mobile.disable();
  }

  hasNoEmailClicked()
  {
    this.otpHasMobile = true;
    this.otpHasMobileBothStatesRevealed = true;
    this.formGroupBasic.controls.mobile.enable();

    this.formGroupBasic.controls.email.setValue(null);
    this.formGroupBasic.controls.email.disable();
  }

}
