import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { PeriodTypeEnum } from '../../../../api/models/Models/Application/FinancialDetails/period-type-enum';
import { LivingSituationEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { Constants } from '../../../../dataobject/constants';
import { ApplicationFormControlNames } from '../../../../types/applicationFromControlNames';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { IncomeDetailForm, Step3EmploymentDetailsForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { EmploymentBasis } from '../../../../api/models/CommonUtilities/employment-basis';
import { EmploymentStatus } from '../../../../api/models/Models/v3/Application/EmploymentDetails/employment-status';
import { ListItem } from '../../../../types/listItem';
import { KiwiSaverRate } from '../../../../api/models/CommonUtilities/kiwi-saver-rate';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { MatIconModule } from '@angular/material/icon';
import { IncomeType } from '../../../../api/models/Models/v3/Application/FinancialDetails/income-type';
import { EmploymentBasisComponent } from '../employment-basis/employment-basis.component';
import { UnemploymentStatusEnum } from '../../../../api/models/Models/v3/Application/EmploymentDetails/unemployment-status-enum';
import { startWith, pairwise } from 'rxjs';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { RoleEnum } from '../../../../api/models/Models/v3/Application/EmploymentDetails/role-enum';

@Component({
  selector: 'app-employment-detail',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormFieldCurrencyComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    MatIconModule,
    EmploymentBasisComponent
  ],
  templateUrl: './employment-detail.component.html',
  styleUrl: './employment-detail.component.scss'
})
export class EmploymentDetailComponent extends BaseStepComponent {
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;
  @Input() fg!: FormGroup<Step3EmploymentDetailsForm>;

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  ApplicationFormControlNames = ApplicationFormControlNames;
  Constants = Constants;
  LivingSituationEnum = LivingSituationEnum;
  PeriodTypeEnum = PeriodTypeEnum;
  EmploymentStatus = EmploymentStatus;
  EmploymentBasis = EmploymentBasis;
  KiwisaverRate = KiwiSaverRate;
  UserAction = UserAction;
  SelectListType = SelectListType;
  UnemploymentStatusEnum = UnemploymentStatusEnum;

  individualHeaderDisplay: string = '';

  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);      
    }));

    this.subscriptions.push(this.fg.controls.hasOtherIncome.valueChanges
      .pipe(
        startWith(this.fg.controls.hasOtherIncome.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.otherIncome.clear();
            this.fg.controls.hasOtherIncome.setValue(UserAction.No);
          } else {
            this.addIncome();
          }
        }
      }));

   this.subscriptions.push(this.fg.controls.employmentStatus.valueChanges
      .pipe(
        startWith(this.fg.controls.employmentStatus.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (oldValue == EmploymentStatus.Unemployed) {
            this.disableControls([this.fg.controls.unemploymentStatus, this.fg.controls.durationMonths, this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount]);
          }
          if (oldValue == EmploymentStatus.Student) {
            this.disableControls([this.fg.controls.studentReceivesAllowance, this.fg.controls.isStudentEmployed]);
          }

          if ((newValue == EmploymentStatus.Employed || newValue == EmploymentStatus.SelfEmployed)) {
            this.enableControls([this.fg.controls.employmentBasis,
            this.fg.controls.role, this.fg.controls.industryClassification, this.fg.controls.employerName, this.fg.controls.durationMonths,
            this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount, this.fg.controls.kiwiSaverRate
            ]);
          }
          else if (oldValue == EmploymentStatus.Employed || oldValue == EmploymentStatus.SelfEmployed) {
            //disable the employment related fields
            this.disableControls([this.fg.controls.employmentBasis,
              this.fg.controls.role, this.fg.controls.industryClassification, this.fg.controls.employerName,  this.fg.controls.durationMonths,
              this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount, this.fg.controls.kiwiSaverRate
              ]);
          }

          if (newValue == EmploymentStatus.Unemployed) {
            this.enableControls([this.fg.controls.unemploymentStatus, this.fg.controls.durationMonths, this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount]);
          } 
         
          if (newValue == EmploymentStatus.Student) {
            this.enableControls([this.fg.controls.studentReceivesAllowance, this.fg.controls.isStudentEmployed]);
          } 
        }
      })); 

    this.activateControlsWhen(this.fg.controls.isStudentEmployed,
      [this.fg.controls.role, this.fg.controls.industryClassification, this.fg.controls.employerName, this.fg.controls.employmentBasis, this.fg.controls.durationMonths,
      this.fg.controls.incomeFrequency, this.fg.controls.incomeAmount, this.fg.controls.kiwiSaverRate
      ], null,
      [{ control: this.fg.controls.isStudentEmployed, expectedValues: [UserAction.Yes] }]);
  }

  removeIncome(index: number): void {
    this.fg.controls.otherIncome.removeAt(index);
    if (index == 0) {
      this.fg.controls.hasOtherIncome.setValue(UserAction.No);
    }
  }

  addIncome(): void {
    this.fg.controls.otherIncome.push(this.createIncomeGroup());
  }

  createIncomeGroup(): FormGroup<IncomeDetailForm> {
    var idFg = new FormGroup<IncomeDetailForm>({
      amount: new FormControl<number | null>(null, Validators.required),
      type: new FormControl<IncomeType | null>(null, Validators.required),
      description: new FormControl<string | null>(null, Validators.required),
      incomeFrequency: new FormControl<PeriodTypeEnum | null>(null, Validators.required),
      isBeforeTax: new FormControl<UserAction | null>(UserAction.No, Validators.required)
    } as IncomeDetailForm);

    return idFg;
  }


}
