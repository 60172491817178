import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../../../shared/shared.module';
import { AsFormControlPipe } from '../../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldDateComponent } from '../../../shared/form-field-date/form-field-date.component';
import { FormFieldNumericComponent } from '../../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldSelectComponent, SelectListType } from '../../../shared/form-field-select/form-field-select.component';
import { FormFieldComponent } from '../../../shared/form-field/form-field.component';
import { AddressComponent } from '../../address/address.component';
import { BaseStepComponent } from '../../base-step/base-step.component';
import { AssetPropertyForm, CreditFacilityForm, FinanceCommitmentForm, LiabilitiesForm, VehicleIndividualForm } from '../../../../types/vehicleIndividualSteps';
import { startWith, pairwise, Subscription } from 'rxjs';
import { UserAction } from '../../../../api/models/CommonUtilities/user-action';
import { LivingSituationEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { CreditFacilityType } from '../../../../api/models/Models/v3/Application/FinancialDetails/credit-facility-type';
import { FormFieldCurrencyComponent } from '../../../shared/form-field-currency/form-field-currency.component';
import { OtherFinanceCommitmentType } from '../../../../api/models/Models/v3/Application/FinancialDetails/other-finance-commitment-type';
import { PeriodTypeEnum } from '../../../../api/models/Models/Application/FinancialDetails/period-type-enum';
import { ApplicationExpenseType } from '../../../../api/models/CommonUtilities/application-expense-type';
import { VehicleAppService } from '../../../../services/vehicle-app.service';
import { MaritalStatusEnum } from '../../../../api/models/Models/v3/Application/PersonalDetails/marital-status-enum';
import { numericRangeValidator } from '../../../../types/validators';

@Component({
  selector: 'app-liabilities',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    AsFormControlPipe,
    AddressComponent,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatButtonToggleGroup,
    MatError,
    MatSelectModule,
    MatOptionModule,
    FormFieldDateComponent,
    FormButtonGroupSimpleComponent,
    FormFieldSelectComponent,
    MatIconModule,
    FormFieldCurrencyComponent
  ],
  templateUrl: './liabilities.component.html',
  styleUrl: './liabilities.component.scss'
})
export class LiabilitiesComponent extends BaseStepComponent {
  @Input() parentFg!: FormGroup<VehicleIndividualForm>;

  get fg(): FormGroup<LiabilitiesForm> {
    return this.parentFg.controls.liabilitiesFg;
  }

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  UserAction = UserAction;
  SelectListType = SelectListType;
  OtherFinanceCommitmentType = OtherFinanceCommitmentType;

  private livingPaymentAmountSubscription: Subscription | null = null;
  private livingPaymentFrequencySubscription: Subscription | null = null;
  individualHeaderDisplay: string = '';

  hasPartner: boolean = false;

  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.vehicleAppService.individualType$.subscribe(value => {
      this.individualHeaderDisplay = this.vehicleAppService.buildIndividualHeaderInfo(value, this.parentFg);
    }));

    // Add the disabled home loan if the user has a mortgage
    this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.valueChanges
      .pipe(
        startWith(this.parentFg.controls.step1.controls.addressPhysical.controls.livingSituation.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          var existingExpenseIndex = this.fg.controls.financeCommitments.controls.findIndex(x => x.controls.type.value === OtherFinanceCommitmentType.HomeLoan);

          if (newValue === LivingSituationEnum.Mortgaged) {
            if (existingExpenseIndex === -1) {
              this.addFinanceCommitment(OtherFinanceCommitmentType.HomeLoan);
              existingExpenseIndex = this.fg.controls.financeCommitments.controls.length - 1;
            }

            // get the expense by index
            var expense = this.fg.controls.financeCommitments.controls[existingExpenseIndex];
            expense.controls.paymentPeriod.setValue(this.parentFg.controls.step1.controls.livingPaymentFrequency.value);
            expense.controls.paymentPeriod.disable();
            expense.controls.amountPerPeriod.setValue(this.parentFg.controls.step1.controls.livingPaymentAmount.value);
            expense.controls.amountPerPeriod.disable();

            this.fg.controls.hasOtherFinancialCommitments.setValue(UserAction.Yes);
            this.fg.controls.hasOtherFinancialCommitments.disable();

            this.setupLivingPaymentListeners();

          } else {
            this.removeLivingPaymentListeners();
            if (existingExpenseIndex !== -1) {
              this.removeFinanceCommitment(existingExpenseIndex);
              this.fg.controls.hasOtherFinancialCommitments.enable();

              if (this.fg.controls.financeCommitments.length === 0) {
                this.fg.controls.hasOtherFinancialCommitments.setValue(UserAction.No);
              }
            }
          }
        }
      });


    this.fg.controls.hasCreditFacilities.valueChanges
      .pipe(
        startWith(this.fg.controls.hasCreditFacilities.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.creditFacilities.clear();
          } else {
            if (this.fg.controls.creditFacilities.length === 0) {
              this.addCreditFacility();
            }
          }
        }
      });

    this.fg.controls.hasOtherFinancialCommitments.valueChanges
      .pipe(
        startWith(this.fg.controls.hasOtherFinancialCommitments.value), // Emit the initial value
        pairwise() // Emit the previous and current values as an array
      )
      .subscribe(([oldValue, newValue]) => {
        if (newValue && newValue != oldValue) {
          if (newValue == UserAction.No) {
            this.fg.controls.financeCommitments.clear();
          } else {
            if (this.fg.controls.financeCommitments.length === 0) {
              this.addFinanceCommitment();
            }
          }
        }
      });


    this.subscriptions.push(this.parentFg.controls.step2.controls.maritalStatus.valueChanges.subscribe((value: any) => {

      this.hasPartner = (value == MaritalStatusEnum.Married || value == MaritalStatusEnum.DeFacto);

      this.fg.controls.creditFacilities.controls.forEach((cf: FormGroup<CreditFacilityForm>) => {        
        if (this.hasPartner) {
          cf.controls.repaymentsSharedWithPartner.enable();
        }
        else {
          cf.controls.repaymentsSharedWithPartner.setValue(null);
          cf.controls.repaymentsSharedWithPartner.disable();
        }
      });

      this.fg.controls.financeCommitments.controls.forEach((loan: FormGroup<FinanceCommitmentForm>) => {        
        if (this.hasPartner) {
          loan.controls.repaymentsSharedWithPartner.enable();
        }
        else {
          loan.controls.repaymentsSharedWithPartner.setValue(null);
          loan.controls.repaymentsSharedWithPartner.disable();
        }
      });
    }));

  }

  setupLivingPaymentListeners(): void {

    // Listen for the living payment frequency changes
    const livingPaymentFrequencyControl = this.parentFg.controls.step1.controls.livingPaymentFrequency;
    this.livingPaymentFrequencySubscription = livingPaymentFrequencyControl.valueChanges.subscribe((value: any) => {
      var expense = this.fg.controls.financeCommitments.controls.find(x => x.controls.type.value === OtherFinanceCommitmentType.HomeLoan);
      if (!expense) {
        return;
      }
      // Handle the value change
      expense.controls.paymentPeriod.enable();
      expense.controls.paymentPeriod.setValue(this.parentFg.controls.step1.controls.livingPaymentFrequency.value);
      expense.controls.paymentPeriod.disable();
    });

    // Listen for the living payment amount changes
    const livingPaymentAmountControl = this.parentFg.controls.step1.controls.livingPaymentAmount;
    this.livingPaymentAmountSubscription = livingPaymentAmountControl.valueChanges.subscribe((value: any) => {
      var expense = this.fg.controls.financeCommitments.controls.find(x => x.controls.type.value === OtherFinanceCommitmentType.HomeLoan);
      if (!expense) {
        return;
      }
      // Handle the value change
      expense.controls.amountPerPeriod.enable();
      expense.controls.amountPerPeriod.setValue(this.parentFg.controls.step1.controls.livingPaymentAmount.value);
      expense.controls.amountPerPeriod.disable();
    });

  }

  removeLivingPaymentListeners(): void {
    if (this.livingPaymentFrequencySubscription) {
      this.livingPaymentFrequencySubscription.unsubscribe();
      this.livingPaymentFrequencySubscription = null;
    }

    if (this.livingPaymentAmountSubscription) {
      this.livingPaymentAmountSubscription.unsubscribe();
      this.livingPaymentAmountSubscription = null;
    }
  }

  removeCreditFacility(index: number): void {
    this.fg.controls.creditFacilities.removeAt(index);
    if (index == 0 && this.fg.controls.creditFacilities.length == 0) {
      this.fg.controls.hasCreditFacilities.setValue(UserAction.No);
    }
  }

  addCreditFacility(facilityType: CreditFacilityType | null = null): void {
    this.fg.controls.creditFacilities.push(this.createCreditFacilityGroup(facilityType));
  }

  createCreditFacilityGroup(facilityType: CreditFacilityType | null = null): FormGroup<CreditFacilityForm> {
    var fg = new FormGroup<CreditFacilityForm>({
      balance: new FormControl<number | null>(null, Validators.compose([Validators.required, numericRangeValidator(0, 1000000)])),
      type: new FormControl<CreditFacilityType | null>({ value: facilityType, disabled: facilityType != null }, Validators.required),
      provider: new FormControl<string | null>(null, Validators.required),
      creditLimit: new FormControl<number | null>(null, Validators.compose([Validators.required, numericRangeValidator(0, 1000000)])),
      repaymentsSharedWithPartner: new FormControl<UserAction | null>({value: null, 'disabled' : !this.hasPartner}, Validators.required),
      isPaidInFullEachMonth: new FormControl<UserAction | null>({value: null, 'disabled' : true}, Validators.required),
    } as CreditFacilityForm);

    this.subscriptions.push(fg.controls.type.valueChanges.subscribe((value: any) => {

      var isPaidInFullEachMonthControl = fg.controls.isPaidInFullEachMonth;
      if (value == CreditFacilityType.CreditCard) {
        isPaidInFullEachMonthControl.enable();
      }
      else {
        isPaidInFullEachMonthControl.setValue(null);
        isPaidInFullEachMonthControl.disable();
      }
    }));

    return fg;
  }

  removeFinanceCommitment(index: number): void {
    this.fg.controls.financeCommitments.removeAt(index);
    if (index == 0 && this.fg.controls.financeCommitments.length == 0) {
      this.fg.controls.hasOtherFinancialCommitments.setValue(UserAction.No);
    }
  }

  addFinanceCommitment(type: OtherFinanceCommitmentType | null = null): FormGroup<FinanceCommitmentForm> {
    var fg = this.createFinanceCommitmentGroup(type);
    this.fg.controls.financeCommitments.push(fg);
    return fg;
  }

  createFinanceCommitmentGroup(type: OtherFinanceCommitmentType | null = null): FormGroup<FinanceCommitmentForm> {
    var fg = new FormGroup<FinanceCommitmentForm>({
      type: new FormControl<OtherFinanceCommitmentType | null>({ value: type, disabled: type != null }, Validators.required),
      provider: new FormControl<string | null>(null, Validators.required),
      balance: new FormControl<number | null>(null, Validators.required),
      amountPerPeriod: new FormControl<number | null>(null, Validators.required),
      paymentPeriod: new FormControl<PeriodTypeEnum | null>(null, Validators.required),
      repaymentsSharedWithPartner: new FormControl<UserAction | null>({value: null, 'disabled' : !this.hasPartner}, Validators.required),
    } as FinanceCommitmentForm);

    return fg;
  }

}
