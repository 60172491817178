/* tslint:disable */
/* eslint-disable */
export enum ApplicationExpenseType {
  None = 'None',
  MortgageOrRent = 'MortgageOrRent',
  Utilities = 'Utilities',
  Transport = 'Transport',
  Groceries = 'Groceries',
  Entertainment = 'Entertainment',
  Insurance = 'Insurance',
  ClothingShoesPersonalCare = 'ClothingShoesPersonalCare',
  Rates = 'Rates',
  Accommodation = 'Accommodation',
  Medical = 'Medical',
  EducationChildcare = 'EducationChildcare',
  ChildSupport = 'ChildSupport',
  GivingTithing = 'GivingTithing',
  CreditCard = 'CreditCard',
  Overdraft = 'Overdraft',
  RevolvingCreditHomeLoan = 'RevolvingCreditHomeLoan',
  FinanceCommitment = 'FinanceCommitment',
  HomeLoan = 'HomeLoan',
  CarLoan = 'CarLoan',
  PersonalLoan = 'PersonalLoan',
  BuyNowPayLater = 'BuyNowPayLater',
  OtherLoans = 'OtherLoans',
  Other = 'Other'
}
