/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApplicationResponseBaseResponse as ModelsV3ApplicationApplicationResponseBaseResponse } from '../../models/Models/v3/Application/application-response-base-response';
import { PublicApplicationStartRequest as ModelsV3ApplicationPublicPublicApplicationStartRequest } from '../../models/Models/v3/Application/Public/public-application-start-request';

export interface V3PublicApplicationVehiclePost$Json$Params {
      body?: ModelsV3ApplicationPublicPublicApplicationStartRequest
}

export function v3PublicApplicationVehiclePost$Json(http: HttpClient, rootUrl: string, params?: V3PublicApplicationVehiclePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>> {
  const rb = new RequestBuilder(rootUrl, v3PublicApplicationVehiclePost$Json.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ModelsV3ApplicationApplicationResponseBaseResponse>;
    })
  );
}

v3PublicApplicationVehiclePost$Json.PATH = '/v3/public/application/vehicle';
