/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NotifyDealerRequest as ModelsV3DealerNotifyDealerRequest } from '../../models/Models/v3/Dealer/notify-dealer-request';

export interface V3ApplicationsApplicationIdNotifyDealerPost$Params {
  applicationId: string;
      body?: ModelsV3DealerNotifyDealerRequest
}

export function v3ApplicationsApplicationIdNotifyDealerPost(http: HttpClient, rootUrl: string, params: V3ApplicationsApplicationIdNotifyDealerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, v3ApplicationsApplicationIdNotifyDealerPost.PATH, 'post');
  if (params) {
    rb.path('applicationId', params.applicationId, {});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

v3ApplicationsApplicationIdNotifyDealerPost.PATH = '/v3/applications/{applicationId}/notify-dealer';
