import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private http: HttpClient) { }

  registerIcons(iconFolderPath: string): void {

    // Add icon names here, this can then be used in the html as <mat-icon svgIcon="custom_icon1"></mat-icon>
    // Eg: 
    // <mat-icon svgIcon="attach"></mat-icon>
    const icons = [
      'add',
      'attach',
      'back-arrow',
      'info',
      'check',
      'check-circle',
      'tick',
      'check-small',
      'error',
      'file-upload',
      'star-border',
      'trash',
      'transport',
      'phone',
      'insurance',
      'food',
      'clothing',
      'bills',
      'progress',
      'file',
    ];

    icons.forEach(iconName => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconFolderPath}/${iconName}.svg`)
      );
    });
  }

  // registerIcons(iconFolderPath: string): void {
  //   this.http.get<string[]>(`${iconFolderPath}/icons.json`).subscribe(icons => {
  //     icons.forEach(iconName => {
  //       this.matIconRegistry.addSvgIcon(
  //         iconName,
  //         this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconFolderPath}/${iconName}.svg`)
  //       );
  //     });
  //   });
  // }
}
