/* tslint:disable */
/* eslint-disable */
export enum IndustryClassificationEnum {
  AgricultureForestryAndFishing = 'AgricultureForestryAndFishing',
  Mining = 'Mining',
  Manufacturing = 'Manufacturing',
  ElectricityGasWaterAndWasteServices = 'ElectricityGasWaterAndWasteServices',
  Construction = 'Construction',
  WholesaleTrade = 'WholesaleTrade',
  RetailTrade = 'RetailTrade',
  AccommodationAndFoodServices = 'AccommodationAndFoodServices',
  TransportPostalAndWarehousing = 'TransportPostalAndWarehousing',
  InformationMediaAndTelecommunications = 'InformationMediaAndTelecommunications',
  FinancialAndInsuranceServices = 'FinancialAndInsuranceServices',
  RentalHiringAndRealEstateServices = 'RentalHiringAndRealEstateServices',
  ProfessionalScientificAndTechnicalServices = 'ProfessionalScientificAndTechnicalServices',
  AdministrativeAndSupportServices = 'AdministrativeAndSupportServices',
  PublicAdministrationAndSafety = 'PublicAdministrationAndSafety',
  EducationAndTraining = 'EducationAndTraining',
  HealthCareAndSocialAssistance = 'HealthCareAndSocialAssistance',
  ArtsAndRecreationServices = 'ArtsAndRecreationServices',
  Other = 'Other'
}
