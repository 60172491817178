import { Injectable } from '@angular/core';
import { OtpEventArgs } from '../dataobject/otp-event-args';
import { ApplicationResponse } from '../api/models/Models/v3/Application/application-response';
import { PublicVehicleApplicationService } from '../api/services';
import { ErrorService } from './error.service';
import { PublicApplicationStartRequest } from '../api/models/Models/v3/Application/Public/public-application-start-request';
import { environment } from "../../environments/environment";
import { AboutForm, LoanTypeEntityForm, LoanTypeForm, LoanTypeIndividualForm, SummaryReviewForm, VehicleApplicationForm, VehicleDetailsTradeInForm } from '../types/vehicle-application-types';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { UserAction } from '../api/models/CommonUtilities/user-action';
import { ApplicationUpdateVehicleResponse } from '../api/models/Models/v3/Application/Public/application-update-vehicle-response';
import { ApplicationFormControlNames } from '../types/applicationFromControlNames';
import { conditionalRequiredValidator, conditionalRequiredValidatorTraverseFg, numericRangeValidator } from '../types/validators';
import { LoanEntityType } from '../api/models/CommonUtilities/loan-entity-type';
import { VehicleClass } from '../api/models/CommonUtilities/vehicle-class';
import { IndividualRequest } from '../api/models/Models/v3/Application/individual-request';
import { ContactType } from '../api/models/CommonUtilities/contact-type';
import { ApplicationConfigResponse } from '../api/models/Models/v3/Application/application-config-response';
import { AddressForm, IndividualCommonDataForm, VehicleIndividualForm } from '../types/vehicleIndividualSteps';
import { MessageService } from './message.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressRequest } from '../api/models/Models/v3/Application/PersonalDetails/address-request';

@Injectable({
  providedIn: 'root'
})
export class VehicleAppService {

  otpEventArgs!: OtpEventArgs;
  newApplicationResponse: ApplicationResponse = {};
  isVerified: boolean = false;

  vehicleApplicationFg: VehicleApplicationForm | null = null;
  public stepperDuration: string = '200';

  loanApplicationResponse: ApplicationUpdateVehicleResponse = {
    loanInformation: {
      hasDeposit: UserAction.Unspecified,
      hasTradeInVehicle: UserAction.Unspecified,
      hasVehicle: UserAction.Unspecified,
      isBusinessLoan: UserAction.Unspecified,
      loanAmount: 0,
      loanTermMonths: 36,
      depositValue: 0,
      isVehicleTradeInFinanced: UserAction.Unspecified,
      vehicles: [{
        vehicleClass: VehicleClass.PassengerVehicle
      }],
      allowLowerAmountApprovalCheck: false,
      loanEntityType: LoanEntityType.Individual,

    }
  };

  //Default to virutal 9 steps (only actually 3 steps until an individual is added)
  public totalSteps: number = 9;

  currentStep: number = 1;

  public entityTypeDisplay: string = 'Person';

  get percentageStepsComplete(): number {
    return (this.currentStep / this.totalSteps) * 100;
  }

  public setTotalStepsActual(): void {
    this.totalSteps = 3;
  }

  public setCurrentStep(stepIndex: number): void {
    this.currentStep = stepIndex;
  }

  public ApplicationConfigResponse: ApplicationConfigResponse = {};

  private entityTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public entityType$: Observable<string> = this.entityTypeSubject.asObservable();

  private individualTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public individualType$: Observable<string> = this.individualTypeSubject.asObservable();

  constructor(
    private publicVehicleApplicationService: PublicVehicleApplicationService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {

    // Debug mode
    if (environment.bypassOtpVerification) {
      if (environment.apiUrl.includes('localhost')) {
        this.newApplicationResponse.publicId = '1C732738AD2744E08BB709843E3A9E2F';
      }
      else if (environment.apiUrl.includes('api-integration')) {
        this.newApplicationResponse.publicId = '87edd2b40e6548fc847d7a70bdb11b78';
      }
      this.isVerified = true;

      this.loanApplicationResponse.applicants = {
        individuals: [{
          customerDetails: {
            firstName: 'Testfirstname',
            lastName: 'Testlastname',
            applicantType: ContactType.Primary,
            mobileNumber: '02111111',
            email: 'test@motorcentral.co.nz',
          },
        }]
      }

      this.vehicleApplicationFg = this.buildVehicleApplicationFg(this.loanApplicationResponse);
      this.buildTestData();
    }
  }


  buildTestData() {
    if (this.vehicleApplicationFg) {
      this.vehicleApplicationFg.aboutFg.controls.purchaseVehicleType.setValue(VehicleClass.PassengerVehicle);
      this.vehicleApplicationFg.aboutFg.controls.hasVehicle.setValue(UserAction.No);
      this.vehicleApplicationFg.aboutFg.controls.hasDeposit.setValue(UserAction.No);
      this.vehicleApplicationFg.aboutFg.controls.hasTradeInVehicle.setValue(UserAction.No);
      //this.vehicleApplicationFg.aboutFg.controls.isBusinessPurpose.setValue(UserAction.No);
      this.vehicleApplicationFg.aboutFg.controls.hasOtherItems.setValue(UserAction.No);
      this.vehicleApplicationFg.aboutFg.controls.approveLesserAmount.setValue(true);
      this.vehicleApplicationFg.aboutFg.controls.loanAmount.setValue(10000);
      this.vehicleApplicationFg.loanTypeFg.controls.loanEntityType.setValue(LoanEntityType.Individual);
      this.vehicleApplicationFg.loanTypeFg.controls.hasAdditionalBorrowers.setValue(UserAction.Yes);

      this.vehicleApplicationFg.loanTypeFg.controls.individuals.push(this.buildIndividualForm(
        {
          customerDetails: {
            firstName: 'Testfirstname',
            lastName: 'Testlastname',
            applicantType: ContactType.Primary,
            mobileNumber: '021111111',
            email: 'test@motorcentral.co.nz '
          }
        }
      ));

    }
  }

  moveCurrentStepCountBack() {

    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
    console.log('moveCurrentStepCountBack', this.currentStep);
    console.log('total steps', this.totalSteps);
  }

  moveCurrentStepCountForward() {
    this.currentStep += 1;
    console.log('moveCurrentStepCountForward', this.currentStep);
    console.log('total steps', this.totalSteps);
  }

  verifyInitIdentity(otpEventArgs: OtpEventArgs): Promise<boolean> {
    this.otpEventArgs = otpEventArgs;

    const urlParams = new URLSearchParams(window.location.search);
    const publicId = urlParams.get('publicId');
    const dealershipCode = urlParams.get('dealershipCode');
    const origin = urlParams.get('origin');
    const stockNo = urlParams.get('stockNo');
    const externalListingId = urlParams.get('listingId');

    return new Promise((resolve, reject) => {
      this.publicVehicleApplicationService.v3PublicApplicationVehiclePost$Json({
        body: {
          details: {
            publicAccountId: publicId,
            branchCode: dealershipCode,
            otpDetails: {
              clientNonce: otpEventArgs.otpNonce,
              email: otpEventArgs.email,
              mobile: otpEventArgs.mobile,
              name: otpEventArgs.firstName,
              otpHash: otpEventArgs.otpHash,
              otp: otpEventArgs.otpCode,
            },
            locationSource: origin ? origin : '',
            stockNo: stockNo,
            externalListingId: externalListingId ? externalListingId : '',
            lastName: otpEventArgs.lastName,
          }
        } as PublicApplicationStartRequest
      })
        .subscribe({
          next: (response) => {
            this.newApplicationResponse = response.entityData!;
            this.isVerified = true;
            console.log(response);

            this.loanApplicationResponse.applicants = {
              individuals: [{
                customerDetails: {
                  firstName: this.otpEventArgs.firstName,
                  lastName: this.otpEventArgs.lastName,
                  applicantType: ContactType.Primary,
                  mobileNumber: this.otpEventArgs.mobile,
                  email: this.otpEventArgs.email != '' ? this.otpEventArgs.email : null,
                }
              }]
            }

            this.vehicleApplicationFg = this.buildVehicleApplicationFg(this.loanApplicationResponse);

            // Attempt to get the vehicle info
            if (stockNo != '' || externalListingId != '') {
              console.log('get vehicle info', stockNo, ' | ',  externalListingId);
                            
              this.publicVehicleApplicationService.v3PublicApplicationVehicleApplicationIdVehicleInfoGet$Json({
                applicationId: response.entityData!.publicId!
              }).subscribe({
                next: (response) => {

                  if (response.entityData) {
                    if (response.entityData.make && response.entityData.make != '') {
                      this.vehicleApplicationFg?.aboutFg.controls.vehicleMake.setValue(response.entityData.make);
                    }
                    if (response.entityData.model && response.entityData.model != '') {
                      this.vehicleApplicationFg?.aboutFg.controls.vehicleModel.setValue(response.entityData.model);
                    }
                    if (response.entityData.year && response.entityData.year > 1950) {
                      this.vehicleApplicationFg?.aboutFg.controls.vehicleYear.setValue(response.entityData.year);
                    }
                  }
                },
                error: (errorResponse) => {
                  // do nothing
                }
              });
            }

            if (environment.debug) {
              this.buildTestData();
            }
            resolve(true);
          },
          error: (errorResponse) => {
            this.errorService.handleError(errorResponse);
            this.messageService.setLoadingState(false);
            reject(false);
          }
        });
    });
  }

  buildVehicleApplicationFg(app: ApplicationUpdateVehicleResponse): VehicleApplicationForm {
    return {
      aboutFg: this.buildAboutFg(this.loanApplicationResponse),
      loanTypeFg: this.buildLoanTypeFg(this.loanApplicationResponse),
      indivFormArray: null,
      summaryReviewFg: this.buildSummaryReviewFg(this.loanApplicationResponse),
      entityFg: null,
    };

  }

  buildSummaryReviewFg(app: ApplicationUpdateVehicleResponse): FormGroup<SummaryReviewForm> {
    return this.formBuilder.group<SummaryReviewForm>({
      hasFutureUpcomingChanges: new FormControl(UserAction.Unspecified),
      futureUpcomingChangesDescription: new FormControl(''),
    });
  }

  buildAboutFg(app: ApplicationUpdateVehicleResponse): FormGroup<AboutForm> {
    const date = new Date();
    const maxLoanAmount = 75000;

    const urlParams = new URLSearchParams(window.location.search);
    const vehiclePriceIn = Number(urlParams.get('price'));
    const vehicleStockNo = Number(urlParams.get('stockNo'));

    let hasVehicle = null;
    let vehiclePrice = null;
    let hasVehiclePlateVin = null;

    if (vehiclePriceIn > 0) {
      if (vehicleStockNo > 0) {
        hasVehicle = UserAction.Yes;
        hasVehiclePlateVin = UserAction.No;
        vehiclePrice = vehiclePriceIn;
      } else {
        hasVehicle = UserAction.No;
        vehiclePrice = vehiclePriceIn;
      }
    }

    const formGroup = this.formBuilder.group<AboutForm>({
      purchaseVehicleType: new FormControl(null, Validators.required),
      hasVehicle: new FormControl(hasVehicle, Validators.required),
      //isBusinessPurpose: new FormControl(null, Validators.required),
      loanAmount: new FormControl(vehiclePrice, Validators.compose([Validators.required, numericRangeValidator(1000, maxLoanAmount)])),
      netLoanAmount: new FormControl(null, Validators.compose([Validators.required, numericRangeValidator(1000, 100000)])),
      hasOtherItems: new FormControl(null, Validators.required),
      otherItemsCost: new FormControl(null, conditionalRequiredValidator(ApplicationFormControlNames.HasOtherItems, UserAction.Yes)),
      hasDeposit: new FormControl(null, Validators.required),
      depositAmount: new FormControl(null, Validators.compose([conditionalRequiredValidator(ApplicationFormControlNames.HasDeposit, UserAction.Yes), numericRangeValidator(1)])),
      approveLesserAmount: new FormControl(true),

      hasVehiclePlateVin: new FormControl({ value: hasVehiclePlateVin, disabled: hasVehiclePlateVin == null }, conditionalRequiredValidator('hasVehicle', UserAction.Yes)),
      vehiclePlateVin: new FormControl({ value: null, disabled: true }, conditionalRequiredValidator('hasVehiclePlateVin', UserAction.Yes)),
      vehicleYear: new FormControl({ value: null, disabled: hasVehiclePlateVin != UserAction.No }, Validators.compose([conditionalRequiredValidator('hasVehiclePlateVin', UserAction.No), numericRangeValidator(1950, date.getFullYear() + 1)])),
      vehicleMake: new FormControl({ value: null, disabled: hasVehiclePlateVin != UserAction.No }, conditionalRequiredValidator('hasVehiclePlateVin', UserAction.No)),
      vehicleModel: new FormControl({ value: null, disabled: hasVehiclePlateVin != UserAction.No }, conditionalRequiredValidator('hasVehiclePlateVin', UserAction.No)),
      vehicleIsBrandNew: new FormControl({ value: null, disabled: vehiclePrice == null }, conditionalRequiredValidator('hasVehicle', UserAction.Yes)),
      vehiclePrice: new FormControl({ value: vehiclePrice, disabled: vehiclePrice == null }, Validators.compose([conditionalRequiredValidator('hasVehicle', UserAction.Yes),
      numericRangeValidator(1000, maxLoanAmount)])),

      hasTradeInVehicle: new FormControl(null, Validators.required),
      isVehicleTradeInFinanced: new FormControl(null, conditionalRequiredValidator('hasTradeInVehicle', UserAction.Yes)),

      tradeInVehicle: this.formBuilder.group<VehicleDetailsTradeInForm>({
        plate: new FormControl(null, conditionalRequiredValidatorTraverseFg('hasTradeInVehicle', UserAction.Yes)),
        year: new FormControl(null, Validators.compose([conditionalRequiredValidatorTraverseFg('hasTradeInVehicle', UserAction.Yes), numericRangeValidator(1950, date.getFullYear() + 1)])),
        make: new FormControl(null, conditionalRequiredValidatorTraverseFg('hasTradeInVehicle', UserAction.Yes)),
        model: new FormControl(null, conditionalRequiredValidatorTraverseFg('hasTradeInVehicle', UserAction.Yes)),
        price: new FormControl(null, Validators.compose([conditionalRequiredValidatorTraverseFg('hasTradeInVehicle', UserAction.Yes), numericRangeValidator(1000, 10000000)])),
        encumbrance: new FormControl(null, Validators.compose([conditionalRequiredValidatorTraverseFg('isVehicleTradeInFinanced', UserAction.Yes), numericRangeValidator(1)])),
      })
    }
    );


    //  // Subscribe to value changes to trigger reevaluation
    //  formGroup.get('hasVehicle')?.valueChanges.subscribe(() => {
    //   formGroup.get('hasVehiclePlateVin')?.updateValueAndValidity();
    //   formGroup.get('vehicleIsBrandNew')?.updateValueAndValidity();
    //   formGroup.get('vehiclePrice')?.updateValueAndValidity();
    // });

    // formGroup.get('hasVehiclePlateVin')?.valueChanges.subscribe(() => {
    //   formGroup.get('vehiclePlateVin')?.updateValueAndValidity();
    //   formGroup.get('vehicleYear')?.updateValueAndValidity();
    //   formGroup.get('vehicleMake')?.updateValueAndValidity();
    //   formGroup.get('vehicleModel')?.updateValueAndValidity();
    // });

    formGroup.get('hasTradeInVehicle')?.valueChanges.subscribe(() => {
      formGroup.get('isVehicleTradeInFinanced')?.updateValueAndValidity();
      const tradeInVehicleGroup = formGroup.get('tradeInVehicle') as FormGroup;
      tradeInVehicleGroup.get('plate')?.updateValueAndValidity();
      tradeInVehicleGroup.get('year')?.updateValueAndValidity();
      tradeInVehicleGroup.get('make')?.updateValueAndValidity();
      tradeInVehicleGroup.get('model')?.updateValueAndValidity();
      tradeInVehicleGroup.get('price')?.updateValueAndValidity();
    });

    formGroup.controls.isVehicleTradeInFinanced.valueChanges.subscribe(() => {
      const tradeInVehicleGroup = formGroup.controls.tradeInVehicle as FormGroup;
      tradeInVehicleGroup.get('encumbrance')?.updateValueAndValidity();
    });

    return formGroup;
  }

  buildLoanTypeFg(app: ApplicationUpdateVehicleResponse): FormGroup<LoanTypeForm> {
    var loanType = this.formBuilder.group<LoanTypeForm>({
      loanEntityType: new FormControl(LoanEntityType.Individual, Validators.required),
      individuals: this.buildIndividualFormArray(app),
      entities: this.formBuilder.array<FormGroup<LoanTypeEntityForm>>([]),
      hasAdditionalBorrowers: new FormControl(null, Validators.required)
    });

    return loanType;
  }

  buildIndividualFormArray(app: ApplicationUpdateVehicleResponse): FormArray<FormGroup<LoanTypeIndividualForm>> {

    var formArray = this.formBuilder.array<FormGroup<LoanTypeIndividualForm>>([]);
    if (app.applicants?.individuals && app.applicants?.individuals?.length > 0) {
      app.applicants.individuals.forEach(individual => {
        formArray.push(this.buildIndividualForm(individual));
      });
    }
    return formArray;
  }

  buildIndividualForm(individual: IndividualRequest): FormGroup<LoanTypeIndividualForm> {
    return this.formBuilder.group<LoanTypeIndividualForm>({
      firstName: new FormControl(individual.customerDetails!.firstName!, Validators.required),
      middleName: new FormControl(''),
      lastName: new FormControl(individual.customerDetails!.lastName!, Validators.required),
      contactType: new FormControl(individual.customerDetails?.applicantType!, Validators.required),
      mobile: new FormControl(''),
      email: new FormControl(''),
      birthDate: new FormControl(''),
      directorIndex: new FormControl(-1),
    });
  }

  public buildIndividualHeaderInfo(indivCaption: string, individualFg: FormGroup<VehicleIndividualForm> | null): string {
    if (individualFg) {
      if (indivCaption == '') {
        indivCaption = 'Person';
      }

      if (individualFg) {
        var applicantIndividualNumber = this.vehicleApplicationFg!.indivFormArray!.controls.indexOf(individualFg) + 1;
        return `${indivCaption} ${applicantIndividualNumber}:`;
      }
    }

    return '';
  }

  setEntityType(entityType: LoanEntityType | null): void {
    this.setEntityTypeCaption(entityType);
    this.setIndividualTypeCaption(entityType);
  }

  private setEntityTypeCaption(entityType: LoanEntityType | null): void {
    let value = '';
    switch (entityType) {
      case LoanEntityType.Company:
        value = 'Company';
        break;
      case LoanEntityType.Trust:
        value = 'Trust';
        break;
      case LoanEntityType.Other:
        value = 'Entity';
        break;
    }

    this.entityTypeSubject.next(value);
  }

  private setIndividualTypeCaption(entityType: LoanEntityType | null): void {

    var entityTypeDisplay = '';
    switch (entityType) {
      case LoanEntityType.Individual:
        entityTypeDisplay = 'Person';
        // if(individualFg.controls.step1.controls.contactType.value == ContactType.Primary){
        // }else if(individualFg.controls.step1.controls.contactType.value == ContactType.Secondary){
        //   entityTypeDisplay = 'Guarantor';
        // }
        break;
      case LoanEntityType.Company:
        entityTypeDisplay = 'Guarantor';
        break;
      case LoanEntityType.Other:
      case LoanEntityType.SoleTrader:
      case LoanEntityType.Partner:
        entityTypeDisplay = 'Applicant';
        break;
      case LoanEntityType.Trust:
        entityTypeDisplay = 'Trustee';
        break;
    }

    this.individualTypeSubject.next(entityTypeDisplay);
  }

  // public getLoanEntityTypeDisplay(loanEntityType: LoanEntityType): string {
  //   var entityTypeDisplay = '';
  //   switch (loanEntityType) {
  //     case LoanEntityType.Individual:
  //       entityTypeDisplay = 'Person';
  //       // if(individualFg.controls.step1.controls.contactType.value == ContactType.Primary){
  //       // }else if(individualFg.controls.step1.controls.contactType.value == ContactType.Secondary){
  //       //   entityTypeDisplay = 'Guarantor';
  //       // }
  //       break;
  //     case LoanEntityType.Other:
  //     case LoanEntityType.Company:
  //       entityTypeDisplay = 'Guarantor';
  //       break;
  //     case LoanEntityType.SoleTrader:
  //       entityTypeDisplay = 'Applicant';
  //       break;
  //     case LoanEntityType.Partner:
  //       entityTypeDisplay = 'Applicant';
  //       break;
  //     case LoanEntityType.Trust:
  //       entityTypeDisplay = 'Trustee';
  //       break;
  //   }
  //   this.entityTypeDisplay = entityTypeDisplay;
  //   return entityTypeDisplay;
  // }

  public getDependentPartnerFg(currentIndividualFg: FormGroup<VehicleIndividualForm>, partnerId: string): FormGroup<VehicleIndividualForm> | null {
    // get the index of the individualFg from this.vehicleApplicationFg?.indivFormArray.controls
    var partnerIndex = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x.controls.common.controls.id.value?.toString() == partnerId);
    var partnerFg = this.vehicleApplicationFg!.indivFormArray!.controls[partnerIndex] as FormGroup<VehicleIndividualForm>;

    if (partnerFg) {
      // Check to see if the current individual is before the partner in the array
      // This means the partner is a dependent of the current individual
      var currentIndividualId = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x == currentIndividualFg);
      if (currentIndividualId < partnerIndex && currentIndividualFg.controls.step2.controls.isPartnerOnApplication.value == UserAction.Yes) {
        return partnerFg;
      }
    }

    return null;
  }

  public getParentPartnerFg(currentIndividualFg: FormGroup<VehicleIndividualForm>, partnerId: string): FormGroup<VehicleIndividualForm> | null {

    // get the index of the individualFg from this.vehicleApplicationFg?.indivFormArray.controls
    var partnerIndex = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x.controls.common.controls.id.value?.toString() == partnerId);
    var partnerFg = this.vehicleApplicationFg!.indivFormArray!.controls[partnerIndex] as FormGroup<VehicleIndividualForm>;

    if (partnerFg) {
      // Check to see if the current individual is before the partner in the array
      // This means the partner is a dependent of the current individual
      var currentIndividualId = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x == currentIndividualFg);
      if (currentIndividualId > partnerIndex) {
        return partnerFg;
      }
    }

    return null;
  }

  // public getIndividualPartnerFormGroup(currentIndividualFg: FormGroup<VehicleIndividualForm>, partnerId: string): FormGroup<VehicleIndividualForm> | null {
  //   // get the index of the individualFg from this.vehicleApplicationFg?.indivFormArray.controls
  //   var partnerIndex = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x.controls.common.controls.id.value?.toString() == partnerId);
  //   var partnerFg = this.vehicleApplicationFg!.indivFormArray!.controls[partnerIndex] as FormGroup<VehicleIndividualForm>;
  //   if (partnerFg) {

  //     debugger;

  //     // get the index of currentIndividualFg from this.vehicleApplicationFg?.indivFormArray.controls

  //     var linkedIndividualId = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x == currentIndividualFg);


  //     var linkedIndividualId = this.vehicleApplicationFg!.indivFormArray!.controls.findIndex(x => x.controls.step2.controls.partnerIdentifier.value?.toString() == partnerId &&
  //       x.controls.step2.controls.isPartnerOnApplication.value == UserAction.Yes);

  //     if (linkedIndividualId >= 0) {
  //       return partnerFg;
  //     }
  //   }
  //   return null;
  // }

  getApplicationFg(): VehicleApplicationForm {
    return this.vehicleApplicationFg!;
  }

  buildAddressFg(address: AddressRequest, durationRequired: boolean = false, livingSituationRequired: boolean = false): FormGroup<AddressForm> {

    var durationValidators = durationRequired ? [Validators.required] : [];
    var livingSituationValidators = livingSituationRequired ? [Validators.required] : [];

    return this.formBuilder.group<AddressForm>({
      isManualEdit: new FormControl(false),
      caption: new FormControl('', conditionalRequiredValidator('isManualEdit', false)),
      street: new FormControl('', Validators.required),
      streetNo: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      suburb: new FormControl('', Validators.required),
      postCode: new FormControl('', Validators.required),
      region: new FormControl(''),
      country: new FormControl(null, Validators.required),
      durationMonths: new FormControl(null, durationValidators),
      livingSituation: new FormControl(null, livingSituationValidators),
    })
  }
}
