import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appResizeObserver]',
  standalone: true
})

export class ResizeObserverDirective
{
  observer?: ResizeObserver;
  @Output() onElementHeightChange: EventEmitter<number> = new EventEmitter<number>();
  constructor(el: ElementRef)
  {
    this.observer = new ResizeObserver((entries) =>
    {
      let max = 0;
      for (const e of entries)
      {
        max = Math.ceil(Math.max(max, e.contentRect.height));
      }
      this.onElementHeightChange.emit(max);
    });
    
    this.observer.observe(el.nativeElement);
  }
}
