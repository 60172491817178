import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { VehicleAppService } from '../../services/vehicle-app.service';
import { ApplicationConfigResponse } from '../../api/models/Models/v3/Application/application-config-response';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '../../shared/shared.module';
import { FormFieldComponent } from '../shared/form-field/form-field.component';

@Component({
  selector: 'app-application-completed',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
  ],
  templateUrl: './application-completed.component.html',
  styleUrl: './application-completed.component.scss'
})
export class ApplicationCompletedComponent {

  applicationConfigResponse: ApplicationConfigResponse;

  constructor(private vehicleAppService: VehicleAppService) {
    this.applicationConfigResponse = vehicleAppService.ApplicationConfigResponse;
   }
}
