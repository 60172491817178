/* tslint:disable */
/* eslint-disable */
export enum LivingSituationEnum {
  HomeownerWithoutMortgage = 'HomeownerWithoutMortgage',
  Mortgaged = 'Mortgaged',
  Renter = 'Renter',
  Boarder = 'Boarder',
  LivingWithFamily = 'LivingWithFamily',
  GovernmentHousing = 'GovernmentHousing',
  EmployerProvided = 'EmployerProvided',
  Other = 'Other'
}
