<form [formGroup]="fg" #form="ngForm">
  <section>
    <div class="group-section">
      <h1 class="mat-headline-large">Loan type</h1>
    </div>
    <div class="group-section small-group">
      <p class="mat-body-large">What entity type will be applying for this loan?</p>
      <div class="group-required">
        <mat-button-toggle-group class="button-group" aria-label="What type of vehicle are you looking to purchase?"
          [formControl]="fg.controls.loanEntityType" [hideSingleSelectionIndicator]="true">
          <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.Individual}}">Individual</mat-button-toggle>
          <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.Company}}">Company</mat-button-toggle>
          <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.Trust}}">Trust</mat-button-toggle>
          <!-- <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.Partner}}">Partnership</mat-button-toggle> -->
          <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.SoleTrader}}">Sole trader</mat-button-toggle>
          <mat-button-toggle (change)="onLoanEntityTypeValChange($event.value)"
            value="{{LoanEntityType.Other}}">Other</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-error *ngIf="form.submitted && fg.controls.loanEntityType.hasError('required')">Required</mat-error>
      </div>
    </div>
    <p class="error-text" *ngIf="fg.controls.loanEntityType.value === LoanEntityType.SoleTrader">As a sole trader,
      your business is not a separate legal entity from you. Therefore, please complete this application in a
      personal capacity using your individual information.</p>
    <p class="error-text" *ngIf="fg.controls.loanEntityType.value === LoanEntityType.Partner">As a partnership, your
      business is not a separate legal entity from the individual partners. Therefore, please complete this
      application in a personal capacity using individual information.</p>

    <!-- Individual/SoleTrader/Partnership -->
    <ng-container
      *ngIf="fg.controls.loanEntityType.value === LoanEntityType.Individual || fg.controls.loanEntityType.value === LoanEntityType.SoleTrader || fg.controls.loanEntityType.value === LoanEntityType.Partner">

      <ng-container *ngFor="let group of this.fg.controls.individuals.controls; let i = index">
        <!-- Primary applicant -->
        <ng-container *ngIf="i == 0">
          <div class="group-section small-group">
            <p class="mat-body-large">Who is the primary applicant of this loan?</p>
            <div class="group-section">
              <app-form-field [formSubmitted]="form.submitted" label="First name" [control]="group.controls.firstName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Middle name (optional)"
                [control]="group.controls.middleName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Last name" [control]="group.controls.lastName">
              </app-form-field>
            </div>
          </div>
          <div class="group-section" *ngIf="fg.controls.loanEntityType.value">
            <app-form-button-group-simple [formSubmitted]="form.submitted"
              [control]="fg.controls.hasAdditionalBorrowers"
              label="Would you like to add any co-borrowers or guarantors?">
            </app-form-button-group-simple>
          </div>
        </ng-container>

        <!-- Additional co-borrowers/guarantors -->
        <ng-container *ngIf="i > 0" class="section-spacing">
          <div class="group-section small-group">
            <div class="row">
              <div class="col-12">
                <div class="flex-content height-24">
                  <p class="mat-body-large">Person {{i+1}}</p>
                  <button mat-button class="link-button no-background-link remove" type="button"
                    (click)="removeIndividual(i)"><mat-icon svgIcon="trash"></mat-icon>Remove</button>
                </div>
              </div>
            </div>
            <div class="group-section">
              <app-form-field [formSubmitted]="form.submitted" label="First name" [control]="group.controls.firstName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Middle name (optional)"
                [control]="group.controls.middleName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Last name" [control]="group.controls.lastName">
              </app-form-field>

              <app-form-field-select [control]="group.controls.contactType"                
                [listType]="SelectListType.OtherApplicantTypes" label="Type"></app-form-field-select>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="height-24"
        *ngIf="fg.controls.hasAdditionalBorrowers.value == UserAction.Yes && this.fg.controls.individuals.controls.length <3">
        <button mat-button class="link-button no-background-link" (click)="addIndividual()" type="button"><mat-icon
            svgIcon="add"></mat-icon>Add
          another</button>
      </div>
    </ng-container>

    <ng-container *ngIf="this.fg.controls.entities.length > 0">

      <ng-container *ngFor="let entity of this.fg.controls.entities.controls; let i = index">
        <div class="group-section x-small-group"
          *ngIf="fg.controls.loanEntityType.value === LoanEntityType.Company && !entity.controls.isManualCompanyNameEdit.value">
          <app-form-field-autocomplete label="Company legal name" [control]="entity.controls.caption"
            [options]="companyOptions" (filterChanged)="filterCompanies($event)"
            (optionSelected)="filterCompaniesOptionSelected($event)">
          </app-form-field-autocomplete>
          <div class="flex-content"
            *ngIf="!entity.controls.isManualCompanyNameEdit.value && entity.controls.nzbn.value">
            NZBN: {{entity.controls.nzbn.value}}
          </div>

          <div class="flex-content">
            <button mat-button class="link-button no-background-link height-24" type="button"
              (click)="setCompanyManualEditVisibility(true)">Enter company details manually</button>
          </div>
        </div>

        <div class="group-section" *ngIf="entity.controls.isManualCompanyNameEdit.value">

          <div class="group-section" *ngIf="fg.controls.loanEntityType.value == LoanEntityType.Company">
            <div class="group-section small-group" style="gap: 10px;">
              <div class="flex-content">
                <button mat-button class="link-button no-background-link height-24" type="button"
                  (click)="setCompanyManualEditVisibility(false)">Search for company instead</button>
              </div>
            </div>
          </div>

          <div class="group-section">
            <app-form-field [formSubmitted]="form.submitted" [label]="legalNameLabel"
              [control]="entity.controls.legalName">
            </app-form-field>
          </div>
          <div class="group-section">
            <app-form-field [formSubmitted]="form.submitted" label="NZBN" *ngIf="entity.controls.nzbn.enabled;"
              [control]="entity.controls.nzbn">
            </app-form-field>
          </div>
        </div>

        <!-- Directors -->
        <ng-container *ngIf="entity.controls.directors && entity.controls.directors.controls.length >0">
          <!-- Primary entity -->
          <ng-container *ngIf="i == 0">

            <hr class="separator no-margin" />

            <div class="group-section title-section">
              <h1 class="mat-headline-large">Directors</h1>
              <span class="mat-body-large">At least 2 required</span>
            </div>
            <span class="mat-body-large">If your business only has one director, please enter their
              details
              below and you will be prompted to provide a witness at time of signing.</span>

            <ng-container *ngIf="entity.controls.directors">
              <div class="section-spacing">
                <ng-container *ngFor="let director of entity.controls.directors.controls; let dIndex = index">
                  <div class="group-section small-group">
                    <div class="row">
                      <div class="col-12">
                        <div class="flex-content height-24">
                          <p class="mat-body-large">Director {{dIndex+1}}</p>

                          <button mat-button class="link-button no-background-link remove" type="button"
                            *ngIf="dIndex >= 1" (click)="removeDirector(dIndex)"><mat-icon
                              svgIcon="trash"></mat-icon>Remove</button>
                        </div>
                      </div>
                    </div>
                    <div class="group-section">
                      <app-form-field [formSubmitted]="form.submitted" label="First name"
                        [control]="director.controls.firstName">
                      </app-form-field>

                      <app-form-field [formSubmitted]="form.submitted" label="Middle name (optional)"
                        [control]="director.controls.middleName">
                      </app-form-field>

                      <app-form-field [formSubmitted]="form.submitted" label="Last name"
                        [control]="director.controls.lastName">
                      </app-form-field>

                      <app-form-field-date [formSubmitted]="form.submitted" label="Date of birth"
                        [control]="director.controls.birthDate" hint="DD/MM/YYYY"
                        [dateRangeType]="DateRangeType.BirthDate">
                      </app-form-field-date>

                      <app-form-field [formSubmitted]="form.submitted" label="Mobile" type="tel"
                        [control]="director.controls.mobile">
                      </app-form-field>

                      <app-form-field [formSubmitted]="form.submitted" label="Email"
                        [control]="director.controls.email">
                      </app-form-field>

                      <mat-slide-toggle [formControl]="director.controls.addAsGuarantor" hideIcon
                        *ngIf="dIndex <=2">Also add as guarantor</mat-slide-toggle>
                    </div>

                    <!-- <div class="height-24">
                      <button mat-button class="link-button no-background-link"
                        (click)="addDirectorAsGuarantor(director)" type="button" *ngIf="dIndex <=2"><mat-icon
                          svgIcon="file"></mat-icon>Copy details to guarantor section</button>
                    </div> -->
                  </div>
                </ng-container>
                <div *ngIf="entity.controls.directors.controls.length < 5" class="height-24">
                  <button mat-button class="link-button no-background-link" (click)="addDirector()"
                    type="button"><mat-icon svgIcon="add"></mat-icon>Add
                    another director</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Guarantors/Trustees -->
      <hr class="separator no-margin" />

      <div class="group-section title-section">
        <h1 class="mat-headline-large">{{entityTypeDisplay}}s</h1>
        <span class="mat-body-large">At least 1 required</span>
      </div>

      <ng-container *ngFor="let indiv of this.fg.controls.individuals.controls; let i = index">
        <ng-container class="section-spacing">
          <div class="group-section small-group">
            <div class="row">
              <div class="col-12">
                <div class="flex-content height-24">
                  <p class="mat-body-large">{{entityTypeDisplay}} {{i+1}}</p>
                  <button mat-button class="link-button no-background-link remove" type="button" *ngIf="i> 0 && indiv.enabled"
                    (click)="removeIndividual(i)"><mat-icon svgIcon="trash"></mat-icon>Remove</button>
                </div>
              </div>
            </div>
            <div class="group-section">
              <app-form-field [formSubmitted]="form.submitted" label="First name" [control]="indiv.controls.firstName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Middle name (optional)"
                [control]="indiv.controls.middleName">
              </app-form-field>
              <app-form-field [formSubmitted]="form.submitted" label="Last name" [control]="indiv.controls.lastName">
              </app-form-field>
              <app-form-field-select [control]="indiv.controls.contactType"
                *ngIf="fg.controls.loanEntityType.value == LoanEntityType.Trust"
                [listType]="SelectListType.TrusteeTypes" label="Type"></app-form-field-select>

                <app-form-field-select [control]="indiv.controls.contactType" 
                  *ngIf="fg.controls.loanEntityType.value == LoanEntityType.Other"
                  [listType]="SelectListType.OtherApplicantTypes" label="Type"></app-form-field-select>

            </div>
          </div>
        </ng-container>
      </ng-container>


      <div class="height-24" *ngIf="this.fg.controls.individuals.controls.length <3">
        @if(fg.controls.loanEntityType.value != LoanEntityType.Trust){
        <button mat-button class="link-button no-background-link" (click)="addIndividual(true, ContactType.Guarantor)"
          type="button"><mat-icon svgIcon="add"></mat-icon>Add another {{entityTypeDisplay | lowercase}}</button>
        }
        @else{
        <button mat-button class="link-button no-background-link" (click)="addIndividual(false)" type="button"><mat-icon
            svgIcon="add"></mat-icon>Add
          another {{entityTypeDisplay | lowercase}}</button>
        }
      </div>
    </ng-container>

    <div class="section-next">
      <button class="btn-next" mat-flat-button (click)="finalizeStep()">Next</button>
    </div>
  </section>
</form>