import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../shared/shared.module';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MY_DATE_FORMATS } from '../../../types/my-date-formats';
import { MomentDateAdapter, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MyErrorStateMatcher } from '../../../types/custom-error-state-matcher';

// // Depending on whether rollup is used, moment needs to be imported differently.
// // Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// // syntax. However, rollup creates a synthetic default module and we thus need to import it using
// // the `default as` syntax.
// import * as _moment from 'moment';
// // tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;

// // See the Moment.js docs for the meaning of these formats:
// // https://momentjs.com/docs/#/displaying/format/
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'LL',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@Component({
  selector: 'app-form-field-date',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  templateUrl: './form-field-date.component.html',
  styleUrl: './form-field-date.component.scss',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldDateComponent implements OnInit {
  @Input() control!: FormControl;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() formSubmitted: boolean = false;

  @Input() dateRangeType: DateRangeType = DateRangeType.Default;

  @Input() minDate: Date = new Date();
  @Input() maxDate: Date = new Date();



  constructor() {
    

  }

  ngOnInit(): void {
    console.log(this.control);

    const currentDate = new Date();
    console.log(this.dateRangeType);

    switch (this.dateRangeType) {
      case DateRangeType.Default:
        this.minDate = new Date(currentDate.getFullYear() - 100, 0, 1); // 100 years ago
        this.maxDate = new Date(currentDate.getFullYear() + 5, 11, 31); // Five years in the future
        break;

      case DateRangeType.BirthDate:
        this.minDate = new Date(currentDate.getFullYear() - 100, 0, 1); // 100 years ago
        this.maxDate = new Date(currentDate.getFullYear() - 15, 11, 31); // 15 years ago          
        break;

      case DateRangeType.PastOnly:
        this.minDate = new Date(currentDate.getFullYear() - 100, 0, 1); // 100 years ago
        this.maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Today
        break;

      case DateRangeType.VisaStartDate:
        this.minDate = new Date(currentDate.getFullYear() - 10, currentDate.getMonth(), currentDate.getDate()); // 10 years ago
        this.maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Today
        break;

        case DateRangeType.VisaEndDate:
          this.minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Today
        this.maxDate = new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate()); // Ten years in the future
          break;

      case DateRangeType.FutureOnly:
        this.minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()); // Today
        this.maxDate = new Date(currentDate.getFullYear() + 10, 11, 31); // Ten years in the future

        break;

    }

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(type, event.value);

    if (type === 'change') {
      //this.control.setValue(new Date().toISOString());
    }


    //
  }
}

export enum DateRangeType {  
  Default = 'Default',
  Custom = 'Custom',
  BirthDate = 'BirthDate',
  PastOnly = 'PastOnly',
  FutureOnly = 'FutureOnly',
  VisaStartDate = 'VisaStartDate',
  VisaEndDate = 'VisaEndDate',
}

