<mat-form-field class="form-field currency" subscriptSizing="dynamic">
    <mat-label>{{label}}</mat-label>
    <input matInput currencyMask [formControl]="control" [placeholder]="placeholder"
        [options]="{ nullable: true, align: 'left', inputMode: 1, precision: decimal_precision, min: 0 }" [type]="type">
    <mat-icon matSuffix *ngIf="formSubmitted && control.errors">error</mat-icon>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="control.errors?.['rangeError']">
        {{ control.errors?.['rangeError']?.message }}
    </mat-error>

</mat-form-field>