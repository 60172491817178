import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { SummaryReviewForm } from '../../../types/vehicle-application-types';
import { BaseStepComponent } from '../base-step/base-step.component';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '../../../shared/shared.module';
import { AsFormControlPipe } from '../../../transforms/form-control-pipe';
import { FormButtonGroupSimpleComponent } from '../../shared/form-button-group-simple/form-button-group-simple.component';
import { FormFieldNumericComponent } from '../../shared/form-field-numeric/form-field-numeric.component';
import { FormFieldComponent } from '../../shared/form-field/form-field.component';
import { VehicleAppService } from '../../../services/vehicle-app.service';

@Component({
  selector: 'app-summary-review-step',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormButtonGroupSimpleComponent,
    MatSlideToggle,
    MatIcon,
    MatOption,
    MatSelectModule,
    AsFormControlPipe
],
  templateUrl: './summary-review-step.component.html',
  styleUrl: './summary-review-step.component.scss'
})
export class SummaryReviewStepComponent extends BaseStepComponent implements OnInit {

  @Input() fg!: FormGroup<SummaryReviewForm>;

  @Input() parentStepper!: MatStepper;
  @Input() formSubmitted!: boolean;

  UserAction = UserAction;
  
  constructor(vehicleAppService: VehicleAppService) {
    super(vehicleAppService);
  }
  
  override ngOnInit(): void {
    super.ngOnInit();
  }


}
