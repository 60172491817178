<form [formGroup]="fg" #form="ngForm">
    <section>
        <div class="group-section title-section">
            <h1 class="mat-headline-large">{{entityTypeCaption}} address details</h1>
        </div>

        <div class="group-section ">
            <!-- Physical address -->
            <app-address [fg]="fg.controls.addressPhysical" label="Physical Address" 
                (addressChanged)="physicalAddressChanged($event)"></app-address>

            <mat-slide-toggle [formControl]="fg.controls.addressPostalIsSame" hideIcon>Postal
                same as physical</mat-slide-toggle>
        </div>
        <div class="group-section">
            <!-- Postal address -->
            <ng-container *ngIf="fg.controls.addressPostalIsSame.value == false">
                <app-address [fg]="fg.controls.addressPostal" label="Postal Address"></app-address>
            </ng-container>
        </div>
      
        <!-- <div class="group-section">
            <app-form-field [formSubmitted]="form.submitted" label="Mobile phone" [control]="fg.controls.mobileNumber" type="tel">
            </app-form-field>

            <app-form-field [formSubmitted]="form.submitted" label="Work phone" [control]="fg.controls.daytimeNumber" type="tel">
            </app-form-field>

            <app-form-field [formSubmitted]="form.submitted" label="Email address" [control]="fg.controls.emailAddress">
            </app-form-field>
        </div> -->
        <div>
            <!-- <button mat-button matStepperNext>Next</button> -->
            <button class="btn-next" mat-flat-button (click)="onButtonNext(fg, parentStepper)">Next</button>
        </div>
    </section>
</form>