import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PublicApplicationService, PublicVehicleApplicationService, VehicleApplicationService } from '../../../api/services';
import { CommonUtilitiesApplicationExpenseType, ModelsV3ApplicationApplicationRequest, ModelsV3ApplicationApplicationStartRequest, ModelsV3ApplicationEmploymentDetailsUnemploymentStatusEnum, ModelsV3ApplicationFinancialDetailsExpenseDetail, ModelsV3ApplicationFinancialDetailsIncomeDetail, ModelsV3ApplicationFinancialDetailsIncomeType, ModelsV3ApplicationGenderEnum, ModelsV3ApplicationIdentificationDriverLicenseDriverLicenseTypeConditionalEnum, ModelsV3ApplicationIdentificationDriverLicenseDriverLicenseTypeEnum, ModelsV3ApplicationIdentificationIdentificationTypeEnum, ModelsV3ApplicationLoanDetailLoanInformationRequest, ModelsV3ApplicationPersonalDetailsAddressRequest, ModelsV3ApplicationPersonalDetailsCommunicationEnum, ModelsV3ApplicationPersonalDetailsMaritalStatusEnum, ModelsV3ApplicationVehicleDetailsVehicleDetailsRequest } from '../../../api/models';
import { SharedModule } from '../../../shared/shared.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { ApplicationSubmissionOptions } from '../../../api/models/CommonUtilities/application-submission-options';
import { ContactType } from '../../../api/models/CommonUtilities/contact-type';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserAction } from '../../../api/models/CommonUtilities/user-action';
import { ApplicationFormControlNames } from '../../../types/applicationFromControlNames';
import { VehicleClass } from '../../../api/models/CommonUtilities/vehicle-class';
import { ErrorService } from '../../../services/error.service';
import { StepOtpComponent } from '../../../components/step-otp/step-otp.component';
import { OtpEventArgs } from '../../../dataobject/otp-event-args';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ApplicationUpdateVehicleResponse } from '../../../api/models/Models/v3/Application/Public/application-update-vehicle-response';
import { IndividualApplicantComponent } from "../../../components/vehicle-finance/individual-applicant/individual-applicant.component";
import { PeriodTypeEnum } from '../../../api/models/Models/v3/Application/FinancialDetails/period-type-enum';
import { BankStatementCommunicationMethod } from '../../../api/models/CommonUtilities/bank-statement-communication-method';
import { BankStatementSourceEnum } from '../../../api/models/Models/v3/Application/FinancialDetails/bank-statement-source-enum';
import { LivingSituationEnum } from '../../../api/models/Models/v3/Application/PersonalDetails/living-situation-enum';
import { FormFieldComponent } from '../../../components/shared/form-field/form-field.component';
import { conditionalRequiredValidator, dateValidatorMoment, conditionalRequiredValidatorMultiParentDependent, employmentSectionRequiredValidator, incomeSectionRequiredValidator, phoneNumberValidator, numericRangeValidator, nzDriversLicenceValidator, conditionalRequiredValidatorMulti, conditionalRequiredValidatorTraverseFg, conditionalRequiredValidator2, livingPaymentAmountValidator, conditionalRequiredValidatorMulti2 } from '../../../types/validators';
import { FormFieldNumericComponent } from '../../../components/shared/form-field-numeric/form-field-numeric.component';
import { FormButtonGroupSimpleComponent } from '../../../components/shared/form-button-group-simple/form-button-group-simple.component';
import { AboutStepComponent } from '../../../components/vehicle-finance/about-step/about-step.component';
import { EmploymentStatus } from '../../../api/models/Models/v3/Application/EmploymentDetails/employment-status';
import { LoanTypeStepComponent } from '../../../components/vehicle-finance/loan-type-step/loan-type-step.component';
import { LoanEntityType } from '../../../api/models/CommonUtilities/loan-entity-type';
import { BaseStepComponent } from '../../../components/vehicle-finance/base-step/base-step.component';
import { AsFormArrayPipe } from '../../../transforms/form-array-pipe';
import { IndividualRequest } from '../../../api/models/Models/v3/Application/individual-request';
import { AddressRequest } from '../../../api/models/Models/v3/Application/PersonalDetails/address-request';
import { MatIcon } from '@angular/material/icon';
import { AddressForm, AssetCashForm, AssetOtherForm, AssetPropertyForm, AssetVehicleForm, CreditFacilityForm, ExpenseDetailForm, FinanceCommitmentForm, IncomeDetailForm, IndividualCommonDataForm, LiabilitiesForm, Step1PersonalDetailsForm, Step2PersonalIdentificationForm, Step3EmploymentDetailsForm, Step4LivingExpensesForm, Step5AssetsForm, VehicleIndividualForm } from '../../../types/vehicleIndividualSteps';
import { IdentificationTypeEnum } from '../../../api/models/Models/v3/Application/Identification/identification-type-enum';
import { FormFieldDateComponent } from '../../../components/shared/form-field-date/form-field-date.component';
import { AsFormControlPipe } from "../../../transforms/form-control-pipe";
import { NonResidentVisaTypeEnum } from '../../../api/models/Models/v3/Application/Identification/non-resident-visa-type-enum';
import moment from 'moment';
import { VehicleAppService } from '../../../services/vehicle-app.service';
import { AboutForm, EntityCommonForm, EntityContactDetailForm, EntityForm, EntityIncomeExpensesForm, LoanTypeEntityDirectorForm, LoanTypeForm, LoanTypeIndividualForm } from '../../../types/vehicle-application-types';
import { PublicPaymentDetail } from '../../../api/models/Models/v3/Application/Public/Financial/public-payment-detail';
import { CreditFacilityDetail } from '../../../api/models/Models/v3/Application/FinancialDetails/credit-facility-detail';
import { FinancialCommitment } from '../../../api/models/Models/v3/Application/FinancialDetails/financial-commitment';
import { IncomeDetail } from '../../../api/models/Models/v3/Application/FinancialDetails/income-detail';
import { SummaryReviewStepComponent } from '../../../components/vehicle-finance/summary-review-step/summary-review-step.component';
import { ButtonNextEventArgs } from '../../../dataobject/button-next-event-args';
import { ApplicationCompletedComponent } from '../../../components/application-completed/application-completed.component';
import { OtherFinanceCommitmentType } from '../../../api/models/Models/v3/Application/FinancialDetails/other-finance-commitment-type';
import { environment } from '../../../../environments/environment';
import { VehicleDetailsRequest } from '../../../api/models/Models/v3/Application/VehicleDetails/vehicle-details-request';
import { MaritalStatus } from '../../../api/models/CommonUtilities/marital-status';
import { Condition } from '../../../dataobject/condition';

import { CodeInputModule } from 'angular-code-input';
import { BehaviorSubject } from 'rxjs';
import { EntityDetailsRequest } from '../../../api/models/Models/v3/Application/Customer/entity-details-request';
import { ContactDetailComponent } from "../../../components/vehicle-finance/entity/contact-detail/contact-detail.component";
import { IncomeExpensesComponent } from '../../../components/vehicle-finance/entity/income-expenses/income-expenses.component';
import { BusinessTypeEnum } from '../../../api/models/Models/v3/Application/business-type-enum';
import { PaymentDetail } from '../../../api/models/Models/v3/Application/FinancialDetails/payment-detail';
import { EntityDirectorDetailRequest } from '../../../api/models/Models/v3/Application/Customer/entity-director-detail-request';
import { MessageService } from '../../../services/message.service';
import { NzPassportDetails } from '../../../api/models/Models/v3/Application/Identification/Passport/nz-passport-details';
import { IdentificationTypeOtherEnum } from '../../../api/models/Models/v3/Application/Identification/identification-type-other-enum';
import { OtherIdentificationDetails } from '../../../api/models/Models/v3/Application/Identification/other-identification-details';
import { OverseasPassportDetails } from '../../../api/models/Models/v3/Application/Identification/Passport/overseas-passport-details';
import { Country } from '../../../api/models/CommonUtilities/country';

@Component({
  selector: 'app-vehicle-finance-view',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonToggleModule,
    MatProgressBar,
    FormFieldComponent,
    FormFieldNumericComponent,
    FormButtonGroupSimpleComponent,
    FormFieldDateComponent,
    AsFormArrayPipe,
    MatIcon,
    StepOtpComponent,
    IndividualApplicantComponent,
    AboutStepComponent,
    LoanTypeStepComponent,
    AsFormControlPipe,
    SummaryReviewStepComponent,
    ApplicationCompletedComponent,
    CodeInputModule,
    ContactDetailComponent,
    IncomeExpensesComponent
  ],
  templateUrl: './vehicle-finance-view.component.html',
  styleUrl: './vehicle-finance-view.component.scss',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class VehicleFinanceViewComponent extends BaseStepComponent implements OnInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChildren(IndividualApplicantComponent) applicantIndividualComponents!: QueryList<IndividualApplicantComponent>;
  @ViewChild(StepOtpComponent) stepOtpComponent!: StepOtpComponent;

  isFirstStep: boolean = true;
  isSubmitted: boolean = false;

  loading: boolean = false;


  environment = environment;

  loanApplicationResponse: ApplicationUpdateVehicleResponse = {
    loanInformation: {
      hasDeposit: UserAction.Unspecified,
      hasTradeInVehicle: UserAction.Unspecified,
      hasVehicle: UserAction.Unspecified,
      isBusinessLoan: UserAction.Unspecified,
      loanAmount: 0,
      loanTermMonths: 36,
      depositValue: 0,
      isVehicleTradeInFinanced: UserAction.Unspecified,
      vehicles: [{
        vehicleClass: VehicleClass.PassengerVehicle
      }],
      allowLowerAmountApprovalCheck: false,
      loanEntityType: LoanEntityType.Individual,
    }
  };


  errorMessages: string[] = [];



  ApplicationFormControlNames = ApplicationFormControlNames;
  UserAction = UserAction;

  //aboutFg!: FormGroup<AboutForm>;
  //loanTypeFg!: FormGroup<LoanTypeForm>;
  //indivFormArray!: FormArray<FormGroup<VehicleIndividualForm>>


  constructor(
    private publicVehicleApplicationService: PublicVehicleApplicationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private publicApplicationService: PublicApplicationService,
    private errorService: ErrorService,
    public override vehicleAppService: VehicleAppService,
    public messageService: MessageService
  ) {
    super(vehicleAppService);
    this.messageService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  completeNestedStepper(stepper: MatStepper) {
    var steps = stepper.steps.toArray();
    var currentStep = steps[stepper.selectedIndex - 1];

    if (currentStep.interacted && currentStep.stepControl.valid) {
      console.log('last step isvalid and next clicked');
      this.stepper.next();
    }
  }

  previousStep(sourceStepper: MatStepper) {
    console.log('previous step');

    var isOnNestedStepperStep = false;
    this.applicantIndividualComponents.forEach(indCom => {
      var childComponentParentStepIndex = indCom.getParentStepIndex();
      if (sourceStepper.selectedIndex == childComponentParentStepIndex) {

        indCom.goBack();
        isOnNestedStepperStep = true;
        this.vehicleAppService.moveCurrentStepCountBack();
      }
    });

    if (!isOnNestedStepperStep) {
      console.log(sourceStepper.selectedIndex);
      if (sourceStepper.selectedIndex > 0) {
        if (sourceStepper.selectedIndex == 1) {
          this.isFirstStep = true;
          this.vehicleAppService.setCurrentStep(1);
        } else {
          this.vehicleAppService.moveCurrentStepCountBack();
        }

        this.stepper.previous();

      } else {
        this.isFirstStep = true;
        this.vehicleAppService.setCurrentStep(1);
      }
    }
  }

  otpResponse: string = '';

  isSubmitting: boolean = false;

  onButtonNextCompleted(eventArgs: ButtonNextEventArgs) {
    var currentIndex = eventArgs.stepper.selectedIndex;
    var steps = eventArgs.stepper.steps.toArray();

    //check if is last step and trigger the parent stepper next
    if (eventArgs.fg.valid) {
      eventArgs.stepper.next();
      this.vehicleAppService.moveCurrentStepCountForward();
    }
  }

  onFinalStepNextCompleted(eventArgs: ButtonNextEventArgs) {
    if (eventArgs.fg.valid) {
      this.submit();
    }

  }
  submit() {
    if (!this.isSubmitting) {
      try {
        this.loading = true;
        this.isSubmitting = true;
        this.errorMessages = [];

        if (this.vehicleAppService.vehicleApplicationFg != null) {
          const urlParams = new URLSearchParams(window.location.search);
          const stockNo = urlParams.get('stockNo');          

          let loanRequest = {
            applicationSubmissionOptions: ApplicationSubmissionOptions.AttemptAutoApproval,
            legalCompliance: {
              privacyWaiver: this.vehicleAppService.otpEventArgs.privacyPolicyConsented,
              electroMarketChk: false,
              disclosureChk: false,
              creditCheckConsented: this.vehicleAppService.otpEventArgs.creditCheckConsented,
            },
            applicants: {
              individuals: this.mapIndividuals(),
              entities: this.mapEntities(),
            },
            productDetails: {
              extras: [],
              products: [],
            },
            loanInformation: {
              loanTermMonths: 36,
              hasVehicle: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.hasVehicle.value,
              loanAmount: Number(this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.loanAmount.value),
              allowLowerAmountApprovalCheck: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.approveLesserAmount.value,
              hasDeposit: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.hasDeposit.value,
              depositValue: Number(this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.depositAmount.value),
              loanEntityType: this.vehicleAppService.vehicleApplicationFg.loanTypeFg.controls.loanEntityType.value,
              isBusinessLoan: UserAction.No,
              hasTradeInVehicle: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.hasTradeInVehicle.value,
              isVehicleTradeInFinanced: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.isVehicleTradeInFinanced.value,
              vehicleTradeInDetails: this.mapTradeInVehicle(),
              vehicles: [
                {
                  vehicleClass: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.purchaseVehicleType.value,
                  hasOtherItems: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.hasOtherItems.value,
                  otherItemsValue: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.otherItemsCost.value,
                  encumbrance: 0,
                  encumbranceFinancier: '',
                  isBrandNew: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehicleIsBrandNew.value,
                  make: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehicleMake.value,
                  model: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehicleModel.value,
                  subModel: '',
                  price: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehiclePrice.value,
                  manufactureYear: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehicleYear.value,
                  stockId: (stockNo !== null && !isNaN(Number(stockNo))) ? Number(stockNo) : null,                  
                  plateNumber: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.vehiclePlateVin.value,
                }],
            },
          } as ModelsV3ApplicationApplicationRequest;

          // if (loanRequest.applicants?.entities && loanRequest.applicants.entities.length > 0 && loanRequest.loanInformation) {
          //   loanRequest.loanInformation.isBusinessLoan = UserAction.Yes;
          // }
          if (loanRequest.loanInformation) {
            switch (this.vehicleAppService.vehicleApplicationFg.loanTypeFg.controls.loanEntityType.value) {
              case LoanEntityType.Company:
              case LoanEntityType.Trust:
              case LoanEntityType.Partner:
              case LoanEntityType.SoleTrader:
              case LoanEntityType.Other:
                loanRequest.loanInformation.isBusinessLoan = UserAction.Yes;
                break;
              default:
                loanRequest.loanInformation.isBusinessLoan = UserAction.No;
                break;
            }

            //loanRequest.loanInformation.isBusinessLoan = this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.isBusinessPurpose.value!;
          }

          // Map the assets
          if (this.vehicleAppService.vehicleApplicationFg.indivFormArray) {
            if (this.vehicleAppService.vehicleApplicationFg.indivFormArray.length > 0) {

              var firstIndividual = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[0] as FormGroup<VehicleIndividualForm>;

              loanRequest.assetDetails = {
                isPropertyOwner: UserAction.No,
                hasAdditionalCashInvestments: UserAction.No,
                hasAdditionalVehicleAssets: UserAction.No,
                hasOtherAssets: UserAction.No,
                propertyAssets: [],
                otherAssets: [],
                vehicleAssets: [],
                cashInvestmentAssets: [],
              }

              this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls.forEach((individual) => {
                //propertyAssets
                individual.controls.step5.controls.propertyAssets.controls.forEach((propertyAsset) => {
                  // only add property if it hasn't already been added by another individual
                  var found = loanRequest.assetDetails?.propertyAssets?.find(x => x.description == propertyAsset.controls.description.value);
                  if (!found) {
                    loanRequest.assetDetails?.propertyAssets?.push({
                      description: propertyAsset.controls.description.value,
                      value: Number(propertyAsset.controls.value.value)
                    });
                  }
                });

                // otherAssets
                individual.controls.step5.controls.otherAssets.controls.forEach((otherAsset) => {
                  // Only add the other asset if it is unique
                  var found = loanRequest.assetDetails?.otherAssets?.find(x => x.description == otherAsset.controls.description.value && x.value == Number(otherAsset.controls.value.value));
                  if (!found) {
                    loanRequest.assetDetails?.otherAssets?.push({
                      description: otherAsset.controls.description.value,
                      value: Number(otherAsset.controls.value.value)
                    });
                  }
                });

                // vehicle asssets
                individual.controls.step5.controls.vehicleAssets.controls.forEach((vehicleAsset) => {
                  loanRequest.assetDetails?.vehicleAssets?.push({
                    plateNumber: vehicleAsset.controls.plateVin.value,
                    vehicleClass: vehicleAsset.controls.vehicleClass.value!,
                    useAsLoanSecurity: vehicleAsset.controls.useAsLoanSecurity.value ? UserAction.Yes : UserAction.No,
                    value: Number(vehicleAsset.controls.value.value),
                    description: vehicleAsset.controls.description.value,
                    make: null,
                    manufacturerYear: null,
                    model: null,
                    ownerIdentifiers: [individual.controls.common.controls.id.value!],
                  });
                });


                // cash investments
                individual.controls.step5.controls.cashInvestmentAssets.controls.forEach((cashAsset) => {
                  var found = loanRequest.assetDetails?.cashInvestmentAssets?.find(x => x.investmentType == cashAsset.controls.type.value && x.value == Number(cashAsset.controls.value.value));
                  if (!found) {
                    loanRequest.assetDetails?.cashInvestmentAssets?.push({
                      investmentType: cashAsset.controls.type.value!,
                      value: Number(cashAsset.controls.value.value)
                    });
                  }
                });
              });

              loanRequest.assetDetails.hasAdditionalCashInvestments = loanRequest.assetDetails.cashInvestmentAssets!.length > 0 ? UserAction.Yes : UserAction.No;
              loanRequest.assetDetails.hasAdditionalVehicleAssets = loanRequest.assetDetails.vehicleAssets!.length > 0 ? UserAction.Yes : UserAction.No;
              loanRequest.assetDetails.hasOtherAssets = loanRequest.assetDetails.otherAssets!.length > 0 ? UserAction.Yes : UserAction.No;

              // Only the "first individual" is used to determine if the applicant is a property owner
              // This is due to the assets only applying to the first applicant in ALF
              loanRequest.assetDetails.isPropertyOwner = firstIndividual.controls.step5.controls.isPropertyOwner.value!;
              //loanRequest.assetDetails.isPropertyOwner = loanRequest.assetDetails.propertyAssets!.length > 0 ? UserAction.Yes : UserAction.No;

              // firstIndividual.controls.step5.controls.propertyAssets.controls.forEach((propertyAsset) => {
              //   loanRequest.assetDetails?.propertyAssets?.push({
              //     description: propertyAsset.controls.description.value,
              //     value: Number(propertyAsset.controls.value.value)
              //   });
              // });

              // firstIndividual.controls.step5.controls.otherAssets.controls.forEach((otherAsset) => {
              //   loanRequest.assetDetails?.otherAssets?.push({
              //     description: otherAsset.controls.description.value,
              //     value: Number(otherAsset.controls.value.value)
              //   });
              // });

              // firstIndividual.controls.step5.controls.vehicleAssets.controls.forEach((vehicleAsset) => {
              //   loanRequest.assetDetails?.vehicleAssets?.push({
              //     plateNumber: vehicleAsset.controls.plateVin.value,
              //     vehicleClass: vehicleAsset.controls.vehicleClass.value!,
              //     useAsLoanSecurity: vehicleAsset.controls.useAsLoanSecurity.value ? UserAction.Yes : UserAction.No,
              //     value: Number(vehicleAsset.controls.value.value),
              //     description: vehicleAsset.controls.description.value,
              //     make: null,
              //     manufacturerYear: null,
              //     model: null,
              //     ownerIdentifiers: null,
              //   });
              // });

              // firstIndividual.controls.step5.controls.cashInvestmentAssets.controls.forEach((cashAsset) => {
              //   loanRequest.assetDetails?.cashInvestmentAssets?.push({
              //     investmentType: cashAsset.controls.type.value!,
              //     value: Number(cashAsset.controls.value.value)
              //   });
              // });
            }
          }

          console.log('Saving application', loanRequest);

          this.publicVehicleApplicationService.v3PublicApplicationVehicleApplicationIdPut$Json({
            applicationId: this.vehicleAppService.newApplicationResponse.publicId!,
            body: loanRequest
          })
            .subscribe({
              next: (response) => {
                if (environment.debug) {
                  this.snackBar.open('Application submitted', 'Close', {
                    duration: 3000,
                    panelClass: ['mat-toolbar', 'mat-warn']
                  });
                }
                console.log('App submitted');

                this.isSubmitted = !environment.debug;
                this.isSubmitting = false;
                this.loading = false;
              },
              error: (errorResponse) => {
                this.errorService.handleError(errorResponse);
                this.isSubmitting = false;
                this.loading = false;
              }
            });
        }
      } catch (error) {
        this.isSubmitting = false;
        this.loading = false;
        this.errorService.showError('An error occurred while submitting the application');

      }
    }
  }

  private mapTradeInVehicle(): VehicleDetailsRequest | null {
    if (this.vehicleAppService.vehicleApplicationFg) {
      return {
        plateNumber: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.plate.value,
        encumbrance: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.encumbrance.value,
        manufactureYear: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.year.value,
        make: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.make.value,
        model: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.model.value,
        price: this.vehicleAppService.vehicleApplicationFg.aboutFg.controls.tradeInVehicle.controls.price.value,
      };
    }
    return null;
  }


  private mapEntities(): EntityDetailsRequest[] {
    var entities: EntityDetailsRequest[] = [];

    const application = this.vehicleAppService.vehicleApplicationFg;
    if (application && application.entityFg) {
      var primaryIndividual = application?.indivFormArray?.controls[0] as FormGroup<VehicleIndividualForm>;
      const entity = application.entityFg!;

      var entityOut = {
        actingOnBehalfOfId: primaryIndividual.controls.common.controls.id.value!,
        businessIncome: undefined,
        applicantType: ContactType.Primary,
        legalName: application.loanTypeFg.controls.entities.controls[0].controls.legalName.value,
        isEntityTrading: entity.controls.incomeExpensesFg.controls.isBusinessTrading.value,
        tradingName: entity.controls.incomeExpensesFg.controls.tradingName.value,
        nzbn: application.loanTypeFg.controls.entities.controls[0].controls.nzbn.value,
        annualRevenue: 0,
        businessDurationMonths: 0,
        businessType: BusinessTypeEnum.Other,
        directors: this.mapDirectors(application.loanTypeFg.controls.entities.controls[0].controls.directors),
        hasIncomeLiabilities: UserAction.Unspecified,
        address: this.mapAddress(entity.controls.contactDetailFg.controls.addressPhysical),
        isPostalAddressSame: (entity.controls.contactDetailFg.controls.addressPostalIsSame.value ? UserAction.Yes : UserAction.No),
        addressPostal: this.mapAddress(entity.controls.contactDetailFg.controls.addressPostal),
      } as EntityDetailsRequest;

      if (entity?.controls.incomeExpensesFg.controls.isBusinessTrading.value == UserAction.Yes) {
        entityOut.hasIncomeLiabilities = UserAction.Yes;

        entityOut.businessIncome = {
          amount: Number(entity.controls.incomeExpensesFg.controls.incomeAmount.value),
          period: entity.controls.incomeExpensesFg.controls.incomeFrequency.value!
        } as PaymentDetail;

        entityOut.businessExpenses = {
          amount: Number(entity.controls.incomeExpensesFg.controls.expenseAmount.value),
          period: entity.controls.incomeExpensesFg.controls.expenseFrequency.value!
        } as PaymentDetail;
        entityOut.industryClassification = entity.controls.incomeExpensesFg.controls.industryClassification.value!;
        entityOut.hasOffshoreIncome = entity.controls.incomeExpensesFg.controls.isIncomeOffshore.value!;

      } else {
        entityOut.hasOffshoreIncome = UserAction.Unspecified;
        //entityOut.industryClassification = null;
      }

      switch (application.loanTypeFg.controls.loanEntityType.value) {
        case LoanEntityType.Company:
          entityOut.businessType = BusinessTypeEnum.Company;
          break;
        case LoanEntityType.Trust:
          entityOut.businessType = BusinessTypeEnum.Trust;
          break;
        default:
          entityOut.businessType = BusinessTypeEnum.Other;
          break;
      }


      entities.push(entityOut);
    }

    return entities;
  }

  private mapDirectors(directorsIn: FormArray<FormGroup<LoanTypeEntityDirectorForm>>): EntityDirectorDetailRequest[] {
    var directors: EntityDirectorDetailRequest[] = [];
    if (directorsIn) {
      for (let index = 0; index < directorsIn.length; index++) {
        const director = directorsIn.controls[index] as unknown as FormGroup<LoanTypeEntityDirectorForm>;
        directors.push({
          firstName: director.controls.firstName.value!,
          lastName: director.controls.lastName.value!,
          middleName: director.controls.middleName.value!,
          emailAddress: director.controls.email.value!,
          mobileNumber: director.controls.mobile.value!,
          mobileCountry: Country.Newzealand,
          birthDate: this.formatDateOnly(director.controls.birthDate.value),
          isGuarantor: this.mapBooleanToUserAction(director.controls.addAsGuarantor.value!)
        });
      }
    }

    return directors;
  }

  private mapIndividuals(): IndividualRequest[] {
    var individuals: IndividualRequest[] = [];
    if (this.vehicleAppService.vehicleApplicationFg?.indivFormArray) {
      for (let index = 0; index < this.vehicleAppService.vehicleApplicationFg.indivFormArray.length; index++) {
        const applicant = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[index] as FormGroup<VehicleIndividualForm>;

        var mappedApplicant = this.mapIndividualRequest(applicant);
        if (mappedApplicant != null) {
          var appFg = this.vehicleAppService.vehicleApplicationFg;
          if (mappedApplicant.financialCommitments) {
            mappedApplicant.financialCommitments.hasFutureUpcomingChanges = this.mapBooleanToUserAction(appFg.summaryReviewFg.controls.futureUpcomingChangesDescription.value != '');
            mappedApplicant.financialCommitments.futureUpcomingChangesDescription = appFg.summaryReviewFg.controls.futureUpcomingChangesDescription.value;
          }
        }
        individuals.push(mappedApplicant)
      }
    }
    return individuals;
  }

  private mapIndividualRequest(fgApplicant: FormGroup<VehicleIndividualForm>): IndividualRequest {
    // debugger;

    var result = {
      customerDetails: {
        applicantType: fgApplicant.controls.step1.controls.contactType.value!,
        preferredName: fgApplicant.controls.step1.controls.firstName.value,
        email: fgApplicant.controls.step1.controls.emailAddress.value,
        mobileNumber: fgApplicant.controls.step1.controls.mobileNumber.value,
        birthDate: this.formatDateOnly(fgApplicant.controls.step2.controls.birthDate.value),
        daytimeNumber: fgApplicant.controls.step1.controls.daytimeNumber.value,
        firstName: fgApplicant.controls.step1.controls.firstName.value,
        gender: fgApplicant.controls.step2.controls.gender.value!,
        identifier: fgApplicant.controls.common.controls.id.value!,
        lastName: fgApplicant.controls.step1.controls.lastName.value,
        middleName: fgApplicant.controls.step1.controls.middleName.value,
        //title:CustomerTitleEnum.
      },
      employmentDetails: {
        employmentStatus: fgApplicant.controls.step3.controls.employmentStatus.value!,
        employmentBasis: fgApplicant.controls.step3.controls.employmentBasis.value!,
        role: fgApplicant.controls.step3.controls.role.value!,
        industryClassification: fgApplicant.controls.step3.controls.industryClassification.value!,
        employerName: fgApplicant.controls.step3.controls.employerName.value,
        timeAtEmploymentStatus: Number(fgApplicant.controls.step3.controls.durationMonths.value),
        income: this.mapIncome(fgApplicant.controls.step3),
        kiwiSaverRate: fgApplicant.controls.step3.controls.kiwiSaverRate.value!,
        hasStudentLoan: fgApplicant.controls.step3.controls.hasStudentLoan.value!,
        hasOtherIncome: fgApplicant.controls.step3.controls.hasOtherIncome.value!,
        otherIncome: this.mapOtherIncome(fgApplicant.controls.step3.controls.otherIncome),
        isStudentEmployed: fgApplicant.controls.step3.controls.isStudentEmployed.value!,
        otherEmploymentDescription: fgApplicant.controls.step3.controls.otherEmploymentDescription.value,
        unemploymentStatus: fgApplicant.controls.step3.controls.unemploymentStatus.value!,
        studentReceivesAllowance: fgApplicant.controls.step3.controls.studentReceivesAllowance.value!,
        hasIncomeSource: fgApplicant.controls.step3.controls.employmentStatus.value! == EmploymentStatus.Unemployed ? UserAction.Yes : UserAction.No
      },
      financialCommitments: {
        hasCreditFacilities: fgApplicant.controls.liabilitiesFg.controls.hasCreditFacilities.value!,
        otherFinancialCommitments: this.mapOtherFinancialCommitments(fgApplicant.controls.liabilitiesFg.controls.financeCommitments),
        hasOtherFinancialCommitments: fgApplicant.controls.liabilitiesFg.controls.hasOtherFinancialCommitments.value!,
        creditFacilities: this.mapCreditFacilities(fgApplicant.controls.liabilitiesFg.controls.creditFacilities),

        bankStatementSource: BankStatementSourceEnum.ProvideLater,
        bankStatementReference: '',
        bankStatementCommunicationMethod: BankStatementCommunicationMethod.Email,

        hasFutureUpcomingChanges: UserAction.Unspecified,
        futureUpcomingChangesDescription: '',

      },
      identification: {
        residency: {
          hasResidency: fgApplicant.controls.step2.controls.nzResident.value!,
          visaExpiryDate: this.formatDateOnly(fgApplicant.controls.step2.controls.visaExpiryDate.value),
          birthCountry: fgApplicant.controls.step2.controls.birthCountry.value!,
          citizenshipCountry: fgApplicant.controls.step2.controls.citizenshipCountry.value!,
          visaStartDate: this.formatDateOnly(fgApplicant.controls.step2.controls.visaStartDate.value),
          visaNumber: fgApplicant.controls.step2.controls.visaNumber.value,
          visaType: fgApplicant.controls.step2.controls.visaType.value!,
        },
        type: fgApplicant.controls.step2.controls.identificationType.value!,
        // temporaryLicense:{},
      },
      livingExpenses: {
        expenses: this.mapLivingExpenses(fgApplicant.controls.step4.controls.otherExpenses),
        expensesSharedWithPartner: fgApplicant.controls.step4.controls.expensesSharedWithPartner.value!,
        partnerGrossIncome: Number(fgApplicant.controls.step4.controls.partnerGrossIncome.value),
      },
      personalDetails: {
        address: this.mapAddress(fgApplicant.controls.step1.controls.addressPhysical),
        previousAddress: fgApplicant.controls.step1.controls.addressPrevious != undefined ? this.mapAddress(fgApplicant.controls.step1.controls.addressPrevious) : undefined,
        addressPostal: this.mapAddress(fgApplicant.controls.step1.controls.addressPostal),
        dependentsCount: Number(fgApplicant.controls.step2.controls.dependentsNo.value),
        isPostalAddressSame: (fgApplicant.controls.step1.controls.addressPostalIsSame.value ? UserAction.Yes : UserAction.No),
        livingPayment: this.mapLivingPayment(fgApplicant),
        maritalStatus: fgApplicant.controls.step2.controls.maritalStatus.value!,
        mobileNumber: fgApplicant.controls.step1.controls.mobileNumber.value,
        preferredCommunication: ModelsV3ApplicationPersonalDetailsCommunicationEnum.Email,
        isPartnerOnApplication: fgApplicant.controls.step2.controls.isPartnerOnApplication.value!,
        partnerIdentifier: Number(fgApplicant.controls.step2.controls.partnerIdentifier.value),
        isAddressProvidedByPartner: fgApplicant.controls.step1.controls.isAddressProvidedByPartner.value!,
      }
    } as IndividualRequest;

    if (result.identification) {
      switch (fgApplicant.controls.step2.controls.identificationType.value) {
        case IdentificationTypeEnum.NzDriverLicense:
          result.identification.driverLicense = {
            number: fgApplicant.controls.step2.controls.driverLicenceNo.value!,
            type: ModelsV3ApplicationIdentificationDriverLicenseDriverLicenseTypeEnum.Full,
            version: fgApplicant.controls.step2.controls.driverLicenceVersion.value!,
            //conditionalType:  ModelsV3ApplicationIdentificationDriverLicenseDriverLicenseTypeConditionalEnum.
          };
          break;
        case IdentificationTypeEnum.NzPassport:
          result.identification.passport = {
            expiryDate: this.formatDateOnly(fgApplicant.controls.step2.controls.passportNzExpiryDate.value),
            number: fgApplicant.controls.step2.controls.passportNzNumber.value,
          } as NzPassportDetails;
          break;
        case IdentificationTypeEnum.OverseasPassport:
          result.identification.overseasPassport = {
            expiryDate: this.formatDateOnly(fgApplicant.controls.step2.controls.passportOtherExpiryDate.value),
            number: fgApplicant.controls.step2.controls.passportOtherNumber.value,
            countryOfIssue: fgApplicant.controls.step2.controls.passportOtherIssuerCountry.value,
          } as OverseasPassportDetails;
          break;
        case IdentificationTypeEnum.Other:
          result.identification.otherIdDetails = {
            description: fgApplicant.controls.step2.controls.otherIdDescription.value,
            number: fgApplicant.controls.step2.controls.otherIdNumber.value,
            type: fgApplicant.controls.step2.controls.otherIdType.value,
          } as OtherIdentificationDetails;
          break;
      }
    }
    return result;
  }

  reverseUserAction(userAction: UserAction | null): UserAction | undefined {
    if (userAction) {
      switch (userAction) {
        case UserAction.Yes:
          return UserAction.No;
        case UserAction.No:
          return UserAction.Yes;
      }
    }
    return undefined;
  }

  mapOtherFinancialCommitments(fa: FormArray<FormGroup<FinanceCommitmentForm>>): Array<FinancialCommitment> | null {

    var financialCommitments: FinancialCommitment[] = [];
    if (fa) {
      for (let index = 0; index < fa.length; index++) {
        const financialCommitment = fa.controls[index] as unknown as FormGroup<FinanceCommitmentForm>;

        var canAdd = true;
        if (financialCommitment.controls.type.value == OtherFinanceCommitmentType.HomeLoan && financialCommitment.controls.amountPerPeriod.disabled) {
          // If the home loan is not editable, then it has been added using the livingPayment section, so do not add it again here
          canAdd = false;
        }

        if (canAdd) {
          financialCommitments.push({
            type: financialCommitment.controls.type.value!,
            lenderName: financialCommitment.controls.provider.value!,
            balance: Number(financialCommitment.controls.balance.value),
            amount: Number(financialCommitment.controls.amountPerPeriod.value),
            period: financialCommitment.controls.paymentPeriod.value!,
            repaymentsSharedWithPartner: financialCommitment.controls.repaymentsSharedWithPartner.value!,
          });

        }
      }
      return financialCommitments;
    }
    return null;
  }

  mapCreditFacilities(fa: FormArray<FormGroup<CreditFacilityForm>>): Array<CreditFacilityDetail> | null {
    var creditFacilities: CreditFacilityDetail[] = [];
    if (fa) {
      for (let index = 0; index < fa.length; index++) {
        const creditFacility = fa.controls[index] as unknown as FormGroup<CreditFacilityForm>;

        creditFacilities.push({
          balance: Number(creditFacility.controls.balance.value),
          bankName: creditFacility.controls.provider.value!,
          creditLimit: Number(creditFacility.controls.creditLimit.value),
          facilityType: creditFacility.controls.type.value!,
          repaymentsSharedWithPartner: creditFacility.controls.repaymentsSharedWithPartner.value!,
          isPaidInFullEachMonth: creditFacility.controls.isPaidInFullEachMonth.value!,
        });
      }
      return creditFacilities;
    }

    return null;
  };


  mapLivingExpenses(fa: FormArray<FormGroup<ExpenseDetailForm>>): Array<PublicPaymentDetail> | null {
    var expenses: PublicPaymentDetail[] = [];
    if (fa) {
      for (let index = 0; index < fa.length; index++) {
        const expense = fa.controls[index] as unknown as FormGroup<ExpenseDetailForm>;

        expenses.push({
          amount: Number(expense.controls.amount.value),
          description: expense.controls.description.value,
          period: expense.controls.frequency.value!,
          expenseType: expense.controls.type.value!
        });
      }
      return expenses;
    }
    return null;
  }

  mapIncome(employmentDetailsFg: FormGroup<Step3EmploymentDetailsForm>): IncomeDetail | undefined {
    if (employmentDetailsFg.controls.incomeFrequency.value) {
      return {
        amount: Number(employmentDetailsFg.controls.incomeAmount.value),
        isAmountBeforeTax: !employmentDetailsFg.controls.incomeAfterTax.value ? UserAction.Yes : UserAction.No,
        period: employmentDetailsFg.controls.incomeFrequency.value!,
        type: ModelsV3ApplicationFinancialDetailsIncomeType.WageSalary,
        description: ''
      };
    }
    return undefined;
  }

  mapOtherIncome(fa: FormArray<FormGroup<IncomeDetailForm>>): Array<ModelsV3ApplicationFinancialDetailsIncomeDetail> | null {
    var otherIncome: ModelsV3ApplicationFinancialDetailsIncomeDetail[] = [];
    if (fa) {
      for (let index = 0; index < fa.length; index++) {
        const income = fa.controls[index] as unknown as FormGroup<IncomeDetailForm>;

        otherIncome.push({
          amount: Number(income.controls.amount.value),
          description: income.controls.description.value,
          period: income.controls.incomeFrequency.value!,
          isAmountBeforeTax: income.controls.isBeforeTax.value!,
          type: income.controls.type.value!
        });
      }
      return otherIncome;
    }
    return null;
  }

  mapLivingPayment(fgAll: FormGroup<VehicleIndividualForm>): ModelsV3ApplicationFinancialDetailsExpenseDetail {
    var livingPaymentAmount = 0;
    var livingPaymentFrequency = PeriodTypeEnum.None;

    if (fgAll.controls.step1.controls.livingPaymentFrequency.value) {
      livingPaymentFrequency = fgAll.controls.step1.controls.livingPaymentFrequency.value;
    }
    if (fgAll.controls.step1.controls.livingPaymentAmount.value) {
      livingPaymentAmount = fgAll.controls.step1.controls.livingPaymentAmount.value;
    }

    let balance = 0;
    let provider = null;
    let repaymentsSharedWithPartner = UserAction.Unspecified;

    // Set the balance and provider for the home loan if it is not editable as it has been populated using the liabilities section
    var homeLoanFinCom = this.getHomeLoanFinCommitment(fgAll);
    if (homeLoanFinCom != null && homeLoanFinCom.controls.amountPerPeriod.disabled) {
      provider = homeLoanFinCom.controls.provider.value;
      balance = homeLoanFinCom.controls.balance.value!;
      repaymentsSharedWithPartner = homeLoanFinCom.controls.repaymentsSharedWithPartner.value!;
    }

    return {
      amount: livingPaymentAmount,
      balance: balance,
      expenseType: CommonUtilitiesApplicationExpenseType.MortgageOrRent,
      period: livingPaymentFrequency,
      lenderName: provider,
      repaymentsSharedWithPartner: repaymentsSharedWithPartner
    };
  }

  getHomeLoanFinCommitment(fgAll: FormGroup<VehicleIndividualForm>): FormGroup<FinanceCommitmentForm> | null {
    if (fgAll.controls.step1.controls.addressPhysical.controls.livingSituation.value === LivingSituationEnum.Mortgaged) {
      var homeLoanExpenseIndex = fgAll.controls.liabilitiesFg.controls.financeCommitments.controls.findIndex(x => x.controls.type.value === OtherFinanceCommitmentType.HomeLoan);
      if (homeLoanExpenseIndex == 0) {
        return fgAll.controls.liabilitiesFg.controls.financeCommitments.controls[homeLoanExpenseIndex];
      }
    }
    return null;
  }

  formatDateOnly(fgVal: any, dateFormat: string = 'YYYY-MM-DD', defaultValue: string = '1900-01-01') {

    if (moment.isMoment(fgVal)) {
      return fgVal.format('YYYY-MM-DD');
    }
    else {
      return fgVal != '' ? fgVal : defaultValue;
    }
  }

  mapAddress(addressFg: FormGroup<AddressForm>): AddressRequest | undefined {
    if (!addressFg.disabled) {
      return {
        postCode: addressFg.controls.postCode.value!,
        street: addressFg.controls.street.value!,
        streetNumber: addressFg.controls.streetNo.value!,
        //caption: addressFg.controls.caption.value,
        city: addressFg.controls.city.value,
        country: addressFg.controls.country.value!,
        durationMonths: Number(addressFg.controls.durationMonths.value),
        livingSituation: addressFg.controls.livingSituation.value!,
        region: addressFg.controls.region.value,
        suburb: addressFg.controls.suburb.value
      };
    }
    return undefined;
  }

  verifyInitSubmitting: boolean = false;
  async verifyInitIdentity(otpEventArgs: OtpEventArgs) {
    if (this.verifyInitSubmitting) return;

    this.verifyInitSubmitting = true;

    this.messageService.setLoadingState(true);
    var verified = false;
    try {
      verified = await this.vehicleAppService.verifyInitIdentity(otpEventArgs);
    }
    catch (error) {
      console.log('Failed to verify identity');
      this.stepOtpComponent.showOtpValidationError();
    }
    finally
    {
      this.verifyInitSubmitting = false;
      this.messageService.setLoadingState(false);
    }
  }

  handleLoanTypeRemoveApplicant(individualIndex: number) {
    // Clear any previously added individuals due to the individuals changing, and it is tricky to track the index order
    // of which one is remvoed especially in regards to directors and married links
    if(this.vehicleAppService.vehicleApplicationFg?.indivFormArray){
      this.vehicleAppService.vehicleApplicationFg.indivFormArray = null;
    }
    // if (this.vehicleAppService.vehicleApplicationFg?.indivFormArray && this.vehicleAppService.vehicleApplicationFg.indivFormArray.length > individualIndex) {

    //   // check to see if the individual being remove has been assigned as a partner to another individual
    //   var indivToRemove = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[individualIndex] as FormGroup<VehicleIndividualForm>;
    //   var partnerIndex = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls.findIndex(x => x.controls.step2.controls.partnerIdentifier.value == indivToRemove.controls.common.controls.id.value);

    //   if (partnerIndex >= 0) {
    //     var partner = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[partnerIndex] as FormGroup<VehicleIndividualForm>;
    //     partner.controls.step2.controls.isPartnerOnApplication.setValue(UserAction.No);
    //     partner.controls.step2.controls.partnerIdentifier.setValue(null);
    //   }

    //   this.vehicleAppService.vehicleApplicationFg.indivFormArray.removeAt(individualIndex);
    // }
  }

  handleLoanTypeStepFinalize(formGroup: FormGroup<LoanTypeForm>) {

    console.log('Received loan type form group:', formGroup);
    if (this.vehicleAppService.vehicleApplicationFg) {

      // Build the individual steps
      if (!this.vehicleAppService.vehicleApplicationFg.indivFormArray) {
        this.vehicleAppService.setTotalStepsActual();
        this.vehicleAppService.vehicleApplicationFg.indivFormArray = new FormArray<FormGroup<VehicleIndividualForm>>([]);
      }

      // Add the entity form group
      if (formGroup.controls.loanEntityType.value == LoanEntityType.Company || formGroup.controls.loanEntityType.value == LoanEntityType.Trust ||
        formGroup.controls.loanEntityType.value == LoanEntityType.Other) {


        if (this.vehicleAppService.vehicleApplicationFg.entityFg == null) {
          this.vehicleAppService.totalSteps += 2;
          this.vehicleAppService.vehicleApplicationFg.entityFg = new FormGroup<EntityForm>({
            commonFg: this.formBuilder.group<EntityCommonForm>({
              entityType: new FormControl(formGroup.controls.loanEntityType.value)
            }),
            contactDetailFg: this.formBuilder.group<EntityContactDetailForm>({
              addressPostalIsSame: new FormControl(true, Validators.required),
              addressPhysical: this.vehicleAppService.buildAddressFg({} as AddressRequest, false, false),
              addressPostal: this.vehicleAppService.buildAddressFg({} as AddressRequest, false, false),
              // emailAddress: new FormControl('', Validators.compose([Validators.required, Validators.email])),
              // mobileNumber: new FormControl('', Validators.compose([Validators.required, phoneNumberValidator()])),
              // daytimeNumber: new FormControl('', Validators.compose([phoneNumberValidator()])),
            }),
            incomeExpensesFg: this.formBuilder.group<EntityIncomeExpensesForm>({
              isBusinessTrading: new FormControl(null, Validators.required),
              tradingName: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
              industryClassification: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),

              expenseAmount: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
              expenseFrequency: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
              incomeAmount: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
              incomeFrequency: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
              isIncomeOffshore: new FormControl(null, conditionalRequiredValidator('isBusinessTrading', UserAction.Yes)),
            }),
          });
          this.vehicleAppService.setEntityType(formGroup.controls.loanEntityType.value);
        }
      }

      // Build the individual/guarantor steps
      // get the last index of the individuals array
      var nextId = 0;
      var lastIndividualIndex = this.vehicleAppService.vehicleApplicationFg.indivFormArray.length - 1;
      if (lastIndividualIndex >= 0) {
        var lastIndividual = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[lastIndividualIndex];
        nextId = lastIndividual.controls.common.controls.id.value!;
      }

      for (let index = 0; index < formGroup.controls.individuals.length; index++) {

        const fgApplicant = formGroup.controls.individuals.controls[index] as FormGroup<LoanTypeIndividualForm>;
        if (this.vehicleAppService.vehicleApplicationFg.indivFormArray.length <= index) {
          this.vehicleAppService.vehicleApplicationFg.indivFormArray.push(this.buildIndividualFg(this.loanApplicationResponse, index,
            formGroup.controls.loanEntityType.value!, fgApplicant.controls.contactType.value!, nextId));
          nextId += 1;
        }

        // find the existing form group in the indivFormArray by index
        const fgExisting = this.vehicleAppService.vehicleApplicationFg.indivFormArray.controls[index];
        fgExisting.patchValue({
          step1: {
            firstName: fgApplicant.controls.firstName.value,
            lastName: fgApplicant.controls.lastName.value,
            middleName: fgApplicant.controls.middleName.value,
            emailAddress : (fgApplicant.controls.email.value != '' ? fgApplicant.controls.email.value  : fgExisting.controls.step1.controls.emailAddress.value),
            mobileNumber : (fgApplicant.controls.mobile.value != '' ? fgApplicant.controls.mobile.value  : fgExisting.controls.step1.controls.mobileNumber.value),
          },
          step2: {
            birthDate: (fgApplicant.controls.birthDate.value != '' ? fgApplicant.controls.birthDate.value  : fgExisting.controls.step2.controls.birthDate.value),
          }
        });



        if (index == 0) {

          if (formGroup.controls.loanEntityType.value == LoanEntityType.SoleTrader) {
            fgExisting.controls.step3.controls.employmentStatus.setValue(EmploymentStatus.SelfEmployed);
            fgExisting.controls.step3.controls.employmentStatus.disable();
          } else {
            fgExisting.controls.step3.controls.employmentStatus.enable();
          }

          if (fgExisting.controls.step1.controls.mobileNumber.value == '' && this.vehicleAppService.otpEventArgs) {
            fgExisting.controls.step1.controls.mobileNumber.setValue(this.vehicleAppService.otpEventArgs.mobile);
          }
          if (fgExisting.controls.step1.controls.emailAddress.value == '' && this.vehicleAppService.otpEventArgs) {
            fgExisting.controls.step1.controls.emailAddress.setValue(this.vehicleAppService.otpEventArgs.email);
          }
        }
      }

    }
  }


  buildIndividualFg(app: ApplicationUpdateVehicleResponse, individualIndex: number, loanEntityType: LoanEntityType, contactType: ContactType,
    id: number
  ): FormGroup<VehicleIndividualForm> {
    var fgPhysical = this.vehicleAppService.buildAddressFg({} as AddressRequest, true, true);

    var fg = this.formBuilder.group<VehicleIndividualForm>({
      common: this.formBuilder.group<IndividualCommonDataForm>({
        entityType: new FormControl(loanEntityType),
        id: new FormControl(id),
      }),
      step1: this.formBuilder.group<Step1PersonalDetailsForm>({
        contactType: new FormControl(contactType),
        firstName: new FormControl('', Validators.required),
        middleName: new FormControl(''),
        lastName: new FormControl('', Validators.required),
        emailAddress: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        mobileNumber: new FormControl('', Validators.compose([Validators.required, phoneNumberValidator()])),
        daytimeNumber: new FormControl('', Validators.compose([phoneNumberValidator()])),
        livingPaymentAmount: new FormControl(null, livingPaymentAmountValidator(fgPhysical.controls.livingSituation)),
        livingPaymentFrequency: new FormControl(null, conditionalRequiredValidatorMulti2(fgPhysical, 'livingSituation', [null, LivingSituationEnum.HomeownerWithoutMortgage], true)),
        addressPostalIsSame: new FormControl(true, Validators.required),
        addressPhysical: fgPhysical,
        addressPostal: this.vehicleAppService.buildAddressFg({} as AddressRequest, false, false),
        addressPrevious: this.vehicleAppService.buildAddressFg({} as AddressRequest, false, false),
        isAddressProvidedByPartner: new FormControl({value: null, disabled: true}),
      }),
      step2: this.formBuilder.group<Step2PersonalIdentificationForm>({
        identificationType: new FormControl(IdentificationTypeEnum.NzDriverLicense, Validators.required),
        driverLicenceNo: new FormControl('', Validators.compose([conditionalRequiredValidator('identificationType', IdentificationTypeEnum.NzDriverLicense), nzDriversLicenceValidator()])),
        driverLicenceVersion: new FormControl('', Validators.compose([conditionalRequiredValidator('identificationType', IdentificationTypeEnum.NzDriverLicense), numericRangeValidator(100, 999)])),
        gender: new FormControl(null, Validators.required),
        maritalStatus: new FormControl(null, Validators.required),
        dependentsNo: new FormControl(null, Validators.required),
        birthDate: new FormControl(null, [Validators.required, dateValidatorMoment()]),

        nzResident: new FormControl(null, Validators.required),
        visaType: new FormControl(null, conditionalRequiredValidator('nzResident', UserAction.No)),
        visaNumber: new FormControl('', conditionalRequiredValidatorMultiParentDependent('nzResident', [UserAction.No], ApplicationFormControlNames.VisaType, [NonResidentVisaTypeEnum.WorkingVisa, NonResidentVisaTypeEnum.OtherVisa])),
        visaExpiryDate: new FormControl('', conditionalRequiredValidatorMultiParentDependent('nzResident', [UserAction.No], ApplicationFormControlNames.VisaType, [NonResidentVisaTypeEnum.WorkingVisa, NonResidentVisaTypeEnum.OtherVisa])),
        visaStartDate: new FormControl('', conditionalRequiredValidatorMultiParentDependent('nzResident', [UserAction.No], ApplicationFormControlNames.VisaType, [NonResidentVisaTypeEnum.WorkingVisa, NonResidentVisaTypeEnum.OtherVisa])),
        birthCountry: new FormControl(null, conditionalRequiredValidator('nzResident', UserAction.No)),
        citizenshipCountry: new FormControl(null, conditionalRequiredValidator('nzResident', UserAction.No)),

        isPartnerOnApplication: new FormControl(null, conditionalRequiredValidatorMulti('maritalStatus', [MaritalStatus.Married, MaritalStatus.DeFacto])),
        partnerIdentifier: new FormControl(null, conditionalRequiredValidatorMultiParentDependent('maritalStatus', [MaritalStatus.Married, MaritalStatus.DeFacto], 'isPartnerOnApplication', [UserAction.Yes])),

        passportNzExpiryDate: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.NzPassport)),
        passportNzNumber: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.NzPassport)),

        passportOtherExpiryDate: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.OverseasPassport)),
        passportOtherNumber: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.OverseasPassport)),
        passportOtherIssuerCountry: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.OverseasPassport)),

        otherIdType: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.Other)),
        otherIdDescription: new FormControl({value:null, disabled:true }, conditionalRequiredValidatorMultiParentDependent('otherIdType', [IdentificationTypeOtherEnum.Other], 'identificationType', [IdentificationTypeEnum.Other])),
        otherIdNumber: new FormControl(null, conditionalRequiredValidator('identificationType', IdentificationTypeEnum.Other)),
      }),
      step3: this.formBuilder.group<Step3EmploymentDetailsForm>({
        employmentStatus: new FormControl(null, Validators.required),
        employmentBasis: new FormControl(null, employmentSectionRequiredValidator()),
        industryClassification: new FormControl(null, employmentSectionRequiredValidator()),
        durationMonths: new FormControl(null, employmentSectionRequiredValidator()),
        employerName: new FormControl('', employmentSectionRequiredValidator()),
        hasOtherIncome: new FormControl(null, Validators.required),
        hasStudentLoan: new FormControl(null, Validators.required),
        incomeAmount: new FormControl(null, incomeSectionRequiredValidator()),
        incomeAfterTax: new FormControl(true),
        incomeFrequency: new FormControl(null, incomeSectionRequiredValidator()),
        kiwiSaverRate: new FormControl(null, employmentSectionRequiredValidator()),
        otherEmploymentDescription: new FormControl(''),
        otherIncome: this.formBuilder.array<FormGroup<IncomeDetailForm>>([]),
        role: new FormControl(null, employmentSectionRequiredValidator()),
        unemploymentStatus: new FormControl(null, conditionalRequiredValidator('employmentStatus', EmploymentStatus.Unemployed)),
        isStudentEmployed: new FormControl(null, conditionalRequiredValidator('employmentStatus', EmploymentStatus.Student)),
        studentReceivesAllowance: new FormControl(null, conditionalRequiredValidator('employmentStatus', EmploymentStatus.Student)),
        hasIncomeSource: new FormControl(null),
      }),
      step4: this.formBuilder.group<Step4LivingExpensesForm>({
        understandsBankStatReq: new FormControl(null, Validators.requiredTrue),
        hasOtherExpenses: new FormControl(null, Validators.required),
        otherExpenses: this.formBuilder.array<FormGroup<ExpenseDetailForm>>([]),
        expensesSharedWithPartner: new FormControl({ value: null, disabled: true }, conditionalRequiredValidatorMultiParentDependent('maritalStatus', [MaritalStatus.Married, MaritalStatus.DeFacto], 'isPartnerOnApplication', [UserAction.Yes])),
        partnerGrossIncome: new FormControl(null, Validators.compose([conditionalRequiredValidator('expensesSharedWithPartner', UserAction.Yes), numericRangeValidator(0, 10000000)])),
        hasNonSharedPartnerExpenses: new FormControl(null, conditionalRequiredValidatorMultiParentDependent('maritalStatus', [MaritalStatus.Married, MaritalStatus.DeFacto], 'isPartnerOnApplication', [UserAction.Yes])),
      }),
      step5: this.formBuilder.group<Step5AssetsForm>({
        isPropertyOwner: new FormControl(null, Validators.required),
        hasAdditionalCashInvestments: new FormControl(null, Validators.required),
        hasAdditionalVehicleAssets: new FormControl(null, Validators.required),
        hasOtherAssets: new FormControl(null, Validators.required),
        otherAssets: this.formBuilder.array<FormGroup<AssetOtherForm>>([]),
        propertyAssets: this.formBuilder.array<FormGroup<AssetPropertyForm>>([]),
        vehicleAssets: this.formBuilder.array<FormGroup<AssetVehicleForm>>([]),
        cashInvestmentAssets: this.formBuilder.array<FormGroup<AssetCashForm>>([]),
      }),
      liabilitiesFg: this.formBuilder.group<LiabilitiesForm>({
        hasCreditFacilities: new FormControl(null, Validators.required),
        hasOtherFinancialCommitments: new FormControl(null, Validators.required),
        creditFacilities: this.formBuilder.array<FormGroup<CreditFacilityForm>>([]),
        financeCommitments: this.formBuilder.array<FormGroup<FinanceCommitmentForm>>([]),
      }),
    });



    var step1Fg = (fg.get('step1') as FormGroup);
    (fgPhysical.controls.durationMonths)
      .valueChanges.subscribe(value => {
        if (value && value > 0 && value < 24) {
          step1Fg.setControl(ApplicationFormControlNames.AddressPrevious, this.vehicleAppService.buildAddressFg({} as AddressRequest));
        } else {
          step1Fg.setControl(ApplicationFormControlNames.AddressPrevious, null);
        }
      });

    fg.controls.step4.controls.expensesSharedWithPartner = new FormControl(null, conditionalRequiredValidator2(
      [{ control: fg.controls.step2.controls.maritalStatus, expectedValues: [MaritalStatus.Married, MaritalStatus.DeFacto] }]
    ));


    fg.controls.step1.controls.isAddressProvidedByPartner = new FormControl(null, conditionalRequiredValidator2(
      [
        { control: fg.controls.step2.controls.maritalStatus, expectedValues: [MaritalStatus.Married, MaritalStatus.DeFacto] },
        { control: fg.controls.step2.controls.isPartnerOnApplication, expectedValues: [UserAction.Yes] }
      ]
    ));

    // Required when expenses are shared, but the partner is not on the application
    fg.controls.step4.controls.partnerGrossIncome = new FormControl(null, conditionalRequiredValidator2(
      [{ control: fg.controls.step4.controls.expensesSharedWithPartner, expectedValues: [UserAction.Yes] },
      { control: fg.controls.step2.controls.isPartnerOnApplication, expectedValues: [UserAction.No] }]
    ));



    // //Step 2 - Identification
    // fg.controls.step2.controls.identPassportNz.controls.passportNo = new FormControl('', conditionalRequiredValidator2(
    //   [{ control: fg.controls.step2.controls.identificationType, expectedValues: [IdentificationTypeEnum.NzPassport] }]
    // ));
    // fg.controls.step2.controls.identPassportNz.controls.expiryDate = new FormControl('', conditionalRequiredValidator2(
    //   [{ control: fg.controls.step2.controls.identificationType, expectedValues: [IdentificationTypeEnum.NzPassport] }]
    // ))

    this.vehicleAppService.totalSteps += 6;
    return fg;
  }



  mapBooleanToUserAction(value: boolean): UserAction {
    return value ? UserAction.Yes : UserAction.No;
  }
}
