/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthChallengeResponse as ModelsAuthenticationAuthChallengeResponse } from '../models/Models/Authentication/auth-challenge-response';
import { AuthLoginResponse as ModelsAuthenticationAuthLoginResponse } from '../models/Models/Authentication/auth-login-response';
import { AuthTokenResponse as ModelsAuthenticationAuthTokenResponse } from '../models/Models/Authentication/auth-token-response';
import { v3TokenAuthorizePost$Json } from '../fn/token-authorization/v-3-token-authorize-post-json';
import { V3TokenAuthorizePost$Json$Params } from '../fn/token-authorization/v-3-token-authorize-post-json';
import { v3TokenAuthorizePost$Plain } from '../fn/token-authorization/v-3-token-authorize-post-plain';
import { V3TokenAuthorizePost$Plain$Params } from '../fn/token-authorization/v-3-token-authorize-post-plain';
import { v3TokenGet$Json } from '../fn/token-authorization/v-3-token-get-json';
import { V3TokenGet$Json$Params } from '../fn/token-authorization/v-3-token-get-json';
import { v3TokenGet$Plain } from '../fn/token-authorization/v-3-token-get-plain';
import { V3TokenGet$Plain$Params } from '../fn/token-authorization/v-3-token-get-plain';
import { v3TokenPost$Json } from '../fn/token-authorization/v-3-token-post-json';
import { V3TokenPost$Json$Params } from '../fn/token-authorization/v-3-token-post-json';
import { v3TokenPost$Plain } from '../fn/token-authorization/v-3-token-post-plain';
import { V3TokenPost$Plain$Params } from '../fn/token-authorization/v-3-token-post-plain';

@Injectable({ providedIn: 'root' })
export class TokenAuthorizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v3TokenGet()` */
  static readonly V3TokenGetPath = '/v3/token';

  /**
   * Get the access token using a valid refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3TokenGet$Plain$Response(params: V3TokenGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthTokenResponse>> {
    return v3TokenGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the access token using a valid refresh token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3TokenGet$Plain(params: V3TokenGet$Plain$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthTokenResponse> {
    return this.v3TokenGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthTokenResponse>): ModelsAuthenticationAuthTokenResponse => r.body)
    );
  }

  /**
   * Get the access token using a valid refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3TokenGet$Json$Response(params: V3TokenGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthTokenResponse>> {
    return v3TokenGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the access token using a valid refresh token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v3TokenGet$Json(params: V3TokenGet$Json$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthTokenResponse> {
    return this.v3TokenGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthTokenResponse>): ModelsAuthenticationAuthTokenResponse => r.body)
    );
  }

  /** Path part for operation `v3TokenPost()` */
  static readonly V3TokenPostPath = '/v3/token';

  /**
   * Login and get refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenPost$Plain$Response(params?: V3TokenPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>> {
    return v3TokenPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Login and get refresh token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenPost$Plain(params?: V3TokenPost$Plain$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthLoginResponse> {
    return this.v3TokenPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>): ModelsAuthenticationAuthLoginResponse => r.body)
    );
  }

  /**
   * Login and get refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenPost$Json$Response(params?: V3TokenPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>> {
    return v3TokenPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Login and get refresh token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenPost$Json(params?: V3TokenPost$Json$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthLoginResponse> {
    return this.v3TokenPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthLoginResponse>): ModelsAuthenticationAuthLoginResponse => r.body)
    );
  }

  /** Path part for operation `v3TokenAuthorizePost()` */
  static readonly V3TokenAuthorizePostPath = '/v3/token/authorize';

  /**
   * Authorize - Get the auth code for the challenge verification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenAuthorizePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenAuthorizePost$Plain$Response(params?: V3TokenAuthorizePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthChallengeResponse>> {
    return v3TokenAuthorizePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Authorize - Get the auth code for the challenge verification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenAuthorizePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenAuthorizePost$Plain(params?: V3TokenAuthorizePost$Plain$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthChallengeResponse> {
    return this.v3TokenAuthorizePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthChallengeResponse>): ModelsAuthenticationAuthChallengeResponse => r.body)
    );
  }

  /**
   * Authorize - Get the auth code for the challenge verification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v3TokenAuthorizePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenAuthorizePost$Json$Response(params?: V3TokenAuthorizePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelsAuthenticationAuthChallengeResponse>> {
    return v3TokenAuthorizePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Authorize - Get the auth code for the challenge verification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v3TokenAuthorizePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  v3TokenAuthorizePost$Json(params?: V3TokenAuthorizePost$Json$Params, context?: HttpContext): Observable<ModelsAuthenticationAuthChallengeResponse> {
    return this.v3TokenAuthorizePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelsAuthenticationAuthChallengeResponse>): ModelsAuthenticationAuthChallengeResponse => r.body)
    );
  }

}
