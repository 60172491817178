/* tslint:disable */
/* eslint-disable */
export enum ReferralMethodEnum {
  None = 'None',
  YouTube = 'YouTube',
  Brochure = 'Brochure',
  Tv = 'Tv',
  Email = 'Email',
  Radio = 'Radio',
  Facebook = 'Facebook',
  StoreWindows = 'StoreWindows',
  FriendOrFamily = 'FriendOrFamily',
  GoogleAdvertising = 'GoogleAdvertising',
  Other = 'Other'
}
