import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ListItem } from '../types/listItem';
import { PublicApplicationService, PublicVehicleApplicationService } from '../api/services';
import { V3PublicApplicationLookupsApplicationIdCountriesGet$Json$Params } from '../api/fn/public-application/v-3-public-application-lookups-application-id-countries-get-json';
import { VehicleAppService } from './vehicle-app.service';
import { ErrorService } from './error.service';
import { LookupInternalResponse } from '../api/models/Models/v3/Common/lookup-internal-response';
import { AutocompleteAddress } from '../api/models/Models/Customer/autocomplete-address';
import { ModelsCustomerNzbnCompany, ModelsCustomerNzbnCompanyDetails, ModelsCustomerNzbnCompanyDetailsBaseResponse, ModelsCustomerNzbnCompanyResponse, ModelsCustomerNzbnCompanyResponseBaseResponse } from '../api/models';

@Injectable({
  providedIn: 'root',
  deps: [PublicApplicationService, VehicleAppService, ErrorService]
})
export class LookupService
{

  constructor(
    private publicApplicationService: PublicApplicationService,
    private vehicleAppService: VehicleAppService,
    private errorService: ErrorService,

  ) { }

  getCountries(): Observable<ListItem[]>
  {
    return this.publicApplicationService.v3PublicApplicationLookupsApplicationIdCountriesGet$Json({
      applicationId: this.vehicleAppService.newApplicationResponse.publicId!
    }).pipe(
      map(response =>
      {
        // Assuming response.entityData is an array of objects with properties that can be mapped to ListItem
        return response?.entityData?.map((item: LookupInternalResponse) => ({
          value: item.memberCaption,
          displayName: item.displayName,
          valueCaption: item.memberCaption
        })) as unknown as ListItem[];
      })
    );
  }

  getAddressAutocomplete(addressSearch: string): Observable<AutocompleteAddress[]>
  {
    return this.publicApplicationService.v3PublicApplicationApplicationIdGetAddressAutocompleteGet$Json({
      query: addressSearch,
      applicationId: this.vehicleAppService.newApplicationResponse.publicId!
    }).pipe(
      map(response =>
      {
        // console.log(response);
        return response?.entityData?.addresses ?? [];
      })
    );
  }

  getCompanyAutocomplete(companySearch: string): Observable<ModelsCustomerNzbnCompany[]>
  {
    return this.publicApplicationService.v3PublicApplicationApplicationIdGetCompanyAutocompleteGet$Json({
      query: companySearch,
      applicationId: this.vehicleAppService.newApplicationResponse.publicId!
    }).pipe(
      map(response =>
      {
        console.log('getCompanyAutocomplete', response);
        return response?.entityData?.companies ?? [];
      })
    );
  }

  getCompanyDetails(nzbn: string): Observable<ModelsCustomerNzbnCompanyDetails | null>
  {
    return this.publicApplicationService.v3PublicApplicationApplicationIdGetCompanyAutocompleteNzbnGet$Json({
      nzbn: nzbn,
      applicationId: this.vehicleAppService.newApplicationResponse.publicId!
    }).pipe(
      map(response =>
      {
        console.log('getCompanyDetails', response);
        return response?.entityData ?? null;
      })
    );
  }
}
